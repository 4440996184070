import api from '../../../api/apiIndex';
import { CarBrand } from '../../../models/resources/CarBrand';

const dealersEndpoint = '/v2/admin/dealers';

export interface IGetDealers {
  active?: boolean;
  type?: string;
  country?: string;
}
export type ICreateDealerRequest = {
  name: string;
  arabic_name: string;
  city_id: number;
  whatsapp_number: string;
  add_logo: { id: number } | null;
  brand_dealer: CarBrand[];
};

export type IUpdateDealerRequest = {
  name?: string;
  arabic_name?: string;
  city_id?: number;
  brand_dealer?: CarBrand[];
  whatsapp_number?: string;
  add_logo?: { id: number };
  remove_logo?: { url: string };
};

export const getAllDealers = async ({ active, type, country }: IGetDealers) => {
  
  const headers: { country?: string } = {};
  if (country) {
    headers.country = country;
  }

  const response = await api.get(dealersEndpoint, {
    params: { active, type },
    headers,
  });
  return response.data;
};

export const getDealerNames = async () => {
  const response = await api.get(`${dealersEndpoint}/names`);
  return response.data;
};

export const createDealer = async (request: ICreateDealerRequest) => {
  const response = await api.post(`${dealersEndpoint}`, request);
  return response.data;
};

export const updateDealer = async ({
  id,
  request,
}: {
  id: number;
  request: IUpdateDealerRequest;
}) => {
  const response = await api.put(`${dealersEndpoint}/${id}`, request);
  return response.data;
};

export const deleteDealer = async (id: number) => {
  const response = await api.delete(`${dealersEndpoint}/${id}`);
  return response.data;
};

export const activateDealer = async (id: number) => {
  const response = await api.put(`${dealersEndpoint}/activate/${id}`);
  return response.data;
};

export const deactivateDealer = async (id: number) => {
  const response = await api.put(`${dealersEndpoint}/deactivate/${id}`);
  return response.data;
};
