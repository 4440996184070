import { Select } from 'antd';
import { useEffect } from 'react';

interface IBrandSelectProps {
  selectedBrand: number | undefined;
  brandNames: any[] | undefined;
  isLoadingBrandNames: boolean;
  setSelectedBrand: (value: any) => void;
}

export const BrandSelect = ({
  selectedBrand,
  brandNames,
  isLoadingBrandNames,
  setSelectedBrand,
}: IBrandSelectProps) => {
  useEffect(() => {
    if (brandNames && brandNames.length > 0) {
      setSelectedBrand(brandNames[0].id);
    }
  }, [brandNames]);
  return (
    <Select
      showSearch
      placeholder="Select a brand"
      loading={isLoadingBrandNames}
      disabled={isLoadingBrandNames}
      style={{
        width: 200,
        marginBottom: 30,
      }}
      onChange={value => setSelectedBrand(value)}
      optionFilterProp="children"
      value={selectedBrand}
    >
      {brandNames?.map((brand: any) => (
        <Select.Option key={brand.id} value={brand.id}>
          {brand.name}
        </Select.Option>
      ))}
    </Select>
  );
};
