import api from '../../../../api/apiIndex';

const bikeBatteryCapacityEndpoint =
  'v2/admin/resources/bikes/battery-capacities';

export interface IGetBikeBatteryCapacities {
  active?: boolean;
}

export interface IBikeBatteryCapacityPayload {
  name: string;
  arabic_name: string;
  bike_engine_types: Array<number>;
}

export const getBikeBatteryCapacities = async ({
  active,
}: IGetBikeBatteryCapacities) => {
  try {
    const response = await api.get(bikeBatteryCapacityEndpoint, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeBatteryCapacity = async (
  data: IBikeBatteryCapacityPayload,
) => {
  const response = await api.post(bikeBatteryCapacityEndpoint, data);
  return response.data;
};

export const updateBikeBatteryCapacity = async (
  id: number,
  data: IBikeBatteryCapacityPayload,
) => {
  const response = await api.put(`${bikeBatteryCapacityEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeBatteryCapacity = async (id: number) => {
  try {
    const response = await api.delete(`${bikeBatteryCapacityEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeBatteryCapacity = async (id: number) => {
  try {
    const response = await api.put(
      `${bikeBatteryCapacityEndpoint}/activate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeBatteryCapacity = async (id: number) => {
  try {
    const response = await api.put(
      `${bikeBatteryCapacityEndpoint}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
