import { useNavigate } from 'react-router-dom';
import { CTAButton } from '../../../components/buttons/CTAButton';
import Icon from '../../../components/icons/Icon';

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/login');
  };

  return (
    <div className="w-full h-full flex justify-content-center align-items-center">
      <div className="flex flex-column justify-content-center row-gap-10">
        <h1 style={{ color: 'var(--error)' }}>Unauthorized</h1>
        <h3>You don't seem to have enough permissions to access this page!</h3>
        <div className="mt-6" style={{ width: '200px' }}>
          <CTAButton
            leadingIcon={<Icon icon="pi-arrow-left" />}
            text="Go Back"
            onClick={goBack}
          />
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
