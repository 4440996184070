import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Table, Button, Popconfirm, message, Tag, Input } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY,
  useActivateCarOptionalFeature,
  useCarOptionalFeatures,
  useDeactivateCarOptionalFeature,
  useDeleteCarOptionalFeature,
} from '../hooks/useCarOptionalFeatures';
import { useQueryClient } from '@tanstack/react-query';
import { CarOptionalFeatureModal } from './CarOptionalFeatureModal';
import { useEngineTypes } from '../../engine_types/hooks/useEngineType';
import { Layout } from '../../../../components/Layout';
import { useSearch } from '../../../../hooks/useSearch';
import { IOptionalFeature } from '../utils/apiUtils';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';

const { Search } = Input;

const CarOptionalFeaturesLayout = () => {
  const queryClient = useQueryClient();

  // States
  const [open, setOpen] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCarOptionalFeature, setSelectedCarOptionalFeature] = useState<
    IOptionalFeature | undefined
  >(undefined);

  // API Hooks
  const { data: carOptionalFeatures, isLoading: isLoadingCarOptionalFeatures } =
    useCarOptionalFeatures({ enabled: true });

  const { mutateAsync: deleteOptionalFeature, isLoading: isDeleting } =
    useDeleteCarOptionalFeature();
  const { mutateAsync: activateOptionalFeature, isLoading: isActivating } =
    useActivateCarOptionalFeature();
  const { mutateAsync: deactivateOptionalFeature, isLoading: isDeactivating } =
    useDeactivateCarOptionalFeature();

  const { data: carEngineTypes } = useEngineTypes({ enabled: true });
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchCarFeature } = useSearch();
  const [filteredData, setFilteredData] = useState(carOptionalFeatures);

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IOptionalFeature | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateOptionalFeature(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Optional Feature deactivated successfully');
          },
        })
      : activateOptionalFeature(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Optional Feature activated successfully');
          },
        });
  };

  // Search
  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchCarFeature(carOptionalFeatures, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchCarFeature(carOptionalFeatures, searchValue);
    setFilteredData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(carOptionalFeatures);
  }, [carOptionalFeatures]);

  // Column
  const tableColumns: ColumnsType<IOptionalFeature> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (rowData): string => {
        return rowData ? rowData?.toString() : 'N/A';
      },
    },
    {
      title: 'Arabic Name',
      dataIndex: `${carOptionalFeatures?.map(
        (carOptionalFeature: IOptionalFeature) => carOptionalFeature.t[0]?.name,
      )}`,
      key: `${carOptionalFeatures?.map(
        (carOptionalFeature: IOptionalFeature) => carOptionalFeature.t[0]?.name,
      )}`,
      render: (text: any, record: IOptionalFeature) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Used Cars Button',
      dataIndex: `${carOptionalFeatures?.map(
        (carOptionalFeature: IOptionalFeature) =>
          carOptionalFeature.button_state,
      )}`,
      key: `${carOptionalFeatures?.map(
        (carOptionalFeature: IOptionalFeature) =>
          carOptionalFeature.button_state,
      )}`,
      render: (text: any, record: IOptionalFeature) =>
        record.button_state === 'ThreeState' ? (
          <Tag color="cyan">Three State</Tag>
        ) : (
          <Tag color="purple">Two State</Tag>
        ),
    },
    {
      title: 'Sell Your Car Button',
      dataIndex: `${carOptionalFeatures?.map(
        (carOptionalFeature: IOptionalFeature) =>
          carOptionalFeature.website_button_state,
      )}`,
      key: `${carOptionalFeatures?.map(
        (carOptionalFeature: IOptionalFeature) =>
          carOptionalFeature.website_button_state,
      )}`,
      render: (text: any, record: IOptionalFeature) =>
        record.website_button_state === 'ThreeState' ? (
          <Tag color="cyan">Three State</Tag>
        ) : (
          <Tag color="purple">Two State</Tag>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IOptionalFeature) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              onClick={() => {
                setSelectedCarOptionalFeature(rowData);
                handleOpenModal();
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Are you sure you want to delete this optional feature?"
              open={open === rowData.id}
              onConfirm={() => {
                handleDelete(rowData.id);
              }}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpen(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                onClick={() => {
                  setOpen(rowData.id);
                  setSelectedCarOptionalFeature(rowData);
                }}
              />
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this optional feature.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  // Functions
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleDelete = (id: number) => {
    deleteOptionalFeature(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY,
        });
        setSelectedCarOptionalFeature(undefined);
        message.success('Optional feature deleted successfully');
        setOpen(null);
      },
    });
  };

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Here"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />

        <Button type="primary" onClick={handleOpenModal}>
          Add
        </Button>
      </div>

      <Table
        dataSource={filteredData}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
        columns={tableColumns}
        pagination={false}
        loading={
          carOptionalFeatures?.length === 0 || isLoadingCarOptionalFeatures
        }
      />

      <CarOptionalFeatureModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedCarOptionalFeature={selectedCarOptionalFeature}
        setSelectedCarOptionalFeature={setSelectedCarOptionalFeature}
        carEngineTypes={carEngineTypes}
      />
    </Layout>
  );
};

export default CarOptionalFeaturesLayout;
