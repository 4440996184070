export const ChartLoading = () => {
  return (
    <svg
      aria-labelledby="gtc3pam-aria"
      role="img"
      viewBox="0 0 400 180"
      width="200"
      height="90"
    >
      <title id="gtc3pam-aria">Loading...</title>
      <rect
        role="presentation"
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath="url(#gtc3pam-diff)"
        style={{ fill: 'url(#gtc3pam-animated-diff)' }}
        // style='fill: url("#gtc3pam-animated-diff");'
      ></rect>
      <defs role="presentation">
        <clipPath id="gtc3pam-diff">
          <rect x="20" y="5" rx="0" ry="0" width="1" height="170"></rect>
          <rect x="20" y="175" rx="0" ry="0" width="360" height="1"></rect>
          <rect x="40" y="75" rx="0" ry="0" width="35" height="100"></rect>
          <rect x="80" y="125" rx="0" ry="0" width="35" height="50"></rect>
          <rect x="120" y="105" rx="0" ry="0" width="35" height="70"></rect>
          <rect x="160" y="35" rx="0" ry="0" width="35" height="140"></rect>
          <rect x="200" y="55" rx="0" ry="0" width="35" height="120"></rect>
          <rect x="240" y="15" rx="0" ry="0" width="35" height="160"></rect>
          <rect x="280" y="135" rx="0" ry="0" width="35" height="40"></rect>
          <rect x="320" y="85" rx="0" ry="0" width="35" height="90"></rect>
        </clipPath>
        <linearGradient id="gtc3pam-animated-diff">
          <stop offset="0%" stopColor="#D9D9D9" stopOpacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </stop>
          <stop offset="50%" stopColor="#eee" stopOpacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </stop>
          <stop offset="100%" stopColor="#D9D9D9" stopOpacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
