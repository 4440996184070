import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICityPayload,
  activateCity,
  createCity,
  deactivateCity,
  deleteCity,
  getCities,
  updateCity,
} from '../apis';
import { queryClient } from '../../../App';

const ALL_CITIES_KEY = ['allCities'];

interface IUseCities {
  enabled: boolean;
  active?: boolean;
}

export const useCities = ({ enabled, active }: IUseCities) => {
  return useQuery({
    queryKey: ALL_CITIES_KEY,
    queryFn: () => getCities({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCity = () => {
  return useMutation({
    mutationFn: (data: ICityPayload) => createCity(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CITIES_KEY);
    },
  });
};

export const useUpdateCity = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICityPayload }) => {
      return updateCity(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CITIES_KEY);
    },
  });
};

export const useDeleteCity = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deleteCity(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CITIES_KEY);
    },
  });
};

export const useActivateCity = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return activateCity(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CITIES_KEY);
    },
  });
};

export const useDeactivateCity = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deactivateCity(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CITIES_KEY);
    },
  });
};
