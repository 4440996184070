import { Form, Input, Select, Button, Space, Col, Row } from 'antd';
import { Flex } from '../../../../components/Flex';
import { useCountries } from '../../../../countries/hooks/useCountries';
import { ILocationPayload } from '../locationsApi';
import { ILocation } from '../../../../models/locations/Location';
import { useCurrencies } from '../../../currencies/hooks';
import { ICurrency } from '../../../../models/countries/Currency';

const { Item } = Form;

type ILocationFormProps = {
  location: ILocation | null;
  isSubmitting: boolean;
  onSubmit: (values: ILocationPayload) => void;
  onClose: () => void;
  isEditing: boolean;
  isLoading: boolean;
};

export const LocationsForm = ({
  location,
  isSubmitting,
  onSubmit,
  onClose,
  isEditing,
  isLoading,
}: ILocationFormProps) => {
  //States
  const [form] = Form.useForm();

  const iniatialValues = {
    address: location?.address,
    arabic_address: location?.t?.[0]?.address,
    country_id: location?.country_id,
    currency_id: location?.currency_id,
  };

  //APIs
  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies({
    enabled: true,
  });

  const resetForm = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={iniatialValues}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Item
            name="address"
            label="Address"
            rules={[{ required: true, message: 'Please enter an address' }]}
          >
            <Input placeholder="Enter Address" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_address"
            label="Arabic Address"
            rules={[
              {
                required: true,
                message: 'Please enter an Arabic address',
              },
            ]}
          >
            <Input placeholder="Enter Arabic Address" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Item
            name="country_id"
            label="Country"
            rules={[{ required: true, message: 'Please select a country' }]}
          >
            <Select loading={isLoadingCountries} placeholder="Select Country">
              {countries?.map(country => (
                <Select.Option key={country.id} value={country.id}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Currency"
            name="currency_id"
            rules={[
              {
                required: true,
                message: 'Please select currency',
              },
            ]}
          >
            <Select loading={isLoadingCurrencies} placeholder="Select Currency">
              {currencies?.map((currency: ICurrency) => (
                <Select.Option key={currency.id} value={currency.id}>
                  {currency.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Flex justify="flex-end">
        <Space>
          <Button
            danger
            disabled={isLoading}
            onClick={() => {
              form.resetFields();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {isEditing ? 'Edit Location' : 'Add Location'}
          </Button>
        </Space>
      </Flex>
    </Form>
  );
};
