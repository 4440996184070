import { Modal, message } from 'antd';
import { ICarBatteryCapacity } from '../../../../models/resources/ CarBatteryCapacity';
import { BatteryRangeAddEditForm } from '../../car_battery_range/components';
import { useCreateBatteryCapacity, useUpdateBatteryCapacity } from '../hooks';
import { BatteryCapacitiesAddEditForm } from './BatteryCapacitiesAddEditForm';

interface BatteryRangeModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentBatteryCapacity: ICarBatteryCapacity | null;
  setCurrentBatteryCapacity: (value: ICarBatteryCapacity | null) => void;
  engineTypes: any;
}

export const BatteryCapacitiesModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBatteryCapacity,
  setCurrentBatteryCapacity,
  engineTypes,
}: BatteryRangeModalProps) => {
  const { mutateAsync: createBatteryCapacity, isLoading: isCreating } =
    useCreateBatteryCapacity();
  const { mutateAsync: updateBatteryCapacity, isLoading: isUpdating } =
    useUpdateBatteryCapacity();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createBatteryCapacity(data);

      setIsFormOpen(false);
      message.success('Battery Capacity Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateBatteryCapacity({
        id: currentBatteryCapacity!.id,
        data,
      });

      setIsFormOpen(false);
      setCurrentBatteryCapacity(null);
      message.success('Battery Capacity Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Battery Capacity' : 'Add Battery Capacity'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBatteryCapacity?.id}
      >
        <BatteryCapacitiesAddEditForm
          resource={currentBatteryCapacity}
          engineTypes={engineTypes}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
        />
      </Modal>
    </>
  );
};
