import { useEffect, useState } from 'react';
import {
  Image,
  List,
  Space,
  Spin,
  Input,
  Divider,
  Button,
  Popconfirm,
} from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { EmptyState } from '../../../components/emptyState/EmptyState';
import { Flex, FlexCentered, FlexColumn } from '../../../components/Flex';
import { useSearch } from '../../../hooks/useSearch';
import fallBackLogo from '../../../assets/fallback.webp';
import { RentalDealer } from '../../../models/dealers/rentals/RentalDealer';
import { Layout } from '../../../components/Layout';
import {
  ALL_Rental_DEALERS_NAMES_KEY,
  useActivateRentalDealer,
  useDeactivateRentalDealer,
  useDeleteRentalDealers,
  useGetRentalDealers,
} from '../hooks';
import { useQueryClient } from '@tanstack/react-query';
import useAuth from '../../../hooks/useAuth';
import { UserGroups } from '../../../common/constants';
import { RentalDealerModal } from './RentalDealerModal';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import { TabsCountrySwitcher } from '../../../components/TabsCountrySwitcher';

const { Search } = Input;

export const AllRentalDealersLayout = () => {
  const [country, setCountry] = useState('qa');
  const [currentRentalDealer, setCurrentRentalDealer] =
    useState<RentalDealer | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<RentalDealer | null>(
    null,
  );
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<RentalDealer | null>(null);

  // Search States
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchRentalDealers } = useSearch();

  const { data: rentalDealers, isLoading: isLoadingRentalDealers } =
    useGetRentalDealers({ country });

  const [filteredData, setFilteredData] = useState(
    rentalDealers
      ? Array.isArray(rentalDealers)
        ? rentalDealers
        : [rentalDealers]
      : [],
  );

  const { mutateAsync: deleteRentalDealer, isLoading: isDeleting } =
    useDeleteRentalDealers();
  const { mutateAsync: activateRentalDealer, isLoading: isActivating } =
    useActivateRentalDealer();
  const { mutateAsync: deactivateRentalDealer, isLoading: isDeactivating } =
    useDeactivateRentalDealer();

  const queryClient = useQueryClient();

  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isSuperAdmin = userGroup === UserGroups.OAC;

  const openEmptyForm = () => {
    setIsFormOpen(true);
    setCurrentRentalDealer(null);
    setIsEditing(false);
  };

  const openEditForm = (rental: RentalDealer) => {
    setCurrentRentalDealer(rental);
    setIsFormOpen(true);
    setIsEditing(true);
  };

  const handleDelete = (id: number) => {
    deleteRentalDealer(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ALL_Rental_DEALERS_NAMES_KEY,
        });
        setOpenDeleteDialog(null);
      },
    });
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateRentalDealer(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ALL_Rental_DEALERS_NAMES_KEY,
            });
            setOpenToggleActivateDialog(null);
          },
        })
      : activateRentalDealer(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ALL_Rental_DEALERS_NAMES_KEY,
            });
            setOpenToggleActivateDialog(null);
          },
        });
  };

  useEffect(() => {
    const newData = handleSearchRentalDealers(
      rentalDealers
        ? Array.isArray(rentalDealers)
          ? rentalDealers
          : [rentalDealers]
        : [],
      searchValue,
    );
    setFilteredData(newData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(
      rentalDealers
        ? Array.isArray(rentalDealers)
          ? rentalDealers
          : [rentalDealers]
        : [],
    );
  }, [rentalDealers]);

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5 align-items-center">
        <Search
          placeholder="Search Rental Dealers"
          onSearch={value => setSearchValue(value)}
          style={{ width: 230 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <TabsCountrySwitcher
          setCountry={setCountry}
          style={{ paddingRight: '250px' }}
        />
        {isSuperAdmin && (
          <div className="align-self-end" style={{ width: 'fit-content' }}>
            <Button
              style={{ backgroundColor: 'var(--primary-color)' }}
              type="primary"
              onClick={openEmptyForm}
            >
              New
            </Button>
          </div>
        )}
      </div>
      {isLoadingRentalDealers ? (
        <div
          className="w-full p-5 flex justify-content-center align-items-center"
          style={{ height: '400px' }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {filteredData && filteredData?.length > 0 ? (
            <List
              style={{
                padding: '0 1rem',
              }}
              grid={{
                gutter: 16,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 2,
                xxl: 3,
              }}
              dataSource={filteredData}
              renderItem={(rental: RentalDealer) => (
                <List.Item
                  key={rental.id}
                  style={{ borderBottom: '1px solid #e8e8e8' }}
                >
                  <List.Item.Meta key={rental.id} />
                  <FlexCentered
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '2rem',
                    }}
                  >
                    <Flex style={{ alignItems: 'center' }}>
                      <Space size={'middle'}>
                        <Image
                          src={rental.logo}
                          width={120}
                          height={50}
                          preview={false}
                          style={{
                            objectFit: 'contain',
                          }}
                          fallback={fallBackLogo}
                          alt="fallback image"
                        />
                        <FlexColumn>{rental.name}</FlexColumn>
                      </Space>
                    </Flex>
                    <Space split={<Divider type="vertical" />}>
                      {isSuperAdmin && (
                        <Popconfirm
                          title="Are you sure?"
                          description={`Clicking "Yes" will ${
                            rental.is_active ? 'deactivate' : 'activate'
                          } this rental dealer.`}
                          open={openToggleActivateDialog === rental}
                          onConfirm={() =>
                            handleToggleActivate(rental.id, rental.is_active)
                          }
                          okButtonProps={{
                            loading: isActivating || isDeactivating,
                          }}
                          onCancel={() => setOpenToggleActivateDialog(null)}
                          okText="Yes"
                          placement="topRight"
                        >
                          {rental.is_active ? (
                            <InactiveIcon
                              onClick={() => {
                                setOpenToggleActivateDialog(rental);
                              }}
                            />
                          ) : (
                            <ActiveIcon
                              onClick={() => {
                                setOpenToggleActivateDialog(rental);
                              }}
                            />
                          )}
                        </Popconfirm>
                      )}
                      <EditTwoTone onClick={() => openEditForm(rental)} />
                      {isSuperAdmin && (
                        <Popconfirm
                          title="Are you sure?"
                          description="Deleting this rental dealer will delete all its related data."
                          open={openDeleteDialog === rental}
                          onConfirm={() => handleDelete(rental.id)}
                          okButtonProps={{ loading: isDeleting }}
                          onCancel={() => setOpenDeleteDialog(null)}
                          okText="Yes"
                          placement="topRight"
                        >
                          <DeleteTwoTone
                            style={{ fontSize: '1rem', color: 'var(--error)' }}
                            onClick={() => {
                              setOpenDeleteDialog(rental);
                            }}
                          />
                        </Popconfirm>
                      )}
                    </Space>
                  </FlexCentered>
                </List.Item>
              )}
            />
          ) : (
            <EmptyState type="dealers" />
          )}
          <RentalDealerModal
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            currentRentalDealer={currentRentalDealer}
            setCurrentRentalDealer={setCurrentRentalDealer}
          />
        </>
      )}
    </Layout>
  );
};
