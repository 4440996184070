import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';

interface IBreadCrumbItem {
  key: string;
  title: string;
  href?: string;
}

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const items = location.pathname.split('/').filter(item => item !== '');
  const breadCrumbItems: IBreadCrumbItem[] =
    items.map((item, index) => {
      const pathToItem = `/${items.slice(0, index + 1).join('/')}`;
      return {
        key: item,
        title: item.charAt(0).toUpperCase() + item.slice(1),
        href: pathToItem,
      };
    }) || [];

  breadCrumbItems.unshift({
    key: 'home',
    href: '/',
    title: 'Home',
  });

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        {breadCrumbItems.map(item => (
          <Breadcrumb.Item key={item.key}>
            {item.href ? <Link to={item.href}>{item.title}</Link> : item.title}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      {children}
    </>
  );
};
