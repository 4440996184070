import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateNewBikeLead,
  IEditNewBikeLead,
  createNewBikeLead,
  deleteNewBikeLead,
  editNewBikeLead,
  getNewBikeLeads,
  getNewBikeLeadsDetails,
} from '../apis';
import { queryClient } from '../../../App';

interface IUseLeadsDetails {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

export const useNewBikeLeads = () => {
  return useQuery({
    queryKey: ['newBikeLeads'],
    queryFn: () => getNewBikeLeads(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useNewBikeLeadsDetail = ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: IUseLeadsDetails) => {
  return useQuery({
    queryKey: ['newBikeLeadsDetails', dealerSlug, brandSlug, modelSlug],
    queryFn: () =>
      getNewBikeLeadsDetails({
        dealerSlug,
        brandSlug,
        modelSlug,
      }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useCreateNewBikeLead = () => {
  return useMutation({
    mutationFn: (rentalDealer: ICreateNewBikeLead) =>
      createNewBikeLead(rentalDealer),
    mutationKey: ['createNewBikeLead'],
    onSuccess: () => {
      queryClient.invalidateQueries(['newBikeLeads']);
      queryClient.invalidateQueries(['newBikeLeadsDetails']);
    },
  });
};

export const useEditNewBikeLead = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEditNewBikeLead }) =>
      editNewBikeLead({ id, data }),
    mutationKey: ['editNewBikeLead'],
    onSuccess: () => {
      queryClient.invalidateQueries(['newBikeLeads']);
      queryClient.invalidateQueries(['newBikeLeadsDetails']);
    },
    onError: (error: any) => {
      console.log(error);
    },
  });
};

export const useDeleteNewBikeLead = () => {
  return useMutation({
    mutationFn: (id: number) => deleteNewBikeLead(id),
    mutationKey: ['deleteNewBikeLead'],
    onSuccess: () => {
      queryClient.invalidateQueries(['newBikeLeads']);
      queryClient.invalidateQueries(['newBikeLeadsDetails']);
    },
  });
};
