import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeModelPayload,
  activateBikeModel,
  createBikeModel,
  deactivateBikeModel,
  deleteBikeModel,
  getBikeModels,
  getBikeModelBySlug,
  updateBikeModel,
} from '../apis';
import { queryClient } from '../../../../App';

export interface IUseGetBikeModels {
  enabled: boolean;
  dealerId?: number;
  brandId?: number;
  active?: boolean;
  type?: string;
  country?: string;
}

export const BIKE_MODELS_QUERY_KEY = ['bike_models'];

export const BIKE_MODEL_SLUG_QUERY_KEY = ['bike_model_by_slug'];

export const useGetBikeModels = ({
  enabled,
  dealerId,
  brandId,
  active,
  type,
}: IUseGetBikeModels) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [...BIKE_MODELS_QUERY_KEY, dealerId, brandId, active],
    queryFn: () => getBikeModels({ dealerId, brandId, active, type }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};

export const useGetBikeModelBySlug = (slug: string) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [...BIKE_MODEL_SLUG_QUERY_KEY, slug],
    queryFn: () => getBikeModelBySlug(slug),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!slug,
  });
};

export const useDeleteBikeModel = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeModel(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODELS_QUERY_KEY);
    },
  });
};

export const useActivateBikeModel = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeModel(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODELS_QUERY_KEY);
    },
  });
};

export const useDeactivateBikeModel = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeModel(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODELS_QUERY_KEY);
    },
  });
};

export const useCreateBikeModel = () => {
  return useMutation({
    mutationFn: (data: IBikeModelPayload) => createBikeModel(data),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODELS_QUERY_KEY);
    },
  });
};

export const useEditBikeModel = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeModelPayload }) =>
      updateBikeModel(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODELS_QUERY_KEY);
    },
  });
};
