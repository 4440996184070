import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import { IBikeBatteryRange } from '../../../../models/resources/bikes/BikeBatteryRange';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { IBikeBatteryRangePayload } from '../apis';

interface BikeBatteryRangesAddEditFormProps {
  bikeBatteryRange: IBikeBatteryRange | null;
  isEditing: boolean;
  bikeEngineTypes: IBikeEngineType[] | null;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (values: IBikeBatteryRangePayload) => Promise<void>;
}

const { Item } = Form;
const CheckboxGroup = Checkbox.Group;
export const BikeBatteryRangesAddEditForm = ({
  bikeBatteryRange,
  isEditing,
  bikeEngineTypes,
  onClose,
  isLoading,
  onSubmit,
}: BikeBatteryRangesAddEditFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: bikeBatteryRange?.name,
    arabic_name: bikeBatteryRange?.t?.[0]?.name,
    bike_engine_types:
      bikeBatteryRange?.bike_battery_range_bike_engine_type?.map(
        bikeEngineType => bikeEngineType?.bike_engine_types?.id,
      ),
  };

  return (
    <Form
      form={form}
      onFinish={async (values: IBikeBatteryRangePayload) => {
        try {
          await onSubmit(values);
        } catch (error) {
          console.error(error);
        }
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input battery Range name!',
              },
            ]}
          >
            <Input placeholder="Battery Range Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please input battery Range arabic name!',
              },
            ]}
          >
            <Input placeholder="Battery Range Arabic Name" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="bike_engine_types"
            label="Engine Type"
            rules={[{ required: true, message: 'Please select engine type' }]}
          >
            <CheckboxGroup>
              {bikeEngineTypes?.map(bikeEngineType => (
                <Checkbox value={bikeEngineType?.id}>
                  {bikeEngineType?.name}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
