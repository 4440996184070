import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICarTrimPayload,
  getTrims,
  createTrim,
  updateTrim,
  deleteTrim,
  activateTrim,
  deactivateTrim,
} from '../apis';
import { queryClient } from '../../../../App';

export interface IUseGetTrims {
  enabled: boolean;
  model_id?: number;
  active?: boolean;
}

export const useGetTrims = ({ enabled, model_id, active }: IUseGetTrims) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['trims', model_id, active],
    queryFn: () => getTrims({ model_id, active }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};

export const useCreateTrim = () => {
  return useMutation({
    mutationFn: (data: ICarTrimPayload) => createTrim(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['trims']);
    },
  });
};

export const useEditTrim = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICarTrimPayload }) =>
      updateTrim(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['trims']);
    },
  });
};

export const useDeleteTrim = () => {
  return useMutation({
    mutationFn: (id: number) => deleteTrim(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['trims']);
    },
  });
};

export const useActivateTrim = () => {
  return useMutation({
    mutationFn: (id: number) => activateTrim(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['trims']);
    },
  });
};

export const useDeactivateTrim = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateTrim(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['trims']);
    },
  });
};
