import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
import { NewBikeLeadsDetail } from '../../../models/overview/NewBikeLead';
import { useGetAllBikeBrands } from '../../../resources/bikes/bike_brands/hooks';
import { CustomizedRequiredMark } from '../../../components/misc/RequiredMark';
import { BikeBrand } from '../../../models/resources/bikes/BikeBrand';
import { IBikeModel } from '../../../models/resources/bikes/BikeModel';
import { leadContactStatuses } from '../../constants';
import { useGetBikeModels } from '../../../resources/bikes/bike_models/hooks';
import { useGetAllDealers } from '../../../dealers/Automotive/hooks';

type AddEditNewBikeLeadFormProps = {
  lead: NewBikeLeadsDetail | null;
  onSubmit: (formData: any) => Promise<void>;
  isLoading: boolean;
  onClose: () => void;
  isEditing: boolean;
};

export const AddEditNewBikeLeadForm = ({
  isEditing,
  isLoading,
  lead,
  onClose,
  onSubmit,
}: AddEditNewBikeLeadFormProps) => {
  const [form] = Form.useForm();
  const { Item } = Form;
  const { TextArea } = Input;
  const dealerId = Form.useWatch('dealer_id', form);
  const brandId = Form.useWatch('brand_id', form);

  const { data: dealers, isLoading: isLoadingDealers } = useGetAllDealers({
    enabled: true,
    type: 'new',
  });

  const { data: brands, isLoading: isLoadingBrands } = useGetAllBikeBrands({
    enabled: isEditing || !!dealerId,
    dealerId,
    type: 'new',
  });

  const { data: models, isLoading: isLoadingModels } = useGetBikeModels({
    enabled: isEditing || !!brandId,
    dealerId,
    brandId,
    type: 'new',
  });

  const initialValues = lead
    ? {
        name: lead.name,
        email: lead.email,
        mobile_number: lead.mobile_number.slice(4),
        source: lead.source,
        contacted: lead.contacted,
        notes: lead.notes,
      }
    : undefined;

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values: any) => {
          try {
            onSubmit(values);
          } catch (error) {
            console.error(error);
          }
        }}
        initialValues={initialValues}
        requiredMark={CustomizedRequiredMark}
      >
        {!isEditing && (
          <Row gutter={24} wrap>
            <Col span={8}>
              <Item
                label="Dealer"
                name="dealer_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select the dealer of the car',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a dealer"
                  optionFilterProp="children"
                  loading={isLoadingDealers}
                  disabled={isLoadingDealers}
                  onChange={() => {
                    form.setFieldsValue({
                      brand_id: undefined,
                      model_id: undefined,
                    });
                  }}
                >
                  {dealers?.map((dealerName: any) => (
                    <Select.Option key={dealerName.id} value={dealerName.id}>
                      {dealerName.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="Brand"
                name="brand_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select the brand of the car',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a brand"
                  optionFilterProp="children"
                  loading={isLoadingBrands}
                  disabled={isLoadingBrands || !dealerId}
                  onChange={() => {
                    form.setFieldsValue({
                      model_id: undefined,
                    });
                  }}
                >
                  {brands?.map((brand: BikeBrand) => (
                    <Select.Option key={brand.id} value={brand.id}>
                      {brand.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label="Model"
                name="model_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select the model of the car',
                  },
                ]}
                dependencies={['brand']}
              >
                <Select
                  showSearch
                  placeholder="Select a model"
                  optionFilterProp="children"
                  loading={isLoadingModels}
                  disabled={isLoadingModels || !brandId}
                >
                  {models?.map((model: IBikeModel) => (
                    <Select.Option key={model.id} value={model.id}>
                      {model.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
        )}

        <Row gutter={24} wrap>
          <Col span={8}>
            <Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter a name' }]}
            >
              <Input placeholder="English Name" />
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Email" name="email">
              <Input placeholder="Email" />
            </Item>
          </Col>

          <Col span={8}>
            <Item
              label="Mobile Number"
              name="mobile_number"
              rules={[
                { required: true, message: 'Please enter a mobile number' },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Mobile Number"
                addonBefore="+974"
                minLength={3}
                min={0}
                controls={false}
              />
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={12}>
            <Item
              label="Source"
              name="source"
              rules={[{ required: true, message: 'Please enter the source' }]}
            >
              <Input placeholder="Source" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Status"
              name="contacted"
              rules={[
                {
                  required: true,
                  message: 'Select a status',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a contact status"
                optionFilterProp="children"
              >
                {leadContactStatuses
                  .filter(status => status.value !== '')
                  .map((status: any) => (
                    <Select.Option key={status.value} value={status.value}>
                      {status.text}
                    </Select.Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={24}>
            <Item label="Notes" name="notes">
              <TextArea placeholder="Notes" autoSize={{ minRows: 3 }} />
            </Item>
          </Col>
        </Row>

        <Row
          style={{
            paddingTop: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Space>
            <Button danger onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isEditing ? 'Edit' : 'Create'}
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};
