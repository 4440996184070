import { UserGroups } from '../../../../../common/constants';
import { NewBikeBrand } from '../../../../../listings/bikes/new_bikes';
import { RouteType } from '../../../../routes/routesConfig';

const NewBikesBrands = () => {
  return <NewBikeBrand />;
};

const newBikesBrandRoute: RouteType = {
  element: <NewBikesBrands />,
  state: 'new_bikes',
  index: true,
  path: '/listings/bikes/new-bikes/:brandId',
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { NewBikesBrands, newBikesBrandRoute };
