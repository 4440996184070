export class BankingLead {
  public name: string;
  public leads: number;
  public impressions: number;
  public clicks: number;
  constructor(apiResponse: any) {
    this.name = apiResponse.name || '-';
    this.leads = apiResponse.inquires_count || 0;
    this.impressions = apiResponse.impressions || 0;
    this.clicks = apiResponse.clicks || 0;
  }
}
