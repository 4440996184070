import { useEffect } from 'react';
import { CarCategory } from '../../../listings/cars/used_cars/carsApi';
import CarsListLayout from '../../../listings/cars/used_cars/components/CarsListLayout';
import { RouteType } from '../../routes/routesConfig';

const UsedCars = () => {
  return <CarsListLayout carsCategory={CarCategory.Used} />;
};

const usedCarsRoute: RouteType = {
  element: <UsedCars />,
  state: 'used-cars',
  index: true,
  path: '/listings/cars/used-cars',
  sideBarProps: {
    displayText: 'Used Cars',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR', 'Automotive'],
};

export { UsedCars, usedCarsRoute };
