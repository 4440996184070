import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateBank,
  IEditBank,
  activateBank,
  createBank,
  deactivateBank,
  deleteBank,
  editBank,
  getAllBanks,
} from '../utils';
import { IBank } from '../../models/banks/Bank';
import { TImage, uploadImage } from '../../common/utils';

export const ALL_BANKS_KEY = ['banks'];

interface IUseGetBanks {
  enabled: boolean;
}

export const useGetBanks = ({ enabled }: IUseGetBanks) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_BANKS_KEY,
    queryFn: (): Promise<IBank[]> => getAllBanks(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useUploadBankLogo = () => {
  return useMutation({
    mutationFn: ({
      file,
      path,
      onProgress,
    }: {
      file: TImage;
      path: string;
      onProgress: any;
    }) => uploadImage({ file, path, onProgress }),
  });
};

export const useCreateBank = () => {
  return useMutation({
    mutationFn: (bank: ICreateBank) => createBank(bank),
  });
};

export const useEditBank = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEditBank }) =>
      editBank({ id, data }),
    onError: (error: any) => {
      console.log(error);
    },
  });
};

export const useDeleteBanks = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBank(id),
  });
};

export const useActivateBank = () => {
  return useMutation({
    mutationFn: (id: number) => activateBank(id),
  });
};

export const useDeactivateBank = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBank(id),
  });
};
