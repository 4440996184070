import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeConditionPayload,
  activateBikeCondition,
  createBikeCondition,
  deactivateBikeCondition,
  deleteBikeCondition,
  getBikeConditions,
  updateBikeCondition,
} from '../apis';
import { queryClient } from '../../../../App';

export const BIKE_CONDITIONS_QUERY_KEY = ['bikeConditions'];

interface IUseBikeConditions {
  enabled: boolean;
  active?: boolean;
}

export const useGetBikeConditions = ({
  enabled,
  active,
}: IUseBikeConditions) => {
  return useQuery({
    queryKey: BIKE_CONDITIONS_QUERY_KEY,
    queryFn: () => getBikeConditions({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeCondition = () => {
  return useMutation({
    mutationFn: (data: IBikeConditionPayload) => createBikeCondition(data),
    mutationKey: ['createBikeCondition'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_CONDITIONS_QUERY_KEY);
    },
  });
};

export const useUpdateBikeCondition = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeConditionPayload }) =>
      updateBikeCondition(id, data),
    mutationKey: ['updateBikeCondition'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_CONDITIONS_QUERY_KEY);
    },
  });
};

export const useDeleteBikeCondition = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeCondition(id),
    mutationKey: ['deleteBikeCondition'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_CONDITIONS_QUERY_KEY);
    },
  });
};

export const useActivateBikeCondition = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeCondition(id),
    mutationKey: ['activateBikeCondition'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_CONDITIONS_QUERY_KEY);
    },
  });
};

export const useDeactivateBikeCondition = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeCondition(id),
    mutationKey: ['deactivateBikeCondition'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_CONDITIONS_QUERY_KEY);
    },
  });
};
