import { useEffect, useState } from 'react';
import { Button } from 'antd';
import Loader from '../../components/misc/Loader';
import { IArticle } from '../../models/articles/Article';
import { EmptyState } from '../../components/emptyState/EmptyState';
import { Input } from 'antd';
import { useSearch } from '../../hooks/useSearch';
import { Layout } from '../../components/Layout';
import { useGetArticles } from '../hooks';
import { ArticleModal } from './ArticleModal';
import { ArticleCard } from './ArticleCard';

const { Search } = Input;

export const ArticlesLayout = () => {
  const { data: articles, isLoading: isLoadingArticles } = useGetArticles();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentArticle, setCurrentArticle] = useState<IArticle | null>(null);
  const [sortedArticles, setSortedArticles] = useState<Array<IArticle>>([]);

  // Search States
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchArticles } = useSearch();
  const [filteredData, setFilteredData] = useState(articles);

  const sortByFeatured = (articlesToSort: Array<IArticle>) => {
    const featured = articlesToSort?.filter(article => article.is_featured);
    const nonFeatured = articlesToSort?.filter(article => !article.is_featured);
    return [...featured, ...nonFeatured];
  };

  const onArticleEdit = (article: IArticle) => {
    setCurrentArticle(article);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setIsEditing(false);
    setCurrentArticle(null);
    setIsFormOpen(true);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchArticles(articles, searchValue);
  };

  useEffect(() => {
    if (articles) {
      const newSortedArticles = sortByFeatured(articles);
      setSortedArticles(newSortedArticles);
    }
  }, [articles]);

  useEffect(() => {
    if (sortedArticles) {
      const newData = handleSearchArticles(sortedArticles, searchValue);
      setFilteredData(newData);
    }
  }, [searchValue, sortedArticles]);

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Articles"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          New
        </Button>
      </div>
      {isLoadingArticles ? (
        <div className="w-full" style={{ height: '400px' }}>
          <Loader width="100px" height="100px" />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          {filteredData?.length > 0 ? (
            filteredData.map((article: any) => (
              <div key={article.id}>
                <ArticleCard
                  article={article}
                  onEdit={() => onArticleEdit(article)}
                />
              </div>
            ))
          ) : (
            <EmptyState type="articles" />
          )}
        </div>
      )}
      <ArticleModal
        currentArticle={currentArticle}
        isFormOpen={isFormOpen}
        isEditing={isEditing}
        setCurrentArticle={setCurrentArticle}
        setIsFormOpen={setIsFormOpen}
      />
    </Layout>
  );
};
