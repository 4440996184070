import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateDealRequest,
  IUpdateDealRequest,
  activateDeal,
  createDeal,
  deactivateDeal,
  deleteDeal,
  getDealByModelId,
  updateDeal,
} from '../apis/newDealsApi';
import { queryClient } from '../../../../App';

const ARTICLES_KEY = ['articles'];

export const useCreateDeal = () => {
  return useMutation({
    mutationFn: (request: ICreateDealRequest) => createDeal(request),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};

export const useUpdateDeal = () => {
  return useMutation({
    mutationFn: ({
      id,
      request,
    }: {
      id: number;
      request: IUpdateDealRequest;
    }) => updateDeal(id, request),
    onSuccess: () => {
      queryClient.invalidateQueries(['articles']);
    },
  });
};

export const useDeleteDeal = () => {
  return useMutation({
    mutationFn: (id: number) => deleteDeal(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};

export const useGetDeals = (slug: string, country?: string) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['articles', slug, country],
    queryFn: () => getDealByModelId(slug, country),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!slug && !!country,
  });
};

export const useActivateDeal = () => {
  return useMutation({
    mutationFn: (id: number) => activateDeal(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};

export const useDeactivate = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateDeal(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};
