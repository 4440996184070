import { Button } from 'primereact/button';

type ReloadTableButtonProps = {
  onClick: () => void;
};

const ReloadTableButton = (props: ReloadTableButtonProps) => (
  <Button
    className="refresh-button"
    icon="pi pi-refresh"
    rounded
    text
    onClick={props.onClick}
    style={{
      background: 'transparent !important',
      color: 'var(--primary-color) !important',
    }}
  />
);

export default ReloadTableButton;
