import { useEffect, useState } from 'react';
import { IBanner, IBannerImage } from '../../../models/ads/Banner';
import { BannerPlacement } from '../bannersApi';
import { Option } from '../../../components/inputs/DropDown';
import FileUpload from '../../../components/inputs/FileUpload';
import {
  Form,
  Input,
  Select,
  Space,
  Button,
  Row,
  Col,
  DatePicker,
  UploadFile,
  InputNumber,
  FormInstance,
} from 'antd';
import { ICity } from '../../../models/countries/City';
import dayjs from 'dayjs';
import { useCountries } from '../../../countries/hooks/useCountries';
import { IBannerResource } from '../../../models/ads/BannerResource';
import type {
  UploadRequestError,
  UploadRequestOption,
} from 'rc-upload/lib/interface';
import {
  getUniqueContentTypesForSectionName,
  getUniqueSectionNamesForPlacement,
} from '../../banner_resources/helpers/bannerResourceHelper';
import { IBannerAddedImage } from './BannersModal';
import VideoUpload from '../../../components/inputs/VideoUpload';
import { uploadImage } from '../../../common/utils';
import { AxiosError } from 'axios';
import { uploadVideo } from '../../../common/utils/videoApi';

type AddEditBannerFormProps = {
  form: FormInstance;
  banner: IBanner | null;
  bannerResources: IBannerResource[] | undefined;
  isEditing: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: any) => void;
  addedImages: IBannerAddedImage[];
  setAddedImages: (value: any) => void;
  addedThumbails: IBannerAddedImage[];
  setAddedThumbails: (value: any) => void;
  fileListEn: UploadFile[];
  setFileListEn: (value: any) => void;
  fileListAr: UploadFile[];
  setFileListAr: (value: any) => void;
  fileListThumbAr: UploadFile[];
  setFileListThumbAr: (value: any) => void;
  fileListThumbEn: UploadFile[];
  setFileListThumbEn: (value: any) => void;
};

const AddEditBannerForm = (props: AddEditBannerFormProps) => {
  const {
    banner,
    isEditing,
    isLoading,
    bannerResources,
    onClose,
    onSubmit,
    addedImages,
    setAddedImages,
    addedThumbails,
    setAddedThumbails,
    form,
    fileListAr,
    fileListEn,
    fileListThumbEn,
    fileListThumbAr,
    setFileListAr,
    setFileListEn,
    setFileListThumbAr,
    setFileListThumbEn,
  } = props;
  const [cityOptions, setCityOptions] = useState<Array<Option>>();

  const content_type = Form.useWatch('content_type', form);

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const { Item } = Form;

  const { RangePicker } = DatePicker;

  const initialThumbnail = banner?.images?.find(thumb => thumb.lang === 'en')
    ?.thumbnail
    ? [
        {
          uid: `${banner?.id}-thumbnail`,
          name: banner?.images
            ?.find(thumb => thumb.lang === 'en')
            ?.thumbnail?.split('/')
            .pop(),
          status: 'old',
          url: banner?.images?.find(thumb => thumb.lang === 'en')?.thumbnail,
        },
      ]
    : [];

  const initialThumbnailAr = banner?.images?.find(thumb => thumb.lang === 'ar')
    ?.thumbnail
    ? [
        {
          uid: `${banner?.id}-thumbnail`,
          name: banner?.images
            ?.find(thumb => thumb.lang === 'ar')
            ?.thumbnail?.split('/')
            .pop(),
          status: 'old',
          url: banner?.images?.find(thumb => thumb.lang === 'ar')?.thumbnail,
        },
      ]
    : [];

  const initialValues = banner
    ? {
        section_name: bannerResources?.find(
          bannerResource => bannerResource.id === banner.banner_resource_id,
        )?.section_name,
        placement: bannerResources?.find(
          bannerResource => bannerResource.id === banner.banner_resource_id,
        )?.placement,
        content_type: bannerResources?.find(
          bannerResource => bannerResource.id === banner.banner_resource_id,
        )?.content_type,
        cta: banner?.images.find(img => img.lang === 'en')?.call_to_action,
        cta_ar: banner?.images.find(img => img.lang === 'ar')?.call_to_action,
        title: banner?.images.find(img => img.lang === 'en')?.title,
        title_ar: banner?.images.find(img => img.lang === 'ar')?.title,
        thumbnail: initialThumbnail,
        thumbnail_ar: initialThumbnailAr,
        advertiser: banner.advertiser,
        order: banner.order,
        country_id: countries?.find(country =>
          country.cities?.find(city => city.id === banner.city_id),
        )?.id,
        city_id: banner.city_id,
        img_en: banner?.images.find((img: IBannerImage) => img.lang === 'en')
          ?.url
          ? [
              {
                uid: `bannerEnUrl-${banner?.id}`,
                name:
                  banner?.images
                    .find(img => img.lang === 'en')
                    ?.url.split('/images/')
                    .pop() || '',
                status: 'done',
                url: banner?.images.find(img => img.lang === 'en')?.url,
              },
            ]
          : [],
        img_ar: banner?.images.find((img: IBannerImage) => img.lang === 'ar')
          ?.url
          ? [
              {
                uid: `bannerArUrl-${banner?.id}`,
                name:
                  banner?.images
                    .find(img => img.lang === 'ar')
                    ?.url.split('/images/')
                    .pop() || '',
                status: 'done',
                url: banner?.images.find(img => img.lang === 'ar')?.url,
              },
            ]
          : [],

        dateRange: [dayjs(banner.from_date), dayjs(banner.to_date)],
      }
    : undefined;

  const countryId = Form.useWatch('country_id', form);
  const placementWatch = Form.useWatch('placement', form);
  const sectionNameWatch = Form.useWatch('section_name', form);

  const videoUpload = async ({
    onSuccess,
    onProgress,
    file,
    data,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadVideo({
        file,
        path: 'banner-video',
        onProgress: onProgress,
        extraBodyData: {
          lang: data?.lang,
        },
      });
      if (onSuccess) onSuccess('ok');
      const langExists = addedImages.some(item => item.lang === response.lang);
      if (langExists) {
        const updatedState = addedImages.map(item =>
          item.lang === response.lang ? response : item,
        );
        setAddedImages(updatedState);
        setIsUploadingImage(false);
      } else {
        setAddedImages((prevImages: IBannerAddedImage[]) => [
          ...prevImages,
          response,
        ]);
        setIsUploadingImage(false);
      }
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  const imageUpload = async ({
    onSuccess,
    onProgress,
    file,
    data,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'banner-image',
        onProgress: onProgress,
        extraBodyData: {
          lang: data?.lang,
        },
      });
      if (onSuccess) onSuccess('ok');
      const langExists = addedImages.some(item => item.lang === response.lang);

      if (langExists) {
        const updatedState = addedImages.map(item =>
          item.lang === response.lang ? response : item,
        );
        setAddedImages(updatedState);
        setIsUploadingImage(false);
      } else {
        setAddedImages((prevImages: IBannerAddedImage[]) => [
          ...prevImages,
          response,
        ]);
        setIsUploadingImage(false);
      }
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  const thumbnailUpload = async ({
    onSuccess,
    onProgress,
    file,
    data,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'banner-image',
        onProgress: onProgress,
        extraBodyData: {
          lang: data?.lang,
        },
      });
      if (onSuccess) onSuccess('ok');
      const langExists = addedThumbails.some(
        item => item.lang === response.lang,
      );

      if (langExists) {
        const updatedState = addedThumbails.map(item =>
          item.lang === response.lang ? response : item,
        );
        setAddedThumbails(updatedState);
        setIsUploadingImage(false);
      } else {
        setAddedThumbails((prevImages: IBannerAddedImage[]) => [
          ...prevImages,
          response,
        ]);
        setIsUploadingImage(false);
      }
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [banner, form, countries]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <Row gutter={16} wrap>
        <Col span={8}>
          <Item
            label="Placement"
            name="placement"
            rules={[
              {
                required: true,
                message: 'Please select the placement of the banner',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a placement"
              optionFilterProp="children"
              size="large"
            >
              {Object.values(BannerPlacement).map(value => {
                return (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Section"
            name="section_name"
            rules={[
              {
                required: true,
                message: 'Please select the section of the banner',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a section"
              optionFilterProp="children"
              size="large"
              disabled={!placementWatch}
            >
              {getUniqueSectionNamesForPlacement(
                bannerResources!,
                placementWatch,
              )?.map(value => {
                return (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Type"
            name="content_type"
            rules={[
              {
                required: true,
                message: 'Please select the section of the banner',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a section"
              optionFilterProp="children"
              size="large"
              disabled={!sectionNameWatch}
            >
              {getUniqueContentTypesForSectionName(
                bannerResources!,
                sectionNameWatch,
              )?.map(value => {
                return (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={8}>
          <Item
            label="Advertising Partner"
            name="advertiser"
            rules={[
              {
                required: true,
                message: 'Please select the advertising partner of the banner',
              },
            ]}
          >
            <Input placeholder="Advertiser" size="large" />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Date Range"
            name="dateRange"
            rules={[
              {
                required: true,
                message: 'Please select date range',
              },
            ]}
          >
            <RangePicker
              style={{
                width: '100%',
              }}
              size="large"
            />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Banner Priority"
            name="order"
            rules={[
              {
                required: true,
                message: 'Select the order of the banner',
              },
            ]}
          >
            <InputNumber
              placeholder="Order"
              size="large"
              min={0}
              style={{
                width: '100%',
              }}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Call to action"
            name="cta"
            rules={[
              {
                required: true,
                message: 'Please select the cta of the banner',
              },
            ]}
          >
            <Input placeholder="CTA" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label=" Arabic Call to action"
            name="cta_ar"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Arabic CTA" size="large" />
          </Item>
        </Col>
      </Row>
      {sectionNameWatch === 'A1' && (
        <Row gutter={16} wrap>
          <Col span={12}>
            <Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}
            >
              <Input placeholder="Name" size="large" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Arabic Title"
              name="title_ar"
              rules={[
                {
                  required: true,
                  message: 'Please enter Arabic name',
                },
              ]}
            >
              <Input placeholder="Arabic Name" size="large" />
            </Item>
          </Col>
        </Row>
      )}
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Country"
            name="country_id"
            rules={[
              {
                required: true,
                message: 'Please select the country of the banner',
              },
            ]}
          >
            <Select
              disabled={isLoadingCountries}
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              onChange={() => {
                form.setFieldsValue({
                  city_id: undefined,
                });
              }}
              size="large"
            >
              {countries?.map(country => {
                return (
                  <Select.Option key={country.id} value={country.id}>
                    {country.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="City"
            name="city_id"
            rules={[
              {
                required: true,
                message: 'Please select the city of the banner',
              },
            ]}
          >
            <Select
              disabled={!countryId}
              showSearch
              placeholder="Select a city"
              optionFilterProp="children"
              size="large"
            >
              {cityOptions?.map((city: Option) => {
                return (
                  <Select.Option key={city.code} value={city.code}>
                    {city.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      {content_type === 'Image' && (
        <Row gutter={16} wrap>
          <Col span={4}>
            <FileUpload
              label="English Banner"
              maxNbFiles={1}
              name="img_en"
              fileList={fileListEn}
              setFileList={setFileListEn}
              customRequest={props =>
                imageUpload({ ...props, data: { lang: 'en' } })
              }
              requiredMessage='Please upload an image'
            />
          </Col>
          <Col span={4}>
            <FileUpload
              label="Arabic Banner"
              maxNbFiles={1}
              name="img_ar"
              fileList={fileListAr}
              setFileList={setFileListAr}
              customRequest={props =>
                imageUpload({ ...props, data: { lang: 'ar' } })
              }
              requiredMessage='Please upload an image'
            />
          </Col>
        </Row>
      )}
      {sectionNameWatch === 'A1' && content_type === 'Video' && (
        <Row
          gutter={16}
          wrap
          style={{
            marginLeft: '6%',
          }}
        >
          <Col span={6}>
            <VideoUpload
              label="English Banner"
              maxNbFiles={1}
              name="img_en"
              fileList={fileListEn}
              setFileList={setFileListEn}
              customRequest={props =>
                videoUpload({
                  ...props,
                  data: { lang: 'en' },
                })
              }
              requiredMessage='Please upload a video'
            />
          </Col>
          <Col span={6}>
            <VideoUpload
              label="Arabic Banner"
              maxNbFiles={1}
              name="img_ar"
              fileList={fileListAr}
              setFileList={setFileListAr}
              customRequest={props =>
                videoUpload({
                  ...props,
                  data: { lang: 'ar' },
                })
              }
              requiredMessage='Please upload a video'
            />
          </Col>
          <Col span={6}>
            <FileUpload
              label="English Thumbnail"
              maxNbFiles={1}
              name="thumbnail"
              fileList={fileListThumbEn}
              setFileList={setFileListThumbEn}
              customRequest={props =>
                thumbnailUpload({
                  ...props,
                  data: { lang: 'en' },
                })
              }
              requiredMessage='Please upload a thumbnail'
            />
          </Col>
          <Col span={6}>
            <FileUpload
              label="Arabic Thumbnail"
              maxNbFiles={1}
              name="thumbnail_ar"
              fileList={fileListThumbAr}
              setFileList={setFileListThumbAr}
              customRequest={props =>
                thumbnailUpload({
                  ...props,
                  data: { lang: 'ar' },
                })
              }
              requiredMessage='Please upload a thumbnail'
            />
          </Col>
        </Row>
      )}
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button onClick={onClose} danger>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isUploadingImage}
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default AddEditBannerForm;
