import { useMutation, useQuery } from '@tanstack/react-query';
import {
  activateCurrency,
  createCurrency,
  deactivateCurrency,
  deleteCurrency,
  getCurrencies,
  ICurrencyPayload,
  updateCurrency,
} from '../apis';
import { queryClient } from '../../../App';

const ALL_CURRENCIES_KEY = ['allCurrencies'];

export interface IUseCurrencies {
  enabled: boolean;
  active?: boolean;
}

export const useCurrencies = ({ enabled, active }: IUseCurrencies) => {
  return useQuery({
    queryKey: ALL_CURRENCIES_KEY,
    queryFn: () => getCurrencies({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCurrency = () => {
  return useMutation({
    mutationFn: (data: ICurrencyPayload) => createCurrency(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CURRENCIES_KEY);
    },
  });
};

export const useUpdateCurrency = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICurrencyPayload }) => {
      return updateCurrency(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CURRENCIES_KEY);
    },
  });
};

export const useDeleteCurrency = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deleteCurrency(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CURRENCIES_KEY);
    },
  });
};

export const useActivateCurrency = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return activateCurrency(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CURRENCIES_KEY);
    },
  });
};

export const useDeactivateCurrency = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deactivateCurrency(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CURRENCIES_KEY);
    },
  });
};
