import { useMutation, useQuery } from '@tanstack/react-query';
import {
  INewBikeDealPayload,
  activateNewBikeDeal,
  createNewBikeDeal,
  deactivateNewBikeDeal,
  deleteNewBikeDeal,
  getBikeDealByModelSlug,
  getNewBikeBrandsWithModelsCount,
  updateNewBikeDeal,
} from '../apis';
import { queryClient } from '../../../../App';
import { BIKE_MODEL_SLUG_QUERY_KEY } from '../../../../resources/bikes/bike_models/hooks';

const BIKE_BRANDS_WITH_MODELS_COUNT_KEY = [
  'NEW_BIKES_BRANDS_WITH_MODELS_COUNT',
];

const BIKE_DEALS = ['BIKE_DEALS'];

export const useGetBikeDeals = (slug: string, country?: string) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['BIKE_DEALS', slug, country],
    queryFn: () => getBikeDealByModelSlug(slug, country),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!slug && !!country,
  });
};

export const useGetNewBikeBrandsWithModelsCount = (country?: string) => {
  return useQuery({
    queryKey: ['NEW_BIKES_BRANDS_WITH_MODELS_COUNT', country],
    queryFn: () => getNewBikeBrandsWithModelsCount(country),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!country || true,
  });
};

export const useDeleteNewBikeDeal = () => {
  return useMutation({
    mutationFn: (id: number) => deleteNewBikeDeal(id),
    mutationKey: ['DELETE_NEW_BIKE_DEAL'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODEL_SLUG_QUERY_KEY);
      queryClient.invalidateQueries(BIKE_BRANDS_WITH_MODELS_COUNT_KEY);
      queryClient.invalidateQueries(BIKE_DEALS);
    },
  });
};

export const useActivateNewBikeDeal = () => {
  return useMutation({
    mutationFn: (id: number) => activateNewBikeDeal(id),
    mutationKey: ['ACTIVATE_NEW_BIKE_DEAL'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODEL_SLUG_QUERY_KEY);
      queryClient.invalidateQueries(BIKE_BRANDS_WITH_MODELS_COUNT_KEY);
      queryClient.invalidateQueries(BIKE_DEALS);
    },
  });
};

export const useDeactivateNewBikeDeal = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateNewBikeDeal(id),
    mutationKey: ['DEACTIVATE_NEW_BIKE_DEAL'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODEL_SLUG_QUERY_KEY);
      queryClient.invalidateQueries(BIKE_BRANDS_WITH_MODELS_COUNT_KEY);
      queryClient.invalidateQueries(BIKE_DEALS);
    },
  });
};

export const useCreateNewBikeDeal = () => {
  return useMutation({
    mutationFn: (data: INewBikeDealPayload) => createNewBikeDeal(data),
    mutationKey: ['CREATE_NEW_BIKE_DEAL'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODEL_SLUG_QUERY_KEY);
      queryClient.invalidateQueries(BIKE_BRANDS_WITH_MODELS_COUNT_KEY);
      queryClient.invalidateQueries(BIKE_DEALS);
    },
  });
};

export const useUpdateNewBikeDeal = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: INewBikeDealPayload }) =>
      updateNewBikeDeal(id, data),
    mutationKey: ['UPDATE_NEW_BIKE_DEAL'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_MODEL_SLUG_QUERY_KEY);
      queryClient.invalidateQueries(BIKE_BRANDS_WITH_MODELS_COUNT_KEY);
      queryClient.invalidateQueries(BIKE_DEALS);
    },
  });
};
