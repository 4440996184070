import { Modal, message } from 'antd';
import { useState } from 'react';
import { RamadanForm } from './RamadanForm';
import { useCreateRamadanOffer, useUpdateRamadanOffer } from '../hooks';
import { IRamadanOffer } from '../../../../models/listings/cars/ramadan/ramadanOffer';
import { RcFile } from 'antd/es/upload';

interface RamadanModalProps {
  isVisible: boolean;
  isEditing: boolean;
  selectedOffer: IRamadanOffer | null;
  onClose: () => void;
  setIsFormOpen: (value: boolean) => void;
  setSelectedOffer: (value: any) => void;
}

export interface IRamadanOfferMobileCoverImageUpload {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface IRamadanOfferImageUpload {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface IRamadanOfferUploadedImages extends IRamadanOfferImageUpload {
  file: RcFile;
}

export const RamadanModal = ({
  isVisible,
  selectedOffer,
  isEditing,
  onClose,
  setIsFormOpen,
  setSelectedOffer,
}: RamadanModalProps) => {
  const [addedImages, setAddedImages] = useState<IRamadanOfferUploadedImages[]>(
    [],
  );
  const [removedImages, setRemovedImages] = useState<
    Array<Record<string, string>>
  >([]);
  const [addedMobileCoverImages, setAddedMobileCoverImages] = useState<
    IRamadanOfferUploadedImages[]
  >([]);
  const [removedMobileCoverImages, setRemovedMobileCoverImages] = useState<
    Array<Record<string, string>>
  >([]);

  const { mutateAsync: createRamadanOffer, isLoading: isCreating } =
    useCreateRamadanOffer();

  const { mutateAsync: updateRamadanOffer, isLoading: isUpdating } =
    useUpdateRamadanOffer();

  const _create = async (data: any) => {
    if (data == null) return;
    data['add_images'] = addedImages.map(image => ({ id: image.id }));
    data['benefits'] = data.Benefits?.map((benefit: any) => benefit.benefits);
    data['arabic_benefits'] = data.Benefits?.map(
      (benefit: any) => benefit.arabic_benefits,
    );
    data['add_mobile_cover_images'] = addedMobileCoverImages.map(image => ({
      id: image.id,
    }));
    delete data.Benefits;
    delete data.image;
    try {
      const response = await createRamadanOffer(data);
      setIsFormOpen(false);
      message.success('Ramadan offer created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (data: any) => {
    data['add_images'] = addedImages.map(image => ({ id: image.id }));
    data['remove_images'] = removedImages;
    data['benefits'] = data.Benefits?.map((benefit: any) => benefit.benefits);
    data['arabic_benefits'] = data.Benefits?.map(
      (benefit: any) => benefit.arabic_benefits,
    );
    data['add_mobile_cover_images'] = addedMobileCoverImages.map(image => ({
      id: image.id,
    }));
    data['remove_mobile_cover_images'] = removedMobileCoverImages;
    delete data.Benefits;
    delete data.image;
    try {
      const response = await updateRamadanOffer({
        id: selectedOffer!.id,
        data,
      });
      setIsFormOpen(false);
      setSelectedOffer(null);
      message.success('Ramadan offer updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <Modal
      title={isEditing ? 'Edit Ramadan Offer' : 'Add Ramadan Offer'}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={1200}
      style={{ top: 20 }}
      key={selectedOffer?.id}
    >
      <RamadanForm
        addedImages={addedImages}
        isEditing={isEditing}
        isLoading={isCreating || isUpdating}
        onClose={onClose}
        onSubmit={isEditing ? _edit : _create}
        removedImages={removedImages}
        selectedOffer={selectedOffer}
        setAddedImages={setAddedImages}
        setRemovedImages={setRemovedImages}
        setAddedMobileCoverImages={setAddedMobileCoverImages}
        setRemovedMobileCoverImages={setRemovedMobileCoverImages}
      />
    </Modal>
  );
};
