import { getBase64 } from '../../../../services/ImageService';
import { RcFile, UploadFile } from 'antd/es/upload';

export const useImageUtils = ({
  setImage,
  setOpenImage,
}: {
  setImage: (image: string) => void;
  setOpenImage: (open: boolean) => void;
}) => {
  const handlePreview = async (file: UploadFile) => {
    if (!file.url) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setImage(file.url || (file.preview as string));
    setOpenImage(true);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return { normFile, handlePreview };
};
