import { useMutation, useQuery } from '@tanstack/react-query';
import {
  UpdateCylinderRequest,
  activateCylinder,
  createCylinder,
  deactivateCylinder,
  deleteCylinder,
  getCylinders,
  updateCylinder,
} from '../apis/carCylindersApi';
import { queryClient } from '../../../../App';

export const ALL_CYLINDERS_KEY = ['allCylinders'];

interface IUseCylinders {
  enabled: boolean;
  active?: boolean;
}

export const useCylinders = ({ enabled, active }: IUseCylinders) => {
  return useQuery({
    queryKey: ALL_CYLINDERS_KEY,
    queryFn: () => getCylinders({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCylinder = () => {
  return useMutation({
    mutationFn: createCylinder,
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CYLINDERS_KEY);
    },
  });
};

export const useUpdateCylinder = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateCylinderRequest }) =>
      updateCylinder(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CYLINDERS_KEY);
    },
  });
};

export const useDeleteCylinder = () => {
  return useMutation({
    mutationFn: (id: number) => deleteCylinder(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CYLINDERS_KEY);
    },
  });
};

export const useActivateCylinder = () => {
  return useMutation({
    mutationFn: (id: number) => activateCylinder(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CYLINDERS_KEY);
    },
  });
};

export const useDeactivateCylinder = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateCylinder(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CYLINDERS_KEY);
    },
  });
};
