import { IBannerResource } from '../../../models/ads/BannerResource';
import {
  BannerPlacement,
  BannerSection,
  BannerContentType,
} from '../../banners/bannersApi';

export const getUniqueSectionNamesForPlacement = (
  bannerResources: IBannerResource[],
  chosenPlacement: BannerPlacement,
): BannerSection[] => {
  const uniqueSectionNames = new Set<BannerSection>();

  bannerResources.forEach(bannerResource => {
    if (bannerResource.placement === chosenPlacement) {
      uniqueSectionNames.add(bannerResource.section_name as BannerSection);
    }
  });

  return Array.from(uniqueSectionNames);
};

export const getUniqueContentTypesForSectionName = (
  bannerResources: IBannerResource[],
  chosenSectionName: BannerSection,
): BannerContentType[] => {
  const uniqueContentTypes = new Set<BannerContentType>();

  bannerResources.forEach(bannerResource => {
    if (bannerResource.section_name === chosenSectionName) {
      uniqueContentTypes.add(bannerResource.content_type as BannerContentType);
    }
  });

  return Array.from(uniqueContentTypes);
};
