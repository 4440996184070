import api from '../../../../api/apiIndex';
import { ICarTrim } from '../../../../models/resources/CarTrim';

const trimsEndpoint = '/v2/admin/resources/cars/trims';

export interface ICarTrimPayload {
  name: string;
  arabic_name: string;
  model_id: number;
}

export interface IGetTrims {
  model_id?: number;
  active?: boolean;
}

export const getTrims = async ({ model_id, active }: IGetTrims) => {
  const response = await api.get(trimsEndpoint, {
    params: {
      model_id,
      active,
    },
  });
  return response.data;
};

export const createTrim = async (data: ICarTrimPayload) => {
  const response = await api.post(trimsEndpoint, data);
  return response.data;
};

export const updateTrim = async (id: number, data: ICarTrimPayload) => {
  const response = await api.put(`${trimsEndpoint}/${id}`, data);
  return response.data;
};

export const deleteTrim = async (id: number) => {
  const response = await api.delete(`${trimsEndpoint}/${id}`);
  return response.data;
};

export const activateTrim = async (id: number) => {
  const response = await api.put(`${trimsEndpoint}/activate/${id}`);
  return response.data;
};

export const deactivateTrim = async (id: number) => {
  const response = await api.put(`${trimsEndpoint}/deactivate/${id}`);
  return response.data;
};
