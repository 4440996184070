import { useEffect, useState } from 'react';
import { CarBrand } from '../../../../models/resources/CarBrand';
import { Input, message, Button, Spin, Table, Popconfirm, Tag } from 'antd';
import { EmptyState } from '../../../../components/emptyState/EmptyState';
import { useSearch } from '../../../../hooks/useSearch';
import {
  useActivateBrand,
  useDeactivateBrand,
  useDeleteBrand,
  useGetAllBrands,
} from '../hooks';
import { Layout } from '../../../../components/Layout';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';
import { BrandsModal } from './BrandsModal';
import { ColumnsType } from 'antd/es/table';

const { Search } = Input;

const BrandsLayout = () => {
  const { data: brands, isLoading: LoadingBrands } = useGetAllBrands({
    enabled: true,
  });
  const { mutateAsync: activeBrand, isLoading: isActive } = useActivateBrand();
  const { mutateAsync: deactiveBrand, isLoading: isDeactive } =
    useDeactivateBrand();
  const { mutateAsync: deleteBrand, isLoading: isDeletingBrand } =
    useDeleteBrand();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentBrand, setCurrentBrand] = useState<CarBrand | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState(brands);
  const { handleSearchCarBrand } = useSearch();
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<CarBrand | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<CarBrand | null>(
    null,
  );

  const openEmptyForm = () => {
    setCurrentBrand(null);
    setIsFormOpen(true);
  };

  const onEditButtonPress = (brand: CarBrand) => {
    setCurrentBrand(brand);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setCurrentBrand(null);
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactiveBrand(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Brand Deactivated Successfully');
          },
        })
      : activeBrand(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Brand Activated Successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteBrand(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Brand Deleted Successfully');
      },
    });
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchCarBrand(brands, value);
  };

  useEffect(() => {
    const newData = handleSearchCarBrand(brands, searchValue);
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(brands);
  }, [brands]);

  const tableColumns: ColumnsType<CarBrand> = [
    {
      title: 'Logo',
      dataIndex: 'original_logo',
      key: 'original_logo',
      render: (image: string) => (
        <img
          src={image}
          alt="brand"
          style={{ width: '70px', height: '70px' }}
        />
      ),
      width: '20%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${brands?.map((brand: CarBrand) => brand.t[0].name)}`,
      key: `${brands?.map((brand: CarBrand) => brand.t[0].name)}`,
      width: '20%',
      render: (text: any, record: CarBrand) => <div>{record.t[0].name}</div>,
    },
    {
      title: 'Dealers',
      dataIndex: 'brand_dealer',
      key: 'brand_dealer',
      width: '20%',
      render: (text: any, record: CarBrand) => {
        const dealerNames = record.brand_dealer?.map(
          (dealer: any) => dealer.dealers.name,
        );

        if (!dealerNames || dealerNames.length === 0) {
          return <Tag color="red">No Dealers</Tag>;
        }

        const namesString = dealerNames.join(', ');

        return (
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {namesString}
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: CarBrand) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: CarBrand) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this brand will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{ loading: isDeletingBrand }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone
              onClick={() => {
                setOpenDeleteDialog(record);
              }}
            />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this brand.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActive || isDeactive,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Brands"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          New Brand
        </Button>
      </div>
      {LoadingBrands ? (
        <div className="flex justify-content-center align-items-center">
          <Spin />
        </div>
      ) : (
        <>
          {filteredData?.length > 0 ? (
            <Table
              loading={LoadingBrands}
              dataSource={filteredData.length > 0 ? filteredData : brands}
              columns={tableColumns}
              pagination={
                filteredData.length > 10
                  ? {
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '30'],
                      position: ['bottomCenter'],
                    }
                  : false
              }
              scroll={{ x: 1300, y: 720 }}
              rowKey={record => record.id}
            />
          ) : (
            <EmptyState type="brands" />
          )}
        </>
      )}
      <BrandsModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        currentBrand={currentBrand}
        setCurrentBrand={setCurrentBrand}
        onClose={closeForm}
      />
    </Layout>
  );
};

export default BrandsLayout;
