import { Tabs } from 'antd';
import { useCountries } from '../countries/hooks/useCountries';
import { UserGroups } from '../common/constants';
import useAuth from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';

interface TabsCountrySwitcherProps {
  setCountry: (country: string) => void;
  style?: React.CSSProperties;
  country?: string;
}

export const TabsCountrySwitcher = ({
  setCountry,
  country,
  style,
}: TabsCountrySwitcherProps) => {
  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isSuperAdmin = userGroup === UserGroups.OAC;
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { data: countries } = useCountries({ enabled: true });

  const countryItems =
    countries?.map(country => ({
      key: country.code,
      label: country.name,
    })) || [];

  const handleTabChange = (key: string) => {
    setCountry(key);
    params.set('country', key);
    const newUrl = `${location.pathname}?${params.toString()}`;
    window.history.pushState(null, '', newUrl);
  };

  if (!isSuperAdmin) {
    setCountry('');
    return null;
  }

  return (
    <Tabs
      style={style}
      activeKey={country}
      onChange={handleTabChange}
      items={countryItems}
    />
  );
};
