import { useState } from 'react';
import { Modal, message } from 'antd';
import {
  IBannerResource,
  IBannerResourceImage,
} from '../../../models/ads/BannerResource';
import { useCreateBanner, useUpdateBanner } from '../hooks';
import { BannersForm } from './BannersForm';

interface BannersModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  onClose: () => void;
  isEditing: boolean;
  currentBanner: IBannerResource | null;
  setCurrentBanner: (value: IBannerResource | null) => void;
}
export const BannersModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBanner,
  setCurrentBanner,
  onClose,
}: BannersModalProps) => {
  const [addedImages, setAddedImages] = useState<IBannerResourceImage[]>([]);
  const [removedImages, setRemovedImages] = useState<
    Array<Record<string, string>>
  >([]);

  const { mutateAsync: createBanner, isLoading: isCreating } =
    useCreateBanner();
  const { mutateAsync: updateBanner, isLoading: isUpdating } =
    useUpdateBanner();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      data['add_image'] =
        addedImages.length > 0 ? { id: addedImages[0].id } : null;
      const response = await createBanner(data);
      setIsFormOpen(false);
      setCurrentBanner(null);
      message.success('Banner Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (currentBanner == null) return;
    try {
      data['add_image'] =
        addedImages.length > 0 ? { id: addedImages[0].id } : null;
      data['remove_image'] =
        removedImages.length > 0 ? { url: removedImages[0].url } : null;
      const response = await updateBanner({
        id: currentBanner.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentBanner(null);
      message.success('Banner Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Banner' : 'Add Banner'}
        onCancel={onClose}
        open={isFormOpen}
        footer={null}
        width={1200}
        style={{ top: 20 }}
        key={currentBanner?.id}
      >
        <BannersForm
          isEditing={isEditing}
          banner={currentBanner}
          onClose={onClose}
          addedImages={addedImages}
          onSubmit={isEditing ? _edit : _create}
          removedImages={removedImages}
          setAddedImages={setAddedImages}
          setRemovedImages={setRemovedImages}
          isLoading={isCreating || isUpdating}
        />
      </Modal>
    </>
  );
};
