import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateDealerRequest,
  IUpdateDealerRequest,
  activateDealer,
  createDealer,
  deactivateDealer,
  deleteDealer,
  getAllDealers,
  getDealerNames,
  updateDealer,
} from '../apis';
import { queryClient } from '../../../App';

interface IUseGetAllDealers {
  enabled?: boolean;
  active?: boolean;
  type?: string;
  country?: string;
}

export const ALL_DEALERS_NAMES_KEY = ['ALL_DEALERS_NAMES'];

export const useGetAllDealers = ({
  enabled,
  active,
  type,
  country,
}: IUseGetAllDealers) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [ALL_DEALERS_NAMES_KEY, country],
    queryFn: () => getAllDealers({ active, type, country }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!country || true,
  });
};

export const useGetDealers = ({ enabled, active }: IUseGetAllDealers) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_DEALERS_NAMES_KEY,
    queryFn: () => getAllDealers({ active }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateDealer = () => {
  return useMutation({
    mutationFn: (request: ICreateDealerRequest) => createDealer(request),
    onSuccess: () => {
      queryClient.invalidateQueries([ALL_DEALERS_NAMES_KEY]);
    },
  });
};

export const useUpdateDealer = () => {
  return useMutation({
    mutationFn: ({
      id,
      request,
    }: {
      id: number;
      request: IUpdateDealerRequest;
    }) => updateDealer({ id, request }),
    onSuccess: () => {
      queryClient.invalidateQueries([ALL_DEALERS_NAMES_KEY]);
    },
  });
};

export const useDeleteDealer = () => {
  return useMutation({
    mutationFn: (id: number) => deleteDealer(id),
    onSuccess: () => {
      queryClient.invalidateQueries([ALL_DEALERS_NAMES_KEY]);
    },
  });
};

export const useActivateDealer = () => {
  return useMutation({
    mutationFn: (id: number) => activateDealer(id),
    onSuccess: () => {
      queryClient.invalidateQueries([ALL_DEALERS_NAMES_KEY]);
    },
  });
};

export const useDeactivateDealer = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateDealer(id),
    onSuccess: () => {
      queryClient.invalidateQueries([ALL_DEALERS_NAMES_KEY]);
    },
  });
};
