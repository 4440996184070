import {
  Form,
  Input,
  Select,
  Space,
  Radio,
  Button,
  Row,
  Col,
  DatePicker,
  Spin,
  Switch,
  Divider,
  Typography,
  InputNumber,
  UploadFile,
} from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useGetAllBrands } from '../../../../../resources/cars/car_brands/hooks/useBrands';
import { useGetAllModels } from '../../../../../resources/cars/car_models/hooks/useModels';
import { CarStatus } from '../../carsApi';
import dayjs from 'dayjs';
import { useCarCondition } from '../../../../../resources/cars/car_condition/hooks/useCarCondition';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, FlexCentered } from '../../../../../components/Flex';
import useAuth from '../../../../../hooks/useAuth';
import { adminStatuses, dealerStatuses } from '../../carsApi';
import { useEngineTypes } from '../../../../../resources/cars/engine_types/hooks/useEngineType';
import { useBatteryRange } from '../../../../../resources/cars/car_battery_range/hooks/useBatteryRange';
import { useTransmissions } from '../../../../../resources/cars/car_transmission/hooks/useCarTransmissions';
import { useEngineSizes } from '../../../../../resources/cars/engine_sizes/hooks/useEngineSize';
import { useCylinders } from '../../../../../resources/cars/car_cylinders/hooks/useEngineCyl';
import { useBatteryCapacity } from '../../../../../resources/cars/car_battery_capacity/hooks/useBatteryCapacity';
import { useCarSeatMaterial } from '../../../../../resources/cars/car_seat_material/hooks/useCarSeatMaterial';
import { useCarInteriorColor } from '../../../../../resources/cars/car_interior_color/hooks/useCarInteriorColor';
import { useDriveTrain } from '../../../../../resources/cars/car_drivetrain/hooks/useDriveTrain';
import { useCarOptionalFeatures } from '../../../../../resources/cars/car_features/hooks/useCarOptionalFeatures';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useState } from 'react';
import { useCarExteriorColor } from '../../../../../resources/cars/car_exterior_color/hooks/useCarExteriorColor';
import { IUsedCar } from '../../../../../models/listings/cars/used_cars/UsedCar';
import { ICarDealer } from '../../../../../models/dealers/cars';
import { ICarModel } from '../../../../../models/resources/CarModel';
import { CarBrand } from '../../../../../models/resources/CarBrand';
import { UserGroups } from '../../../../../common/constants';
import { useGetAllDealers } from '../../../../../dealers/Automotive/hooks';
import { IEngineType } from '../../../../../models/resources/CarEngineType';
import { CustomizedRequiredMark } from '../../../../../components/misc/RequiredMark';
import FileUpload from '../../../../../components/inputs/FileUpload';
import { IUsedCarImages } from './AddEditUsedCarModal';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { RejectUsedCarsModal } from './RejectUsedCarsModal';
import { uploadImage } from '../../../../../common/utils';
import { UploadRequestError } from 'rc-upload/lib/interface';
import { AxiosError } from 'axios';
import { useGetTrims } from '../../../../../resources/cars/car_trims/hooks';
import { ICarTrim } from '../../../../../models/resources/CarTrim';
import { Option } from '../../../../../components/inputs/DropDown';
import { useCountries } from '../../../../../countries/hooks/useCountries';
import { ICity } from '../../../../../models/countries/City';
import { set } from 'lodash';
import { useGetCountriesAreaCodes } from '../../../../../resources/countries/hooks';

type CustomUploadFileStatus = UploadFileStatus | 'old';

type AddEditUsedCarFormProps = {
  car: IUsedCar | null;
  isEditing: boolean;
  isLoading: boolean;
  onClose: () => void;
  addedImages: any[];
  removedImages: any[];
  rejectReason?: string;
  setRejectReason: (reason: string) => void;
  setAddedImages: Dispatch<SetStateAction<IUsedCarImages[]>>;
  setRemovedImages: (urls: Array<Record<string, string>>) => void;
  onSubmit: (formData: any) => Promise<void>;
  countryCode: string;
  setCountryCode: (code: string) => void;
};

const { Text } = Typography;
const { Item } = Form;
export const AddEditUsedForm = ({
  car,
  addedImages,
  removedImages,
  isEditing,
  isLoading,
  rejectReason,
  onClose,
  onSubmit,
  setAddedImages,
  setRemovedImages,
  setRejectReason,
  countryCode,
  setCountryCode,
}: AddEditUsedCarFormProps) => {
  const initialImages =
    car?.images?.map((image: any) => {
      return {
        uid: image.id,
        name: image.split('/').pop(),
        status: 'old',
        url: image,
      };
    }) || [];

  const [fileList, setFileList] = useState<UploadFile[]>(
    initialImages as UploadFile[],
  );
  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);
  const [cityOptions, setCityOptions] = useState<Array<Option>>();

  const [form] = Form.useForm();
  const brandId = Form.useWatch('brand_id', form);
  const modelId = Form.useWatch('model_id', form);
  const engineTypeId = Form.useWatch('engine_type_id', form);
  const sellerType = Form.useWatch('seller_type', form);
  const user = useAuth();
  const isOneAutocar = user?.userInfo?.group === UserGroups.OAC;
  const fuelTypeSelected = engineTypeId !== 3 && engineTypeId !== undefined;
  const electricTypeSelected = engineTypeId === 3;

  //Apis

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const { data: brands, isLoading: isLoadingBrands } = useGetAllBrands({
    active: true,
  });

  const { data: models, isLoading: isLoadingModels } = useGetAllModels({
    enabled: isEditing || !!brandId,
    brandId: brandId,
    active: true,
  });

  const { data: trims, isLoading: isLoadingTrims } = useGetTrims({
    enabled: isEditing || !!modelId,
    model_id: modelId,
    active: true,
  });

  const { data: carConditions, isLoading: isLoadingCarConditions } =
    useCarCondition({
      enabled: true,
      active: true,
    });

  const [countryName, setCountryName] = useState('');

  const { data: dealers, isLoading: isLoadingDealerNames } = useGetAllDealers({
    country: countryName,
  });

  const handleDealerSelect = (dealerId: ICarDealer) => {
    const selectedDealer = dealers.find(
      (dealer: any) => dealer.id === dealerId,
    )?.whatsapp_number;
    const formattedNumber =
      selectedDealer && selectedDealer.startsWith('974')
        ? selectedDealer.slice(3)
        : selectedDealer;
    form.setFieldValue('whatsapp_number', formattedNumber);
  };

  const { data: engineTypes, isLoading: isLoadingEngineTypes } = useEngineTypes(
    {
      enabled: true,
      active: true,
    },
  );
  const { data: transmissions, isLoading: isLoadingTransmissions } =
    useTransmissions({
      enabled:
        engineTypes?.find(
          (engineType: IEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric' && engineTypeId !== undefined,
      active: true,
    });
  const { data: engineSizes, isLoading: isLoadingEngineSizes } = useEngineSizes(
    {
      enabled:
        engineTypes?.find(
          (engineType: IEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric' && engineTypeId !== undefined,
      active: true,
    },
  );
  const { data: cylinders, isLoading: isLoadingCylinders } = useCylinders({
    enabled:
      engineTypes?.find(
        (engineType: IEngineType) => engineType.id === engineTypeId,
      )?.slug !== 'electric' && engineTypeId !== undefined,
    active: true,
  });
  const { data: batteryRange, isLoading: isLoadingBatteryRange } =
    useBatteryRange({
      enabled:
        engineTypes?.find(
          (engineType: IEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric',
      active: true,
    });
  const { data: batteryCapacity, isLoading: isLoadingBatteryCapacity } =
    useBatteryCapacity({
      enabled:
        engineTypes?.find(
          (engineType: IEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric',
      active: true,
    });
  const { data: exteriorColors, isLoading: isLoadingExteriorColors } =
    useCarExteriorColor({
      enabled: true,
      active: true,
    });
  const { data: interiorColors, isLoading: isLoadingInteriorColors } =
    useCarInteriorColor({
      enabled: true,
      active: true,
    });
  const { data: seatMaterials, isLoading: isLoadingSeatMaterials } =
    useCarSeatMaterial({
      enabled: true,
      active: true,
    });
  const { data: variants, isLoading: isLoadingVariants } = useDriveTrain({
    enabled: true,
    active: true,
  });
  const { data: carFeatures, isLoading: isLoadingCarFeatures } =
    useCarOptionalFeatures({
      enabled: true,
      active: true,
    });

  const countryId = Form.useWatch('country_id', form);

  const { data: areaCodes, isLoading: isLoadingAreaCodes } =
    useGetCountriesAreaCodes();

  const areaCodesPattern = areaCodes
    ?.map((areaCode: any) => areaCode.area_code.replace('+', ''))
    .join('|');

  const areaCodeRegex = new RegExp(`^(${areaCodesPattern})`);

  const initialValues = car
    ? {
        brand_id: brands?.find(
          (brand: CarBrand) =>
            brand.id ===
            models?.find((model: ICarModel) => model.id === car?.model_id)
              ?.brand_id,
        )?.id,
        model_id: models?.find((model: ICarModel) => model.id === car.model_id)
          ?.id,
        type_id: car.type_id,
        trim_id: car.trim_id,
        year: dayjs().year(car.year),
        doors: car.doors,
        condition_id: car.condition_id,
        is_featured: car.is_featured,
        engine_type_id: car.engine_type_id || undefined,
        transmission_id: car.transmission_id || undefined,
        engine_size_id: car.engine_size_id || undefined,
        cylinder_id: car.cylinder_id || undefined,
        battery_capacity_id: car.battery_capacity_id || undefined,
        battery_range_id: car.battery_range_id || undefined,
        exterior_color_id: car.exterior_color_id || undefined,
        interior_color_id: car.interior_color_id || undefined,
        seat_material_id: car.seat_material_id || undefined,
        optional_features_ids: car.optional_features
          ?.filter((feature: any) => {
            const correspondingFeature = carFeatures?.find(
              (carFeature: any) => carFeature.id === feature.id,
            );
            return correspondingFeature
              ? correspondingFeature.is_active
              : false;
          })
          ?.map((feature: any) => feature.id),
        country_id: countries?.find(country =>
          country.cities?.find(city => city.id === car.city_id),
        )?.id,
        city_id: car.city_id,
        variant_id: car.variant_id,
        status: car.status,
        mobile_number:
          car.mobile_number && areaCodeRegex?.test(car.mobile_number)
            ? car.mobile_number.slice(3)
            : car.mobile_number,
        whatsapp_number:
          car.whatsapp_number && areaCodeRegex?.test(car.whatsapp_number)
            ? car.whatsapp_number.slice(3)
            : car.whatsapp_number,
        price: car.price,
        mileage: car.mileage,
        seller_type: isOneAutocar ? (car.dealer_id ? 'dealer' : 'private') : '',
        seller_id: isOneAutocar
          ? dealers?.find((dealer: ICarDealer) => dealer.id === car?.dealer_id)
              ?.id || car.user_id
          : '',
        description: car.description,
        images: initialImages as UploadFile[],
      }
    : undefined;

  //Functions

  const closeRejectForm = () => {
    setIsRejectFormOpen(false);
  };

  const handleReject = async (status: CarStatus, reason: string) => {
    setRejectReason(reason);
  };

  const imageUpload = async ({
    onSuccess: onImageSuccess,
    onProgress,
    file,
    onError,
  }: UploadRequestOption) => {
    try {
      const response = await uploadImage({
        file,
        path: 'used-car-image',
        onProgress: onProgress,
      });
      if (onImageSuccess) onImageSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedImages(prevImages => [...prevImages, addedImage]);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
    }
  };

  const removeImage = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedImages([
        ...removedImages,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedImages(addedImages.filter(image => image.file.uid !== file.uid));
    }
  };

  const handleStatusChange = (value: any) => {
    if (value === CarStatus.rejected) {
      setIsRejectFormOpen(true);
    }
  };

  const resetForm = () => {
    form.resetFields();
  };

  //UseEffects

  useEffect(() => {
    if (countryId) {
      const selectedCountry = countries?.find(
        country => country.id === countryId,
      );
      if (selectedCountry) {
        setCountryName(selectedCountry.code);
        setCountryCode(selectedCountry.area_code);
      }
    }
  }, [countryId, countries]);

  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [car, form, brands, dealers, carConditions, carFeatures]);

  useEffect(() => {
    if (!brandId) {
      form.resetFields();
    }
  }, [models]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values: any) => {
          onSubmit(values).then(res => {
            if (res !== undefined) {
              resetForm();
            }
          });
        }}
        initialValues={initialValues}
        requiredMark={CustomizedRequiredMark}
      >
        <Row gutter={16} wrap>
          <Col span={12}>
            <Item
              label="Country"
              name="country_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the country of the banner',
                },
              ]}
            >
              <Select
                disabled={isLoadingCountries}
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                onChange={() => {
                  form.setFieldsValue({
                    city_id: undefined,
                  });
                }}
              >
                {countries?.map(country => {
                  return (
                    <Select.Option key={country.id} value={country.id}>
                      {country.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="City"
              name="city_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the city of the banner',
                },
              ]}
            >
              <Select
                disabled={!countryId}
                showSearch
                placeholder="Select a city"
                optionFilterProp="children"
              >
                {cityOptions?.map((city: Option) => {
                  return (
                    <Select.Option key={city.code} value={city.code}>
                      {city.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
        </Row>
        {isOneAutocar && (
          <>
            <Row gutter={16} wrap>
              <Col span={6}>
                <Flex>
                  <Space wrap>
                    <Item
                      name="seller_type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select the seller type',
                        },
                      ]}
                      initialValue={'dealer'}
                    >
                      <Radio.Group
                        style={{
                          width: '100%',
                        }}
                      >
                        <Radio value="dealer">Dealer</Radio>
                        <Radio value="private">Private</Radio>
                      </Radio.Group>
                    </Item>
                  </Space>
                </Flex>
              </Col>
              <Col span={12}>
                <Item
                  name="seller_id"
                  rules={[
                    {
                      required: true,
                      message:
                        sellerType === 'dealer'
                          ? 'Select a dealer'
                          : 'Enter the seller id',
                    },
                  ]}
                >
                  {sellerType === 'dealer' ? (
                    <Select
                      showSearch
                      placeholder="Select a dealer"
                      optionFilterProp="children"
                      loading={isLoadingDealerNames}
                      disabled={isLoadingDealerNames}
                      onChange={handleDealerSelect}
                    >
                      {dealers?.map((dealerName: any) => (
                        <Select.Option
                          key={dealerName.id}
                          value={dealerName.id}
                        >
                          {dealerName.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input placeholder="Seller Id" />
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Flex>
                  <Space wrap align="start">
                    <div
                      style={{
                        paddingTop: '5px',
                      }}
                    >
                      <Text>Featured</Text>
                    </div>
                    <Item name="is_featured" valuePropName="checked">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        title="Featured"
                        checked={car?.is_featured ?? false}
                      />
                    </Item>
                  </Space>
                </Flex>
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <Row gutter={16} wrap>
          <Col span={6}>
            <Item
              label="Brand"
              name="brand_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the brand of the car',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a brand"
                optionFilterProp="children"
                loading={isLoadingBrands}
                disabled={isLoadingBrands}
                onChange={() => {
                  form.setFieldsValue({
                    model_id: undefined,
                  });
                }}
              >
                {brands?.map((brand: CarBrand) => (
                  <Select.Option key={brand.id} value={brand.id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label="Model"
              name="model_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the model of the car',
                },
              ]}
              dependencies={['brand']}
            >
              <Select
                showSearch
                placeholder="Select a model"
                optionFilterProp="children"
                loading={isLoadingModels}
                disabled={isLoadingModels || !brandId}
                onChange={() => {
                  form.setFieldsValue({
                    type_id: undefined,
                    trim_id: undefined,
                  });
                }}
              >
                {models?.map((model: any) => (
                  <Select.Option key={model.id} value={model.id}>
                    {model.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label="Type"
              name="type_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the type of the car',
                },
              ]}
              dependencies={['model']}
            >
              <Select
                showSearch
                placeholder="Select a type"
                optionFilterProp="children"
                disabled={!modelId}
              >
                {models
                  ?.find((model: any) => model.id === modelId)
                  ?.model_type.map((type: any) => {
                    return (
                      <Select.Option key={type.types.id} value={type.types.id}>
                        {type.types.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Trim" name="trim_id" dependencies={['model']}>
              <Select
                showSearch
                allowClear
                placeholder="Select a trim"
                optionFilterProp="children"
                loading={isLoadingTrims}
                disabled={isLoadingTrims || !modelId}
              >
                {trims?.map((trim: ICarTrim) => (
                  <Select.Option key={trim.id} value={trim.id}>
                    {trim.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={8}>
            <Item label="Condition" name="condition_id">
              <Radio.Group
                disabled={isLoadingCarConditions}
                style={{
                  width: '100%',
                }}
              >
                {carConditions ? (
                  carConditions.map((condition: any) => (
                    <Radio key={condition.id} value={condition.id}>
                      {condition.name}
                    </Radio>
                  ))
                ) : (
                  <FlexCentered>
                    <Spin tip="Loading..." indicator={<LoadingOutlined />}>
                      <div
                        style={{
                          padding: '0 50px',
                          borderRadius: '4px',
                        }}
                      />
                    </Spin>
                  </FlexCentered>
                )}
              </Radio.Group>
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Door Count" name="doors">
              <Select
                showSearch
                placeholder="Select a door count"
                optionFilterProp="children"
              >
                <Select.Option value={2}>2</Select.Option>
                <Select.Option value={4}>4</Select.Option>
                <Select.Option value={5}>5</Select.Option>
              </Select>
            </Item>
          </Col>
          <Col span={4}>
            <Item
              label="Year"
              name="year"
              rules={[
                {
                  required: true,
                  message: 'Select the year of the car',
                },
              ]}
            >
              <DatePicker
                placeholder="Select a year"
                picker="year"
                disabledDate={current => {
                  return current.year() > dayjs().year() + 1;
                }}
                style={{
                  width: '100%',
                }}
              />
            </Item>
          </Col>
          <Col span={4}>
            <Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: 'Select Status',
                },
              ]}
              tooltip={rejectReason}
            >
              <Select
                showSearch
                placeholder="Select a status"
                optionFilterProp="children"
                onSelect={handleStatusChange}
              >
                {isOneAutocar
                  ? adminStatuses
                      .filter(
                        status =>
                          status.value !== '' &&
                          status.value !== CarStatus.draft &&
                          (!isEditing
                            ? status.value !== CarStatus.rejected
                            : true),
                      )
                      .map((status: any) => (
                        <Select.Option key={status.value} value={status.value}>
                          {status.label}
                        </Select.Option>
                      ))
                  : dealerStatuses.map((status: any) => (
                      <Select.Option key={status.value} value={status.value}>
                        {status.label}
                      </Select.Option>
                    ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={8}>
            <Item label="Engine Type" name="engine_type_id">
              <Select
                showSearch
                placeholder="Select an engine type"
                optionFilterProp="children"
                loading={isLoadingEngineTypes}
                disabled={isLoadingEngineTypes}
              >
                {engineTypes?.map((engineType: any) => (
                  <Select.Option
                    key={engineType.id}
                    value={engineType.id}
                    id={engineType.id}
                  >
                    {engineType.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          {fuelTypeSelected && (
            <Col span={8}>
              <Item label="Transmission" name="transmission_id">
                <Select
                  showSearch
                  placeholder="Select a transmission"
                  optionFilterProp="children"
                  loading={isLoadingTransmissions}
                  disabled={isLoadingTransmissions}
                >
                  {transmissions?.map((transmission: any) => (
                    <Select.Option
                      key={transmission.id}
                      value={transmission.id}
                    >
                      {transmission.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          {electricTypeSelected && (
            <Col span={8}>
              <Item label="Battery Capacity" name="battery_capacity_id">
                <Select
                  showSearch
                  placeholder="Select a battery capacity"
                  optionFilterProp="children"
                  loading={isLoadingBatteryCapacity}
                  disabled={isLoadingBatteryCapacity}
                >
                  {batteryCapacity?.map((capacity: any) => (
                    <Select.Option key={capacity.id} value={capacity.id}>
                      {capacity.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          {electricTypeSelected && (
            <Col span={8}>
              <Item label="Battery Range" name="battery_range_id">
                <Select
                  showSearch
                  placeholder="Select a battery range"
                  optionFilterProp="children"
                  loading={isLoadingBatteryRange}
                  disabled={isLoadingBatteryRange}
                >
                  {batteryRange?.map((range: any) => (
                    <Select.Option key={range.id} value={range.id}>
                      {range.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          {fuelTypeSelected && (
            <Col span={4}>
              <Item label="Engine Size" name="engine_size_id">
                <Select
                  showSearch
                  placeholder="Select an engine size"
                  optionFilterProp="children"
                  loading={isLoadingEngineSizes}
                  disabled={isLoadingEngineSizes}
                >
                  {engineSizes?.map((engineSize: any) => (
                    <Select.Option key={engineSize.id} value={engineSize.id}>
                      {engineSize.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          {fuelTypeSelected && (
            <Col span={4}>
              <Item label="Cylinders" name="cylinder_id">
                <Select
                  showSearch
                  placeholder="Select a cylinder count"
                  optionFilterProp="children"
                  loading={isLoadingCylinders}
                  disabled={isLoadingCylinders}
                >
                  {cylinders?.map((cylinder: any) => (
                    <Select.Option key={cylinder.id} value={cylinder.id}>
                      {cylinder.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
        </Row>
        <Row gutter={16} wrap>
          <Col span={6}>
            <Item label="Exterior Color" name="exterior_color_id">
              <Select
                showSearch
                placeholder="Select an exterior color"
                optionFilterProp="children"
                loading={isLoadingExteriorColors}
                disabled={isLoadingExteriorColors}
              >
                {exteriorColors?.map((color: any) => (
                  <Select.Option key={color.id} value={color.id}>
                    {color.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Interior Color" name="interior_color_id">
              <Select
                showSearch
                placeholder="Select an interior color"
                optionFilterProp="children"
                loading={isLoadingInteriorColors}
                disabled={isLoadingInteriorColors}
              >
                {interiorColors?.map((color: any) => (
                  <Select.Option key={color.id} value={color.id}>
                    {color.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Seats Material" name="seat_material_id">
              <Select
                showSearch
                placeholder="Select a seat material"
                optionFilterProp="children"
                loading={isLoadingSeatMaterials}
                disabled={isLoadingSeatMaterials}
              >
                {seatMaterials?.map((material: any) => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Variant" name="variant_id">
              <Select
                showSearch
                placeholder="Select a variant"
                optionFilterProp="children"
                loading={isLoadingVariants}
                disabled={isLoadingVariants}
              >
                {variants?.map((train: any) => (
                  <Select.Option key={train.id} value={train.id}>
                    {train.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={24}>
            <Item label="Features" name="optional_features_ids">
              <Select
                showSearch
                placeholder="Select the car features (Optional) / You can select multiple features"
                loading={isLoadingCarFeatures}
                disabled={isLoadingCarFeatures}
                mode="multiple"
                optionFilterProp="children"
                allowClear
              >
                {carFeatures?.map((feature: any) => (
                  <Select.Option key={feature.id} value={feature.id}>
                    {feature.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap justify={'center'}>
          <Col span={6}>
            <Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Please enter the price of the car',
                },
              ]}
            >
              <InputNumber
                placeholder="Price of the car"
                min={0}
                addonAfter="QAR"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Odometer" name="mileage">
              <InputNumber
                placeholder="Odometer reading"
                min={0}
                addonAfter="KM"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Mobile Number" name="mobile_number">
              <InputNumber
                placeholder="Mobile number of the seller"
                addonBefore={countryCode}
                minLength={3}
                maxLength={17}
                min={0}
                controls={false}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Whatsapp Number" name="whatsapp_number">
              <InputNumber
                placeholder="Whatsapp number of the seller"
                addonBefore={countryCode}
                minLength={3}
                maxLength={17}
                min={0}
                controls={false}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={24}>
            <Item label="Description" name="description">
              <Input.TextArea
                placeholder="Description of the car"
                rows={4}
                maxLength={600}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={24}>
            <FileUpload
              label="Images"
              maxNbFiles={8}
              name="images"
              fileList={fileList}
              setFileList={setFileList}
              customRequest={imageUpload}
              onRemove={removeImage}
              requiredMessage="Please upload at least one image of the car."
            />
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Space>
            <Button onClick={onClose} danger>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isEditing ? 'Edit' : 'Create'}
            </Button>
          </Space>
        </Row>
      </Form>
      {isRejectFormOpen && (
        <RejectUsedCarsModal
          updateStatusFunction={handleReject}
          onClose={closeRejectForm}
          isVisible={isRejectFormOpen}
          initialReason={rejectReason}
        />
      )}
    </>
  );
};
