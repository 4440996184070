import { useEffect, useState } from 'react';
import {
  Input,
  Popconfirm,
  Table,
  Tabs,
  TabsProps,
  Image,
  message,
  Tag,
  Space,
} from 'antd';
import { useSearch } from '../../../hooks/useSearch';
import { Layout } from '../../../components/Layout';
import { Button } from 'antd/lib';
import { IBanner, IBannerImage } from '../../../models/ads/Banner';
import {
  ALL_BANNERS_KEY,
  useActivateBanner,
  useDeactivateBanner,
  useDeleteBanner,
  useDeleteManyBanners,
  useGetBanners,
} from '../hooks/useBanners';
import dayjs from 'dayjs';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { useGetBannerResources } from '../../banner_resources/hooks/useBannerResources';
import fallBackLogo from '../../../assets/fallback.webp';
import { Language } from '../../../helpers/helperFunctions';
import { queryClient } from '../../../App';
import { ColumnsType } from 'antd/es/table';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import { BannersModal } from './BannersModal';
import { IFilters } from '../../../listings/cars/used_cars/carsApi';
import { FilterDropDown } from '../../../listings/cars/used_cars/components/used_car_form/FilterDropDown';

const { Search } = Input;

enum Filter {
  Expired = 'Expired',
  Ongoing = 'Ongoing',
  Upcoming = 'Upcoming',
}

const groupColors = {
  Expired: 'red',
  Ongoing: 'green',
  Upcoming: 'orange',
};

export const filterBannerOptions: IFilters[] = [
  { text: 'Expired', value: Filter.Expired },
  { text: 'Ongoing', value: Filter.Ongoing },
  { text: 'Upcoming', value: Filter.Upcoming },
];

const BannersLayout = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentBanner, setCurrentBanner] = useState<IBanner | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IBanner | null>(
    null,
  );
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IBanner | null>(null);

  const { handleSearchBanner } = useSearch();
  const [filterBanner, setFilterBanner] = useState<string>('');

  const {
    data: banners,
    isLoading: isLoadingBanners,
    refetch,
  } = useGetBanners({
    enabled: true,
    filter: filterBanner,
  });

  const [selectedBanners, setSelectedBanners] = useState<IBanner[]>([]);

  const {
    mutateAsync: deleteSelectedBanners,
    isLoading: isDeletingSelectedBanners,
  } = useDeleteManyBanners();

  const _deleteSelectedBanners = async () => {
    if (selectedBanners.length === 0) return;
    try {
      await deleteSelectedBanners(selectedBanners.map(banner => banner.id));
      message.success('Banners deleted successfully');
      queryClient.invalidateQueries(['ALL_BANNERS']);
      setSelectedBanners([]);
    } catch (error) {
      message.error('Error deleting banners');
      console.error(error);
    }
  };

  const hanldeSelectFilter = (value: string) => {
    if (value === Filter.Expired) {
      setFilterBanner('Expired');
    } else if (value === Filter.Ongoing) {
      setFilterBanner('Ongoing');
    } else if (value === Filter.Upcoming) {
      setFilterBanner('Upcoming');
    } else {
      setFilterBanner('');
    }
  };

  const [filteredData, setFilteredData] = useState(banners);

  const { data: bannerResources, isLoading: isLoadingBannerResources } =
    useGetBannerResources({
      enabled: true,
    });
  const { mutateAsync: deleteBanner, isLoading: isDeleting } =
    useDeleteBanner();
  const { mutateAsync: activateBanner, isLoading: isActivating } =
    useActivateBanner();
  const { mutateAsync: deactivateBanner, isLoading: isDeactivating } =
    useDeactivateBanner();

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const handleDelete = (id: number) => {
    deleteBanner(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['ALL_BANNERS'],
        });
        setOpenDeleteDialog(null);
        message.success('Banner deleted successfully');
      },
      onError: () => {
        message.error('Error deleting banner');
      },
    });
  };

  const openEmptyForm = () => {
    setIsEditing(false);
    setCurrentBanner(null);
    setIsFormOpen(true);
  };

  const onEditClick = (banner: IBanner) => {
    setCurrentBanner(banner);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const getFallbackImage = (banner: IBanner) => {
    const bannerResource = bannerResources?.find(
      bannerResource => bannerResource.id === banner.banner_resource_id,
    );
    return bannerResource?.banner_resource_images ?? fallBackLogo;
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateBanner(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ALL_BANNERS_KEY,
            });
            setOpenToggleActivateDialog(null);
            message.success('Banner deactivated successfully');
          },
          onError: () => {
            message.error('Error deactivating banner');
          },
        })
      : activateBanner(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ALL_BANNERS_KEY,
            });
            setOpenToggleActivateDialog(null);
            message.success('Banner activated successfully');
          },
          onError: () => {
            message.error('Error activating banner');
          },
        });
  };

  const columns: ColumnsType<IBanner> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Section',
      dataIndex: 'banner_resource_id',
      key: 'banner_resource_id',
      render: (bannerResourceId: number) =>
        bannerResources?.find(
          bannerResource => bannerResource.id === bannerResourceId,
        )?.section_name,
    },
    {
      title: 'Content',
      dataIndex: 'banner_resource_id',
      key: 'banner_resource_id',
      render: (bannerResourceId: number) =>
        bannerResources?.find(
          bannerResource => bannerResource.id === bannerResourceId,
        )?.content_type,
    },
    {
      title: 'Placement',
      dataIndex: 'banner_resource_id',
      key: 'banner_resource_id',
      render: (bannerResourceId: number) =>
        bannerResources?.find(
          bannerResource => bannerResource.id === bannerResourceId,
        )?.placement,
    },
    {
      title: 'Priority',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Advertiser',
      dataIndex: 'advertiser',
      key: 'advertiser',
    },
    {
      title: 'Filter',
      dataIndex: 'filter',
      key: 'filter',
      align: 'center',
      render: (filter: string, record: IBanner) => {
        const currentDate = dayjs();
        const isExpired = dayjs(record.to_date).isBefore(currentDate);
        const isUpcoming = dayjs(record.from_date).isAfter(currentDate);
        const isOngoing = !isExpired && !isUpcoming;

        const filterText = isExpired
          ? Filter.Expired
          : isUpcoming
          ? Filter.Upcoming
          : isOngoing
          ? Filter.Ongoing
          : '';

        return (
          <Tag color={groupColors[filterText as keyof typeof groupColors]}>
            {filterText}
          </Tag>
        );
      },
      filters: filterBannerOptions,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={filterBanner}
            setFilter={hanldeSelectFilter}
            options={filterBannerOptions}
            isLoading={isLoadingBanners}
          />
        );
      },
    },
    {
      title: 'From',
      dataIndex: 'from_date',
      key: 'from_date',
      render: (fromDate: string) => dayjs(fromDate).format('DD/MM/YYYY'),
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) =>
          dayjs(a.from_date).valueOf() - dayjs(b.from_date).valueOf(),
        multiple: 2,
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'To',
      dataIndex: 'to_date',
      key: 'to_date',
      render: (toDate: string) => dayjs(toDate).format('DD/MM/YYYY'),
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) =>
          dayjs(a.to_date).valueOf() - dayjs(b.to_date).valueOf(),
        multiple: 1,
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      render: (_: any, rowData: IBanner) => {
        return (
          <div className="flex justify-content-around">
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this banner.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>
            <EditTwoTone
              style={{ fontSize: '1rem', color: 'var(--primary)' }}
              onClick={() => {
                onEditClick(rowData);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Deleting this banner will delete all its related data."
              open={openDeleteDialog === rowData}
              onConfirm={() => handleDelete(rowData.id)}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                style={{ fontSize: '1rem', color: 'var(--error)' }}
                onClick={() => {
                  setOpenDeleteDialog(rowData);
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const expandedRowRender = (record: IBanner) => {
    const expandedRowColumns: ColumnsType<IBannerImage> = [
      {
        title: 'Image',
        dataIndex: 'name',
        key: 'name',
        width: '40%',
        render: (name: string) => {
          return (
            <Image
              src={name}
              width={120}
              height={50}
              preview={false}
              style={{
                objectFit: 'contain',
              }}
              fallback={getFallbackImage(record)}
              alt="fallback image"
            />
          );
        },
      },
      {
        title: 'Call To Action',
        dataIndex: 'call_to_action',
        key: 'call_to_action',
      },
    ];

    const sortOrder = Object.keys(Language);

    const sortedImages = record.images.sort(
      (a, b) => sortOrder.indexOf(a.lang) - sortOrder.indexOf(b.lang),
    );

    const items: TabsProps['items'] = sortedImages.map(image => {
      return {
        key: image.lang,
        label: Language[image.lang as keyof typeof Language],
        children: (
          <Table
            columns={expandedRowColumns}
            dataSource={record.images.filter(img => img.lang === image.lang)}
            pagination={false}
            rowKey="lang"
          />
        ),
      };
    });
    return <Tabs key={record.id} items={items} />;
  };

  useEffect(() => {
    if (banners && bannerResources) {
      const filteredBanners = handleSearchBanner(
        banners,
        searchValue,
        bannerResources,
      );
      setFilteredData(filteredBanners);
    }
  }, [bannerResources, banners, searchValue]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <div>
          <Search
            placeholder="Search Banners"
            style={{ width: 200 }}
            allowClear
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>
        <Space>
          <div>
            <Button
              type="primary"
              loading={isDeletingSelectedBanners}
              disabled={selectedBanners.length === 0}
              onClick={_deleteSelectedBanners}
              danger
            >
              Delete
            </Button>
          </div>
          <div>
            <Button
              style={{ backgroundColor: 'var(--primary-color)' }}
              type="primary"
              onClick={openEmptyForm}
            >
              Add
            </Button>
          </div>
        </Space>
      </div>
      <Table
        columns={columns as any}
        dataSource={filteredData}
        rowKey="id"
        pagination={false}
        loading={isLoadingBanners || isLoadingBannerResources}
        expandable={{
          expandedRowRender: record => expandedRowRender(record as IBanner),
        }}
        className={'mb-5'}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedBanners(selectedRows);
          },
          selectedRowKeys: selectedBanners.map(banner => banner.id),
        }}
      />
      {isFormOpen && (
        <BannersModal
          isFormOpen={isFormOpen}
          currentBanner={currentBanner}
          isEditing={isEditing}
          bannerResources={bannerResources}
          setIsFormOpen={setIsFormOpen}
          setCurrentBanner={setCurrentBanner}
          closeForm={closeForm}
        />
      )}
    </Layout>
  );
};

export default BannersLayout;
