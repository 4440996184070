import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import MainLayout from './navigation/components/layout/MainLayout';
import { generateRoute } from './navigation/routes/routesIndex';
import { LoginPage } from './navigation/pages/Login/LoginPage';
import ProtectedRoute from './navigation/routes/ProtectedRoute';
import UnprotectedRoute from './navigation/routes/UprotectedRoute';
import { newCarsRoute } from './navigation/pages/Listings/new_cars/NewCars';
import { usedCarsRoute } from './navigation/pages/Listings/UsedCars';
import { allBanksRoutes } from './navigation/pages/Financing/AllBanks';
import { allArticlesRoutes } from './navigation/pages/Articles/AllArticles';
import { usersRoute } from './navigation/pages/Users/Users';
import { notificationsRoute } from './navigation/pages/Notifications/Notifications';
import { batteryCapacityRoute } from './navigation/pages/Resources/BatteryCapacity';
import { batteryRangeRoute } from './navigation/pages/Resources/BatteryRange';
import { bodyTypeRoute } from './navigation/pages/Resources/BodyType';
import { brandsRoute } from './navigation/pages/Resources/Brands';
import { carConditionRoute } from './navigation/pages/Resources/CarConditionModel';
import { cylindersRoute } from './navigation/pages/Resources/Cylinders';
import { drivetrainRoute } from './navigation/pages/Resources/Drivetrain';
import { engineSizeRoute } from './navigation/pages/Resources/EngineSize';
import { engineTypeRoute } from './navigation/pages/Resources/EngineType';
import { exteriorColorRoute } from './navigation/pages/Resources/ExteriorColor';
import { featuresRoute } from './navigation/pages/Resources/Features';
import { interiorColorsRoute } from './navigation/pages/Resources/InteriorColor';
import { modelsRoute } from './navigation/pages/Resources/Models';
import { seatMaterialRoute } from './navigation/pages/Resources/SeatMaterial';
import { transmissionRoute } from './navigation/pages/Resources/Transmission';
import { trimsRoute } from './navigation/pages/Resources/Trims';
import { allDealersRoute } from './navigation/pages/Dealers/AllDealers';
import Unauthorized from './navigation/pages/unauthorized/Unauthorized';
import { AppContextProvider } from './context/AppContextProvider';
import { ApiInterceptor } from './api/ApiInterceptor';
import { modelDeals } from './navigation/pages/Listings/new_cars/NewCarsModel';
import { bannersRoute } from './navigation/pages/Ads/Banners';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { newCarsBrandRoute } from './navigation/pages/Listings/new_cars/NewCarsBrand';
import { analyticsRoute } from './navigation/pages/Analytics/analyticsRoute';
import { rentalsRoute } from './navigation/pages/Listings/Rentals';
import { locationsRoute } from './navigation/pages/Resources/RentalLocations';
import { rentalLeadsRoute } from './navigation/pages/Leads/RentalLeads';
import { rentalLeadsDetailsRoute } from './navigation/pages/Leads/RentalLeadsDetails';
import { UserGroups } from './common/constants';
import { bankingLeadsRoute } from './navigation/pages/Financing/BankingLeads';
import { ConfigProvider, notification } from 'antd';
import { newCarLeadsRoute } from './navigation/pages/Leads/NewCarLeads';
import { newCarLeadsDetailsRoute } from './navigation/pages/Leads/NewCarLeadsDetails';
import { theme } from './constants/theme';
import { cityRoute } from './navigation/pages/Resources/Cities';
import { countryRoute } from './navigation/pages/Resources/Countries';
import { bannerRoute } from './navigation/pages/Resources/Banners';
import { ReviewsRoutes } from './navigation/pages/Reviews/Reviews';
import { ReviewersRoutes } from './navigation/pages/Reviewers/Reviewers';
import { settingsRoute } from './navigation/pages/Settings/Settings';
import { usedCarLeadsRoute } from './navigation/pages/Leads/UsedCarLeads';
import { usedCarLeadsDetailsRoute } from './navigation/pages/Leads/UsedCarLeadsDetails';
import { ramadanOfferLeadsRoute } from './navigation/pages/Leads/RamadanOfferLeads';
import { ramadanOfferLeadsDetailsRoute } from './navigation/pages/Leads/RamadanOfferLeadsDetails';
import { ramadanOffersRoute } from './navigation/pages/Listings/RamadanOffers';
import { bikeBrandsRoute } from './navigation/pages/Resources/bikes/BikeBrand';
import { bikeEngineSizeRoute } from './navigation/pages/Resources/bikes/BikeEngineSize';
import { bikeEngineTypeRoute } from './navigation/pages/Resources/bikes/BikeEngineType';
import { bikeCylindersRoute } from './navigation/pages/Resources/bikes/BikeCylinders';
import { bikeOptionalFeatureRoute } from './navigation/pages/Resources/bikes/BikeOptionalFeatures';
import { bikeConditionRoute } from './navigation/pages/Resources/bikes/BikeConditions';
import { bikeColorRoute } from './navigation/pages/Resources/bikes/BikeColors';
import { bikeTransmissionRoute } from './navigation/pages/Resources/bikes/BikeTransmission';
import { bikeBatteryCapacityRoute } from './navigation/pages/Resources/bikes/BikeBatteryCapacity';
import { bikeBatteryRangeRoute } from './navigation/pages/Resources/bikes/BikeBatteryRange';
import { bikeTypesRoute } from './navigation/pages/Resources/bikes/BikeType';
import { bikeModelsRoute } from './navigation/pages/Resources/bikes/BikeModel';
import { bikeTrimsRoute } from './navigation/pages/Resources/bikes/BikeTrim';
import { newBikesRoute } from './navigation/pages/Listings/bikes/new_bikes';
import { newBikesBrandRoute } from './navigation/pages/Listings/bikes/new_bikes/NewBikeBrand';
import { newBikesDealRoute } from './navigation/pages/Listings/bikes/new_bikes/NewBikeModel';
import { usedBikesRoute } from './navigation/pages/Listings/bikes/UsedBikes';
import { newBikeLeadsRoute } from './navigation/pages/Leads/NewBikeLeads';
import { newBikeLeadsDetailsRoute } from './navigation/pages/Leads/NewBikeLeadsDetails';
import { usedBikeLeadsRoute } from './navigation/pages/Leads/UsedBikeLeads';
import { usedBikeLeadsDetailsRoute } from './navigation/pages/Leads/UsedBikeLeadsDetails';
import { currencyRoute } from './navigation/pages/Resources/Currencies';

export const queryClient = new QueryClient();

function App() {
  const [_, contextHolder] = notification.useNotification();

  return (
    <QueryClientProvider client={queryClient}>
      {contextHolder}
      <AppContextProvider>
        <BrowserRouter>
          <ConfigProvider theme={theme}>
            <ApiInterceptor />
            <Routes>
              {/* Public Routes */}
              <Route element={<UnprotectedRoute />}>
                <Route path="login" element={<LoginPage />} />
              </Route>
              <Route path="unauthorized" element={<Unauthorized />} />
              {/* Protected Routes */}
              <Route element={<ProtectedRoute allowedRoles={['']} />}>
                <Route path="*" element={<h2>This is not available</h2>} />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      'ONE AUTOCAR',
                      'Banking',
                      'Insurance',
                      'Rental Dealer',
                      'Automotive',
                    ]}
                  />
                }
              >
                <Route element={<MainLayout />} path="/">
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={['ONE AUTOCAR']}
                        disabled={true}
                      />
                    }
                  >
                    <Route
                      path={'/'}
                      index={analyticsRoute.index}
                      element={analyticsRoute.element}
                      key={analyticsRoute.state}
                    />
                  </Route>
                  <Route
                    element={<ProtectedRoute allowedRoles={['ONE AUTOCAR']} />}
                  >
                    <Route
                      path={analyticsRoute.path}
                      index={analyticsRoute.index}
                      element={analyticsRoute.element}
                      key={analyticsRoute.state}
                    />
                  </Route>

                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          'ONE AUTOCAR',
                          'Automotive',
                          'Rental Dealer',
                        ]}
                      />
                    }
                  >
                    {generateRoute({
                      path: 'dealers',
                      index: false,
                      state: 'dealer',
                      children: [allDealersRoute],
                    })}
                  </Route>

                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          'ONE AUTOCAR',
                          'Automotive',
                          'Rental Dealer',
                        ]}
                      />
                    }
                  >
                    {generateRoute({
                      path: '/listings',
                      index: false,
                      state: 'listings',
                      children: [
                        {
                          path: '/listings/cars',
                          index: false,
                          state: 'cars',
                          children: [
                            newCarsRoute,
                            usedCarsRoute,
                            modelDeals,
                            newCarsBrandRoute,
                            rentalsRoute,
                            ramadanOffersRoute,
                          ],
                        },
                        {
                          path: '/listings/bikes',
                          index: false,
                          state: 'bikes',
                          children: [
                            newBikesRoute,
                            newBikesDealRoute,
                            newBikesBrandRoute,
                            usedBikesRoute,
                          ],
                        },
                      ],
                    })}
                  </Route>

                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={['ONE AUTOCAR', 'Banking']}
                      />
                    }
                  >
                    {generateRoute({
                      path: 'banks',
                      index: false,
                      state: 'banks',
                      children: [allBanksRoutes],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[UserGroups.OAC, UserGroups.Automotive]}
                      />
                    }
                  >
                    {generateRoute({
                      path: newCarLeadsRoute.path,
                      index: false,
                      state: newCarLeadsRoute.state,
                      children: [newCarLeadsRoute, newCarLeadsDetailsRoute],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[UserGroups.OAC, UserGroups.Automotive]}
                      />
                    }
                  >
                    {generateRoute({
                      path: usedCarLeadsRoute.path,
                      index: false,
                      state: usedCarLeadsRoute.state,
                      children: [usedCarLeadsRoute, usedCarLeadsDetailsRoute],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[UserGroups.OAC, UserGroups.Automotive]}
                      />
                    }
                  >
                    {generateRoute({
                      path: usedBikeLeadsRoute.path,
                      index: false,
                      state: usedBikeLeadsRoute.state,
                      children: [usedBikeLeadsRoute, usedBikeLeadsDetailsRoute],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[UserGroups.OAC, UserGroups.Rental]}
                      />
                    }
                  >
                    {generateRoute({
                      path: rentalLeadsRoute.path,
                      index: false,
                      state: rentalLeadsRoute.state,
                      children: [rentalLeadsRoute, rentalLeadsDetailsRoute],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[UserGroups.Banking, UserGroups.OAC]}
                      />
                    }
                  >
                    {generateRoute({
                      path: bankingLeadsRoute.path,
                      index: false,
                      state: bankingLeadsRoute.state,
                      children: [bankingLeadsRoute],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[UserGroups.OAC, UserGroups.Automotive]}
                      />
                    }
                  >
                    {generateRoute({
                      path: ramadanOfferLeadsRoute.path,
                      index: false,
                      state: ramadanOfferLeadsRoute.state,
                      children: [
                        ramadanOfferLeadsRoute,
                        ramadanOfferLeadsDetailsRoute,
                      ],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[UserGroups.OAC, UserGroups.Automotive]}
                      />
                    }
                  >
                    {generateRoute({
                      path: newBikeLeadsRoute.path,
                      index: false,
                      state: newBikeLeadsRoute.state,
                      children: [newBikeLeadsRoute, newBikeLeadsDetailsRoute],
                    })}
                  </Route>
                  <Route
                    element={<ProtectedRoute allowedRoles={['ONE AUTOCAR']} />}
                  >
                    {generateRoute({
                      path: '/articles',
                      index: false,
                      state: 'articles',
                      children: [allArticlesRoutes],
                    })}
                  </Route>
                  <Route
                    element={<ProtectedRoute allowedRoles={['ONE AUTOCAR']} />}
                  >
                    {generateRoute({
                      path: '/reviews',
                      index: false,
                      state: 'reviews',
                      children: [ReviewsRoutes],
                    })}
                  </Route>
                  <Route
                    element={<ProtectedRoute allowedRoles={[UserGroups.OAC]} />}
                  >
                    {generateRoute({
                      path: '/reviewers',
                      index: false,
                      state: 'reviewers',
                      children: [ReviewersRoutes],
                    })}
                  </Route>
                  <Route
                    element={<ProtectedRoute allowedRoles={['ONE AUTOCAR']} />}
                  >
                    {generateRoute({
                      path: '/ads',
                      index: false,
                      state: 'ads',
                      children: [bannersRoute],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute allowedRoles={usersRoute.allowedGroups} />
                    }
                  >
                    {generateRoute(usersRoute)}
                  </Route>

                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={notificationsRoute.allowedGroups}
                      />
                    }
                  >
                    {generateRoute(notificationsRoute)}
                  </Route>
                  <Route
                    element={<ProtectedRoute allowedRoles={['ONE AUTOCAR']} />}
                  >
                    {generateRoute({
                      path: '/resources',
                      index: false,
                      state: 'resources',
                      children: [
                        {
                          path: '/resources/cars',
                          index: false,
                          state: 'resources.cars',
                          children: [
                            brandsRoute,
                            modelsRoute,
                            trimsRoute,
                            bodyTypeRoute,
                            engineTypeRoute,
                            engineSizeRoute,
                            cylindersRoute,
                            transmissionRoute,
                            drivetrainRoute,
                            batteryRangeRoute,
                            batteryCapacityRoute,
                            featuresRoute,
                            seatMaterialRoute,
                            interiorColorsRoute,
                            exteriorColorRoute,
                            carConditionRoute,
                            locationsRoute,
                          ],
                        },
                        {
                          path: '/resources/bikes',
                          index: false,
                          state: 'resources.bikes',
                          children: [
                            bikeBrandsRoute,
                            bikeEngineSizeRoute,
                            bikeEngineTypeRoute,
                            bikeCylindersRoute,
                            bikeOptionalFeatureRoute,
                            bikeConditionRoute,
                            bikeColorRoute,
                            bikeTransmissionRoute,
                            bikeBatteryCapacityRoute,
                            bikeBatteryRangeRoute,
                            bikeModelsRoute,
                            bikeTypesRoute,
                            bikeTrimsRoute,
                          ],
                        },
                        {
                          path: '/resources/cities',
                          index: false,
                          state: 'resources.cities',
                          children: [cityRoute],
                        },
                        {
                          path: '/resources/countries',
                          index: false,
                          state: 'resources.countries',
                          children: [countryRoute],
                        },
                        {
                          path: '/resources/currencies',
                          index: false,
                          state: 'resources.currencies',
                          children: [currencyRoute],
                        },
                        {
                          path: '/resources/banners',
                          index: false,
                          state: 'resources.banners',
                          children: [bannerRoute],
                        },
                      ],
                    })}
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          UserGroups.OAC,
                          UserGroups.Automotive,
                          UserGroups.Rental,
                        ]}
                      />
                    }
                  >
                    {generateRoute({
                      path: '/settings',
                      index: false,
                      state: 'settings',
                      children: [settingsRoute],
                    })}
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ConfigProvider>
        </BrowserRouter>
      </AppContextProvider>
    </QueryClientProvider>
  );
}

export default App;
