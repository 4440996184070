import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeEngineSizePayload,
  activateBikeEngineSize,
  createBikeEngineSize,
  deactivateBikeEngineSize,
  deleteBikeEngineSize,
  getBikeEngineSizes,
  updateBikeEngineSize,
} from '../apis';
import { queryClient } from '../../../../App';

export const ALL_BIKE_ENGINE_SIZES_KEY = ['allBikeEngineSizes'];

interface IUseBikeEngineSizes {
  enabled: boolean;
  active?: boolean;
}

export const useGetBikeEngineSize = ({
  enabled,
  active,
}: IUseBikeEngineSizes) => {
  return useQuery({
    queryKey: ALL_BIKE_ENGINE_SIZES_KEY,
    queryFn: () => getBikeEngineSizes({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useUpdateBikeEngineSize = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeEngineSizePayload }) =>
      updateBikeEngineSize(id, data),
    mutationKey: ['updateBikeEngineSize'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_ENGINE_SIZES_KEY);
    },
  });
};

export const useCreateBikeEngineSize = () => {
  return useMutation({
    mutationFn: (data: IBikeEngineSizePayload) => createBikeEngineSize(data),
    mutationKey: ['createBikeEngineSize'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_ENGINE_SIZES_KEY);
    },
  });
};

export const useDeleteBikeEngineSize = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeEngineSize(id),
    mutationKey: ['deleteBikeEngineSize'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_ENGINE_SIZES_KEY);
    },
  });
};

export const useActivateBikeEngineSize = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeEngineSize(id),
    mutationKey: ['activateBikeEngineSize'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_ENGINE_SIZES_KEY);
    },
  });
};

export const useDeactivateBikeEngineSize = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeEngineSize(id),
    mutationKey: ['deactivateBikeEngineSize'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_ENGINE_SIZES_KEY);
    },
  });
};
