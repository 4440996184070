import { UserGroups } from '../../../common/constants';
import { UsedBikeLeadsDetailsLayout } from '../../../leads/used_bikes/components/UsedBikeLeadsDetailsLayout';

const UsedBikeLeadsDetails = () => {
  return <UsedBikeLeadsDetailsLayout />;
};

const usedBikeLeadsDetailsRoute = {
  path: '/leads/used-bikes/:dealerSlug/:brandSlug?/:modelSlug?',
  index: true,
  element: <UsedBikeLeadsDetails />,
  state: 'newBikeLeadsDetails',
  sideBarProps: {
    displayText: 'Used Bike Leads Details',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { UsedBikeLeadsDetails, usedBikeLeadsDetailsRoute };
