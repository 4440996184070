import DrivetrainsLayout from '../../../resources/cars/car_drivetrain/DrivetrainsLayout';
import { RouteType } from '../../routes/routesConfig';

const Drivetrain = () => {
  return <DrivetrainsLayout />;
};

const drivetrainRoute: RouteType = {
  element: <Drivetrain />,
  state: 'drivetrain',
  index: true,
  path: '/resources/cars/drivetrain',
  sideBarProps: {
    displayText: 'Drivetrain',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Drivetrain, drivetrainRoute };
