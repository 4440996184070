import api from '../../api/apiIndex';
import { IBanner } from '../../models/ads/Banner';

const bannersEndpoint = 'v2/admin/banners';

enum BannerContentType {
  Video = 'Video',
  Image = 'Image',
}

enum BannerSection {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B1 = 'B1',
  C2 = 'C2',
  C3 = 'C3',
  CP = 'CP',
  D2 = 'D2',
  E1 = 'E1',
  E2 = 'E2',
}

enum BannerPlacement {
  Portal = 'Portal',
  MobileApplication = 'MobileApp',
  MobileApplicationSwipe = 'MobileAppSwipe',
}

type CallToAction = {
  lang: string;
  call_to_action: string;
};

type Title = {
  lang: string;
  title: string;
};

type Thumbnail = {
  lang: string;
  thumbnail: string;
};

type AddImage = {
  id: number | undefined;
};

type RemoveImage = {
  url: string | undefined;
};

export type CreateBannerRequest = {
  placement: string;
  section_name: string;
  content_type: string;
  advertiser: string;
  order: number;
  from_date: string;
  to_date: string;
  city_id: number;
  call_to_actions: CallToAction[];
  titles: Title[];
  add_images: AddImage[];
  thumbnails: Thumbnail[];
};

export type UpdateBannerRequest = {
  placement?: string;
  section_name?: string;
  content_type?: string;
  advertiser?: string;
  order?: number;
  from_date?: string;
  to_date?: string;
  city_id?: number;
  call_to_actions?: CallToAction[];
  titles?: Title[];
  thumbnails?: Thumbnail[];
  add_images?: AddImage[];
  remove_images?: RemoveImage[];
};

export interface IBannersPayload {
  country_id: number;
  name: string;
  arabic_name: string;
  logo: string;
  city_id: number;
}

export interface IBannerParams {
  enabled?: boolean;
  filter?: string;
}

const getBanners = async ({ filter }: IBannerParams): Promise<IBanner[]> => {
  const params: Record<string, string> = {};

  if (filter) {
    params.filter = filter;
  }

  const response = await api.get(bannersEndpoint, {
    params,
  });

  return response.data;
};

const createBanner = async (request: CreateBannerRequest) => {
  const response = await api.post(bannersEndpoint, request);
  return response.data;
};

const updateBanner = async (id: number, request: UpdateBannerRequest) => {
  const response = await api.put(`${bannersEndpoint}/${id}`, request);
  return response.data;
};

const deleteBanner = async (id: number) => {
  const response = await api.delete(`${bannersEndpoint}/${id}`);
  return response.data.message;
};

const deleteManyBanners = async (ids: number[]) => {
  const response = await api.delete(`${bannersEndpoint}/delete`, {
    data: { ids },
  });
  return response.data.message;
};

const activateBanner = async (id: number) => {
  const response = await api.put(`${bannersEndpoint}/activate/${id}`);
  return response.data;
};

const deactivateBanner = async (id: number) => {
  const response = await api.put(`${bannersEndpoint}/deactivate/${id}`);
  return response.data;
};

export {
  getBanners,
  createBanner,
  updateBanner,
  deleteBanner,
  deleteManyBanners,
  activateBanner,
  deactivateBanner,
  BannerContentType,
  BannerSection,
  BannerPlacement,
};
