import api from '../../../api/apiIndex';
import { SearchableEntity } from '../../../search/searchv2Api';
import { RcFile } from 'antd/es/upload';

const rentalsEndpoint = 'v2/admin/cars/rental-cars';

interface IRentalCarsProps {
  page: number;
  perPage?: number;
  searchQueryParams: Record<string, string>;
  country?: string;
}

export type TImage = string | Blob | RcFile;

export interface IRentalPayload {
  small_baggage_size: number;
  large_baggage_size: number;
  price_per_day: number;
  price_per_week: number;
  price_per_month: number;
  price_per_year: number;
  description: string;
  arabic_description: string;
  transmission_id: number;
  type_id: number;
  model_id: number;
  year: number;
  dealer_id: number;
  is_featured: boolean;
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
  seats: number;
  mobile_number?: string | null;
  whatsapp_number?: string | null;
  locations: number[];
}

export const getRentals = async ({
  page,
  perPage,
  searchQueryParams,
  country,
}: IRentalCarsProps) => {
  const queryParams = {
    page,
    perPage: perPage,
    ...searchQueryParams,
  };

  const headers: { country?: string } = {};
  if (country) {
    headers.country = country;
  }

  const response = await api.get(rentalsEndpoint, {
    params: queryParams,
    headers,
  });
  return response.data;
};

export const createRental = async (rentalData: IRentalPayload) => {
  try {
    const response = await api.post(rentalsEndpoint, rentalData);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateRental = async ({
  id,
  data: rentalData,
}: {
  id: number;
  data: IRentalPayload;
}) => {
  try {
    const response = await api.put(`${rentalsEndpoint}/${id}`, rentalData);
    return response.data;
  } catch (err) {}
};

export const deleteRental = async (id: number) => {
  const response = await api.delete(`${rentalsEndpoint}/${id}`);
  return response.data;
};

export const uploadImage = async ({
  file,
  onProgress,
}: {
  file: TImage;
  onProgress?: (progress: { percent: number }) => void;
}) => {
  try {
    const response = await api.post(
      '/cars/rentals/images',
      {
        images: [file],
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: ({ total, loaded }) => {
          if (onProgress && total)
            onProgress({
              percent: (loaded / total) * 100,
            });
        },
      },
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteImage = async ({ idToRemove }: { idToRemove: number }) => {
  try {
    await api.delete(`/cars/rentals/images`, {
      data: {
        images: [idToRemove],
      },
    });
  } catch (err) {
    console.log(err);
  }
};
