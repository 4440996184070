import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeColorPayload,
  activateBikeColor,
  createBikeColor,
  deactivateBikeColor,
  deleteBikeColor,
  getBikeColors,
  updateBikeColor,
} from '../apis';
import { queryClient } from '../../../../App';

export const BIKE_COLOR_QUERY_KEY = ['bikeColors'];

interface IUseBikeColors {
  enabled: boolean;
  active?: boolean;
}

export const useGetBikeColors = ({ enabled, active }: IUseBikeColors) => {
  return useQuery({
    queryKey: BIKE_COLOR_QUERY_KEY,
    queryFn: () => getBikeColors({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeColor = () => {
  return useMutation({
    mutationFn: (data: IBikeColorPayload) => createBikeColor(data),
    mutationKey: ['createBikeColor'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_COLOR_QUERY_KEY);
    },
  });
};

export const useUpdateBikeColor = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeColorPayload }) =>
      updateBikeColor(id, data),
    mutationKey: ['updateBikeColor'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_COLOR_QUERY_KEY);
    },
  });
};

export const useDeleteBikeColor = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeColor(id),
    mutationKey: ['deleteBikeColor'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_COLOR_QUERY_KEY);
    },
  });
};

export const useActivateBikeColor = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeColor(id),
    mutationKey: ['activateBikeColor'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_COLOR_QUERY_KEY);
    },
  });
};

export const useDeactivateBikeColor = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeColor(id),
    mutationKey: ['deactivateBikeColor'],
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_COLOR_QUERY_KEY);
    },
  });
};
