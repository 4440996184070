import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBatteryCapacityPayload,
  activateBatteryCapacity,
  createBatteryCapacity,
  deactivateBatteryCapacity,
  deleteBatteryCapacity,
  getBatteryCapacities,
  updateBatteryCapacity,
} from '../apis/batteryCapacityApi';
import { queryClient } from '../../../../App';

export const ALL_BATTERY_CAPACITY = ['allBatteryCapacity'];

interface IUseBatteryCapacity {
  enabled: boolean;
  active?: boolean;
}

export const useBatteryCapacity = ({
  enabled,
  active,
}: IUseBatteryCapacity) => {
  return useQuery({
    queryKey: ALL_BATTERY_CAPACITY,
    queryFn: () => getBatteryCapacities({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBatteryCapacity = () => {
  return useMutation({
    mutationFn: (data: IBatteryCapacityPayload) => createBatteryCapacity(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BATTERY_CAPACITY);
    },
  });
};

export const useUpdateBatteryCapacity = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBatteryCapacityPayload }) =>
      updateBatteryCapacity(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BATTERY_CAPACITY);
    },
  });
};

export const useDeleteBatteryCapacity = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBatteryCapacity(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BATTERY_CAPACITY);
    },
  });
};

export const useActivateBatteryCapacity = () => {
  return useMutation({
    mutationFn: (id: number) => activateBatteryCapacity(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BATTERY_CAPACITY);
    },
  });
};

export const useDeactivateBatteryCapacity = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBatteryCapacity(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BATTERY_CAPACITY);
    },
  });
};
