import { rentalLeadsRoute } from './RentalLeads';
import { UserGroups } from '../../../common/constants';
import { newCarLeadsRoute } from './NewCarLeads';
import { usedCarLeadsRoute } from './UsedCarLeads';
import { ramadanOfferLeadsRoute } from './RamadanOfferLeads';
import { newBikeLeadsRoute } from './NewBikeLeads';
import { usedBikeLeadsRoute } from './UsedBikeLeads';

const allLeadsRoute = {
  index: false,
  state: 'leads',
  children: [
    newCarLeadsRoute,
    usedCarLeadsRoute,
    rentalLeadsRoute,
    ramadanOfferLeadsRoute,
    newBikeLeadsRoute,
    usedBikeLeadsRoute,
  ],
  sideBarProps: {
    displayText: 'Leads',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive, UserGroups.Rental],
};

export { allLeadsRoute };
