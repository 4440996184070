import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Form, Input, Select, Space, Button, Row, Col } from 'antd';
import { ICarType, ICarTypes } from '../../../../models/resources/CarType';
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import { uploadImage } from '../../../../common/utils';
import type {
  UploadRequestError,
  UploadRequestOption,
} from 'rc-upload/lib/interface';
import FileUpload from '../../../../components/inputs/FileUpload';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CarBrand } from '../../../../models/resources/CarBrand';
import { AxiosError } from 'axios';
import { CustomizedRequiredMark } from '../../../../components/misc/RequiredMark';
import {
  IBikeModel,
  IBikeModelImage,
  IBikeModelT,
} from '../../../../models/resources/bikes/BikeModel';
import { useGetAllBikeBrands } from '../../bike_brands/hooks';
import { useBikeTypes } from '../../bike_types/hooks';
import {
  CustomUploadFileStatus,
  IBikeModelAddedImages,
} from './BikeModelModal';

type AddEditModelFormProps = {
  model: IBikeModel | null;
  isEditing: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (model: IBikeModel) => void;
  addedImages: any[];
  removedImages: any[];
  setAddedImages: Dispatch<SetStateAction<IBikeModelAddedImages[]>>;
  setRemovedImages: (urls: Array<Record<string, string>>) => void;
};

const { Item } = Form;

const AddEditBikeModelForm = ({
  model,
  isEditing,
  isLoading,
  onClose,
  onSubmit,
  addedImages,
  removedImages,
  setAddedImages,
  setRemovedImages,
}: AddEditModelFormProps) => {
  const [form] = Form.useForm();

  const { data: brands, isLoading: isBrandLoading } = useGetAllBikeBrands({
    enabled: true,
    active: true,
  });

  const { data: types, isLoading: isTypeLoading } = useBikeTypes({
    enabled: true,
    active: true,
  });

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const onChangeType = (
    checkedValues: CheckboxValueType | CheckboxValueType[],
  ) => {
    const selectedTypesArray = Array.isArray(checkedValues)
      ? checkedValues
      : [checkedValues];
    setSelectedTypes(selectedTypesArray.toString().split(','));
  };

  const onChangeBrand = (brandId: number) => {
    form.setFieldsValue({ brand_id: brandId });
  };

  const initialCoverImage = model?.cover
    ? [
        {
          uid: model.cover,
          name: model.cover?.split('/').pop(),
          id: model.cover,
          status: 'old',
          url: model.cover,
        },
      ]
    : [];

  const initialImages = model?.image
    ? Array.isArray(model?.image)
      ? model?.image.map((image: any) => ({
          uid: image,
          name: image?.split('/').pop(),
          id: image,
          status: 'old',
          url: image,
        }))
      : [
          {
            uid: model?.image,
            name: (model.image as string | undefined)?.includes('/')
              ? (model.image as string).split('/').pop()
              : undefined,
            id: model.image,
            status: 'old',
            url: model.image,
          },
        ]
    : [];

  const [fileListCover, setFileListCover] = useState<UploadFile[]>(
    initialCoverImage as UploadFile[],
  );
  const [fileList, setFileList] = useState<UploadFile[]>(
    initialImages as UploadFile[],
  );

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const initialValues = model
    ? {
        name: model?.name,
        arabic_name: model?.t.find((t: IBikeModelT) => t.lang === 'ar')?.name,
        brand_id: model?.brand.id,
        types: isEditing
          ? model?.model_type.map((type: ICarTypes) => type?.types?.id)
          : [],

        description: model?.description,
        arabic_description: model?.t.find((t: IBikeModelT) => t.lang === 'ar')
          ?.description,
        cover: initialCoverImage,
        image: initialImages,
      }
    : undefined;

  const imageUpload = async ({
    onSuccess,
    onProgress,
    file,
    data,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'bike-model-image',
        onProgress: onProgress,
        extraBodyData: {
          type: data?.type,
        },
      });
      if (onSuccess) onSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedImages(prevImages => [...prevImages, addedImage]);
      setIsUploadingImage(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      const addedImage = { ...(response as any), status: 'error', file };
      setAddedImages(prevImages => [...prevImages, addedImage]);
      setIsUploadingImage(false);
    }
  };

  const removeImage = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedImages([
        ...removedImages,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedImages(addedImages.filter(image => image.file.uid !== file.uid));
    }
  };

  const resetFrom = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [form, model]);

  return (
    <Form
      form={form}
      onFinish={(values: any) => {
        onSubmit(values);
        resetFrom();
        setSelectedTypes([]);
        setFileListCover([]);
        setFileList([]);
        setAddedImages([]);
        setRemovedImages([]);
      }}
      layout="vertical"
      initialValues={initialValues}
      name="modelForm"
      requiredMark={CustomizedRequiredMark}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter Name' }]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              { required: true, message: 'Please enter an Arabic Name  ' },
            ]}
          >
            <Input placeholder=" Arabic Name " />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="brand_id"
            rules={[{ required: true, message: 'Please Select A brand' }]}
            label="Brand"
          >
            <Select
              showSearch
              placeholder="Please Select A brand"
              optionFilterProp="children"
              loading={isBrandLoading}
              disabled={isBrandLoading}
              onChange={onChangeBrand}
              options={brands?.map((brand: CarBrand) => ({
                value: brand.id,
                label: brand.name,
              }))}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="types"
            rules={[{ required: true, message: 'Please Select A Type' }]}
            label="Types"
          >
            <Select
              showSearch
              placeholder="Please Select A Type"
              optionFilterProp="children"
              mode="multiple"
              value={selectedTypes}
              onChange={onChangeType}
              loading={isTypeLoading}
              disabled={isTypeLoading}
              options={types?.map((type: ICarType) => ({
                value: type.id,
                label: type.name,
              }))}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={4}>
          <FileUpload
            label="Cover Image"
            maxNbFiles={1}
            name="cover"
            fileList={fileListCover}
            setFileList={setFileListCover}
            customRequest={props =>
              imageUpload({
                ...props,
                data: { type: 'cover' },
              })
            }
            onRemove={removeImage}
            rules={false}
          />
        </Col>
        <Col span={20}>
          <FileUpload
            label="360° Images"
            maxNbFiles={5}
            name="image"
            fileList={fileList}
            setFileList={setFileList}
            customRequest={props =>
              imageUpload({
                ...props,
                data: { type: 'image' },
              })
            }
            onRemove={removeImage}
            rules={false}
          />
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: 'Please enter a Description  ' },
            ]}
          >
            <Input.TextArea placeholder="Description" size="large" rows={4} />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Description"
            name="arabic_description"
            rules={[
              {
                required: true,
                message: 'Please enter an Arabic Description  ',
              },
            ]}
          >
            <Input.TextArea
              placeholder=" Arabic Description "
              size="large"
              rows={4}
            />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              resetFrom();
              setSelectedTypes([]);
              setFileListCover([]);
              setFileList([]);
              setAddedImages([]);
              onClose();
              setRemovedImages([]);
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={
              isUploadingImage ||
              addedImages.filter(img => img.status !== 'done').length > 0
            }
          >
            {isEditing ? 'Edit Model' : 'Add Model'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default AddEditBikeModelForm;
