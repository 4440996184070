export enum UserGroups {
  Rental = 'Rental Dealer',
  Automotive = 'Automotive',
  Banking = 'Banking',
  Insurance = 'Insurance',
  OAC = 'ONE AUTOCAR',
  User = 'Users',
}

export enum AccessLevels {
  SuperAdmin = 'Super Admin',
  Admin = 'Admin',
  Analyst = 'Analyst',
  Editor = 'Editor',
}
