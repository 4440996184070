import { Modal, message } from 'antd';
import { AddEditUsedForm } from './AddEditUsedForm';
import { IUsedCar } from '../../../../../models/listings/cars/used_cars/UsedCar';
import { queryClient } from '../../../../../App';
import { useState } from 'react';
import { useCreateUsedCar, useEditUsedCar } from '../../hooks/useUsedCars';
import { RcFile } from 'antd/es/upload';
import { CarStatus } from '../../carsApi';

export interface IUsedCarImageUpload {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface IUsedCarImages extends IUsedCarImageUpload {
  file: RcFile;
}

type AddEditUsedCarFormProps = {
  car: IUsedCar | null;
  isEditing: boolean;
  onClose: () => void;
  isVisible: boolean;
  setCurrentCar: (value: IUsedCar | null) => void;
  setIsFormOpen: (value: boolean) => void;
  setIsEditing: (value: boolean) => void;
};

export const AddEditUsedCarModal = ({
  car,
  isEditing,
  onClose,
  isVisible,
  setCurrentCar,
  setIsEditing,
  setIsFormOpen,
}: AddEditUsedCarFormProps) => {
  const [addedImages, setAddedImages] = useState<IUsedCarImages[]>([]);
  const [removedImages, setRemovedImages] = useState<
    Array<Record<string, string>>
  >([]);
  const [countryCode, setCountryCode] = useState('+974');

  const [rejectReason, setRejectReason] = useState(car?.reason);

  const { mutateAsync: createUsedCar, isLoading: isCreating } =
    useCreateUsedCar();
  const { mutateAsync: updateUsedCar, isLoading: isUpdating } =
    useEditUsedCar();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      data.year = data.year.year();
      delete data.images;
      data.mobile_number = data.mobile_number
        ? `${countryCode?.slice(1)}${data.mobile_number.toString()}`
        : null;
      data.whatsapp_number = data.whatsapp_number
        ? `${countryCode?.slice(1)}${data.whatsapp_number.toString()}`
        : null;
      const response = await createUsedCar({
        ...data,
        add_images: addedImages.map(image => ({ id: image.id })),
      });
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['usedCars'],
      });
      message.success('Used Car created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (data: any) => {
    try {
      data.year = data.year.year();
      data.reason = rejectReason;
      delete data.images;
      data.mobile_number = data.mobile_number
        ? `${countryCode?.slice(1)}${data.mobile_number.toString()}`
        : null;
      data.whatsapp_number = data.whatsapp_number
        ? `${countryCode?.slice(1)}${data.whatsapp_number.toString()}`
        : null;
      if (
        data.status === CarStatus.rejected &&
        (!rejectReason || rejectReason === '')
      ) {
        message.error('Please enter a reason to reject the used car');
        return;
      }
      if (!data.trim_id) {
        data.trim_id = null;
      }
      const response = await updateUsedCar({
        id: car!.id,
        data: {
          ...data,
          add_images: addedImages.map(image => ({ id: image.id })),
          remove_images: removedImages,
        },
      });
      setIsFormOpen(false);
      setCurrentCar(null);
      queryClient.invalidateQueries({
        queryKey: ['usedCars'],
      });
      message.success('Used car updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal
      key={car?.id}
      title={isEditing ? 'Edit Listing' : 'Create Listing'}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={1200}
    >
      <AddEditUsedForm
        key={car?.id}
        car={car}
        onSubmit={isEditing ? _edit : _create}
        isEditing={isEditing}
        isLoading={isCreating || isUpdating}
        onClose={onClose}
        addedImages={addedImages}
        removedImages={removedImages}
        rejectReason={rejectReason}
        setAddedImages={setAddedImages}
        setRemovedImages={setRemovedImages}
        setRejectReason={setRejectReason}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    </Modal>
  );
};
