import { INewBike } from '../../../../models/listings/bikes/newBikes';
import { Language } from '../../../../helpers/helperFunctions';
import { NewBikeDealCard } from './NewBikeDealCard';

type NewBikeDealsListProps = {
  deals: INewBike[];
  onEditClick: (deal: INewBike) => void;
  onDeleteClick: (deal: INewBike) => void;
  loading?: boolean;
};

export const NewBikeDealList = ({
  deals,
  onDeleteClick,
  onEditClick,
}: NewBikeDealsListProps) => {
  return (
    <div className="w-full flex flex-column row-gap-5">
      {deals?.length > 0 &&
        deals.map((deal: INewBike) => (
          <NewBikeDealCard
            onEdit={() => onEditClick(deal)}
            onDelete={() => onDeleteClick(deal)}
            key={deal?.id}
            deal={deal}
            lang={Language.en}
          />
        ))}
    </div>
  );
};
