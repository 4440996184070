import api from '../../../api/apiIndex';

const countriesEndpointV2 = 'v2/admin/resources/countries';

export interface IGetCountries {
  active?: boolean;
}

export interface ICountryPayload {
  name: string;
  arabic_name: string;
}

export const getCountries = async ({ active }: IGetCountries) => {
  try {
    const response = await api.get(countriesEndpointV2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCountriesAreCodes = async () => {
  try {
    const response = await api.get(`${countriesEndpointV2}/area-codes`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const createCountry = async (data: ICountryPayload) => {
  try {
    const response = await api.post(countriesEndpointV2, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateCountry = async (id: number, data: ICountryPayload) => {
  try {
    const response = await api.put(`${countriesEndpointV2}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteCountry = async (id: number) => {
  try {
    const response = await api.delete(`${countriesEndpointV2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCountry = async (id: number) => {
  try {
    const response = await api.put(`${countriesEndpointV2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCountry = async (id: number) => {
  try {
    const response = await api.put(`${countriesEndpointV2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
