import CarTypesEditAddDeleteForm from './CarTypesEditAddDeleteForm';
import { useCreateCarType, useUpdateCarType } from '../hooks';
import { ICarType } from '../../../../models/resources/CarType';
import { Modal, message } from 'antd';

interface ICarTypesModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentType: ICarType | null;
  setCurrentType: (value: ICarType | null) => void;
  isLoadingTypes: boolean;
}

const CarTypesModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentType,
  setCurrentType,
  isLoadingTypes,
}: ICarTypesModalProps) => {
  const { mutateAsync: createTypeMutation, isLoading: isCreating } =
    useCreateCarType();
  const { mutateAsync: updateTypeMutation, isLoading: isUpdating } =
    useUpdateCarType();

  const _create = async (data: any) => {
    try {
      const response = await createTypeMutation(data);
      setIsFormOpen(false);
      message.success('Type created successfully');
      return response;
    } catch (error: any) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateTypeMutation({
        id: currentType!.id,
        data,
      });
      message.success('Type updated successfully');
      setCurrentType(null);
      setIsFormOpen(false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Type' : 'Add Type'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentType?.id}
      >
        <CarTypesEditAddDeleteForm
          resourceType={currentType}
          isLoading={isLoadingTypes}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          onClose={() => setIsFormOpen(false)}
          isSubmitting={isCreating || isUpdating}
        />
      </Modal>
    </>
  );
};

export default CarTypesModal;
