import { Space, Checkbox, Button } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { IFilters } from './FilterDropDown';
import { FlexColumn } from '../Flex';

interface CheckboxFilterDropdownProps {
  filter: string[];
  setFilter: (value: any) => void;
  options: IFilters[];
  isLoading: boolean;
}

export const CheckboxFilterDropdown = ({
  filter,
  setFilter,
  options,
  isLoading,
}: CheckboxFilterDropdownProps) => {
  const onChange = (checkedValues: any) => {
    setFilter(checkedValues);
  };

  const handleClear = () => {
    setFilter(['']);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FlexColumn
        style={{
          padding: '0.5rem',
          overflowY: 'auto',
          minHeight: '100px',
          maxHeight: '400px',
        }}
      >
        <Checkbox.Group
          onChange={onChange}
          value={filter as unknown as CheckboxValueType[]}
          disabled={isLoading}
        >
          <Space direction="vertical">
            {options.map((option: any) => (
              <Checkbox key={option.value} value={option.value}>
                {option.text}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </FlexColumn>
      <Button
        type="primary"
        onClick={handleClear}
        disabled={isLoading}
        size="small"
        style={{
          margin: '0.5rem',
          width: '50px',
          visibility: 'visible',
          alignSelf: 'flex-end',
        }}
      >
        Clear
      </Button>
    </div>
  );
};
