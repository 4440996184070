import { UserGroups } from '../../../../common/constants';
import { BikeConditionsLayout } from '../../../../resources/bikes/conditions';
import { RouteType } from '../../../routes/routesConfig';

const BikeCondition = () => {
  return <BikeConditionsLayout />;
};

const bikeConditionRoute: RouteType = {
  element: <BikeCondition />,
  state: 'condition',
  index: true,
  path: '/resources/bikes/conditions',
  sideBarProps: {
    displayText: 'Conditions',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeCondition, bikeConditionRoute };
