import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Language } from '../../../../helpers/helperFunctions';
import { INewCarDeal } from '../../../../models/resources';
import { Popconfirm, message } from 'antd';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';
import { useState } from 'react';
import {
  useActivateDeal,
  useDeactivate,
  useDeleteDeal,
} from '../hooks/useDeals';
import useAuth from '../../../../hooks/useAuth';
import { UserGroups } from '../../../../common/constants';

type NewDealCardProps = {
  deal: INewCarDeal;
  lang: Language;
  onEdit: () => void;
  onDelete: () => void;
};
const NewDealCard = ({ deal, lang, onEdit, onDelete }: NewDealCardProps) => {
  const info = {
    price: deal?.price,
    title: lang == Language.en ? deal?.title : deal?.arabic_title,
    description:
      lang == Language.en ? deal?.description : deal?.arabic_description,
    dealerName: deal?.dealer?.name,
    currency: deal?.cities?.currencies?.name,
  };

  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isSuperAdmin = userGroup === UserGroups.OAC;

  const { mutateAsync: activateDeal, isLoading: isActive } = useActivateDeal();

  const { mutateAsync: deactiveDeal, isLoading: isDeactive } = useDeactivate();

  const { mutateAsync: deleteDeal, isLoading: isDeletingDeal } =
    useDeleteDeal();

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<INewCarDeal | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<INewCarDeal | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactiveDeal(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Deal Deactivated Successfully');
          },
        })
      : activateDeal(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Deal Activated Successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteDeal(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Deal Deleted Successfully');
      },
    });
  };

  return (
    <div className="border-round-lg w-90 shadow-5 flex flex-column row-gap-3 p-5">
      <div
        className="flex column-gap-5"
        style={{ alignSelf: 'end', width: 'fit-content' }}
        key={deal?.id}
      >
        {isSuperAdmin && (
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              deal.is_active ? 'deactivate' : 'activate'
            } this deal.`}
            open={openToggleActivateDialog === deal}
            onConfirm={() => handleToggleActivate(deal.id, deal.is_active)}
            okButtonProps={{
              loading: isActive || isDeactive,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {deal.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(deal);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(deal);
                }}
              />
            )}
          </Popconfirm>
        )}
        <Popconfirm
          title="Are you sure?"
          description="Deleting this deal will delete all its related data."
          open={openDeleteDialog === deal}
          onConfirm={() => handleDelete(deal.id)}
          okButtonProps={{ loading: isDeletingDeal }}
          onCancel={() => setOpenDeleteDialog(null)}
          okText="Yes"
          placement="topRight"
        >
          <DeleteTwoTone
            onClick={() => {
              setOpenDeleteDialog(deal);
            }}
          />
        </Popconfirm>
        <EditTwoTone onClick={onEdit} />
      </div>
      <p
        style={{
          fontSize: '25px',
          fontWeight: '900',
          textTransform: 'uppercase',
        }}
      >
        {info.title}
      </p>
      <p dangerouslySetInnerHTML={{ __html: info?.description }} />
      {info.price ? (
        <p>
          Starting from {info.price} {info.currency}
        </p>
      ) : (
        <p>No price, Contact {info.dealerName} for quotation.</p>
      )}
    </div>
  );
};

export default NewDealCard;
