import { useEffect, useState } from 'react';
import { Input, Table, Button, Tag, message, Popconfirm } from 'antd';
import { ICarDrivetrain } from '../../../models/resources/CarDrivetrain';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { useSearch } from '../../../hooks/useSearch';
import {
  useActivateDriveTrain,
  useDeactivateDriveTrain,
  useDeleteDriveTrain,
  useDriveTrain,
} from './hooks';
import { useEngineTypes } from '../engine_types/hooks';
import { DriveTrainModal } from './components';
import { Layout } from '../../../components/Layout';
import { ColumnsType } from 'antd/lib/table/interface';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';

const { Search } = Input;

const DrivetrainsLayout = () => {
  const { data: drivetrains, isLoading: isDriveTrainLoading } = useDriveTrain({
    enabled: true,
  });
  const { data: engineTypes } = useEngineTypes({
    enabled: true,
  });

  const { mutateAsync: deleteDrivetrain, isLoading: isDeleting } =
    useDeleteDriveTrain();
  const { mutateAsync: activateDrivetrain, isLoading: isActivating } =
    useActivateDriveTrain();
  const { mutateAsync: deactivateDrivetrain, isLoading: isDeactivating } =
    useDeactivateDriveTrain();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentDrivetrain, setCurrentDrivetrain] =
    useState<ICarDrivetrain | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchCarDriveTrain } = useSearch();
  const [filteredData, setFilteredData] = useState(drivetrains);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarDrivetrain | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<ICarDrivetrain | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateDrivetrain(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Drivetrain deactivated successfully');
          },
        })
      : activateDrivetrain(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Drivetrain activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteDrivetrain(id, {
      onSuccess: () => {
        message.success('Drivetrain deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (drivetrain: ICarDrivetrain) => {
    setCurrentDrivetrain(drivetrain);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentDrivetrain(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchCarDriveTrain(drivetrains, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchCarDriveTrain(drivetrains, searchValue);
    setFilteredData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(drivetrains);
  }, [drivetrains]);

  const tableColumns: ColumnsType<ICarDrivetrain> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${drivetrains?.map(
        (drivetrain: ICarDrivetrain) => drivetrain.t[0]?.name,
      )}`,
      key: `${drivetrains?.map(
        (drivetrain: ICarDrivetrain) => drivetrain.t[0]?.name,
      )}`,
      render: (text: any, record: ICarDrivetrain) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarDrivetrain) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICarDrivetrain) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this drivetrain will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this driveTrain.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Here"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Drivetrain
        </Button>
      </div>
      <Table
        loading={drivetrains?.length === 0 || isDriveTrainLoading}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={false}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
      />
      <DriveTrainModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        currentDrivetrain={currentDrivetrain}
        setCurrentDrivetrain={setCurrentDrivetrain}
        engineTypes={engineTypes}
      />
    </Layout>
  );
};

export default DrivetrainsLayout;
