import { Tag, Button, Table, Popconfirm, message, Image, Select } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { useDeleteBanner, useGetBanners, useUpdateBanner } from './hooks';
import { IBannerResource } from '../../models/ads/BannerResource';
import { ColumnsType } from 'antd/es/table';
import { Layout } from '../../components/Layout';
import { BannersModal } from './components';
import { queryClient } from '../../App';
import { FilterDropDown } from '../../listings/cars/used_cars/components/used_car_form/FilterDropDown';
import { IFilters } from '../../listings/cars/used_cars/carsApi';

export const BannersLayout = () => {
  enum ShowDefaultImageStatus {
    Yes = 'Yes',
    No = 'No',
  }

  const showDefaultImageStatus: IFilters[] = [
    { text: 'Yes', value: ShowDefaultImageStatus.Yes },
    { text: 'No', value: ShowDefaultImageStatus.No },
  ];
  const [editKey, setEditKey] = useState<string | null>(null);
  const inputRef = useRef<any>(null);
  const {
    data: banners,
    isLoading: isBannersLoading,
    refetch,
  } = useGetBanners({
    enabled: true,
  });

  const [selectedStatus, setSelectedStatus] = useState<string>('');

  const { mutateAsync: deleteBanner, isLoading: isDeleting } =
    useDeleteBanner();
  const { mutateAsync: updateBanner } = useUpdateBanner();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBanner, setCurrentBanner] = useState<IBannerResource | null>(
    null,
  );
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<IBannerResource | null>(null);

  const handleDelete = (id: number) => {
    deleteBanner(id, {
      onSuccess: () => {
        message.success('Banner deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const handleEditCell = (key: string) => {
    setEditKey(key);
    inputRef.current = key;
  };

  const save = async (
    newValues: any,
    oldValue: any,
    required: boolean = false,
  ) => {
    const { id, ...rest } = newValues;
    const keys = Object.keys(rest);
    const firstKey = keys[0];
    if (rest[firstKey] !== oldValue) {
      await updateBanner({
        id,
        data: {
          ...rest,
          show_default_image: rest.show_default_image === 'Yes' ? true : false,
        },
      });
      queryClient.invalidateQueries({
        queryKey: ['bannersStatus'],
      });
      message.success('Banners show default image updated successfully');
    }
    setEditKey(null);
    inputRef.current = null;
  };

  const handleSelectStatus = (value: string) => {
    setSelectedStatus(value);
    if (value === '') {
      setEditKey(null);
      inputRef.current = null;
    } else {
      const banner = banners?.find(
        (banner: IBannerResource) =>
          banner.show_default_image === (value === 'Yes'),
      );
      setEditKey(`${banner?.id}-show_default_image`);
    }
  };

  const onEditButtonPress = (banner: IBannerResource) => {
    setCurrentBanner(banner);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setIsEditing(false);
    setIsFormOpen(true);
  };

  const tableColumns: ColumnsType<IBannerResource> = [
    {
      title: 'Section',
      dataIndex: 'section_name',
      key: 'section_name',
      width: '5%',
    },
    {
      title: 'Placement',
      dataIndex: 'placement',
      key: 'placement',
      width: '10%',
    },
    {
      title: 'Content Type',
      dataIndex: 'content_type',
      key: 'content_type',
      width: '10%',
    },
    {
      title: 'Image',
      dataIndex: 'banner_resource_images',
      key: 'banner_resource_images',
      width: '10%',
      render: (images: string, record: IBannerResource) => {
        return record.content_type === 'Video' ? (
          <span>N/A</span>
        ) : (
          <Image
            src={images}
            fallback="https://play-lh.googleusercontent.com/J-SpF7SizHC3yGtAIxl00_jq5q6hkZO1A2zE0efFSAjr_SNEW5N37aMgocinR75utQ=w240-h480-rw"
            style={{
              width: '70px',
              height: '70px',
            }}
          />
        );
      },
    },
    {
      title: 'Show Default Image',
      dataIndex: 'show_default_image',
      key: 'show_default_image',
      width: '10%',
      render: (rowData: ShowDefaultImageStatus, record: IBannerResource) =>
        `${record.id}-show_default_image` === editKey ? (
          record.content_type === 'Video' ? (
            <Tag color={rowData ? 'green' : 'red'}>
              {rowData ? 'Yes' : 'No'}
            </Tag>
          ) : (
            <Select
              ref={inputRef}
              style={{ width: '30%' }}
              showSearch
              placeholder="Select Status"
              optionFilterProp="children"
              defaultValue={
                rowData ? ShowDefaultImageStatus.Yes : ShowDefaultImageStatus.No
              }
              onSelect={value =>
                save(
                  { id: record.id, show_default_image: value },
                  rowData,
                  true,
                )
              }
              onBlur={() => setEditKey(null)}
            >
              {showDefaultImageStatus
                .filter(status => status.value !== '')
                .map((status: any) => (
                  <Select.Option key={status.value} value={status.value}>
                    {status.text}
                  </Select.Option>
                ))}
            </Select>
          )
        ) : (
          <Tag
            style={{
              cursor: 'pointer',
            }}
            color={rowData ? 'green' : 'red'}
            onClick={() => handleEditCell(`${record.id}-show_default_image`)}
            onBlur={() => setEditKey(null)}
          >
            {rowData ? 'Yes' : 'No'}
          </Tag>
        ),
      filters: showDefaultImageStatus,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={selectedStatus}
            setFilter={handleSelectStatus}
            options={showDefaultImageStatus}
            isLoading={isBannersLoading}
          />
        );
      },
    },
    {
      title: 'Action',
      key: 'actions',
      width: '5%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IBannerResource) => (
        <div className="flex justify-content-center gap-4">
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this banner will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={openEmptyForm}>
          Add Banner
        </Button>
      </div>
      <Table
        loading={isBannersLoading}
        dataSource={banners}
        columns={tableColumns}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
        pagination={false}
      />
      <BannersModal
        currentBanner={currentBanner}
        isEditing={isEditing}
        isFormOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        setCurrentBanner={setCurrentBanner}
        setIsFormOpen={setIsFormOpen}
      />
    </Layout>
  );
};
