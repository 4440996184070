import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IDrivetrainPayload,
  activateDrivetrain,
  createDrivetrain,
  deactivateDrivetrain,
  deleteDrivetrain,
  getVariants,
  updateDrivetrain,
} from '../apis/drivetrainApi';
import { queryClient } from '../../../../App';

export const ALL_DRIVETRAINS = ['allDriveTrains'];

interface IUseDriveTrain {
  enabled: boolean;
  active?: boolean;
}

export const useDriveTrain = ({ enabled, active }: IUseDriveTrain) => {
  return useQuery({
    queryKey: ALL_DRIVETRAINS,
    queryFn: () => getVariants({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateDriveTrain = () => {
  return useMutation({
    mutationFn: (data: IDrivetrainPayload) => createDrivetrain(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_DRIVETRAINS);
    },
  });
};

export const useUpdateDriveTrain = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IDrivetrainPayload }) => {
      return updateDrivetrain(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_DRIVETRAINS);
    },
  });
};

export const useDeleteDriveTrain = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deleteDrivetrain(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_DRIVETRAINS);
    },
  });
};

export const useActivateDriveTrain = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return activateDrivetrain(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_DRIVETRAINS);
    },
  });
};

export const useDeactivateDriveTrain = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deactivateDrivetrain(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_DRIVETRAINS);
    },
  });
};
