import api from '../../../../api/apiIndex';

const batteryCapacityEndpointv2 = 'v2/admin/resources/cars/battery-capacities';

export interface IGetBatteryCapacities {
  active?: boolean;
}

export type UpdateBatteryCapacityRequest = {
  name?: string;
  arabic_name?: string;
  status?: number;
  enginetypes?: Array<string>;
};

export interface IBatteryCapacityPayload {
  name: string;
  arabic_name: string;
  engine_types: Array<number>;
}

const getBatteryCapacities = async ({ active }: IGetBatteryCapacities) => {
  try {
    const response = await api.get(batteryCapacityEndpointv2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createBatteryCapacity = async (data: IBatteryCapacityPayload) => {
  try {
    const response = await api.post(batteryCapacityEndpointv2, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateBatteryCapacity = async (
  id: number,
  data: IBatteryCapacityPayload,
) => {
  try {
    const response = await api.put(`${batteryCapacityEndpointv2}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteBatteryCapacity = async (id: number) => {
  try {
    const response = await api.delete(`${batteryCapacityEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBatteryCapacity = async (id: number) => {
  try {
    const response = await api.put(
      `${batteryCapacityEndpointv2}/activate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBatteryCapacity = async (id: number) => {
  try {
    const response = await api.put(
      `${batteryCapacityEndpointv2}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getBatteryCapacities,
  createBatteryCapacity,
  updateBatteryCapacity,
  deleteBatteryCapacity,
};
