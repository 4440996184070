import { useQuery } from '@tanstack/react-query';
import {
  IGetAdsBannerMetrics,
  IGetBrandsViews,
  IGetTotalBrandViews,
  getAdsBannerMetrics,
  getAdvertisers,
  getTotalBrandMetrics,
  getViewsByBrands,
} from './analyticsApi';

interface IUseBrandViews extends IGetBrandsViews {
  enabled: boolean;
}

interface IUseTotalBrandViews extends IGetTotalBrandViews {
  enabled: boolean;
}

interface IUseAdsBannerMetrics extends IGetAdsBannerMetrics {
  enabled: boolean;
}

export const useBrandViews = ({
  from,
  to,
  per,
  id,
  enabled,
}: IUseBrandViews) => {
  return useQuery({
    queryKey: ['analytics', from, to, per, id],
    queryFn: () =>
      getViewsByBrands({
        from,
        to,
        per,
        id,
      }),
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    placeholderData: [],
    enabled,
  });
};

export const useTotalBrandViews = ({
  type,
  source,
  enabled,
  from,
  to,
}: IUseTotalBrandViews) => {
  return useQuery({
    queryKey: ['analytics', type, source, from, to],
    queryFn: () =>
      getTotalBrandMetrics({
        type,
        source,
        from,
        to,
      }),
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    enabled,
  });
};

export const useAdsBannerMetrics = ({
  per,
  event,
  from,
  to,
  enabled,
}: IUseAdsBannerMetrics) => {
  return useQuery({
    queryKey: ['adsBannerMetrics', per, event, from, to],
    queryFn: () =>
      getAdsBannerMetrics({
        per,
        event,
        from,
        to,
      }),
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    enabled,
  });
};

export const useAdvertisers = () => {
  return useQuery({
    queryKey: ['advertisers'],
    queryFn: () => getAdvertisers(),
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
