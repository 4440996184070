import { Modal, message } from 'antd';
import AddEditNewDealForm from './AddEditNewDealForm';
import { useCreateDeal, useUpdateDeal } from '../hooks/useDeals';
import { ICarModel, INewCarDeal } from '../../../../models/resources';
import { ICreateDealRequest } from '../apis/newDealsApi';

interface DealModalProps {
  model: ICarModel;
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditingDeal: boolean;
  currentDeal: any;
  setCurrentDeal: (value: any) => void;
}

export const DealModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditingDeal,
  currentDeal,
  setCurrentDeal,
  model,
}: DealModalProps) => {
  const { mutateAsync: createDeal, isLoading: isCreatingDeal } =
    useCreateDeal();

  const { mutateAsync: updateDeal, isLoading: isUpdatingDeal } =
    useUpdateDeal();

  const _create = async (deal: ICreateDealRequest) => {
    if (!deal) return;
    try {
      const response = await createDeal({
        title: deal.title,
        arabic_title: deal.arabic_title,
        description: deal.description,
        arabic_description: deal.arabic_description,
        city_id: deal.city_id,
        dealer_id: deal.dealer_id,
        model_id: deal.model_id || null,
        arabic_call_to_action: deal.arabic_call_to_action,
        arabic_test_drive_url: deal.arabic_test_drive_url,
        call_to_action: deal.call_to_action,
        price: deal?.price
          ? Number(deal.price.toString().replace(/[^0-9]/g, ''))
          : null,
        test_drive_url: deal.test_drive_url,
      });
      setIsFormOpen(false);
      setCurrentDeal(null);
      message.success('Deal Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (deal: INewCarDeal) => {
    if (!deal || currentDeal == null) return;
    try {
      const response = await updateDeal({
        id: currentDeal!.id,
        request: {
          title: deal.title,
          arabic_title: deal.arabic_title,
          description: deal.description,
          arabic_description: deal.arabic_description,
          city_id: deal.city_id,
          dealer_id: deal.dealer_id,
          model_id: deal.model_id,
          arabic_call_to_action: deal.arabic_call_to_action,
          arabic_test_drive_url: deal.arabic_test_drive_url,
          call_to_action: deal.call_to_action,
          price: deal?.price
            ? Number(deal.price.toString().replace(/[^0-9]/g, ''))
            : null,
          test_drive_url: deal.test_drive_url,
        },
      });
      setIsFormOpen(false);
      setCurrentDeal(null);
      message.success('Deal Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        title={isEditingDeal ? 'Edit Deal' : 'Add Deal'}
        width={1200}
      >
        <AddEditNewDealForm
          model={model}
          onClose={() => setIsFormOpen(false)}
          deal={currentDeal}
          isEditing={isEditingDeal}
          onSubmit={isEditingDeal ? _edit : _create}
          isLoading={isCreatingDeal || isUpdatingDeal}
        />
      </Modal>
    </>
  );
};
