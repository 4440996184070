import { Form, Input, Col, Row, Button, Space, message } from 'antd';
import {
  CAR_CONDITIONS_QUERY_KEY,
  useCreateCarCondition,
  useUpdateCarCondition,
} from '../hooks/useCarCondition';
import { queryClient } from '../../../../App';
import { ICarCondition } from '../utils/apiUtils';

const { Item } = Form;

interface IRentalFormProps {
  handleClose: () => void;
  selectedCarCondition?: ICarCondition;
  isEdit?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCarCondition: React.Dispatch<React.SetStateAction<any>>;
}

export const CarConditionForm = ({
  handleClose,
  selectedCarCondition,
  isEdit,
  setIsOpen,
  setSelectedCarCondition,
}: IRentalFormProps) => {
  //Initial Values
  const initialValues = selectedCarCondition
    ? {
        name: selectedCarCondition.name,
        arabic_name: selectedCarCondition.t?.[0]?.name,
      }
    : undefined;

  //Form
  const [form] = Form.useForm();

  // API Hooks
  const { mutateAsync: createCarCondition, isLoading: isCreating } =
    useCreateCarCondition();

  const { mutateAsync: updateCarCondition, isLoading: isUpdating } =
    useUpdateCarCondition();

  //Functions
  const handleFormSuccess = () => {
    form.resetFields();
    setIsOpen(false);
    setSelectedCarCondition(null);
    queryClient.invalidateQueries({
      queryKey: CAR_CONDITIONS_QUERY_KEY,
    });
  };
  //Form Logic
  const handleSubmit = async (values: any) => {
    values = { ...values, status: true };
    try {
      createCarCondition(values, {
        onSuccess: () => {
          handleFormSuccess();
          message.success('Car Condition added successfully');
        },
      });
    } catch {
      console.log('error');
    }
  };

  const handleEdit = async (values: any) => {
    values = { ...values, status: true };
    try {
      if (selectedCarCondition)
        updateCarCondition(
          {
            id: selectedCarCondition.id,
            data: values,
          },
          {
            onSuccess: () => {
              handleFormSuccess();
              message.success('Car Condition edited successfully');
            },
            onError: () => {},
          },
        );
    } catch {
      console.log('error');
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      onFinish={isEdit ? handleEdit : handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name of the car condition',
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic name of the car condition',
              },
            ]}
          >
            <Input placeholder="Arabic Name" size="large" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button danger onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            {isEdit ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
