import { Modal, message } from 'antd';
import { CarEngineTypeAddEditForm } from './CarEngineTypeAddEditDeleteForm';
import { IEngineType } from '../../../../models/resources/CarEngineType';
import { useCreateEngineTypes, useUpdateEngineTypes } from '../hooks';

type ICarEngineTypeModalProps = {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentEngineType: IEngineType | null;
  engingeTypeLoading: boolean;
  setCurrentEngineType: (value: IEngineType | null) => void;
};

const CarEngineTypeModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentEngineType,
  engingeTypeLoading,
  setCurrentEngineType,
}: ICarEngineTypeModalProps) => {
  const { mutateAsync: createEngineType, isLoading: isCreating } =
    useCreateEngineTypes();
  const { mutateAsync: updateEngineType, isLoading: isUpdating } =
    useUpdateEngineTypes();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createEngineType(data);
      setIsFormOpen(false);
      message.success('Engine Type Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    try {
      const response = await updateEngineType({
        id: currentEngineType!.id,
        data: {
          name: data.name,
          arabic_name: data.arabic_name,
          label: data.label,
        },
      });
      setIsFormOpen(false);
      setCurrentEngineType(null);
      message.success('Engine Type Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Engine Type' : 'Add Engine Type'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentEngineType?.id}
      >
        <CarEngineTypeAddEditForm
          resourceType={currentEngineType}
          onClose={() => setIsFormOpen(false)}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={engingeTypeLoading}
          isSubmitting={isCreating || isUpdating}
        />
      </Modal>
    </>
  );
};

export default CarEngineTypeModal;
