import { Modal, message } from 'antd';
import { ICity } from '../../../models/countries/City';
import { useCreateCity, useUpdateCity } from '../hooks';
import { CitiesForm } from './citiesForm';

interface CitiesModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentCity: ICity | null;
  setCurrentCity: (value: ICity | null) => void;
}

export const CitiesModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentCity,
  setCurrentCity,
}: CitiesModalProps) => {
  const { mutateAsync: createCity, isLoading: isCreating } = useCreateCity();
  const { mutateAsync: updateCity, isLoading: isUpdating } = useUpdateCity();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createCity(data);
      setIsFormOpen(false);
      message.success('City created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateCity({
        id: currentCity!.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentCity(null);
      message.success('City updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit City' : 'Add City'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentCity?.id}
      >
        <CitiesForm
          city={currentCity}
          isEditing={isEditing}
          onSubmit={isEditing ? _edit : _create}
          onClose={() => {
            setIsFormOpen(false);
            setIsEditing(false);
          }}
          isLoading={isCreating || isUpdating}
        />
      </Modal>
    </>
  );
};
