import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IReviewPayload,
  activateReview,
  createReview,
  deactivateReview,
  deleteReview,
  getReviews,
  updateReview,
} from '../apis';
import { queryClient } from '../../App';

export const REVIEWS_KEY = ['reviews'];

export const useGetReviews = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: REVIEWS_KEY,
    queryFn: () => getReviews(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useCreateReview = () => {
  return useMutation({
    mutationFn: (request: IReviewPayload) => createReview(request),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWS_KEY);
    },
  });
};

export const useUpdateReview = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IReviewPayload }) =>
      updateReview(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWS_KEY);
    },
  });
};

export const useDeleteReview = () => {
  return useMutation({
    mutationFn: (id: number) => deleteReview(id),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWS_KEY);
    },
  });
};

export const useActivateReview = () => {
  return useMutation({
    mutationFn: (id: number) => activateReview(id),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWS_KEY);
    },
  });
};

export const useDeactivateReview = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateReview(id),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWS_KEY);
    },
  });
};
