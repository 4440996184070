import { message, Modal } from 'antd';
import { ICurrency } from '../../../models/countries/Currency';
import { useCreateCurrency, useUpdateCurrency } from '../hooks';
import { CurrenciesForm } from './CurrenciesForm';

export interface CurrenciesModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentCurrency: ICurrency | null;
  setCurrentCurrency: (value: ICurrency | null) => void;
}
export const CurrenciesModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentCurrency,
  setCurrentCurrency,
}: CurrenciesModalProps) => {
  const { mutateAsync: createCurrency, isLoading: isCreating } =
    useCreateCurrency();
  const { mutateAsync: updateCurrency, isLoading: isUpdating } =
    useUpdateCurrency();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createCurrency(data);
      setIsFormOpen(false);
      message.success('Currency created successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateCurrency({
        id: currentCurrency!.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentCurrency(null);
      message.success('Currency updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title={isEditing ? 'Edit Currency' : 'Add Currency'}
      className="crud-dialog"
      open={isFormOpen}
      onCancel={() => {
        setIsFormOpen(false);
        setCurrentCurrency(null);
        setIsEditing(false);
      }}
      footer={null}
      width={1200}
      key={currentCurrency?.id}
    >
      <CurrenciesForm
        currency={currentCurrency}
        isEditing={isEditing}
        onSubmit={isEditing ? _edit : _create}
        onClose={() => {
          setIsFormOpen(false);
          setCurrentCurrency(null);
          setIsEditing(false);
        }}
        isLoading={isCreating || isUpdating}
      />
    </Modal>
  );
};
