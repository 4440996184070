import api from '../../api/apiIndex';
import { RcFile } from 'antd/es/upload';

const basePath = 'v2/video';

export type TImage = string | Blob | RcFile | File;

export type TUploadImage = {
  file: TImage;
  path: string;
  extraBodyData?: Record<string, unknown>;
  onProgress?: (progress: { percent: number }) => void;
};

export const uploadVideo = async ({
  file,
  path,
  extraBodyData,
  onProgress,
}: TUploadImage) => {
  try {
    const response = await api.post(
      `${basePath}/${path}`,
      {
        video: file,
        ...extraBodyData,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: ({ total, loaded }) => {
          if (onProgress && total)
            onProgress({
              percent: (loaded / total) * 100,
            });
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
