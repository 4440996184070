interface ModelDescriptionProps {
  selectedLanguage: string;
  englishDescription: string;
  arabicDescription: string;
}

export const ModelDescription = ({
  selectedLanguage,
  englishDescription,
  arabicDescription,
}: ModelDescriptionProps) => {
  return (
    <div className="flex flex-column">
      <p
        style={{
          display: 'flex',
          justifyContent:
            selectedLanguage === 'English' ? 'flex-start' : 'flex-end',
        }}
        dangerouslySetInnerHTML={{
          __html:
            selectedLanguage === 'English'
              ? englishDescription
              : arabicDescription,
        }}
      />
    </div>
  );
};
