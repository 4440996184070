import { Modal, message } from 'antd';
import { AddEditUserForm } from './AddEditUserForm';
import { useCreateUser, useEditUser } from '../hooks/useGetAllUsers';
import User from '../../models/User';
import { CreateUserRequest, EditUserRequest } from '../apis';
import { UserGroups } from '../../common/constants';

interface UsersModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  currentUser: User | null;
  setCurrentUser: (value: User | null) => void;
  isEditing: boolean;
  closeForm: () => void;
  isErrors: boolean;
}

export const UsersModal = ({
  isFormOpen,
  setIsFormOpen,
  currentUser,
  setCurrentUser,
  isEditing,
  closeForm,
  isErrors,
}: UsersModalProps) => {
  const { mutateAsync: createUser, isLoading: isCreating } = useCreateUser();
  const { mutateAsync: updateUser, isLoading: isUpdating } = useEditUser();

  const _edit = async (payload: EditUserRequest) => {
    if (currentUser == null) return;
    try {
      if (payload.password === '') delete payload.password;
      if (
        payload.group !== UserGroups.OAC &&
        payload.group !== UserGroups.Automotive &&
        payload.group !== UserGroups.Rental
      ) {
        payload.receive_email_notifications = false;
      }
      await updateUser({ id: currentUser.id, data: payload });
      setIsFormOpen(false);
      setCurrentUser(null);
      message.success('User updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const _create = async (payload: CreateUserRequest) => {
    try {
      if (
        payload.group !== UserGroups.OAC &&
        payload.group !== UserGroups.Automotive &&
        payload.group !== UserGroups.Rental
      ) {
        payload.receive_email_notifications = false;
      }
      const response = await createUser(payload);
      setIsFormOpen(false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit User' : 'Add User'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentUser?.id}
      >
        <AddEditUserForm
          isEditing={isEditing}
          user={currentUser}
          isLoading={isCreating || isUpdating}
          onSubmit={isEditing ? _edit : _create}
          onClose={closeForm}
          isErrors={isErrors}
        />
      </Modal>
    </>
  );
};
