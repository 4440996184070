import { Modal, message } from 'antd';
import { CarCylindersAddEditForm } from './CarCylindersAddEditForm';
import { useCreateCylinder, useUpdateCylinder } from '../hooks';
import { ICylinders } from '../../../../models/resources/CarCylinder';

interface CarCylindersModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentCylinder: ICylinders | null;
  setCurrentCylinder: (value: ICylinders | null) => void;
  engineTypes: any;
}

export const CarCylindersModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentCylinder,
  setCurrentCylinder,
  engineTypes,
}: CarCylindersModalProps) => {
  const { mutateAsync: createCylinder, isLoading: isCreating } =
    useCreateCylinder();
  const { mutateAsync: updateCylinder, isLoading: isUpdating } =
    useUpdateCylinder();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createCylinder(data);
      message.success('Cylinder created successfully');
      setIsFormOpen(false);
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;

    try {
      const response = await updateCylinder({
        id: currentCylinder!.id,
        data,
      });
      message.success('Cylinder updated successfully');
      setIsFormOpen(false);
      setCurrentCylinder(null);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Cylinder' : 'Add Cylinder'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentCylinder?.id}
      >
        <CarCylindersAddEditForm
          engineTypes={engineTypes}
          resource={currentCylinder}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
          isCreating={isCreating}
          isUpdating={isUpdating}
        />
      </Modal>
    </>
  );
};
