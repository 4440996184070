import { IBikeColors } from '../../../../models/resources/bikes/BikeColors';
import { IBikeColorPayload } from '../apis';
import { Button, Col, Form, Input, Row, Space } from 'antd';

interface IBikeColorsAddEditFormProps {
  bikeColors: IBikeColors | null;
  isEditing: boolean;
  onClose: () => void;
  onSubmit: (values: IBikeColorPayload) => Promise<void>;
  isLoading: boolean;
}

const { Item } = Form;

export const AddEditBikeColorsForm = ({
  bikeColors,
  isEditing,
  isLoading,
  onClose,
  onSubmit,
}: IBikeColorsAddEditFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: bikeColors?.name,
    arabic_name: bikeColors?.t?.[0]?.name,
  };
  return (
    <Form
      form={form}
      onFinish={async (values: IBikeColorPayload) => {
        try {
          onSubmit(values);
        } catch (error) {
          console.error(error);
        }
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_name"
            label="Arabic Name"
            rules={[{ required: true, message: 'Arabic Name is required' }]}
          >
            <Input placeholder="Arabic Name" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
