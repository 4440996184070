import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateReviewerPayload,
  IUpdateReviewerPayload,
  createReviewer,
  activateReviewer,
  deactivateReviewer,
  deleteReviewer,
  getReviewers,
  updateReviewer,
  IGetReviewers,
} from '../apis';
import { queryClient } from '../../App';
import { TImage, uploadImage } from '../../common/utils';

export const REVIEWERS_KEY = ['reviewers'];

export const useGetReviewers = (data?: IGetReviewers) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [...REVIEWERS_KEY, data],
    queryFn: () => getReviewers(data),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useCreateReviewer = () => {
  return useMutation({
    mutationFn: (request: ICreateReviewerPayload) => createReviewer(request),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWERS_KEY);
    },
  });
};

export const useUpdateReviewer = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IUpdateReviewerPayload }) =>
      updateReviewer(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWERS_KEY);
    },
  });
};

export const useDeleteReviewer = () => {
  return useMutation({
    mutationFn: (id: number) => deleteReviewer(id),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWERS_KEY);
    },
  });
};

export const useActivateReviewer = () => {
  return useMutation({
    mutationFn: (id: number) => activateReviewer(id),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWERS_KEY);
    },
  });
};

export const useDeactivateReviewer = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateReviewer(id),
    onSuccess: () => {
      queryClient.invalidateQueries(REVIEWERS_KEY);
    },
  });
};

export const useUploadReviewerImage = () => {
  return useMutation({
    mutationFn: ({
      file,
      path,
      onProgress,
    }: {
      file: TImage;
      path: string;
      onProgress: any;
    }) => uploadImage({ file, path, onProgress }),
  });
};
