import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeBatteryCapacityPayload,
  activateBikeBatteryCapacity,
  createBikeBatteryCapacity,
  deactivateBikeBatteryCapacity,
  deleteBikeBatteryCapacity,
  getBikeBatteryCapacities,
  updateBikeBatteryCapacity,
} from '../apis';
import { queryClient } from '../../../../App';

export const ALL_BIKE_BATTERY_CAPACITY = ['allBikeBatteryCapacity'];

interface IUseBikeBatteryCapacity {
  enabled: boolean;
  active?: boolean;
}

export const useGetBikeBatteryCapacities = ({
  enabled,
  active,
}: IUseBikeBatteryCapacity) => {
  return useQuery({
    queryKey: ALL_BIKE_BATTERY_CAPACITY,
    queryFn: () => getBikeBatteryCapacities({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeBatteryCapacity = () => {
  return useMutation({
    mutationFn: (data: IBikeBatteryCapacityPayload) =>
      createBikeBatteryCapacity(data),
    mutationKey: ['createBikeBatteryCapacity'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_CAPACITY);
    },
  });
};

export const useUpdateBikeBatteryCapacity = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: IBikeBatteryCapacityPayload;
    }) => updateBikeBatteryCapacity(id, data),
    mutationKey: ['updateBikeBatteryCapacity'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_CAPACITY);
    },
  });
};

export const useDeleteBikeBatteryCapacity = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeBatteryCapacity(id),
    mutationKey: ['deleteBikeBatteryCapacity'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_CAPACITY);
    },
  });
};

export const useActivateBikeBatteryCapacity = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeBatteryCapacity(id),
    mutationKey: ['activateBikeBatteryCapacity'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_CAPACITY);
    },
  });
};

export const useDeactivateBikeBatteryCapacity = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeBatteryCapacity(id),
    mutationKey: ['deactivateBikeBatteryCapacity'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_CAPACITY);
    },
  });
};
