import api from '../../../api/apiIndex';
import { downloadFile } from '../../../helpers/helperFunctions';
import { LeadContacted } from '../../constants';

const rentalLeadsEndpoint = '/v2/admin/inquiries/rental-car-inquiries';

interface ILeadsDetailsProps {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

interface IExportRentalLeads {
  setIsExporting: (newValue: boolean) => void;
  from: string | null;
  to: string | null;
}

export interface ICreateRentalLead {
  name: string;
  email: string;
  mobile_number: string;
  dealer_id: number;
  brand_id: number;
  model_id: number;
  source: string;
  contacted: LeadContacted;
  notes?: string;
}

export interface IEditRentalLead {
  name?: string;
  email?: string;
  mobile_number?: string;
  source?: string;
  contacted?: LeadContacted;
  notes?: string;
}

export const getRentalLeads = async () => {
  const response = await api.get(rentalLeadsEndpoint);
  return response.data;
};

export const getRentalLeadsDetails = async ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: ILeadsDetailsProps) => {
  if (dealerSlug && brandSlug && modelSlug) {
    const response = await api.get(
      `${rentalLeadsEndpoint}/${dealerSlug}/${brandSlug}/${modelSlug}`,
    );
    return response.data;
  } else if (dealerSlug && brandSlug) {
    const response = await api.get(
      `${rentalLeadsEndpoint}/${dealerSlug}/${brandSlug}`,
    );
    return response.data;
  } else if (dealerSlug) {
    const response = await api.get(`${rentalLeadsEndpoint}/${dealerSlug}`);
    return response.data;
  }
};

export const exportRentalLeads = async ({
  setIsExporting,
  from,
  to,
}: IExportRentalLeads) => {
  setIsExporting(true);
  try {
    const response = await api.get(
      `${rentalLeadsEndpoint}/export?from=${from}&to=${to}`,
      {
        responseType: 'blob',
      },
    );
    downloadFile(response.data, 'Rental_Leads_Report');
  } catch (error: any) {
    console.error(error);
  } finally {
    setIsExporting(false);
  }
};

export const createRentalLead = async (lead: ICreateRentalLead) => {
  try {
    const response = await api.post(rentalLeadsEndpoint, lead);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const editRentalLead = async ({
  id,
  data: lead,
}: {
  id: number;
  data: IEditRentalLead;
}) => {
  try {
    const response = await api.put(`${rentalLeadsEndpoint}/${id}`, lead);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteRentalLead = async (id: number) => {
  const response = await api.delete(`${rentalLeadsEndpoint}/${id}`);
  return response.data.message;
};
