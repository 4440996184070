import { Form, Row, Col, Input, Button, Space } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useEffect } from 'react';

type RejectUsedBikesFormProps = {
  onSubmit: (formData: any) => void;
  onClose: () => void;
  initialValues?: Store;
};

const { Item } = Form;

const { TextArea } = Input;

export const RejectUsedBikesForm = ({
  onSubmit,
  onClose,
  initialValues,
}: RejectUsedBikesFormProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Row gutter={24} wrap>
        <Col span={24}>
          <Item
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Please enter a reason to reject the used bikes',
              },
            ]}
          >
            <TextArea placeholder="Reason" size="large" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button onClick={onClose} danger>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Reject
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
