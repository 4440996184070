import { Card, Select } from 'antd';
import { useState } from 'react';
import {
  useCreateOACSubmittedLeadsEmail,
  useDeleteOACSubmittedLeadsEmail,
  useGetOACSubmittedLeadsEmails,
} from '../hooks';

const OACSubmittedLeadsEmailsLayouts = () => {
  const [search, setSearch] = useState<boolean>(false);
  const { data: leadEmails, isLoading: isFetching } =
    useGetOACSubmittedLeadsEmails({
      enabled: true,
    });

  const { mutateAsync: createLeadEmail, isLoading: isCreating } =
    useCreateOACSubmittedLeadsEmail();

  const { mutateAsync: deleteLeadEmail, isLoading: isDeleting } =
    useDeleteOACSubmittedLeadsEmail();

  const handleAllEmailNotificationsSelect = async (value: string) => {
    await createLeadEmail(value);
  };

  const handleAllEmailNotificationsDeselect = async (value: any) => {
    await deleteLeadEmail(value);
  };

  const handleSearch = (value: string) => {
    if (value) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  return (
    <Card title="Submitted Leads Email Notifications">
      <Select
        mode="tags"
        style={{ width: '100%' }}
        onDeselect={handleAllEmailNotificationsDeselect}
        onSelect={handleAllEmailNotificationsSelect}
        tokenSeparators={[',']}
        onSearch={handleSearch}
        loading={isFetching || isCreating || isDeleting}
        disabled={isFetching || isCreating || isDeleting}
        options={leadEmails?.unregisteredEmails?.map(leadEmail => {
          return { value: leadEmail?.email };
        })}
        popupClassName={`select-popup-hide-selected-options ${
          !search &&
          leadEmails?.unregisteredEmails?.length === 0 &&
          `select-popup-hide`
        }`}
        value={leadEmails?.registeredEmails?.map(lead => lead.email)}
      />
    </Card>
  );
};

export default OACSubmittedLeadsEmailsLayouts;
