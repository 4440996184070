import api from '../../../../api/apiIndex';
import { IUsedBike } from '../../../../models/listings/bikes/UsedBike';
import { Metadata } from '../../../../models/metadata/Metadata';
import { BikeStatus } from '../../constants';
import { IUseGetUsedBikes } from '../hooks/useUsedBikes';

type CreateBikeRequest = {
  year: number;
  description?: string;
  whatsapp_number?: string;
  mobile_number?: string;
  call_to_action?: string;
  mileage?: number;
  price: number;
  model_id?: number;
  seller_id?: number;
  seller_type?: string;
  city_id?: number;
  type_id?: number;
  transmission_id?: number;
  engine_type_id?: number;
  engine_size_id?: number;
  battery_capacity_id?: number;
  cylinder_id?: number;
  battery_range_id?: number;
  color_id?: number;
  condition_id?: number;
  optional_features_ids?: number[];
  status: string;
  add_images: Array<{ id: number }>;
};

export type UpdateBikeRequest = {
  year?: number;
  description?: string;
  whatsapp_number?: string;
  mobile_number?: string;
  call_to_action?: string;
  mileage?: number;
  price?: number;
  model_id?: number;
  trim_id?: number;
  seller_id?: number;
  seller_type?: string;
  city_id?: number;
  type_id?: number;
  transmission_id?: number;
  engine_type_id?: number;
  engine_size_id?: number;
  battery_capacity_id?: number;
  cylinder_id?: number;
  battery_range_id?: number;
  color_id?: number;
  condition_id?: number;
  seat_material_id?: number;
  variant_id?: number;
  optional_features_ids?: number[];
  status?: string;
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
};

const adminBikeStatuses = [
  { label: 'All', value: '' },
  { label: 'Approved', value: BikeStatus.approved },
  { label: 'Pending', value: BikeStatus.pending },
  { label: 'Rejected', value: BikeStatus.rejected },
  { label: 'Draft', value: BikeStatus.draft },
];

const dealerStatuses = [
  { label: 'Draft', value: BikeStatus.draft },
  { label: 'Pending', value: BikeStatus.pending },
];

const bikesEndpoint = 'v2/admin/bikes/used-bikes';

const getUsedBikes = async ({
  searchQueryParams,
  page,
  perPage,
  country,
}: IUseGetUsedBikes): Promise<{ data: IUsedBike[]; meta: Metadata }> => {
  searchQueryParams = {
    ...searchQueryParams,
    is_active: 'true',
  };

  const headers: { country?: string } = {};
  if (country) {
    headers.country = country;
  }

  const response = await api.get(bikesEndpoint, {
    params: {
      ...searchQueryParams,
      page,
      perPage,
    },
    headers,
  });
  return response.data;
};

const getUsedBikesByDealer = async (
  dealer_id: number,
): Promise<IUsedBike[]> => {
  const response = await api.get(`${bikesEndpoint}/dealer/${dealer_id}`);
  return response.data;
};

const createUsedBike = async (request: CreateBikeRequest) => {
  const response = await api.post(bikesEndpoint, request);
  return response;
};

const updateUsedBike = async (id: number, request: UpdateBikeRequest) => {
  const response = await api.put(`${bikesEndpoint}/${id}`, request);
  return response.data;
};

const deleteUsedBike = async (id: number) => {
  const response = await api.delete(`${bikesEndpoint}/${id}`);
  return response.data;
};

const batchDeleteUsedBikes = async (carIds: Array<number>) => {
  const response = await api.patch(`${bikesEndpoint}/delete`, {
    ids: carIds,
  });
  return response.data;
};

const batchUpdateUsedBikesStatuses = async (
  carIds: Array<number>,
  status: BikeStatus,
  reason?: string,
) => {
  const response = await api.patch(`${bikesEndpoint}/status`, {
    ids: carIds,
    status,
    reason,
  });
  return response.data;
};

export {
  getUsedBikes,
  getUsedBikesByDealer,
  createUsedBike,
  updateUsedBike,
  deleteUsedBike,
  adminBikeStatuses,
  dealerStatuses,
  batchDeleteUsedBikes,
  batchUpdateUsedBikesStatuses,
};
