import { UserGroups } from '../../../../common/constants';
import { BikeBatteryCapacitiesLayout } from '../../../../resources/bikes/battery_capacity';
import { RouteType } from '../../../routes/routesConfig';

const BikeBatteryCapacity = () => {
  return <BikeBatteryCapacitiesLayout />;
};

const bikeBatteryCapacityRoute: RouteType = {
  element: <BikeBatteryCapacity />,
  state: 'battery-capacity',
  index: true,
  path: '/resources/bikes/battery-capacity',
  sideBarProps: {
    displayText: 'Battery Capacity',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeBatteryCapacity, bikeBatteryCapacityRoute };
