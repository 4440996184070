import { StopTwoTone } from '@ant-design/icons';

interface InactiveIconProps {
  onClick?: () => void;
}

const InactiveIcon = ({ onClick }: InactiveIconProps) => {
  return <StopTwoTone onClick={onClick} />;
};

export default InactiveIcon;
