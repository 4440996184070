import { useEffect, useRef, useState } from 'react';
import {
  CarCategory,
  filterStatusOptions,
  batchDeleteCars,
  batchUpdateStatuses,
  filterFeaturedOptions,
  CarStatus,
} from '../carsApi';
import {
  Button,
  Dropdown,
  Image,
  Input,
  Menu,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { DownOutlined, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Typography } from 'antd';
import { AddEditUsedCarModal } from './used_car_form/AddEditUsedCarModal';
import dayjs from 'dayjs';
import { FilterDropDown } from './used_car_form/FilterDropDown';
import { Layout } from '../../../../components/Layout';
import { useDeleteUsedCar, useGetUsedCars } from '../hooks/useUsedCars';
import { ICarModel } from '../../../../models/resources/CarModel';
import { IUsedCar } from '../../../../models/listings/cars/used_cars/UsedCar';
import { Popconfirm, message } from 'antd/lib';
import { queryClient } from '../../../../App';
import { RejectUsedCarsModal } from './used_car_form/RejectUsedCarsModal';
import useAuth from '../../../../hooks/useAuth';
import { UserGroups } from '../../../../common/constants';
import { useCountries } from '../../../../countries/hooks/useCountries';

const searchOptions: { label: string; value: SearchOptionKeys }[] = [
  { label: 'Model', value: 'modelName' },
  { label: 'User Email', value: 'userEmail' },
  { label: 'Dealer Name', value: 'dealerName' },
];

const placeholderOptions: Record<SearchOptionKeys, string> = {
  modelName: 'Search by Model Name',
  userEmail: 'Search by User Email',
  dealerName: 'Search by Dealer Name',
};

const { Search } = Input;

type CarsListLayoutProps = {
  carsCategory: CarCategory;
};

type SearchOptionKeys = 'modelName' | 'userEmail' | 'dealerName';

const CarsListLayout = (props: CarsListLayoutProps) => {
  //#region states
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCar, setCurrentCar] = useState<IUsedCar | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [featuredFilter, setFeaturedFilter] = useState<number>(2);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedCars, setSelectedCars] = useState<Array<IUsedCar>>([]);
  const [country, setCountry] = useState('');

  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);

  //Pagination states
  const [usedCarsPerPage, setUsedCarsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);

  // Search states
  const searchValue = useRef<string>('');
  const searchSelectedOption = useRef<SearchOptionKeys>('modelName');
  const [searchQueryParams, setSearchQueryParams] = useState<
    Record<string, string>
  >({});

  const [availableBulkActions, setAvailableBulkActions] = useState<Array<any>>(
    [],
  );
  const [addUsedCarDisabled, setAddUsedCarDisabled] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IUsedCar | null>(
    null,
  );
  const { data: countries } = useCountries({
    enabled: true,
  });

  const filterCountryOptions = [
    { text: 'All', value: '' },
    ...(countries?.map(country => ({
      text: country.name,
      value: country.code,
    })) || []),
  ];

  //#endregion

  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isOneAutocar = userGroup === UserGroups.OAC;

  const { data: usedCarsPaginatedData, isLoading: isFetchingUsedCars } =
    useGetUsedCars({
      searchQueryParams,
      page: currentPage,
      perPage: usedCarsPerPage,
      country,
    });

  const { mutateAsync: deleteUsedCar, isLoading: isDeleting } =
    useDeleteUsedCar();

  //#region Functions

  const _deleteSelectedCars = async () => {
    if (selectedCars.length === 0) return;
    try {
      await batchDeleteCars(selectedCars.map(car => car.id));
      message.success('Used Cars are deleted successfully');
      queryClient.invalidateQueries(['usedCars']);
    } catch (error) {
      message.error('Error deleting used cars');
      console.error(error);
    }
  };

  const _updateSelectedCarsStatus = async (
    status: CarStatus,
    reason?: string,
  ) => {
    if (selectedCars.length === 0) return;
    try {
      await batchUpdateStatuses(
        selectedCars.map(car => car.id),
        status,
        reason,
      );
      clearSelection();
      queryClient.invalidateQueries(['usedCars']);
      message.success(`Used Cars are set as ${status} successfully`);
    } catch (error) {
      console.error(error);
    }
  };

  //#endregion

  const onEditClick = (car: IUsedCar) => {
    setCurrentCar(car);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setUsedCarsPerPage(pageSize);
  };

  const openEmptyForm = () => {
    setCurrentCar(null);
    setIsEditing(false);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setCurrentCar(null);
  };

  const closeRejectForm = () => {
    setIsRejectFormOpen(false);
  };

  const handleSelectChange = (value: any) => {
    searchSelectedOption.current = value;
  };

  const clearSelection = () => {
    setSelectedCars([]);
  };

  const handleDelete = (id: number) => {
    deleteUsedCar(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['users'],
        });
        setOpenDeleteDialog(null);
        message.success('Used car deleted successfully');
      },
      onError: () => {
        message.error('Error deleting used Car');
      },
    });
  };

  const handleSelectStatus = (value: any) => {
    setSelectedStatus(value);
    if (value === '') {
      delete searchQueryParams.status;
    } else if ('status' in searchQueryParams) {
      searchQueryParams.status = value;
    } else {
      searchQueryParams.status = value;
    }
  };

  const handleSelectFeatured = (value: any) => {
    setFeaturedFilter(value);
    if (value === '') {
      delete searchQueryParams.is_featured;
    } else if ('is_featured' in searchQueryParams) {
      searchQueryParams.is_featured = value;
    } else {
      searchQueryParams.is_featured = value;
    }
  };

  const handleSelectCountry = (value: string) => {
    if (value === null || value === undefined) {
      setSelectedCountry('');
      setCountry('');
    } else {
      setSelectedCountry(value);
      setCountry(value);
    }
  };

  const bulkActionSetAsApproved = {
    label: 'Set As Approved',
    icon: 'pi pi-times',
    command: async () => {
      try {
        await _updateSelectedCarsStatus(CarStatus.approved);
      } catch (error) {
        console.error(error);
        message.error('Error deactivating users');
      }
    },
  };

  const bulkActionSetAsPending = {
    label: 'Set As Pending',
    icon: 'pi pi-check',
    command: async () => {
      try {
        await _updateSelectedCarsStatus(CarStatus.pending);
        clearSelection();
      } catch (error) {
        console.error(error);
        message.error('Error deactivating users');
      }
    },
  };

  const bulkActionSetAsRejected = {
    label: 'Set As Rejected',
    icon: 'pi pi-check',
    command: async () => {
      try {
        setIsRejectFormOpen(true);
      } catch (error) {
        console.error(error);
        message.error('Error deactivating users');
      }
    },
  };

  const canBulkSetAsApproved = (): boolean => {
    return selectedCars.some(car => car.status !== 'approved');
  };

  const canBulkSetAsPending = (): boolean => {
    return selectedCars.some(car => car.status !== 'pending');
  };

  const canBulkSetAsRejected = () => {
    return selectedCars.some(car => car.status !== 'rejected');
  };

  const tableColumns: ColumnsType<IUsedCar> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      fixed: 'left',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      render: (rowData: any): string => {
        return rowData?.name;
      },
    },
    {
      title: 'Trim',
      dataIndex: 'trim',
      key: 'trim',
      render: (rowData: any): string => {
        return rowData?.name ?? 'N/A';
      },
    },
    {
      title: 'Image',
      dataIndex: 'images',
      key: 'images',
      width: 150,
      align: 'center',
      render: rowData => {
        return (
          <Image
            src={rowData[0]}
            height={50}
            fallback="https://play-lh.googleusercontent.com/J-SpF7SizHC3yGtAIxl00_jq5q6hkZO1A2zE0efFSAjr_SNEW5N37aMgocinR75utQ=w240-h480-rw"
            alt="car image"
            style={{
              objectFit: 'cover',
            }}
          />
        );
      },
    },
    {
      title: 'Brand',
      dataIndex: 'model',
      key: 'model',
      render: (rowData: ICarModel): string => {
        return rowData?.brand.name;
      },
    },

    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: 70,
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (rowData): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (rowData): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Dealer',
      dataIndex: 'dealer',
      key: 'dealer',
      ellipsis: true,
      render: (rowData): string => {
        return rowData?.name || 'Private';
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      render: (text, record) => {
        const currencyName = record?.city?.currencies?.name;
        return `${text} ${currencyName}`;
      },
    },
    {
      title: 'Country',
      dataIndex: 'city',
      key: 'city',
      render: (rowData): string => {
        return rowData?.countries?.name;
      },
      filters: filterCountryOptions,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={selectedCountry}
            setFilter={handleSelectCountry}
            options={filterCountryOptions}
            isLoading={isFetchingUsedCars}
          />
        );
      },
    },
    {
      title: 'Featured',
      dataIndex: 'is_featured',
      key: 'is_featured',
      align: 'center',
      width: '50',
      fixed: 'right',
      render: (rowData): JSX.Element => {
        return (
          <Tag color={rowData ? 'green' : 'red'}>{rowData ? 'Yes' : 'No'}</Tag>
        );
      },
      filters: filterFeaturedOptions,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={featuredFilter}
            setFilter={handleSelectFeatured}
            options={filterFeaturedOptions}
            isLoading={isFetchingUsedCars}
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      align: 'center',
      render: (rowData: string): JSX.Element => {
        const status = filterStatusOptions.find(
          status =>
            (status.value as string).toLowerCase() === rowData.toLowerCase(),
        );
        return <Tag color={status?.color}>{status?.text}</Tag>;
      },
      filters: filterStatusOptions,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={selectedStatus}
            setFilter={handleSelectStatus}
            options={filterStatusOptions}
            isLoading={isFetchingUsedCars}
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              style={{ fontSize: '1rem', color: 'var(--primary)' }}
              onClick={() => {
                onEditClick(rowData);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Deleting this user will delete all their related data."
              open={openDeleteDialog === rowData}
              onConfirm={() => handleDelete(rowData.id)}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                style={{ fontSize: '1rem', color: 'var(--error)' }}
                onClick={() => {
                  setOpenDeleteDialog(rowData);
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const expandbleColumn: ColumnsType<IUsedCar> = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: rowData => {
        return (
          <Typography.Text>
            {rowData?.replaceAll('&nbsp;', ' ')}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
    },
    {
      title: 'Whatsapp Number',
      dataIndex: 'whatsapp_number',
      key: 'whatsapp_number',
      render: (rowData: string) => rowData ?? 'N/A',
    },
    {
      title: 'KM',
      dataIndex: 'mileage',
      key: 'mileage',
      render: (rowData): string => {
        return rowData ? rowData?.toString() + ' Km' : 'N/A';
      },
    },
  ];

  useEffect(() => {
    const temp = [];
    if (canBulkSetAsApproved()) temp.push(bulkActionSetAsApproved);
    if (canBulkSetAsPending()) temp.push(bulkActionSetAsPending);
    if (canBulkSetAsRejected()) temp.push(bulkActionSetAsRejected);

    setAvailableBulkActions(temp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCars, addUsedCarDisabled]);

  useEffect(() => {
    setAddUsedCarDisabled(selectedCars.length > 0);
  }, [selectedCars]);

  //#endregion
  return (
    <Layout>
      <div className="flex column-gap-4 justify-content-between align-items-center mb-5">
        <div className="flex column-gap-4 justify-content-between align-items-center">
          <Space.Compact>
            <Search
              placeholder={placeholderOptions[searchSelectedOption.current]}
              loading={isFetchingUsedCars}
              onSearch={value =>
                setSearchQueryParams({
                  [searchSelectedOption.current]: searchValue.current,
                })
              }
              onChange={e => {
                searchValue.current = e.target.value;
                if (searchValue.current === '') setSearchQueryParams({});
              }}
              style={{ width: 200 }}
            />
            <Select
              options={searchOptions}
              defaultValue={searchOptions[0].value}
              onChange={value => handleSelectChange(value)}
              style={{ width: 130 }}
            />
          </Space.Compact>
        </div>
        <Space>
          {isOneAutocar && (
            <Dropdown
              overlay={
                <Menu>
                  {availableBulkActions.map((action, index) => (
                    <Menu.Item key={index} onClick={action.command}>
                      {action.label}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              disabled={selectedCars.length === 0}
            >
              <Button type="primary">
                Change Status
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
          <div>
            <Button
              type="primary"
              loading={isFetchingUsedCars}
              disabled={selectedCars.length === 0}
              onClick={_deleteSelectedCars}
              danger
            >
              Delete
            </Button>
          </div>
          <div>
            <Button type="primary" onClick={openEmptyForm}>
              Add
            </Button>
          </div>
        </Space>
      </div>

      <div className=" flex flex-column gap-6 justify-content-start h-full">
        <Table
          dataSource={usedCarsPaginatedData?.data}
          pagination={{
            position: ['bottomCenter'],
            total: usedCarsPaginatedData?.meta?.total,
            pageSize: usedCarsPerPage,
            onChange: onPageChange,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }}
          rowKey="id"
          scroll={{ x: 1300, y: 660 }}
          columns={tableColumns}
          loading={isFetchingUsedCars}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedCars(selectedRows as IUsedCar[]);
            },
            selectedRowKeys: selectedCars.map(car => car.id),
          }}
          expandable={{
            expandedRowRender: record => {
              return (
                <Table
                  dataSource={[record]}
                  pagination={false}
                  rowKey="id"
                  columns={expandbleColumn}
                />
              );
            },
            rowExpandable: record => record.description !== '',
          }}
        />
      </div>

      <AddEditUsedCarModal
        key={currentCar?.id}
        car={currentCar}
        isEditing={isEditing}
        onClose={closeForm}
        isVisible={isFormOpen}
        setCurrentCar={setCurrentCar}
        setIsEditing={setIsEditing}
        setIsFormOpen={setIsFormOpen}
      />

      {isRejectFormOpen && (
        <RejectUsedCarsModal
          updateStatusFunction={_updateSelectedCarsStatus}
          onClose={closeRejectForm}
          isVisible={isRejectFormOpen}
        />
      )}
    </Layout>
  );
};

export default CarsListLayout;
