import { Modal } from 'antd';
import { CarConditionForm } from './CarConditionForm';
import { ICarCondition } from '../utils/apiUtils';

interface CarConditionModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCarCondition?: ICarCondition;
  setSelectedCarCondition: React.Dispatch<React.SetStateAction<any>>;
}

export const CarConditionModal = ({
  isOpen,
  setIsOpen,
  selectedCarCondition,
  setSelectedCarCondition,
}: CarConditionModalProps) => {
  const isEdit = !!selectedCarCondition;

  const handleClose = () => {
    try {
      setIsOpen(false);
      setSelectedCarCondition(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title="Car Condition"
      open={isOpen}
      onCancel={handleClose}
      width={1200}
      footer={null}
      key={selectedCarCondition?.id}
    >
      <CarConditionForm
        handleClose={handleClose}
        selectedCarCondition={selectedCarCondition}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
        setSelectedCarCondition={setSelectedCarCondition}
      />
    </Modal>
  );
};
