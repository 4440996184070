import {
  Form,
  Input,
  Typography,
  Col,
  Row,
  Button,
  Space,
  message,
} from 'antd';
import {
  CAR_EXTERIOR_COLOR_QUERY_KEY,
  useCreateCarExteriorColor,
  useUpdateCarExteriorColor,
} from '../hooks/useCarExteriorColor';
import { queryClient } from '../../../../App';
import { CarExteriorColor } from '../../../../models/resources/CarExteriorColor';
import { IUseCarExteriorColor } from '../utils/apiUtils';

const { Item } = Form;
const { Text } = Typography;

interface IRentalFormProps {
  handleClose: () => void;
  selectedCarExteriorColor?: IUseCarExteriorColor;
  isEdit?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCarExteriorColor: React.Dispatch<React.SetStateAction<any>>;
}

export const CarExteriorColorForm = ({
  handleClose,
  selectedCarExteriorColor,
  isEdit,
  setIsOpen,
  setSelectedCarExteriorColor,
}: IRentalFormProps) => {
  //Initial Values
  const initialValues = selectedCarExteriorColor
    ? {
        name: selectedCarExteriorColor.name,
        arabic_name: selectedCarExteriorColor.t?.[0]?.name,
      }
    : undefined;

  //Form
  const [form] = Form.useForm();

  // API Hooks
  const { mutateAsync: createCarExteriorColor, isLoading: isCreating } =
    useCreateCarExteriorColor();

  const { mutateAsync: updateCarExteriorColor, isLoading: isUpdating } =
    useUpdateCarExteriorColor();

  //Functions
  const handleFormSuccess = () => {
    form.resetFields();
    setIsOpen(false);
    setSelectedCarExteriorColor(null);
    queryClient.invalidateQueries({
      queryKey: CAR_EXTERIOR_COLOR_QUERY_KEY,
    });
  };
  //Form Logic
  const handleSubmit = async (values: any) => {
    values = { ...values, status: true };

    try {
      createCarExteriorColor(values, {
        onSuccess: () => {
          handleFormSuccess();
          message.success('Exterior color added successfully');
        },
      });
    } catch {
      console.log('error');
    }
  };

  const handleEdit = async (values: any) => {
    values = { ...values, status: true };
    try {
      if (selectedCarExteriorColor)
        updateCarExteriorColor(
          {
            id: selectedCarExteriorColor.id,
            data: values,
          },
          {
            onSuccess: () => {
              handleFormSuccess();
              message.success('Exterior color edited successfully');
            },
            onError: () => {},
          },
        );
    } catch {
      console.log('error');
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      onFinish={isEdit ? handleEdit : handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name of the exterior color',
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic name of the exterior color',
              },
            ]}
          >
            <Input placeholder="Arabic Name" size="large" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button danger onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            {isEdit ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
