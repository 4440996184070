import { Modal, message } from 'antd';
import { CarTransmissionAddEditForm } from './CarTransmissionAddEditForm';
import { useCreateTransmission, useUpdateTransmission } from '../hooks';
import { ICarTransmission } from '../../../../models/resources/CarTransmission';

interface CarTransmissionModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentTransmission: ICarTransmission | null;
  setCurrentTransmission: (value: ICarTransmission | null) => void;
  engineTypes: any;
}

export const CarTransmissionModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentTransmission,
  setCurrentTransmission,
  engineTypes,
}: CarTransmissionModalProps) => {
  const { mutateAsync: createTransmission, isLoading: isCreating } =
    useCreateTransmission();
  const { mutateAsync: updateTransmission, isLoading: isUpdating } =
    useUpdateTransmission();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createTransmission(data);
      setIsFormOpen(false);
      message.success('Transmission created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateTransmission({
        id: currentTransmission!.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentTransmission(null);
      message.success('Transmission updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Transmission' : 'Add Transmission'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentTransmission?.id}
      >
        <CarTransmissionAddEditForm
          resource={currentTransmission}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          engineTypes={engineTypes}
          onClose={() => {
            setIsFormOpen(false);
            setIsEditing(false);
          }}
        />
      </Modal>
    </>
  );
};
