import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Table, Button, Popconfirm, message, Tag } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  CAR_SEAT_MATERIALS_QUERY_KEY,
  useActivateCarSeatMaterial,
  useCarSeatMaterial,
  useDeactivateCarSeatMaterial,
  useDeleteCarSeatMaterial,
} from '../hooks/useCarSeatMaterial';
import { useQueryClient } from '@tanstack/react-query';
import { CarSeatMaterialModal } from './CarSeatMaterialModal';
import { ICarSeatMaterial } from '../utils/apiUtils';
import { Layout } from '../../../../components/Layout';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';

const CarSeatMaterialsLayout = () => {
  const [open, setOpen] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCarSeatMaterial, setSelectedCarSeatMaterial] = useState<
    ICarSeatMaterial | undefined
  >(undefined);

  const queryClient = useQueryClient();
  const { data: carSeatMaterials, isLoading: isLoadingCarSeatMaterials } =
    useCarSeatMaterial({ enabled: true });

  const { mutateAsync: deleteSeatMaterial, isLoading: isDeleting } =
    useDeleteCarSeatMaterial();

  const { mutateAsync: activateSeatMaterail, isLoading: isActivating } =
    useActivateCarSeatMaterial();
  const { mutateAsync: deactivateSeatMaterial, isLoading: isDeactivating } =
    useDeactivateCarSeatMaterial();

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarSeatMaterial | null>(null);

  const handleDelete = (id: number) => {
    deleteSeatMaterial(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: CAR_SEAT_MATERIALS_QUERY_KEY,
        });
        setSelectedCarSeatMaterial(undefined);
        message.success('Seat Material deleted successfully');
        setOpen(null);
      },
    });
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateSeatMaterial(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Seat Material deactivated successfully');
          },
        })
      : activateSeatMaterail(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Seat Material activated successfully');
          },
        });
  };

  const tableColumns: ColumnsType<ICarSeatMaterial> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (rowData): string => {
        return rowData ? rowData?.toString() : 'N/A';
      },
    },
    {
      title: 'Arabic Name',
      dataIndex: `${carSeatMaterials?.map(
        (carSeatMaterial: ICarSeatMaterial) => carSeatMaterial.t[0]?.name,
      )}`,
      key: `${carSeatMaterials?.map(
        (carSeatMaterial: ICarSeatMaterial) => carSeatMaterial.t[0]?.name,
      )}`,
      render: (text: any, record: ICarSeatMaterial) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarSeatMaterial) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              onClick={() => {
                setSelectedCarSeatMaterial(rowData);
                handleOpenModal();
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Are you sure you want to delete this seat material?"
              open={open === rowData.id}
              onConfirm={() => {
                handleDelete(rowData.id);
              }}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpen(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                onClick={() => {
                  setOpen(rowData.id);
                  setSelectedCarSeatMaterial(rowData);
                }}
              />
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this seat material.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={handleOpenModal}>
          Add
        </Button>
      </div>
      <Table
        dataSource={carSeatMaterials}
        rowKey="id"
        scroll={{ x: 1300, y: 720 }}
        columns={tableColumns}
        pagination={false}
        loading={isLoadingCarSeatMaterials}
      />
      <CarSeatMaterialModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedCarSeatMaterial={selectedCarSeatMaterial}
        setSelectedCarSeatMaterial={setSelectedCarSeatMaterial}
      />
    </Layout>
  );
};

export default CarSeatMaterialsLayout;
