import { UsedBikeLeadsLayout } from '../../../leads/used_bikes';
import { UserGroups } from '../../../common/constants';

const UsedBikeLeads = () => {
  return <UsedBikeLeadsLayout />;
};

const usedBikeLeadsRoute = {
  path: '/leads/used-bikes',
  index: true,
  element: <UsedBikeLeads />,
  state: 'usedBikeLeads',
  sideBarProps: {
    displayText: 'Used Bikes',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { UsedBikeLeads, usedBikeLeadsRoute };
