import CarTypesLayout from '../../../resources/cars/car_types/CarTypesLayout';
import { RouteType } from '../../routes/routesConfig';

const BodyType = () => {
  return <CarTypesLayout />;
};

const bodyTypeRoute: RouteType = {
  element: <BodyType />,
  state: 'body-type',
  index: true,
  path: '/resources/cars/body-type',
  sideBarProps: {
    displayText: 'Body Type',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { BodyType, bodyTypeRoute };
