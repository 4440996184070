import { Button, Col, Form, Input, Row, Space } from 'antd';
import { ICurrency } from '../../../models/countries/Currency';

export interface CurrenciesFormProps {
  currency: ICurrency | null;
  isEditing: boolean;
  onSubmit: (values: any) => void;
  onClose: () => void;
  isLoading: boolean;
}

const { Item } = Form;

export const CurrenciesForm = ({
  currency,
  isEditing,
  onSubmit,
  onClose,
  isLoading,
}: CurrenciesFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: currency?.name,
    arabic_name: currency?.t?.[0]?.name,
  };

  const resetForm = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter arabic name',
              },
            ]}
          >
            <Input placeholder="Arabic Name" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit Currency' : 'Add Currency'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
