import { BannersLayout } from '../../../resources/banners';
import { RouteType } from '../../routes/routesConfig';

export const Banner = () => {
  return <BannersLayout />;
};

export const bannerRoute: RouteType = {
  element: <Banner />,
  state: 'banner',
  index: true,
  path: '/resources/banners',
  sideBarProps: {
    displayText: 'Banners',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};
