import { Modal, message } from 'antd';
import { AddEditUsedBikeForm } from './AddEditUsedBikeForm';
import { queryClient } from '../../../../App';
import { useState } from 'react';
import {
  USED_BIKES_QUERY_KEY,
  useCreateUsedBike,
  useUpdateUsedBike,
} from '../hooks';
import { RcFile } from 'antd/es/upload';
import {
  IUsedBike,
  IUsedBikeImage,
} from '../../../../models/listings/bikes/UsedBike';
import { BikeStatus } from '../../constants';
import { UploadFileStatus } from 'antd/es/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

export interface IUsedBikeImageUpload {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface IUsedBikeImages extends IUsedBikeImageUpload {
  file: RcFile;
}

type AddEditUsedBikeFormProps = {
  car: IUsedBike | null;
  isEditing: boolean;
  onClose: () => void;
  isVisible: boolean;
  setCurrentBike: (value: IUsedBike | null) => void;
  setIsFormOpen: (value: boolean) => void;
};

export type CustomUploadFileStatus = UploadFileStatus | 'old';

export interface IBikeModelAddedImages extends IUsedBikeImage {
  file: UploadRequestOption['file'];
  status: CustomUploadFileStatus;
}

export const AddEditUsedBikeModal = ({
  car,
  isEditing,
  onClose,
  isVisible,
  setCurrentBike,
  setIsFormOpen,
}: AddEditUsedBikeFormProps) => {
  const [addedImages, setAddedImages] = useState<IBikeModelAddedImages[]>([]);
  const [removedImages, setRemovedImages] = useState<
    Array<Record<string, string>>
  >([]);
  const [countryCode, setCountryCode] = useState('+974');
  const [rejectReason, setRejectReason] = useState(car?.reason);

  const { mutateAsync: createUsedCar, isLoading: isCreating } =
    useCreateUsedBike();
  const { mutateAsync: updateUsedCar, isLoading: isUpdating } =
    useUpdateUsedBike();

  const _create = async (data: any) => {
    try {
      if (data == null) return;
      data.year = data.year.year();
      delete data.images;
      data.mobile_number = data.mobile_number
        ? `${countryCode?.slice(1)}${data.mobile_number.toString()}`
        : null;
      data.whatsapp_number = data.whatsapp_number
        ? `${countryCode?.slice(1)}${data.whatsapp_number.toString()}`
        : null;
      const response = await createUsedCar({
        ...data,
        add_images: addedImages
          .filter(image => image.status === 'done')
          .map(image => ({ id: image.id })),
      });
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: USED_BIKES_QUERY_KEY,
      });
      message.success('Used bike created successfully');
      return response;
    } catch (error) {}
  };

  const _edit = async (data: any) => {
    try {
      data.year = data.year.year();
      data.reason = rejectReason;
      delete data.images;
      data.mobile_number = data.mobile_number
        ? `${countryCode?.slice(1)}${data.mobile_number.toString()}`
        : null;
      data.whatsapp_number = data.whatsapp_number
        ? `${countryCode?.slice(1)}${data.whatsapp_number.toString()}`
        : null;
      if (
        data.status === BikeStatus.rejected &&
        (!rejectReason || rejectReason === '')
      ) {
        message.error('Please enter a reason to reject the used bike');
        return;
      }
      if (!data.trim_id) {
        data.trim_id = null;
      }
      const response = await updateUsedCar({
        id: car!.id,
        data: {
          ...data,
          add_images: addedImages
            .filter(image => image.status === 'done')
            .map(image => ({ id: image.id })),
          remove_images: removedImages,
        },
      });
      setIsFormOpen(false);
      setCurrentBike(null);
      queryClient.invalidateQueries({
        queryKey: USED_BIKES_QUERY_KEY,
      });
      message.success('Used bike updated successfully');
      return response;
    } catch (error) {}
  };
  return (
    <Modal
      key={car?.id}
      title={isEditing ? 'Edit Listing' : 'Create Listing'}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={1200}
      destroyOnClose
    >
      <AddEditUsedBikeForm
        key={car?.id}
        bike={car}
        onSubmit={isEditing ? _edit : _create}
        isEditing={isEditing}
        isLoading={isCreating || isUpdating}
        onClose={onClose}
        addedImages={addedImages}
        removedImages={removedImages}
        rejectReason={rejectReason}
        setAddedImages={setAddedImages}
        setRemovedImages={setRemovedImages}
        setRejectReason={setRejectReason}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    </Modal>
  );
};
