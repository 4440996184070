import api from '../../../api/apiIndex';
import { leadEmailsEndpoint } from './leadEmailsApi';

export interface IGetLeadEmailsWithBrands {
  id: number;
  name: string;
  brand_lead_emails: Array<{ email: string }>;
  unassociated_emails: Array<{ email: string }>;
}

export interface IGetDealerLeadEmailsForUsedCars {
  registeredEmails: Array<{ email: string }>;
  unregisteredEmails: Array<{ email: string }>;
}

export const getLeadEmailsWithBrands = async (): Promise<
  IGetLeadEmailsWithBrands[]
> => {
  const response = await api.get(
    `${leadEmailsEndpoint}/lead-emails-with-brands`,
  );
  return response.data;
};

export const attachLeadEmailToBrand = async (
  email: string,
  brand_id: number,
) => {
  try {
    const response = await api.post(
      `${leadEmailsEndpoint}/attach-brand-lead-email`,
      {
        email,
        brand_id,
      },
    );
    return response.data;
  } catch (err) {}
};

export const detachLeadEmailFromBrand = async (
  email: string,
  brand_id: number,
) => {
  try {
    const response = await api.delete(
      `${leadEmailsEndpoint}/detach-brand-lead-email`,
      { data: { email, brand_id } },
    );
    return response.data;
  } catch (err) {}
};

export const getDealerLeadEmailsForUsedCars =
  async (): Promise<IGetDealerLeadEmailsForUsedCars> => {
    const response = await api.get(
      `${leadEmailsEndpoint}/dealer-lead-emails/used-cars`,
    );
    return response.data;
  };

export const registerDealerLeadEmailsForUsedCars = async (email: string) => {
  try {
    const response = await api.post(
      `${leadEmailsEndpoint}/dealer-lead-emails/used-cars`,
      {
        email,
      },
    );
    return response.data;
  } catch (err) {}
};

export const unregisterDealerLeadEmailFromUsedCars = async (email: string) => {
  try {
    const response = await api.delete(
      `${leadEmailsEndpoint}/dealer-lead-emails/used-cars`,
      { data: { email } },
    );
    return response.data;
  } catch (err) {}
};
