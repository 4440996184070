import { useEffect } from 'react';
import CarInteriorColorsLayout from '../../../resources/cars/car_interior_color/components/CarInteriorColorLayout';
import { RouteType } from '../../routes/routesConfig';

const InteriorColors = () => {
  return <CarInteriorColorsLayout />;
};

const interiorColorsRoute: RouteType = {
  element: <InteriorColors />,
  state: 'interior-colors',
  index: true,
  path: '/resources/cars/interior-colors',
  sideBarProps: {
    displayText: 'Interior Colors',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { InteriorColors, interiorColorsRoute };
