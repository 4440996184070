import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getNewCarLeadsDetails,
  getNewCarLeads,
  IEditNewCarLead,
  ICreateNewCarLead,
  createNewCarLead,
  editNewCarLead,
  deleteNewCarLead,
} from '../utils/newCarLeadsApi';

interface IUseLeadsDetails {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

export const useNewCarLeads = () => {
  return useQuery({
    queryKey: ['newCarLeads'],
    queryFn: () => getNewCarLeads(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useNewCarLeadsDetail = ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: IUseLeadsDetails) => {
  return useQuery({
    queryKey: ['newCarLeadsDetails', dealerSlug, brandSlug, modelSlug],
    queryFn: () =>
      getNewCarLeadsDetails({
        dealerSlug,
        brandSlug,
        modelSlug,
      }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useCreateNewCarLead = () => {
  return useMutation({
    mutationFn: (rentalDealer: ICreateNewCarLead) =>
      createNewCarLead(rentalDealer),
  });
};

export const useEditNewCarLead = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEditNewCarLead }) =>
      editNewCarLead({ id, data }),
    onError: (error: any) => {
      console.log(error);
    },
  });
};

export const useDeleteNewCarLead = () => {
  return useMutation({
    mutationFn: (id: number) => deleteNewCarLead(id),
  });
};
