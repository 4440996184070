import api from '../../api/apiIndex';
import User from '../../models/User';
import { Metadata } from '../../models/metadata/Metadata';

const usersEndpointV2 = 'v2/admin/users';

interface IGetAllUsers {
  group?: string | null;
  searchQueryParams: Record<string, string>;
  paginate?: boolean;
  perPage?: number;
  page?: number;
}

const usersEndpoint = 'v2/admin/users';

export type CreateUserRequest = {
  first_name: string;
  last_name: string;
  username?: string;
  group: string;
  access: string;
  email: string;
  receive_email_notifications: boolean;
  company_id?: number;
  brands?: Array<string>;
  country_id?: number;
  city_id?: number;
};

export type EditUserRequest = {
  first_name?: string;
  last_name?: string;
  username?: string;
  group?: string;
  access?: string | null;
  password?: string;
  email?: string;
  receive_email_notifications?: boolean;
  company_id?: number;
  brands?: Array<string>;
};

const getAllUsers = async ({
  group,
  searchQueryParams,
  perPage,
  page,
}: IGetAllUsers): Promise<{ data: User[]; meta: Metadata }> => {
  try {
    const isSearchQueryParamsEmpty =
      Object.keys(searchQueryParams).length === 0;

    const response = await api.get(usersEndpointV2, {
      params: {
        group: group !== '' ? group : undefined,
        ...(!isSearchQueryParamsEmpty && searchQueryParams),
        perPage,
        page,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createUser = async (request: CreateUserRequest) => {
  const response = await api.post(usersEndpoint, request);
  return response.data;
};

const updateUser = async (userId: number, request: EditUserRequest) => {
  const response = await api.put(`${usersEndpoint}/${userId}`, request);
  const mappedUser = response.data;
  return mappedUser;
};

const deleteUser = async (userId: number) => {
  const response = await api.delete(`${usersEndpoint}/${userId}`);
  return response.data;
};

const activateUser = async (id: number) => {
  const response = await api.put(`${usersEndpointV2}/activate/${id}`);
  return response.data;
};

const deactivateUser = async (id: number) => {
  const response = await api.put(`${usersEndpointV2}/deactivate/${id}`);
  return response.data;
};

const bulkDeactivateUsers = async (idsArray: Array<number>) => {
  const response = await api.put(`${usersEndpointV2}/deactivate-many`, {
    ids: idsArray,
  });
  return response.data;
};

const bulkActivateUsers = async (idsArray: Array<number>) => {
  const response = await api.put(`${usersEndpointV2}/activate-many`, {
    ids: idsArray,
  });
  return response.data;
};

export {
  getAllUsers,
  deactivateUser,
  bulkActivateUsers,
  bulkDeactivateUsers,
  createUser,
  updateUser,
  deleteUser,
  activateUser,
};
