import api from '../../../../api/apiIndex';

const cylindersEndpointv2 = 'v2/admin/resources/cars/cylinder';

export interface IGetCylinders {
  active?: boolean;
}

export type CreateCylinderRequest = {
  name: string;
  arabic_name: string;
  status: number;
  enginetypes: Array<string>;
};

export type UpdateCylinderRequest = {
  name?: string;
  arabic_name?: string;
  status?: number;
  enginetypes?: Array<string>;
};

const getCylinders = async ({ active }: IGetCylinders) => {
  try {
    const response = await api.get(cylindersEndpointv2, { params: { active } });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createCylinder = async (request: CreateCylinderRequest) => {
  try {
    const response = await api.post(cylindersEndpointv2, request);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateCylinder = async (id: number, request: UpdateCylinderRequest) => {
  try {
    const response = await api.put(`${cylindersEndpointv2}/${id}`, request);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteCylinder = async (id: number) => {
  try {
    const response = await api.delete(`${cylindersEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCylinder = async (id: number) => {
  try {
    const response = await api.put(`${cylindersEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCylinder = async (id: number) => {
  try {
    const response = await api.put(`${cylindersEndpointv2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getCylinders, createCylinder, updateCylinder, deleteCylinder };
