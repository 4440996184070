import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICarSeatMaterialPayload,
  activateCarSeatMaterial,
  createCarSeatMaterial,
  deactivateCarSeatMaterial,
  deleteCarSeatMaterial,
  getCarSeatMaterials,
  updateCarSeatMaterial,
} from '../carSeatMaterialApi';
import { queryClient } from '../../../../App';

export const CAR_SEAT_MATERIALS_QUERY_KEY = ['allSeatMaterials'];

interface IUseSeatMaterial {
  enabled: boolean;
  active?: boolean;
}

interface ICarSeatMaterialFormPayload {
  name: string;
  arabicName: string;
  status: number;
}

export const useCarSeatMaterial = ({ enabled, active }: IUseSeatMaterial) => {
  return useQuery({
    queryKey: CAR_SEAT_MATERIALS_QUERY_KEY,
    queryFn: () => getCarSeatMaterials({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCarSeatMaterial = () => {
  return useMutation({
    mutationFn: (data: ICarSeatMaterialPayload) => createCarSeatMaterial(data),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_SEAT_MATERIALS_QUERY_KEY);
    },
  });
};

export const useUpdateCarSeatMaterial = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICarSeatMaterialPayload }) =>
      updateCarSeatMaterial(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_SEAT_MATERIALS_QUERY_KEY);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });
};

export const useDeleteCarSeatMaterial = () => {
  return useMutation({
    mutationFn: (id: number) => deleteCarSeatMaterial(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_SEAT_MATERIALS_QUERY_KEY);
    },
  });
};

export const useActivateCarSeatMaterial = () => {
  return useMutation({
    mutationFn: (id: number) => activateCarSeatMaterial(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_SEAT_MATERIALS_QUERY_KEY);
    },
  });
};

export const useDeactivateCarSeatMaterial = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateCarSeatMaterial(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_SEAT_MATERIALS_QUERY_KEY);
    },
  });
};
