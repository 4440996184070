import api from '../../../../api/apiIndex';

const bikeFeaturesEndpoint = 'v2/admin/resources/bikes/optional-features';

export interface IGetBikeOptionalFeatures {
  active?: boolean;
}

export enum triStateOptions {
  'Two State' = 'TwoState',
  'Three State' = 'ThreeState',
}

export interface IBikesOptionalFeaturesPayload {
  name: string;
  arabic_name: string;
  button_state: string;
  website_button_state: string;
  bike_engine_types: Array<number>;
}

export const getBikeOptionalFeatures = async ({
  active,
}: IGetBikeOptionalFeatures) => {
  try {
    const response = await api.get(bikeFeaturesEndpoint, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeOptionalFeature = async (
  data: IBikesOptionalFeaturesPayload,
) => {
  const response = await api.post(bikeFeaturesEndpoint, data);
  return response.data;
};

export const updateBikeOptionalFeature = async (
  id: number,
  data: IBikesOptionalFeaturesPayload,
) => {
  const response = await api.put(`${bikeFeaturesEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeOptionalFeature = async (id: number) => {
  try {
    const response = await api.delete(`${bikeFeaturesEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeOptionalFeature = async (id: number) => {
  try {
    const response = await api.put(`${bikeFeaturesEndpoint}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeOptionalFeature = async (id: number) => {
  try {
    const response = await api.put(`${bikeFeaturesEndpoint}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
