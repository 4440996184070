import { useEffect, useState } from 'react';
import { ICarTransmission } from '../../../models/resources/CarTransmission';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Tag, Button, Table, Input, Popconfirm, message } from 'antd';
import { useSearch } from '../../../hooks/useSearch';
import {
  useActivateTransmission,
  useDeactivateTransmission,
  useDeleteTransmission,
  useTransmissions,
} from './hooks';
import { useEngineTypes } from '../engine_types/hooks';
import { CarTransmissionModal } from './components';
import { Layout } from '../../../components/Layout';
import { ColumnsType } from 'antd/es/table';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';

const { Search } = Input;

const CarTransmissionsLayout = () => {
  const { data: transmissions, isLoading: isTransmissionsLoading } =
    useTransmissions({
      enabled: true,
    });
  const { data: engineTypes } = useEngineTypes({
    enabled: true,
  });

  const { mutateAsync: deleteTransmission, isLoading: isDeleting } =
    useDeleteTransmission();
  const { mutateAsync: activateTransmission, isLoading: isActivating } =
    useActivateTransmission();
  const { mutateAsync: deactivateTransmission, isLoading: isDeactivating } =
    useDeactivateTransmission();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTransmission, setCurrentTransmission] =
    useState<ICarTransmission | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState(transmissions);
  const { handleSearchCarTransmission } = useSearch();

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarTransmission | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<ICarTransmission | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateTransmission(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Transmission deactivated successfully');
          },
        })
      : activateTransmission(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Transmission activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteTransmission(id, {
      onSuccess: () => {
        message.success('Transmission deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (transmission: ICarTransmission) => {
    setCurrentTransmission(transmission);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentTransmission(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchCarTransmission(transmissions, value);
  };

  useEffect(() => {
    const newData = handleSearchCarTransmission(transmissions, searchValue);
    setFilteredData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(transmissions);
  }, [transmissions]);

  const tableColumns: ColumnsType<ICarTransmission> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${
        transmissions && transmissions[0]?.t && transmissions[0]?.t[0]?.name
      }}`,
      key: `${
        transmissions && transmissions[0]?.t && transmissions[0]?.t[0]?.name
      }}`,
      render(text: any, record: ICarTransmission) {
        return record.t[0].name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarTransmission) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICarTransmission) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this transmission will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this transmission.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Cylinders"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Transmission
        </Button>
      </div>
      <Table
        loading={transmissions?.length === 0 || isTransmissionsLoading}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1300, y: 720 }}
        rowKey={record => record.id}
      />
      <CarTransmissionModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        currentTransmission={currentTransmission}
        setCurrentTransmission={setCurrentTransmission}
        engineTypes={engineTypes}
      />
    </Layout>
  );
};

export default CarTransmissionsLayout;
