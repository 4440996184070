import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Table, Button, Popconfirm, Tag, message } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  CAR_EXTERIOR_COLOR_QUERY_KEY,
  useActivateCarExteriorColor,
  useCarExteriorColor,
  useDeactivateCarExteriorColor,
  useDeleteCarExteriorColor,
} from '../hooks/useCarExteriorColor';
import { useQueryClient } from '@tanstack/react-query';
import { CarExteriorColorModal } from './CarExteriorColorModal';
import { IUseCarExteriorColor } from '../utils/apiUtils';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';
import { Layout } from '../../../../components/Layout';

const CarExteriorColorsLayout = () => {
  const [open, setOpen] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCarExteriorColor, setSelectedCarExteriorColor] = useState<
    IUseCarExteriorColor | undefined
  >(undefined);

  const queryClient = useQueryClient();

  const { data: carExteriorColors, isLoading: isLoadingCarExteriorColors } =
    useCarExteriorColor({ enabled: true });

  const { mutateAsync: deleteExeriorColor, isLoading: isDeleting } =
    useDeleteCarExteriorColor();

  const { mutateAsync: activateExteriorColor, isLoading: isActivating } =
    useActivateCarExteriorColor();

  const { mutateAsync: deactivateExteriorColor, isLoading: isDeactivating } =
    useDeactivateCarExteriorColor();

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IUseCarExteriorColor | null>(null);

  const handleDelete = (id: number) => {
    deleteExeriorColor(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: CAR_EXTERIOR_COLOR_QUERY_KEY,
        });
        setSelectedCarExteriorColor(undefined);
        message.success('Exterior color deleted successfully');
        setOpen(null);
      },
    });
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateExteriorColor(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Exterior Color deactivated successfully');
          },
        })
      : activateExteriorColor(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Exterior Color activated successfully');
          },
        });
  };

  const tableColumns: ColumnsType<IUseCarExteriorColor> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (rowData): string => {
        return rowData ? rowData?.toString() : 'N/A';
      },
    },
    {
      title: 'Arabic Name',
      dataIndex: `${carExteriorColors?.map(
        (carExteriorColor: IUseCarExteriorColor) =>
          carExteriorColor?.t?.[0]?.name,
      )}`,
      key: `${carExteriorColors?.map(
        (carExteriorColor: IUseCarExteriorColor) =>
          carExteriorColor?.t?.[0]?.name,
      )}`,
      render: (text: any, record: IUseCarExteriorColor) => {
        return record?.t?.[0]?.name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IUseCarExteriorColor) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              style={{ fontSize: '1rem', color: 'var(--primary)' }}
              onClick={() => {
                setSelectedCarExteriorColor(rowData);
                handleOpenModal();
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Are you sure you want to delete this exterior color?"
              open={open === rowData.id}
              onConfirm={() => {
                handleDelete(rowData.id);
              }}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpen(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                style={{ fontSize: '1rem', color: 'var(--error)' }}
                onClick={() => {
                  setOpen(rowData.id);
                  setSelectedCarExteriorColor(rowData);
                }}
              />
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this exterior color.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={handleOpenModal}>
          Add
        </Button>
      </div>
      <Table
        dataSource={carExteriorColors}
        rowKey="id"
        scroll={{ x: 1300, y: 720 }}
        columns={tableColumns}
        pagination={false}
        loading={isLoadingCarExteriorColors}
      />
      <CarExteriorColorModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedCarExteriorColor={selectedCarExteriorColor}
        setSelectedCarExteriorColor={setSelectedCarExteriorColor}
      />
    </Layout>
  );
};

export default CarExteriorColorsLayout;
