import { Tag } from 'antd';

export const CustomizedRequiredMark = (
  label: React.ReactNode,
  { required }: { required: boolean },
) => {
  if (required)
    return (
      <>
        <span
          style={{
            color: 'red',
            paddingRight: '3px',
          }}
        >
          *
        </span>
        {label}
      </>
    );
  return (
    <>
      {label}
      <Tag
        color="warning"
        style={{
          margin: '0 5px',
        }}
      >
        optional
      </Tag>
    </>
  );
};
