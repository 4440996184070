import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeTrimPayload,
  getTrims,
  createBikeTrim,
  updateBikeTrim,
  deleteBikeTrim,
  activateBikeTrim,
  deactivateBikeTrim,
} from '../apis';
import { queryClient } from '../../../../App';

export interface IUseGetBikeTrims {
  enabled: boolean;
  model_id?: number;
  active?: boolean;
}

export const BIKE_TRIMS_QUERY_KEY = ['bike_trims'];

export const useGetBikeTrims = ({
  enabled,
  model_id,
  active,
}: IUseGetBikeTrims) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [...BIKE_TRIMS_QUERY_KEY, model_id, active],
    queryFn: () => getTrims({ model_id, active }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};

export const useCreateBikeTrim = () => {
  return useMutation({
    mutationFn: (data: IBikeTrimPayload) => createBikeTrim(data),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TRIMS_QUERY_KEY);
    },
  });
};

export const useUpdateBikeTrim = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeTrimPayload }) =>
      updateBikeTrim(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TRIMS_QUERY_KEY);
    },
  });
};

export const useDeleteBikeTrim = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeTrim(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TRIMS_QUERY_KEY);
    },
  });
};

export const useActivateBikeTrim = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeTrim(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TRIMS_QUERY_KEY);
    },
  });
};

export const useDeactivateBikeTrim = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeTrim(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TRIMS_QUERY_KEY);
    },
  });
};
