import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
import { UsedBikeLeadsDetail } from '../../../models/overview/UsedBikeLead';
import useAuth from '../../../hooks/useAuth';
import { useGetUsedBikesByDealer } from '../../../listings/bikes/used_bikes/hooks';
import { UserGroups } from '../../../common/constants';
import { CustomizedRequiredMark } from '../../../components/misc/RequiredMark';
import { leadContactStatuses } from '../../constants';

type AddEditUsedBikeLeadFormProps = {
  lead: UsedBikeLeadsDetail | null;
  onSubmit: (formData: any) => Promise<void>;
  isLoading: boolean;
  onClose: () => void;
};
export const AddEditUsedBikeLeadForm = ({
  lead,
  onSubmit,
  isLoading,
  onClose,
}: AddEditUsedBikeLeadFormProps) => {
  const [form] = Form.useForm();
  const { Item } = Form;
  const { TextArea } = Input;
  const { userInfo } = useAuth();

  const { data: usedBikes, isLoading: isLoadingUsedBikes } =
    useGetUsedBikesByDealer({
      enabled: userInfo?.group === UserGroups.Automotive,
      dealer_id: userInfo?.dealer_id,
    });

  const initialValues = lead
    ? {
        name: lead.name,
        email: lead.email,
        mobile_number: lead.mobile_number.slice(4),
        source: lead.source,
        contacted: lead.contacted,
        notes: lead.notes,
      }
    : undefined;

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={async values => {
          try {
            await onSubmit(values);
          } catch (error) {
            console.log(error);
          }
        }}
        initialValues={initialValues}
        requiredMark={CustomizedRequiredMark}
      >
        <Row gutter={24} wrap>
          <Col span={24}>
            <Item
              label={
                userInfo?.group === UserGroups.OAC
                  ? 'Used Bike Id'
                  : 'Used Bike'
              }
              name="used_bike_id"
              rules={[
                {
                  required: true,
                  message:
                    userInfo?.group === UserGroups.OAC
                      ? 'Enter the used bike id'
                      : 'Select a used bike',
                },
              ]}
            >
              {userInfo?.group === UserGroups.OAC ? (
                <Input placeholder="Used Bike Id" />
              ) : (
                <Select
                  showSearch
                  placeholder="Select a used bike"
                  optionFilterProp="children"
                  loading={isLoadingUsedBikes}
                  disabled={isLoadingUsedBikes}
                >
                  {usedBikes?.map(usedBike => (
                    <Select.Option key={usedBike.id} value={usedBike.id}>
                      {usedBike.id} - {usedBike.model?.brand?.name}{' '}
                      {usedBike.model?.name} {usedBike.trim?.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={8}>
            <Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter a name' }]}
            >
              <Input placeholder="Name" />
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Email" name="email">
              <Input placeholder="Email" />
            </Item>
          </Col>

          <Col span={8}>
            <Item
              label="Mobile Number"
              name="mobile_number"
              rules={[
                { required: true, message: 'Please enter a mobile number' },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Mobile Number"
                addonBefore="+974"
                minLength={3}
                min={0}
                controls={false}
              />
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={12}>
            <Item
              label="Source"
              name="source"
              rules={[{ required: true, message: 'Please enter the source' }]}
            >
              <Input placeholder="Source" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Status"
              name="contacted"
              rules={[
                {
                  required: true,
                  message: 'Select a status',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a contact status"
                optionFilterProp="children"
              >
                {leadContactStatuses
                  .filter(status => status.value !== '')
                  .map((status: any) => (
                    <Select.Option key={status.value} value={status.value}>
                      {status.text}
                    </Select.Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={24}>
            <Item label="Notes" name="notes">
              <TextArea placeholder="Notes" autoSize={{ minRows: 3 }} />
            </Item>
          </Col>
        </Row>

        <Row
          style={{
            paddingTop: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Space>
            <Button danger onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Create
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};
