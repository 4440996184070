import BankingTable from '../../../overview/components/BankingTable';

const BankingLeadsLayout = () => {
  return (
    <div className="flex flex-column row-gap-5">
      <div className="border-round-xl shadow-5 w-full p-5 flex flex-column row-gap-5">
        <BankingTable />
      </div>
    </div>
  );
};

export default BankingLeadsLayout;
