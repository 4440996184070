import { useMutation } from '@tanstack/react-query';
import { TLoginResponse, login } from '../apis/authenticationApi';
import { notification } from 'antd';
import { AxiosError } from 'axios';
export type TLoginMutation = {
  email: string;
  password: string;
};

export const useLogin = () => {
  return useMutation({
    mutationFn: ({
      email,
      password,
    }: TLoginMutation): Promise<TLoginResponse> =>
      login({
        email,
        password,
      }),
  });
};
