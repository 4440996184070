import api from '../../../api/apiIndex';

const currenciesEndpointV2 = 'v2/admin/resources/currencies';

export interface IGetCurrencies {
  active?: boolean;
}

export interface ICurrencyPayload {
  name: string;
  arabic_name: string;
}

export const getCurrencies = async ({ active }: IGetCurrencies) => {
  try {
    const response = await api.get(currenciesEndpointV2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createCurrency = async (data: ICurrencyPayload) => {
  const response = await api.post(currenciesEndpointV2, data);
  return response.data;
};

export const updateCurrency = async (id: number, data: ICurrencyPayload) => {
  const response = await api.put(`${currenciesEndpointV2}/${id}`, data);
  return response.data;
};

export const deleteCurrency = async (id: number) => {
  try {
    const response = await api.delete(`${currenciesEndpointV2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCurrency = async (id: number) => {
  try {
    const response = await api.put(`${currenciesEndpointV2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCurrency = async (id: number) => {
  try {
    const response = await api.put(`${currenciesEndpointV2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
