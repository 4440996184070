import { useEffect, useRef, useState } from 'react';
import {
  Input,
  Button,
  Table,
  Tag,
  Popconfirm,
  message,
  Space,
  Select,
} from 'antd';
import { useSearch } from '../../../../hooks/useSearch';
import { ICarModel } from '../../../../models/resources';
import { Layout } from '../../../../components/Layout';
import {
  useActivateBikeTrim,
  useDeactivateBikeTrim,
  useDeleteBikeTrim,
  useGetBikeTrims,
} from '../hooks';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { BikeTrimModal } from './BikeTrimModal';
import { IBikeTrim } from '../../../../models/resources/bikes/BikeTrim';
import { IBikeModel } from '../../../../models/resources/bikes/BikeModel';

const { Search } = Input;

const searchOptions: { label: string; value: SearchOptionKeys }[] = [
  { label: 'Trim', value: 'trimName' },
  { label: 'Brand', value: 'brandName' },
  { label: 'Model', value: 'modelName' },
];

const placeholderOptions: Record<SearchOptionKeys, string> = {
  trimName: 'Search by Bike Trim Name',
  brandName: 'Search by Bike Brand Name',
  modelName: 'Search by Bike Model Name',
};

export type SearchOptionKeys = 'trimName' | 'brandName' | 'modelName';

const BikeTrimsLayout = () => {
  const { data: trims, isLoading: isTrimsLoading } = useGetBikeTrims({
    enabled: true,
  });

  const { mutateAsync: deleteTrim, isLoading: isDeletingTrim } =
    useDeleteBikeTrim();
  const { mutateAsync: activeTrim, isLoading: isActivatingTrim } =
    useActivateBikeTrim();
  const { mutateAsync: deactivateTrim, isLoading: isDeactivatingTrim } =
    useDeactivateBikeTrim();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTrim, setCurrentTrim] = useState<IBikeTrim | null>(null);

  // Search States
  const searchValue = useRef<string>('');
  const [searchSelectedOption, setSearchSelectedOption] =
    useState<SearchOptionKeys>('trimName');

  const { handleSearchBikeTrim } = useSearch();

  const [filteredData, setFilteredData] = useState(trims);

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IBikeTrim | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IBikeTrim | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateTrim(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Bike Trim Deactivated Successfully');
          },
        })
      : activeTrim(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Bike Trim Activated Successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteTrim(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Bike Trim Deleted Successfully');
      },
    });
  };

  const handleSelectChange = (value: any) => {
    setSearchSelectedOption(value);
  };

  const handleSearch = () => {
    if (!trims) return;
    const newData = handleSearchBikeTrim(
      trims,
      searchValue.current,
      searchSelectedOption,
    );
    setFilteredData(newData);
  };

  const onEditButtonPress = (trim: IBikeTrim) => {
    setCurrentTrim(trim);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setCurrentTrim(null);
  };

  const openEmptyForm = () => {
    setCurrentTrim(null);
    setIsFormOpen(true);
  };

  useEffect(() => {
    setFilteredData(trims);
  }, [trims]);

  const tableColumns: ColumnsType<IBikeTrim> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Brand',
      dataIndex: 'bike_model',
      key: 'bike_model',
      width: '20%',
      render: (bikeModel: IBikeModel) => bikeModel?.brand?.name,
    },
    {
      title: 'Model',
      dataIndex: 'bike_model',
      key: 'bike_model',
      width: '20%',
      render: (bikeModel: IBikeModel) => bikeModel.name,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IBikeTrim) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: IBikeTrim) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this trim will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{ loading: isDeletingTrim }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone
              onClick={() => {
                setOpenDeleteDialog(record);
              }}
            />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this trim.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivatingTrim || isDeactivatingTrim,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Space.Compact>
          <Search
            placeholder={placeholderOptions[searchSelectedOption]}
            onChange={e => {
              if (e.target.value === '') {
                searchValue.current = e.target.value;
                handleSearch();
              }
            }}
            onSearch={value => {
              searchValue.current = value;
              handleSearch();
            }}
            style={{ width: 200 }}
          />
          <Select
            options={searchOptions}
            defaultValue={searchOptions[0].value}
            onChange={value => handleSelectChange(value)}
            style={{ width: 130 }}
          />
        </Space.Compact>
        <Button type="primary" onClick={openEmptyForm}>
          New Trim
        </Button>
      </div>
      <Table
        loading={isTrimsLoading}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={
          trims.length > 50
            ? {
                defaultPageSize: 50,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                position: ['bottomCenter'],
              }
            : false
        }
        scroll={{ x: 1300, y: 720 }}
        rowKey={record => record.id}
      />
      {isFormOpen && (
        <BikeTrimModal
          currentTrim={currentTrim}
          isEditing={isEditing}
          isFormOpen={isFormOpen}
          onClose={closeForm}
          setCurrentTrim={setCurrentTrim}
          setIsFormOpen={setIsFormOpen}
        />
      )}
    </Layout>
  );
};

export default BikeTrimsLayout;
