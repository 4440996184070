export type IconProps = {
  icon?: string;
  iconSize?: string;
  color?: string;
  onClick?: () => void;
  cursor?: boolean;
};

const Icon = ({
  icon,
  iconSize,
  color = 'inherit',
  onClick,
  cursor = false,
}: IconProps) => (
  <i
    className={`pi ${icon}`}
    style={{
      color: color,
      fontSize: iconSize,
      ...(cursor && { cursor: 'pointer' }),
    }}
    onClick={onClick}
  ></i>
);

export default Icon;
