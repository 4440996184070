import { UserGroups } from '../../../common/constants';
import { NewBikeLeadsDetailsLayout } from '../../../leads/new_bikes';

const NewBikeLeadsDetails = () => {
  return <NewBikeLeadsDetailsLayout />;
};

const newBikeLeadsDetailsRoute = {
  path: '/leads/new-bikes/:dealerSlug/:brandSlug?/:modelSlug?',
  index: true,
  element: <NewBikeLeadsDetails />,
  state: 'newBikeLeadsDetails',
  sideBarProps: {
    displayText: 'New Bike Leads Details',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { NewBikeLeadsDetails, newBikeLeadsDetailsRoute };
