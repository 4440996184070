import api from '../../../../api/apiIndex';

const engineTypesEndpointv2 = 'v2/admin/resources/bikes/engine-types';

export interface IGetBikeEngineTypes {
  active?: boolean;
}

export interface IBikeEngineTypePayload {
  name: string;
  arabic_name: string;
  label: string;
}

export const getBikeEngineTypes = async ({ active }: IGetBikeEngineTypes) => {
  try {
    const response = await api.get(engineTypesEndpointv2, {
      params: { active },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeEngineType = async (data: IBikeEngineTypePayload) => {
  const response = await api.post(engineTypesEndpointv2, data);
  return response.data;
};

export const updateBikeEngineType = async (
  id: number,
  data: IBikeEngineTypePayload,
) => {
  const response = await api.put(`${engineTypesEndpointv2}/${id}`, data);
  return response.data;
};

export const deleteBikeEngineType = async (id: number) => {
  try {
    const response = await api.delete(`${engineTypesEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeEngineType = async (id: number) => {
  try {
    const response = await api.put(`${engineTypesEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeEngineType = async (id: number) => {
  try {
    const response = await api.put(`${engineTypesEndpointv2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
