import { useMutation, useQuery } from '@tanstack/react-query';

import {
  UpdateCarRequest,
  createCar,
  deleteUsedCar,
  getUsedCars,
  getUsedCarsByDealer,
  updateCar,
} from '../carsApi';
import { queryClient } from '../../../../App';
import { TImage, uploadImage } from '../../../../common/utils';

export interface IUseGetUsedCars {
  searchQueryParams: Record<string, string>;
  page?: number;
  perPage?: number;
  country?: string;
}

export interface IUseGetUsedCarsByDealer {
  enabled: boolean;
  dealer_id: number | null | undefined;
}

export const useGetUsedCars = ({
  searchQueryParams,
  page,
  perPage,
  country,
}: IUseGetUsedCars) => {
  return useQuery({
    queryKey: ['usedCars', searchQueryParams, page, perPage, country],
    queryFn: () =>
      getUsedCars({
        searchQueryParams,
        page,
        perPage,
        country,
      }),
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: true,
  });
};

export const useGetUsedCarsByDealer = ({
  enabled,
  dealer_id,
}: IUseGetUsedCarsByDealer) => {
  return useQuery({
    queryKey: ['usedCarsByDealer', dealer_id],
    queryFn: () => getUsedCarsByDealer(dealer_id!),
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled,
  });
};

export const useCreateUsedCar = () => {
  return useMutation({
    mutationFn: createCar,
    onSuccess: () => {
      queryClient.invalidateQueries(['usedCars']);
    },
  });
};

export const useEditUsedCar = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateCarRequest }) =>
      updateCar(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['usedCars']);
    },
  });
};

export const useDeleteUsedCar = () => {
  return useMutation({
    mutationFn: (id: number) => deleteUsedCar(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['usedCars']);
    },
  });
};
