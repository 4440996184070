import { useState } from 'react';
import {
  useActivateCurrency,
  useCurrencies,
  useDeactivateCurrency,
  useDeleteCurrency,
} from './hooks';
import { ICurrency } from '../../models/countries/Currency';
import { Button, message, Popconfirm, Tag, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import InactiveIcon from '../../components/icons/InactiveIcon';
import ActiveIcon from '../../components/icons/ActiveIcon';
import { Layout } from '../../components/Layout';
import { CurrenciesModal } from './components';

export const CurrenciesLayout = () => {
  const { data: currencies, isLoading: isCurrenciesLoading } = useCurrencies({
    enabled: true,
  });
  const { mutateAsync: deleteCurrency, isLoading: isDeleting } =
    useDeleteCurrency();
  const { mutateAsync: activateCurrency, isLoading: isActivating } =
    useActivateCurrency();
  const { mutateAsync: deactivateCurrency, isLoading: isDeactivating } =
    useDeactivateCurrency();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState<ICurrency | null>(
    null,
  );
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICurrency | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<ICurrency | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateCurrency(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Currency deactivated successfully');
          },
        })
      : activateCurrency(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Currency activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteCurrency(id, {
      onSuccess: () => {
        message.success('Currency deleted successfully');
      },
    });
  };

  const onEditButtonPress = (currency: ICurrency) => {
    setCurrentCurrency(currency);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setIsEditing(false);
    setIsFormOpen(true);
    setCurrentCurrency(null);
  };

  const tableColumns: ColumnsType<ICurrency> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${currencies?.map(
        (currency: ICurrency) => currency?.t?.[0]?.name,
      )}`,
      key: `${currencies?.map(
        (currency: ICurrency) => currency?.t?.[0]?.name,
      )}`,
      render(text: any, record) {
        return record.t[0]?.name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICurrency) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICurrency) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this currency will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this currency.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={openEmptyForm}>
          Add Currency
        </Button>
      </div>
      <Table
        loading={isCurrenciesLoading}
        columns={tableColumns}
        dataSource={currencies}
        scroll={{ x: 1300, y: 720 }}
        rowKey={record => record.id}
        pagination={false}
      />
      <CurrenciesModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        currentCurrency={currentCurrency}
        setCurrentCurrency={setCurrentCurrency}
      />
    </Layout>
  );
};
