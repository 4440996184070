import { RouteType } from '../../../routes/routesConfig';
import { UserGroups } from '../../../../common/constants';
import UsedBikesLayout from '../../../../listings/bikes/used_bikes/components/UsedBikesLayout';

const UsedBikes = () => {
  return <UsedBikesLayout />;
};

const usedBikesRoute: RouteType = {
  element: <UsedBikes />,
  state: 'used-bikes',
  index: true,
  path: '/listings/bikes/used-bikes',
  sideBarProps: {
    displayText: 'Used Bikes',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { UsedBikes, usedBikesRoute };
