import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICarTypePayload,
  activateType,
  createType,
  deactivateType,
  deleteType,
  getTypes,
  updateType,
} from '../apis/carTypesApi';
import { queryClient } from '../../../../App';

interface IUserCarTypes {
  enabled: boolean;
  active?: boolean;
}

const TYPES_KEYS = ['types'];

export const useCarTypes = ({ enabled, active }: IUserCarTypes) => {
  return useQuery({
    queryKey: [...TYPES_KEYS, { active }],
    queryFn: () => getTypes({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCarType = () => {
  return useMutation({
    mutationFn: (data: ICarTypePayload) => createType(data),
    onSuccess: () => {
      queryClient.invalidateQueries(TYPES_KEYS);
    },
  });
};

export const useUpdateCarType = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICarTypePayload }) =>
      updateType(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(TYPES_KEYS);
    },
  });
};

export const useDeleteCarType = () => {
  return useMutation({
    mutationFn: (id: number) => deleteType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(TYPES_KEYS);
    },
  });
};

export const useActivateType = () => {
  return useMutation({
    mutationFn: (id: number) => activateType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(TYPES_KEYS);
    },
  });
};

export const useDeactivateType = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(TYPES_KEYS);
    },
  });
};
