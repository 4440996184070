import React from 'react';
import { NewCarLeadsDetail } from '../../../models/overview/NewCarLead';
import { Modal, message } from 'antd';
import { queryClient } from '../../../App';
import {
  useCreateRamadanOfferLead,
  useEditRamadanOfferLead,
} from '../hooks/useRamadanOfferLeads';
import AddEditRamadanOfferLeadForm from './AddEditRamadanOfferLeadForm';
import { RamadanOfferLeadsDetail } from '../../../models/overview/RamadanOfferLead';
import useAuth from '../../../hooks/useAuth';
import { UserGroups } from '../../../common/constants';

interface RamadanOfferLeadModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing?: (value: boolean) => void;
  currentLead: RamadanOfferLeadsDetail | null;
  setCurrentLead?: (value: NewCarLeadsDetail | null) => void;
}

const RamadanOfferLeadModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentLead,
  setCurrentLead,
}: RamadanOfferLeadModalProps) => {
  const { userInfo } = useAuth();

  const { mutateAsync: createLead, isLoading: isCreating } =
    useCreateRamadanOfferLead();
  const { mutateAsync: updateLead, isLoading: isUpdating } =
    useEditRamadanOfferLead();

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing && setIsEditing(false);
    setCurrentLead && setCurrentLead(null);
  };

  const _create = async (lead: any) => {
    if (lead == null) return;
    try {
      const response = await createLead({
        name: lead.name,
        email: lead.email,
        mobile_number: `+974${lead.mobile_number?.toString()}`,
        dealer_id:
          userInfo?.group === UserGroups.OAC
            ? lead.dealer_id
            : userInfo?.dealer_id,
        ramadan_offer_id: lead.ramadan_offer_id,
        brand_id: lead.brand_id,
        model_id: lead.model_id,
        source: lead.source,
        contacted: lead.contacted,
        notes: lead.notes,
      });
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['ramadanOfferLeads'],
      });
      queryClient.invalidateQueries({
        queryKey: ['ramadanOfferLeadsDetails'],
      });
      message.success('Ramadan offer lead created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (lead: any) => {
    try {
      const response = await updateLead({
        id: currentLead!.id,
        data: {
          name: lead.name,
          email: lead.email,
          mobile_number: `+974${lead.mobile_number?.toString()}`,
          source: lead.source,
          contacted: lead.contacted,
          notes: lead.notes,
        },
      });
      setIsFormOpen(false);
      setCurrentLead && setCurrentLead(null);
      queryClient.invalidateQueries({
        queryKey: ['ramadanOfferLeads'],
      });
      queryClient.invalidateQueries({
        queryKey: ['ramadanOfferLeadsDetails'],
      });
      message.success('Ramadan offer lead updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Ramadan Offer Lead' : 'Add Ramadan Offer Lead'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={closeForm}
        footer={null}
        width={1200}
        key={currentLead?.id}
      >
        <AddEditRamadanOfferLeadForm
          lead={currentLead}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={closeForm}
        />
      </Modal>
    </>
  );
};

export default RamadanOfferLeadModal;
