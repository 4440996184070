import api from '../../../../api/apiIndex';

const variantEndpointv2 = 'v2/admin/resources/cars/variants';

export interface IGetVariants {
  active?: boolean;
}

export interface IDrivetrainPayload {
  name: string;
  arabic_name: string;
  engine_types: Array<number>;
}

const getVariants = async ({ active }: IGetVariants) => {
  try {
    const response = await api.get(variantEndpointv2, { params: { active } });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createDrivetrain = async (data: IDrivetrainPayload) => {
  try {
    const response = await api.post(variantEndpointv2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateDrivetrain = async (id: number, data: IDrivetrainPayload) => {
  try {
    const response = await api.put(`${variantEndpointv2}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteDrivetrain = async (id: number) => {
  try {
    const response = await api.delete(`${variantEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateDrivetrain = async (id: number) => {
  try {
    const response = await api.put(`${variantEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateDrivetrain = async (id: number) => {
  try {
    const response = await api.put(`${variantEndpointv2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getVariants, createDrivetrain, updateDrivetrain, deleteDrivetrain };
