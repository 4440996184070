import { IBikeOptionalFeature } from '../../../../models/resources/bikes/BikeOptionalFeatures';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { IBikesOptionalFeaturesPayload, triStateOptions } from '../apis';
import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd';

interface BikeOptionalFeatureAddEditFormProps {
  bikeOptionalFeature: IBikeOptionalFeature | null | undefined;
  bikeEngineTypes: IBikeEngineType[] | null;
  isEditing: boolean;
  onClose: () => void;
  onSubmit: (values: IBikesOptionalFeaturesPayload) => Promise<void>;
  isLoading: boolean;
}

const { Item } = Form;

export const AddEditBikeOptionalFeatureForm = ({
  bikeEngineTypes,
  bikeOptionalFeature,
  isEditing,
  isLoading,
  onClose,
  onSubmit,
}: BikeOptionalFeatureAddEditFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: bikeOptionalFeature?.name,
    arabic_name: bikeOptionalFeature?.t?.[0]?.name,
    button_state: bikeOptionalFeature?.button_state,
    website_button_state: bikeOptionalFeature?.website_button_state,
    bike_engine_types:
      bikeOptionalFeature?.bike_engine_type_bike_optional_feature?.map(
        bikeEngineType => bikeEngineType?.bike_engine_types?.id,
      ),
  };
  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      onFinish={async (values: IBikesOptionalFeaturesPayload) => {
        try {
          await onSubmit(values);
        } catch (error) {
          console.error(error);
        }
      }}
      initialValues={initialValues}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name of the optional feature',
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic name of the optional feature',
              },
            ]}
          >
            <Input placeholder="Arabic Name" size="large" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Used Bikes Button State"
            name="button_state"
            rules={[
              {
                required: true,
                message: 'Please select the state of the used bikes button',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a state"
              optionFilterProp="children"
              size="large"
            >
              {Object.entries(triStateOptions).map(([label, value]) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Sell Your Bike Button State"
            name="website_button_state"
            rules={[
              {
                required: true,
                message: 'Please select the state of the sell your bike button',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a state"
              optionFilterProp="children"
              size="large"
            >
              {Object.entries(triStateOptions).map(([label, value]) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row>
        <Item
          label="Engine Type"
          name="bike_engine_types"
          rules={[
            {
              required: true,
              message: 'Please select the engine types',
            },
          ]}
        >
          <Checkbox.Group>
            {bikeEngineTypes?.map((bikeEngineType: IBikeEngineType) => (
              <Checkbox value={bikeEngineType?.id}>
                {bikeEngineType?.name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Item>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
