import api from '../../../api/apiIndex';
import { rentalDealersEndpoint } from './getRentalDealers';

export const activateRentalDealer = async (id: number) => {
  const response = await api.put(`${rentalDealersEndpoint}/${id}/activate`);
  return response.data;
};

export const deactivateRentalDealer = async (id: number) => {
  const response = await api.put(`${rentalDealersEndpoint}/${id}/deactivate`);
  return response.data;
};
