import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getRamadanOfferLeadsDetails,
  getRamadanOfferLeads,
  IEditRamadanOfferLead,
  ICreateRamadanOfferLead,
  createRamadanOfferLead,
  editRamadanOfferLead,
  deleteRamadanOfferLead,
} from '../utils/ramadanOfferLeadsApi';

interface IUseLeadsDetails {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

export const RAMADAN_OFFER_LEADS_QUERY_KEY = ['ramadanOfferLeads'];

export const RAMADAN_OFFER_LEADS_DETAILS_QUERY_KEY_ELEMENT =
  'ramadanOfferLeadsDetails';

export const useRamadanOfferLeads = () => {
  return useQuery({
    queryKey: RAMADAN_OFFER_LEADS_QUERY_KEY,
    queryFn: () => getRamadanOfferLeads(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useRamadanOfferLeadsDetail = ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: IUseLeadsDetails) => {
  return useQuery({
    queryKey: [
      RAMADAN_OFFER_LEADS_DETAILS_QUERY_KEY_ELEMENT,
      dealerSlug,
      brandSlug,
      modelSlug,
    ],
    queryFn: () =>
      getRamadanOfferLeadsDetails({
        dealerSlug,
        brandSlug,
        modelSlug,
      }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useCreateRamadanOfferLead = () => {
  return useMutation({
    mutationFn: (rentalDealer: ICreateRamadanOfferLead) =>
      createRamadanOfferLead(rentalDealer),
  });
};

export const useEditRamadanOfferLead = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEditRamadanOfferLead }) =>
      editRamadanOfferLead({ id, data }),
    onError: (error: any) => {
      console.log(error);
    },
  });
};

export const useDeleteRamadanOfferLead = () => {
  return useMutation({
    mutationFn: (id: number) => deleteRamadanOfferLead(id),
  });
};
