import { UserGroups } from '../../../../common/constants';
import { BikeBatteryRangesLayout } from '../../../../resources/bikes/battery_range';
import { RouteType } from '../../../routes/routesConfig';

const BikeBatteryRange = () => {
  return <BikeBatteryRangesLayout />;
};

const bikeBatteryRangeRoute: RouteType = {
  element: <BikeBatteryRange />,
  state: 'battery-range',
  index: true,
  path: '/resources/bikes/battery-range',
  sideBarProps: {
    displayText: 'Battery Range',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeBatteryRange, bikeBatteryRangeRoute };
