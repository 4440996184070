import { Form, Row, Col, Input, Space, Button } from 'antd';
import { IEngineType } from '../../../../models/resources/CarEngineType';

const { Item } = Form;

type ICarEngineTypeFormProps = {
  resourceType: IEngineType | null;
  isSubmitting: boolean;
  onSubmit: (values: any) => void;
  onClose: () => void;
  isEditing: boolean;
  isLoading: boolean;
};

export const CarEngineTypeAddEditForm = ({
  resourceType,
  isSubmitting,
  onSubmit,
  onClose,
  isEditing,
}: ICarEngineTypeFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: resourceType?.name,
    arabic_name: resourceType?.t?.[0]?.name,
    label: resourceType?.label,
  };

  const resetForm = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24} wrap>
        <Col span={12}>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter a name',
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_name"
            label="Arabic Name"
            rules={[
              {
                required: true,
                message: 'Please enter an arabic name',
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Item
            name="label"
            label="Label"
            rules={[
              {
                required: true,
                message: 'Please enter a label',
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {isEditing ? 'Edit engine type' : 'Add engine type'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
