import { useMutation, useQuery } from '@tanstack/react-query';
import {
  activateBikeBrand,
  createBikeBrand,
  deactivateBikeBrand,
  deleteBikeBrand,
  editBikeBrand,
  getBikeBrands,
  IBikeBrandPayload,
} from '../apis/bikeBrandsApi';
import { queryClient } from '../../../../App';

interface IUseBikeBrands {
  enabled?: boolean;
  active?: boolean;
  dealerId?: number;
  type?: string;
}

export const ALL_BIKE_BRAND_QUERY_KEY = ['ALL_BIKE_BRAND_QUERY_KEY'];

export const useGetAllBikeBrands = ({
  enabled = true,
  active,
  dealerId,
  type,
}: IUseBikeBrands) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [...ALL_BIKE_BRAND_QUERY_KEY, active, dealerId],
    queryFn: () => getBikeBrands({ dealerId, active, type }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};

export const useCreateBikeBrand = () => {
  return useMutation({
    mutationFn: (data: IBikeBrandPayload) => createBikeBrand(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BRAND_QUERY_KEY);
    },
  });
};

export const useEditBikeBrand = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeBrandPayload }) =>
      editBikeBrand(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BRAND_QUERY_KEY);
    },
  });
};

export const useActivateBikeBrand = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeBrand(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BRAND_QUERY_KEY);
    },
  });
};

export const useDeactivateBikeBrand = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeBrand(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BRAND_QUERY_KEY);
    },
  });
};

export const useDeleteBikeBrand = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeBrand(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BRAND_QUERY_KEY);
    },
  });
};
