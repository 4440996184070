import { Form, Row, Col, Input, Button, Checkbox, Space } from 'antd';
import { ICarTransmission } from '../../../../models/resources/CarTransmission';
import { IEngineType } from '../../../../models/resources/CarEngineType';

interface CarTransmissionAddEditFormProps {
  resource: ICarTransmission | null;
  isEditing: boolean;
  engineTypes: IEngineType[] | null;
  onSubmit: (values: any) => void;
  onClose: () => void;
  isLoading: boolean;
}

const { Item } = Form;

export const CarTransmissionAddEditForm = ({
  resource,
  isEditing,
  engineTypes,
  onSubmit,
  onClose,
  isLoading,
}: CarTransmissionAddEditFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: resource?.name,
    arabic_name: resource?.t?.[0]?.name,
    engine_types: resource?.engine_type_transmission?.map(
      (engineType: any) => engineType?.engine_types?.id,
    ),
  };

  const resetForm = () => {
    form.resetFields();
  };
  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter arabic name',
              },
            ]}
          >
            <Input placeholder="Arabic Name" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="engine_types"
            label="Engine Type"
            rules={[{ required: true, message: 'Please select engine size' }]}
          >
            <Checkbox.Group>
              {engineTypes?.map((engineType: IEngineType) => (
                <Checkbox value={engineType?.id}>{engineType?.name}</Checkbox>
              ))}
            </Checkbox.Group>
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit Transmission' : 'Add Transmission'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
