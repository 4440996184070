import { UserGroups } from '../../../../common/constants';
import { BikeOptionalFeaturesLayout } from '../../../../resources/bikes/optional_features';
import { RouteType } from '../../../routes/routesConfig';

const BikeOptionalFeature = () => {
  return <BikeOptionalFeaturesLayout />;
};

const bikeOptionalFeatureRoute: RouteType = {
  element: <BikeOptionalFeature />,
  state: 'optional-feature',
  index: true,
  path: '/resources/bikes/optional-feature',
  sideBarProps: {
    displayText: 'Optional Features',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeOptionalFeature, bikeOptionalFeatureRoute };
