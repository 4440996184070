import { useEffect, useRef, useState } from 'react';
import User from '../../models/User';
import { bulkActivateUsers, bulkDeactivateUsers } from '../apis/usersApi';
import {
  Table,
  Tag,
  message,
  Input,
  Dropdown,
  Space,
  Button,
  Menu,
  Popconfirm,
} from 'antd';
import { FilterDropDown } from '../../listings/cars/used_cars/components/used_car_form/FilterDropDown';
import {
  useActivateUser,
  useDeactivateUser,
  useDeleteUser,
  useGetAllUsers,
} from '../hooks/useGetAllUsers';
import { DeleteTwoTone, DownOutlined, EditTwoTone } from '@ant-design/icons';
import { Layout } from '../../components/Layout';
import { UsersModal } from './UsersModal';
import { useQueryClient } from '@tanstack/react-query';
import { filterRoleOptions } from '../../listings/cars/used_cars/carsApi';
import { ICountry } from '../../models/countries/Country';
import dayjs from 'dayjs';
import ActiveIcon from '../../components/icons/ActiveIcon';
import InactiveIcon from '../../components/icons/InactiveIcon';

const { Search: AntdSearch } = Input;

const UsersLayout = () => {
  const queryClient = useQueryClient();
  const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
  const [searchQueryParams, setSearchQueryParams] = useState<
    Record<string, string>
  >({});
  const [availableBulkActions, setAvailableBulkActions] = useState<Array<any>>(
    [],
  );
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [filterRole, setFilterRole] = useState('');
  const [usersPerPage, setUsersPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<User | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<User | null>(null);

  const SearchParam = (inputValue: any) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)
      ? 'email'
      : 'mobile_number';
  };
  const searchValue = useRef<string>('');

  const {
    data: usersPaginatedData,
    isLoading: isFetchingUsers,
    isError,
  } = useGetAllUsers({
    group: filterRole,
    searchQueryParams,
    perPage: usersPerPage,
    page: currentPage,
  });

  const { mutateAsync: deleteUser, isLoading: isDeleting } = useDeleteUser();
  const { mutateAsync: activateUser, isLoading: isActivating } =
    useActivateUser();
  const { mutateAsync: deactivateUser, isLoading: isDeactivating } =
    useDeactivateUser();
  const [addUserDisabled, setAddUserDisabled] = useState(false);

  const bulkActionDeactivate = {
    label: 'Deactivate',
    icon: 'pi pi-times',
    command: async () => {
      try {
        await bulkDeactivateUsers(selectedUsers.map(user => user.id));
        queryClient.invalidateQueries(['users']);
        message.success('Users deactivated successfully');
        clearSelection();
      } catch (error) {
        console.error(error);
        message.error('Error deactivating users');
      }
    },
  };

  const bulkActionActivate = {
    label: 'Activate',
    icon: 'pi pi-check',
    command: async () => {
      try {
        await bulkActivateUsers(selectedUsers.map(user => user.id));
        queryClient.invalidateQueries(['users']);
        message.success('Users activated successfully');
        clearSelection();
      } catch (error) {
        console.error(error);
        message.error('Error activating users');
      }
    },
  };

  const canBulkActivate = (): boolean => {
    return selectedUsers.some(user => !user.is_active);
  };

  const canBulkDeactivate = () => {
    return selectedUsers.some(user => user.is_active);
  };

  const onEditClick = (user: User) => {
    setCurrentUser(user);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const handleDelete = (id: number) => {
    deleteUser(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['users'],
        });
        setOpenDeleteDialog(null);
        message.success('User deleted successfully');
      },
      onError: () => {
        message.error('Error deleting user');
      },
    });
  };

  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setUsersPerPage(pageSize);
  };

  const clearSelection = () => {
    setSelectedUsers([]);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setCurrentUser(null);
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateUser(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['users'],
            });
            setOpenToggleActivateDialog(null);
            message.success('User deactivated successfully');
          },
          onError: () => {
            message.error('Error deactivating user');
          },
        })
      : activateUser(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['users'],
            });
            setOpenToggleActivateDialog(null);
            message.success('User activated successfully');
          },
          onError: () => {
            message.error('Error activating user');
          },
        });
  };

  useEffect(() => {
    const temp = [];
    if (canBulkActivate()) temp.push(bulkActionActivate);
    if (canBulkDeactivate()) temp.push(bulkActionDeactivate);

    setAvailableBulkActions(temp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, addUserDisabled]);

  useEffect(() => {
    setAddUserDisabled(selectedUsers.length > 0);
  }, [selectedUsers]);

  const groupColors = {
    'ONE AUTOCAR': 'cyan',
    Users: 'lime',
    Automotive: 'blue',
    'Rental Dealer': 'magenta',
    Insurance: 'volcano',
    Banking: 'gold',
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => <a href={`mailto:${email}`}>{email}</a>,
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      render: (mobile: number | null) =>
        mobile ? (
          <a href={`tel:${mobile}`}>
            {mobile !== null && mobile?.toString().startsWith('9')
              ? `+${mobile}`
              : mobile || ''}
          </a>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      render: (lastLogin: Date) => (lastLogin ? lastLogin : 'N/A'),
    },
    {
      title: 'Role',
      dataIndex: 'group',
      key: 'group',
      align: 'center',
      width: '50',
      fixed: 'right',
      render: (group: string): JSX.Element => {
        return (
          <Tag
            color={
              group
                ? groupColors[group as keyof typeof groupColors] || 'red'
                : 'red'
            }
          >
            {group ? group : 'Not Found'}
          </Tag>
        );
      },
      filters: filterRoleOptions,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={filterRole}
            setFilter={setFilterRole}
            options={filterRoleOptions}
            isLoading={isFetchingUsers}
          />
        );
      },
    },
    {
      title: 'Receives All Emails',
      dataIndex: 'receive_email_notifications',
      key: 'receive_email_notifications',
      render: (receiveEmailNotifications: boolean) =>
        receiveEmailNotifications ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag color="red">No</Tag>
        ),
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (isActive: boolean) =>
        isActive ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      render: (_: any, rowData: User) => {
        return (
          <div className="flex justify-content-around">
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this user.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>

            <EditTwoTone
              style={{ fontSize: '1rem', color: 'var(--primary)' }}
              onClick={() => {
                onEditClick(rowData);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Deleting this user will delete all their related data."
              open={openDeleteDialog === rowData}
              onConfirm={() => handleDelete(rowData.id)}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                style={{ fontSize: '1rem', color: 'var(--error)' }}
                onClick={() => {
                  setOpenDeleteDialog(rowData);
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const expandedRowRender = (record: User) => {
    const columns = [
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: (country: ICountry) => country.name,
      },
      {
        title: 'Last Login',
        dataIndex: 'last_login',
        key: 'last_login',
        render: (lastLogin: Date) =>
          lastLogin ? dayjs(lastLogin).format('DD/MM/YY HH:mm A') : 'N/A',
      },
      {
        title: 'Joined',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date: Date): string => {
          return dayjs(date).format('DD/MM/YY HH:mm A');
        },
      },
      { title: 'Phone', dataIndex: 'mobile_number', key: 'mobile_number' },
    ];
    return <Table columns={columns} dataSource={[record]} pagination={false} />;
  };

  return (
    <>
      <Layout>
        <div className="flex justify-content-between w-full mb-5">
          <div
            style={{
              width: '20%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <AntdSearch
              loading={isFetchingUsers}
              onChange={e => {
                searchValue.current = e.target.value;
                if (searchValue.current === '') {
                  setSearchQueryParams({});
                }
              }}
              placeholder="Search by email or phone number"
              allowClear
              enterButton
              onSearch={() => {
                setSearchQueryParams({
                  [SearchParam(searchValue.current)]: searchValue.current,
                });
              }}
            />
          </div>
          <Space>
            <Button
              className={`custom-button ${
                isFetchingUsers && 'disabled-button'
              }`}
              type="primary"
              loading={isFetchingUsers}
              onClick={
                addUserDisabled
                  ? () => {}
                  : () => {
                      setIsFormOpen(true);
                      setIsEditing(false);
                      setCurrentUser(null);
                    }
              }
            >
              Add
            </Button>
            <Dropdown
              className={`custom-button ${
                isFetchingUsers && 'disabled-button'
              }`}
              overlay={
                <Menu>
                  {availableBulkActions.map((action, index) => (
                    <Menu.Item key={index} onClick={action.command}>
                      {action.label}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              disabled={selectedUsers.length === 0}
            >
              <Button icon={<DownOutlined style={{ color: 'white' }} />} />
            </Dropdown>
          </Space>
        </div>
        <Table
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedUsers(selectedRows as User[]);
            },
            selectedRowKeys: selectedUsers.map(user => user.id),
          }}
          columns={columns as any}
          pagination={{
            position: ['bottomCenter'],
            total: usersPaginatedData?.meta?.total,
            pageSize: usersPerPage,
            onChange: onPageChange,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }}
          expandable={{
            expandedRowRender: record => expandedRowRender(record as User),
          }}
          dataSource={usersPaginatedData?.data}
          rowKey={record => record.id}
          loading={isFetchingUsers}
        />
      </Layout>
      <UsersModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isEditing={isEditing}
        closeForm={closeForm}
        isErrors={isError}
      />
    </>
  );
};

export default UsersLayout;
