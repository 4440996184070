import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICarExteriorColorPayload,
  activateCarExteriorColor,
  createCarExteriorColor,
  deactivateCarExteriorColor,
  deleteCarExteriorColor,
  getCarExteriorColors,
  updateCarExteriorColor,
} from '../carExteriorColorApi';
import { queryClient } from '../../../../App';

export const CAR_EXTERIOR_COLOR_QUERY_KEY = ['carExteriorColors'];

interface IUseCarExteriorColors {
  enabled: boolean;
  active?: boolean;
}

interface ICarExteriorColorFormPayload {
  name: string;
  arabicName: string;
  status: number;
}

export const useCarExteriorColor = ({
  enabled,
  active,
}: IUseCarExteriorColors) => {
  return useQuery({
    queryKey: CAR_EXTERIOR_COLOR_QUERY_KEY,
    queryFn: () => getCarExteriorColors({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCarExteriorColor = () => {
  return useMutation({
    mutationFn: (data: ICarExteriorColorPayload) =>
      createCarExteriorColor(data),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_EXTERIOR_COLOR_QUERY_KEY);
    },
  });
};

export const useUpdateCarExteriorColor = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: ICarExteriorColorPayload;
    }) => updateCarExteriorColor(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_EXTERIOR_COLOR_QUERY_KEY);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });
};

export const useDeleteCarExteriorColor = () => {
  return useMutation({
    mutationFn: (id: number) => deleteCarExteriorColor(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_EXTERIOR_COLOR_QUERY_KEY);
    },
  });
};

export const useActivateCarExteriorColor = () => {
  return useMutation({
    mutationFn: (id: number) => activateCarExteriorColor(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_EXTERIOR_COLOR_QUERY_KEY);
    },
  });
};

export const useDeactivateCarExteriorColor = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateCarExteriorColor(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_EXTERIOR_COLOR_QUERY_KEY);
    },
  });
};
