import { Layout } from '../components/Layout';
import { LeadEmailsLayout } from './lead_emails/components/LeadEmailsLayout';

export const SettingsLayout = () => {
  return (
    <Layout>
      <LeadEmailsLayout />
    </Layout>
  );
};
