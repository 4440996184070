import { Modal } from 'antd';
import { CarStatus } from '../../carsApi';
import { RejectUsedCarsForm } from './RejectUsedCarsForm';

type RejectUsedCarsModalProps = {
  updateStatusFunction: (status: CarStatus, reason: string) => Promise<void>;
  onClose: () => void;
  isVisible: boolean;
  initialReason?: string;
};

export const RejectUsedCarsModal = ({
  updateStatusFunction,
  onClose,
  isVisible,
  initialReason,
}: RejectUsedCarsModalProps) => {
  const _rejectUsedCars = async (data: any) => {
    await updateStatusFunction(CarStatus.rejected, data.reason);
    onClose();
  };

  const initialValues = {
    reason: initialReason,
  };

  return (
    <Modal
      title="Reject Used Cars"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={1200}
    >
      <RejectUsedCarsForm
        onSubmit={_rejectUsedCars}
        onClose={onClose}
        initialValues={initialValues}
      />
    </Modal>
  );
};
