import api from '../../api/apiIndex';

const banksEndpoint = 'v2/admin/banks';

export interface ICreateBank {
  name: string;
  arabic_name: string;
  details: string;
  arabic_details: string;
  bank_benefits: string[];
  arabic_bank_benefits: string[];
  city_id: number;
  add_logo: { id: number };
}

export interface IEditBank {
  name?: string;
  arabic_name?: string;
  details?: string;
  arabic_details?: string;
  bank_benefits?: string[];
  arabic_bank_benefits?: string[];
  city_id?: number;
  add_logo?: { id: number };
  remove_logo?: { url: string };
}

export const getAllBanks = async (isActive?: boolean) => {
  const response = await api.get(banksEndpoint);
  return response.data;
};

export const createBank = async (bank: ICreateBank) => {
  try {
    const response = await api.post(banksEndpoint, bank);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const editBank = async ({
  id,
  data: bank,
}: {
  id: number;
  data: IEditBank;
}) => {
  try {
    const response = await api.put(`${banksEndpoint}/${id}`, bank);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteBank = async (id: number) => {
  const response = await api.delete(`${banksEndpoint}/${id}`);
  return response.data;
};

export const activateBank = async (id: number) => {
  const response = await api.put(`${banksEndpoint}/${id}/activate`);
  return response.data;
};

export const deactivateBank = async (id: number) => {
  const response = await api.put(`${banksEndpoint}/${id}/deactivate`);
  return response.data;
};
