import { useEffect, useState } from 'react';
import {
  useActivateBikeColor,
  useDeactivateBikeColor,
  useDeleteBikeColor,
  useGetBikeColors,
} from './hooks';
import { useSearch } from '../../../hooks/useSearch';
import { IBikeColors } from '../../../models/resources/bikes/BikeColors';
import { Button, Input, Popconfirm, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import { Layout } from '../../../components/Layout';
import { Table } from 'antd/lib';
import { BikeColorsModal } from './components';

const { Search } = Input;

export const BikeColorsLayout = () => {
  const { data: bikeColors, isLoading: isLoadingBikeColors } = useGetBikeColors(
    {
      enabled: true,
    },
  );

  const { mutateAsync: deleteBikeColor, isLoading: isDeleting } =
    useDeleteBikeColor();
  const { mutateAsync: activateBikeColor, isLoading: isActivating } =
    useActivateBikeColor();
  const { mutateAsync: deactivateBikeColor, isLoading: isDeactivating } =
    useDeactivateBikeColor();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBikeColor, setCurrentBikeColor] = useState<IBikeColors | null>(
    null,
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchBikeColor } = useSearch();
  const [filteredData, setFilteredData] = useState(bikeColors);

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IBikeColors | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IBikeColors | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateBikeColor(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Color deactivated successfully');
          },
        })
      : activateBikeColor(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Color activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteBikeColor(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Color deleted successfully');
      },
    });
  };

  const onEditButtonPress = (engineType: IBikeColors) => {
    setCurrentBikeColor(engineType);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentBikeColor(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchBikeColor(bikeColors, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchBikeColor(bikeColors, searchValue);
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(bikeColors);
  }, [bikeColors]);

  const tableColumns: ColumnsType<IBikeColors> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${
        bikeColors && bikeColors[0]?.t && bikeColors[0]?.t[0]?.name
      }}`,
      key: `${bikeColors && bikeColors[0]?.t && bikeColors[0]?.t[0]?.name}}`,
      render: (text: any, record: IBikeColors) => record.t[0].name,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IBikeColors) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: IBikeColors) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this color will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this color.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Colors"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Color
        </Button>
      </div>
      <Table
        loading={isLoadingBikeColors}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={false}
        rowKey={record => record.id.toString()}
        scroll={{ x: 1300, y: 720 }}
      />
      <BikeColorsModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        currentBikeColor={currentBikeColor}
        setCurrentBikeColor={setCurrentBikeColor}
      />
    </Layout>
  );
};
