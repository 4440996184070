import { UserGroups } from '../../../../../common/constants';
import { NewBikeDealLayout } from '../../../../../listings/bikes/new_bikes';
import { RouteType } from '../../../../routes/routesConfig';

const NewBikesDeals = () => {
  return <NewBikeDealLayout />;
};

const newBikesDealRoute: RouteType = {
  element: <NewBikesDeals />,
  state: 'string',
  index: false,
  path: '/listings/bikes/new-bikes/:brandId/:modelSlug',
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { NewBikesDeals, newBikesDealRoute };
