import { Button, Col, Form, Input, Row, Space } from 'antd';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { IBikeEngineTypePayload } from '../apis';

const { Item } = Form;

type IBikeEngineTypeFormProps = {
  bikeEngineType: IBikeEngineType | null;
  onSubmit: (values: IBikeEngineTypePayload) => Promise<void>;
  onClose: () => void;
  isEditing: boolean;
  isLoading: boolean;
};

export const AddEditBikeEngineTypeForm = ({
  bikeEngineType,
  onSubmit,
  onClose,
  isEditing,
  isLoading,
}: IBikeEngineTypeFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: bikeEngineType?.name,
    arabic_name: bikeEngineType?.t?.[0]?.name,
    label: bikeEngineType?.label,
  };

  return (
    <Form
      form={form}
      onFinish={async values => {
        try {
          await onSubmit(values);
        } catch (error) {
          console.error(error);
        }
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24} wrap>
        <Col span={12}>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter a name',
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_name"
            label="Arabic Name"
            rules={[
              {
                required: true,
                message: 'Please enter an arabic name',
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Item
            name="label"
            label="Label"
            rules={[
              {
                required: true,
                message: 'Please enter a label',
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
