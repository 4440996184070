import { Form, Image, Modal, Upload, message } from 'antd';
import { useCallback, useState } from 'react';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile, UploadFile } from 'antd/es/upload';
import { useImageUtils } from '../../listings/cars/rentals/utils/imageUtils';

const { Item } = Form;

export interface CustomUploadFile extends UploadFile {
  imgId?: number;
}

type FileUploadProps = {
  label: string;
  requiredMessage?: string;
  maxNbFiles: number;
  name: string;
  fileList: CustomUploadFile[];
  setFileList: (newFileList: CustomUploadFile[]) => void;
  onFileChange?: () => void;
  customRequest?: (options: UploadRequestOption) => void;
  onRemove?: (file: CustomUploadFile) => void;
};

const maxImageSize = 1000 * 1024 * 1024;

const VideoUpload = ({
  label,
  maxNbFiles,
  name,
  fileList,
  setFileList,
  customRequest,
  onRemove,
  requiredMessage,
}: FileUploadProps) => {
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState<any>(null);

  const { normFile, handlePreview } = useImageUtils({
    setOpenImage,
    setImage,
  });

  return (
    <>
      <Modal
        open={openImage}
        title={'Preview Image'}
        onCancel={() => {
          setOpenImage(false);
        }}
        footer={null}
      >
        <video style={{ width: '100%', height: '400px' }} src={image} />
      </Modal>
      <Item
        name={name}
        label={label}
        valuePropName={`${name}-fileList`}
        getValueFromEvent={normFile}
        rules={[
          {
            required: true,
            message: requiredMessage,
          },
        ]}
      >
        <Upload
          listType="picture-card"
          accept=".mp4, .avi, .wmv, .flv, .mov, .mpeg, .mpg, .3gp, .webm, .ogv, .ts"
          customRequest={customRequest}
          fileList={fileList}
          beforeUpload={(file: RcFile) => {
            //check the size of the file if it is bigger than 5MB
            if (file.size > maxImageSize) {
              message.error(
                `The file ${file.name} is too big, please select a file smaller than 5MB`,
              );
              return Upload.LIST_IGNORE;
            }
            return true;
          }}
          onChange={({ fileList }) => {
            setFileList([...fileList]);
          }}
          onPreview={handlePreview}
          onRemove={onRemove}
          multiple
        >
          {fileList?.length >= maxNbFiles ? null : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Item>
    </>
  );
};

export default VideoUpload;
