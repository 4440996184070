import { Flex } from '../../components/Flex';
import { Chart } from './Chart';
import { Empty } from 'antd';
import { ChartLoading } from './ChartLoading';

interface IChartBoxProps {
  data: any[] | undefined;
  isFetching: boolean;
}

export const ChartBox = ({ data, isFetching }: IChartBoxProps) => {
  return (
    <>
      {data === undefined || data.length === 0 ? (
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          {isFetching ? (
            <ChartLoading />
          ) : (
            <Empty description="No data available" />
          )}
        </Flex>
      ) : (
        <>
          <Chart data={data} />
        </>
      )}
    </>
  );
};
