import { Modal, message } from 'antd';
import { IBikeCondition } from '../../../../models/resources/bikes/BikeCondition';
import { IBikeConditionPayload } from '../apis';
import { useCreateBikeCondition, useUpdateBikeCondition } from '../hooks';
import { AddEditBikeConditionForm } from './AddEditBikeConditionForm';

interface IBikeConditionModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentBikeCondition: IBikeCondition | null;
  setCurrentBikeCondition: (value: IBikeCondition | null) => void;
}

export const BikeConditionModal = ({
  currentBikeCondition,
  isEditing,
  isFormOpen,
  setCurrentBikeCondition,
  setIsFormOpen,
}: IBikeConditionModalProps) => {
  const { mutateAsync: createBikeCondition, isLoading: isCreating } =
    useCreateBikeCondition();
  const { mutateAsync: updateBikeCondition, isLoading: isUpdating } =
    useUpdateBikeCondition();

  const _create = async (data: IBikeConditionPayload) => {
    if (data == null) return;
    const response = await createBikeCondition(data);
    message.success('Condition Created Successfully');
    setIsFormOpen(false);
    setCurrentBikeCondition(null);
    return response;
  };

  const _edit = async (data: IBikeConditionPayload) => {
    const response = await updateBikeCondition({
      id: currentBikeCondition!.id,
      data,
    });
    setIsFormOpen(false);
    setCurrentBikeCondition(null);
    message.success('Condition Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Condition' : 'Add Condition'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBikeCondition?.id}
        destroyOnClose
      >
        <AddEditBikeConditionForm
          bikeCondition={currentBikeCondition}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
          onSubmit={isEditing ? _edit : _create}
          key={currentBikeCondition?.id}
        />
      </Modal>
    </>
  );
};
