import { IBikeCondition } from '../../../../models/resources/bikes/BikeCondition';
import { IBikeConditionPayload } from '../apis';
import { Button, Col, Form, Input, Row, Space } from 'antd';

interface IBikeConditionAddEditFormProps {
  bikeCondition: IBikeCondition | null;
  isEditing: boolean;
  onClose: () => void;
  onSubmit: (values: IBikeConditionPayload) => Promise<void>;
  isLoading: boolean;
}

const { Item } = Form;

export const AddEditBikeConditionForm = ({
  bikeCondition,
  isEditing,
  isLoading,
  onClose,
  onSubmit,
}: IBikeConditionAddEditFormProps) => {
  const [form] = Form.useForm();
  const initialValues = bikeCondition
    ? {
        name: bikeCondition.name,
        arabic_name: bikeCondition.t?.[0]?.name,
      }
    : undefined;
  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      onFinish={async (values: IBikeConditionPayload) => {
        try {
          await onSubmit(values);
        } catch (error) {
          console.log('error');
        }
      }}
      initialValues={initialValues}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name of the bike condition',
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic name of the bike condition',
              },
            ]}
          >
            <Input placeholder="Arabic Name" size="large" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
