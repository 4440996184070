import { UserGroups } from '../../../../common/constants';
import { BikeCylindersLayout } from '../../../../resources/bikes/cylinders';
import { RouteType } from '../../../routes/routesConfig';

const BikeCylinders = () => {
  return <BikeCylindersLayout />;
};

const bikeCylindersRoute: RouteType = {
  element: <BikeCylinders />,
  state: 'cylinders',
  index: true,
  path: '/resources/bikes/cylinders',
  sideBarProps: {
    displayText: 'Cylinders',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeCylinders, bikeCylindersRoute };
