import { useEffect, useState } from 'react';
import {
  Table,
  Tag,
  message,
  Space,
  Button,
  Popconfirm,
  Image,
  Input,
} from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Layout } from '../../components/Layout';
import { useQueryClient } from '@tanstack/react-query';
import ActiveIcon from '../../components/icons/ActiveIcon';
import InactiveIcon from '../../components/icons/InactiveIcon';
import { IReviewer } from '../../models/reviewers/Reviewer';
import {
  REVIEWERS_KEY,
  useActivateReviewer,
  useDeactivateReviewer,
  useDeleteReviewer,
  useGetReviewers,
} from '../hooks';
import { useSearch } from '../../hooks/useSearch';
import { ColumnsType } from 'antd/es/table';
import fallbackLogo from '../../assets/fallback.webp';
import { ReviewerModal } from './ReviewerModal';
import { REVIEWS_KEY } from '../../reviews/hooks';

const { Search } = Input;

const ReviewersLayout = () => {
  const queryClient = useQueryClient();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentReviewer, setCurrentReviewer] = useState<IReviewer | null>(
    null,
  );
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IReviewer | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IReviewer | null>(
    null,
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data: reviewers, isLoading: isFetchingReviewers } = useGetReviewers();

  const { handleSearchReviewers } = useSearch();

  const [filteredData, setFilteredData] = useState(
    reviewers ? (Array.isArray(reviewers) ? reviewers : [reviewers]) : [],
  );

  const { mutateAsync: deleteReviewer, isLoading: isDeleting } =
    useDeleteReviewer();
  const { mutateAsync: activateReviewer, isLoading: isActivating } =
    useActivateReviewer();
  const { mutateAsync: deactivateReviewer, isLoading: isDeactivating } =
    useDeactivateReviewer();

  const onEditClick = (reviewer: IReviewer) => {
    setCurrentReviewer(reviewer);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const handleDelete = (id: number) => {
    deleteReviewer(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: REVIEWERS_KEY,
        });
        queryClient.invalidateQueries({
          queryKey: REVIEWS_KEY,
        });
        setOpenDeleteDialog(null);
        message.success('Reviewer deleted successfully');
      },
      onError: () => {
        message.error('Error deleting reviewer');
      },
    });
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setCurrentReviewer(null);
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateReviewer(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: REVIEWERS_KEY,
            });
            setOpenToggleActivateDialog(null);
            message.success('Reviewer deactivated successfully');
          },
          onError: () => {
            message.error('Error deactivating reviewer');
          },
        })
      : activateReviewer(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: REVIEWERS_KEY,
            });
            setOpenToggleActivateDialog(null);
            message.success('Reviewer activated successfully');
          },
          onError: () => {
            message.error('Error activating reviewer');
          },
        });
  };

  const columns: ColumnsType<IReviewer> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Logo',
      dataIndex: 'reviewer_images',
      key: 'reviewer_images',
      width: 300,
      align: 'center',
      render: (rowData: string) => {
        return (
          <Image
            src={rowData}
            height={50}
            fallback={fallbackLogo}
            alt="car image"
            style={{
              objectFit: 'cover',
            }}
          />
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (isActive: boolean) =>
        isActive ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      render: (_: any, rowData: IReviewer) => {
        return (
          <div className="flex justify-content-around">
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this reviewer.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>

            <EditTwoTone
              style={{ fontSize: '1rem', color: 'var(--primary)' }}
              onClick={() => {
                onEditClick(rowData);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Deleting this reviewer will delete all their related data."
              open={openDeleteDialog === rowData}
              onConfirm={() => handleDelete(rowData.id)}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                style={{ fontSize: '1rem', color: 'var(--error)' }}
                onClick={() => {
                  setOpenDeleteDialog(rowData);
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const newData = handleSearchReviewers(
      reviewers ? (Array.isArray(reviewers) ? reviewers : [reviewers]) : [],
      searchValue,
    );
    setFilteredData(newData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(
      reviewers ? (Array.isArray(reviewers) ? reviewers : [reviewers]) : [],
    );
  }, [reviewers]);

  return (
    <>
      <Layout>
        <div className="flex justify-content-between w-full mb-5">
          <div
            style={{
              width: '20%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Search
              placeholder="Search Reviewers"
              onSearch={value => setSearchValue(value)}
              style={{ width: 230 }}
              onChange={e => setSearchValue(e.target.value)}
            />
          </div>
          <Space>
            <Button
              className={`custom-button ${
                isFetchingReviewers && 'disabled-button'
              }`}
              type="primary"
              loading={isFetchingReviewers}
              onClick={() => {
                setIsFormOpen(true);
                setIsEditing(false);
                setCurrentReviewer(null);
              }}
            >
              Add
            </Button>
          </Space>
        </div>
        <Table
          columns={columns as any}
          pagination={{
            position: ['bottomCenter'],
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }}
          dataSource={filteredData}
          rowKey={record => record.id}
          loading={isFetchingReviewers}
        />
      </Layout>
      <ReviewerModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        currentReviewer={currentReviewer}
        setCurrentReviewer={setCurrentReviewer}
        isEditing={isEditing}
        closeForm={closeForm}
      />
    </>
  );
};

export default ReviewersLayout;
