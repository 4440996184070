import { useState } from 'react';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Card, Tag, Popconfirm, message } from 'antd';
import UploadedImagePreview from '../../components/misc/UploadedImagePreview';
import { IArticle } from '../../models/articles/Article';
import InactiveIcon from '../../components/icons/InactiveIcon';
import ActiveIcon from '../../components/icons/ActiveIcon';
import {
  useActivateArticle,
  useDeactivateArticle,
  useDeleteArticle,
} from '../hooks';
import useAuth from '../../hooks/useAuth';
import { UserGroups } from '../../common/constants';

const { Meta } = Card;

type ArticleCardProps = {
  article: IArticle;
  onEdit: (id: number) => void;
};

export const ArticleCard = ({ article, onEdit }: ArticleCardProps) => {
  const createdAt = new Date(article.created_at).toDateString();

  const { mutateAsync: activeArticle, isLoading: isActive } =
    useActivateArticle();
  const { mutateAsync: deactiveArticle, isLoading: isDeactive } =
    useDeactivateArticle();

  const { mutateAsync: deleteArticle, isLoading: isDeletingArticle } =
    useDeleteArticle();

  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isSuperAdmin = userGroup === UserGroups.OAC;

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IArticle | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IArticle | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactiveArticle(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Article Deactivated Successfully');
          },
        })
      : activeArticle(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Article Activated Successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteArticle(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Article Deleted Successfully');
      },
    });
  };

  return (
    <Card
      hoverable
      style={{
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: '30px',
      }}
      cover={
        <div
          style={{
            height: '150px',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <UploadedImagePreview url={article.image} />
          {article.is_featured && (
            <div style={{ position: 'absolute', top: '10px', left: '16px' }}>
              <Tag style={{ background: '#00CFE8' }} color="#00CFE8">
                Featured
              </Tag>
            </div>
          )}
        </div>
      }
      actions={[
        <>
          {isSuperAdmin && (
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                article.is_active ? 'deactivate' : 'activate'
              } this article.`}
              open={openToggleActivateDialog === article}
              onConfirm={() =>
                handleToggleActivate(article.id, article.is_active)
              }
              okButtonProps={{
                loading: isActive || isDeactive,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {article.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(article);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(article);
                  }}
                />
              )}
            </Popconfirm>
          )}
        </>,
        <EditTwoTone
          type="primary"
          key="edit"
          onClick={() => onEdit(article.id)}
        />,
        <>
          {isSuperAdmin && (
            <Popconfirm
              title="Are you sure?"
              description="Deleting this article will delete all its related data."
              open={openDeleteDialog === article}
              onConfirm={() => handleDelete(article.id)}
              okButtonProps={{ loading: isDeletingArticle }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                onClick={() => {
                  setOpenDeleteDialog(article);
                }}
              />
            </Popconfirm>
          )}
        </>,
      ]}
    >
      <Meta
        style={{ height: '200px' }}
        title={article.title}
        description={
          <div
            style={{ overflow: 'hidden' }}
            dangerouslySetInnerHTML={{ __html: article.description }}
          />
        }
      />
      <small style={{ color: '#929091' }}>
        <strong>{createdAt}</strong>
      </small>
    </Card>
  );
};
