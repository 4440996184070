import { useState } from 'react';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Card, Tag, Popconfirm, message } from 'antd';
import InactiveIcon from '../../components/icons/InactiveIcon';
import ActiveIcon from '../../components/icons/ActiveIcon';
import useAuth from '../../hooks/useAuth';
import { UserGroups } from '../../common/constants';
import { IReviews } from '../../models/reviews';
import {
  useActivateReview,
  useDeactivateReview,
  useDeleteReview,
} from '../hooks';
import UploadedImagePreview from '../../components/misc/UploadedImagePreview';

const { Meta } = Card;

type ReviewsCardProps = {
  review: IReviews;
  onEdit: (id: number) => void;
};

export const ReviewsCard = ({ review, onEdit }: ReviewsCardProps) => {
  const createdAt = new Date(review.created_at).toDateString();

  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isSuperAdmin = userGroup === UserGroups.OAC;

  const { mutateAsync: deleteReview, isLoading: isDeletingReview } =
    useDeleteReview();

  const { mutateAsync: activeReview, isLoading: isActive } =
    useActivateReview();

  const { mutateAsync: deactiveReview, isLoading: isDeactive } =
    useDeactivateReview();

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IReviews | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IReviews | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactiveReview(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Review Deactivated Successfully');
          },
        })
      : activeReview(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Review Activated Successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteReview(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Review Deleted Successfully');
      },
    });
  };

  return (
    <Card
      hoverable
      style={{
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: '30px',
      }}
      cover={
        <div
          style={{
            height: '150px',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <UploadedImagePreview url={review.thumbnail} />
          {review.is_featured && (
            <div style={{ position: 'absolute', top: '10px', left: '16px' }}>
              <Tag style={{ background: '#00CFE8' }} color="#00CFE8">
                Featured
              </Tag>
            </div>
          )}
        </div>
      }
      actions={[
        <>
          {isSuperAdmin && (
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                review.is_active ? 'deactivate' : 'activate'
              } this review.`}
              open={openToggleActivateDialog === review}
              onConfirm={() =>
                handleToggleActivate(review.id, review.is_active)
              }
              okButtonProps={{
                loading: isActive || isDeactive,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {review.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(review);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(review);
                  }}
                />
              )}
            </Popconfirm>
          )}
        </>,
        <EditTwoTone
          type="primary"
          key="edit"
          onClick={() => onEdit(review.id)}
        />,
        <>
          {isSuperAdmin && (
            <Popconfirm
              title="Are you sure?"
              description="Deleting this review will delete all its related data."
              open={openDeleteDialog === review}
              onConfirm={() => handleDelete(review.id)}
              okButtonProps={{ loading: isDeletingReview }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                onClick={() => {
                  setOpenDeleteDialog(review);
                }}
              />
            </Popconfirm>
          )}
        </>,
      ]}
    >
      <Meta
        style={{ height: '200px' }}
        title={review.title}
        description={
          <div
            style={{ overflow: 'hidden' }}
            dangerouslySetInnerHTML={{ __html: review.description }}
          />
        }
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <h3
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 0,
          }}
        >
          {review?.model?.brand?.name}, {review?.model?.name}
        </h3>
        {review.show_in_model && (
          <div>
            <Tag style={{ background: '#00CFE8' }} color="#00CFE8">
              Displayed
            </Tag>
          </div>
        )}
      </div>

      <small style={{ color: '#929091' }}>
        <strong>{createdAt}</strong>
      </small>
    </Card>
  );
};
