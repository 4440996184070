import api from '../../../../api/apiIndex';
import { BikeBrand } from '../../../../models/resources/bikes/BikeBrand';

const bikeBrandsEndpoint = '/v2/admin/resources/bikes/brands';

const newBikesEndPoint = 'v2/admin/bikes/new-bikes';

export type INewBikeDealPayload = {
  title?: string;
  arabic_title?: string;
  city_id?: number;
  price?: number | null;
  call_to_action?: string | null;
  arabic_call_to_action?: string | null;
  bike_model_id?: number;
  bike_dealer_id?: number;
  description?: string;
  arabic_description?: string;
  test_drive_url?: string;
  arabic_test_drive_url?: string;
};

export const getBikeDealByModelSlug = async (
  slug: string,
  country?: string,
) => {
  const response = await api.get(`${newBikesEndPoint}/model/${slug}`, {
    headers: { country },
  });
  return response.data;
};

export const getNewBikeBrandsWithModelsCount = async (country?: string) => {
  try {
    const headers: { country?: string } = {};
    if (country) {
      headers.country = country;
    }

    const response = await api.get(`${bikeBrandsEndpoint}/models/count`, {
      headers,
    });
    const data = response.data;
    const sort = data.sort((a: BikeBrand, b: BikeBrand) => {
      return a.name.localeCompare(b.name);
    });
    return sort;
  } catch (error) {
    console.log(error);
  }
};

export const activateNewBikeDeal = async (id: number) => {
  try {
    const response = await api.put(`${newBikesEndPoint}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deactivateNewBikeDeal = async (id: number) => {
  try {
    const response = await api.put(`${newBikesEndPoint}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteNewBikeDeal = async (id: number) => {
  try {
    const response = await api.delete(`${newBikesEndPoint}/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createNewBikeDeal = async (data: INewBikeDealPayload) => {
  const response = await api.post(newBikesEndPoint, data);
  return response.data;
};

export const updateNewBikeDeal = async (
  id: number,
  data: INewBikeDealPayload,
) => {
  const response = await api.put(`${newBikesEndPoint}/${id}`, data);
  return response.data;
};
