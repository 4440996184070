import { Button, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useLogout } from '../../hooks/useLogout';
import useAuth from '../../../hooks/useAuth';

const LogoutComponent = () => {
  const { userInfo } = useAuth();
  const { mutateAsync: logout, isLoading } = useLogout();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        margin: '10px 0px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography.Text>
          Welcome, {userInfo?.first_name !== null ? userInfo?.first_name : ''}{' '}
          {userInfo?.last_name !== null ? userInfo?.last_name : ''}
        </Typography.Text>
        <Typography.Text type="secondary">{userInfo?.email}</Typography.Text>
      </div>
      <Button
        type="primary"
        danger
        icon={<LogoutOutlined />}
        onClick={() => logout()}
        loading={isLoading}
      >
        Logout
      </Button>
    </div>
  );
};
export default LogoutComponent;
