import api from '../../../../api/apiIndex';

const bikeTypesEndpoint = 'v2/admin/resources/bikes/types';

export interface IBikeTypePayload {
  name: string;
  arabic_name: string;
}

export interface IGetTypes {
  active?: boolean;
}

export const getBikeTypes = async ({ active }: IGetTypes) => {
  try {
    const response = await api.get(bikeTypesEndpoint, {
      params: { active },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeType = async (data: IBikeTypePayload) => {
  try {
    const response = await api.post(bikeTypesEndpoint, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateBikeType = async (id: number, data: IBikeTypePayload) => {
  try {
    const response = await api.put(`${bikeTypesEndpoint}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteBikeType = async (id: number) => {
  try {
    const response = api.delete(`${bikeTypesEndpoint}/${id}`);

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeType = async (id: number) => {
  try {
    const response = api.put(`${bikeTypesEndpoint}/activate/${id}`);

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeType = async (id: number) => {
  try {
    const response = api.put(`${bikeTypesEndpoint}/deactivate/${id}`);

    return response;
  } catch (error) {
    console.error(error);
  }
};
