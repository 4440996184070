import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Space,
  message,
  Select,
  Checkbox,
} from 'antd';
import {
  ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY,
  useCreateCarOptionalFeature,
  useUpdateCarOptionalFeature,
} from '../hooks/useCarOptionalFeatures';
import { queryClient } from '../../../../App';
import { triStateOptions } from '../carOptionalFeaturesApi';
import { IEngineType } from '../../../../models/resources/CarEngineType';
import { IOptionalFeature } from '../utils/apiUtils';

const { Item } = Form;

interface IRentalFormProps {
  handleClose: () => void;
  selectedCarOptionalFeature?: IOptionalFeature;
  isEdit?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCarOptionalFeature: React.Dispatch<React.SetStateAction<any>>;
  carEngineTypes: IEngineType[] | null;
}

export const CarOptionalFeatureForm = ({
  handleClose,
  selectedCarOptionalFeature,
  isEdit,
  setIsOpen,
  setSelectedCarOptionalFeature,
  carEngineTypes,
}: IRentalFormProps) => {
  //Initial Values
  const initialValues = selectedCarOptionalFeature
    ? {
        name: selectedCarOptionalFeature.name,
        arabic_name: selectedCarOptionalFeature?.t?.[0]?.name,
        button_state: selectedCarOptionalFeature.button_state,
        website_button_state: selectedCarOptionalFeature.website_button_state,
        engine_types:
          selectedCarOptionalFeature.engine_type_optional_feature?.map(
            (engineType: any) => engineType?.engine_types?.id,
          ),
      }
    : undefined;

  //Form
  const [form] = Form.useForm();

  // API Hooks
  const { mutateAsync: createCarOptionalFeature, isLoading: isCreating } =
    useCreateCarOptionalFeature();

  const { mutateAsync: updateCarOptionalFeature, isLoading: isUpdating } =
    useUpdateCarOptionalFeature();

  const mappedCarEnginetypes = carEngineTypes?.map(
    (engineType: IEngineType) => ({
      label: engineType.name,
      value: engineType.id,
    }),
  );

  //Functions
  const handleFormSuccess = () => {
    form.resetFields();
    setIsOpen(false);
    setSelectedCarOptionalFeature(null);
    queryClient.invalidateQueries({
      queryKey: ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY,
    });
  };
  //Form Logic
  const handleSubmit = async (values: any) => {
    values = { ...values, status: true };

    try {
      createCarOptionalFeature(values, {
        onSuccess: () => {
          handleFormSuccess();
          message.success('Optional feature added successfully');
        },
      });
    } catch {
      console.log('error');
    }
  };

  const handleEdit = async (values: any) => {
    values = { ...values, status: true };
    try {
      if (selectedCarOptionalFeature)
        updateCarOptionalFeature(
          {
            id: selectedCarOptionalFeature.id,
            data: values,
          },
          {
            onSuccess: () => {
              handleFormSuccess();
              message.success('Optional feature edited successfully');
            },
            onError: () => {},
          },
        );
    } catch {
      console.log('error');
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      onFinish={isEdit ? handleEdit : handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name of the optional feature',
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic name of the optional feature',
              },
            ]}
          >
            <Input placeholder="Arabic Name" size="large" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Used Cars Button State"
            name="button_state"
            rules={[
              {
                required: true,
                message: 'Please select the state of the used cars button',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a state"
              optionFilterProp="children"
              size="large"
            >
              {Object.entries(triStateOptions).map(([label, value]) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Sell Your Car Button State"
            name="website_button_state"
            rules={[
              {
                required: true,
                message: 'Please select the state of the sell your car button',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a state"
              optionFilterProp="children"
              size="large"
            >
              {Object.entries(triStateOptions).map(([label, value]) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row>
        <Item
          label="Engine Types"
          name="engine_types"
          rules={[
            {
              required: true,
              message: 'Please select the engine types',
            },
          ]}
        >
          <Checkbox.Group options={mappedCarEnginetypes} />
        </Item>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button danger onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            {isEdit ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
