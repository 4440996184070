import api from '../../../api/apiIndex';
import { UserGroups } from '../../../common/constants';
import { IUsedCar } from '../../../models/listings/cars/used_cars/UsedCar';
import { Metadata } from '../../../models/metadata/Metadata';
import { IUseGetUsedCars } from './hooks/useUsedCars';

export interface IFilters {
  value: number | string;
  text: string;
  isDefault?: boolean;
  color?: string;
}

export enum CarCategory {
  Used = 'used',
  New = 'new',
}

export enum CarStatus {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
  draft = 'draft',
}

export const filterCountryOptions: IFilters[] = [
  { text: 'All', value: '' },
  { text: 'Qatar', value: 1 ? 'qa' : 'qa' },
  { text: 'KSA', value: 2 ? 'sa' : 'sa' },
];

export const filterFeaturedOptions: IFilters[] = [
  { text: 'All', value: '', isDefault: true },
  { text: 'Featured', value: 'true' },
  { text: 'Not Featured', value: 'false' },
];

export const filterRoleOptions: IFilters[] = [
  { text: 'All', value: '', isDefault: true },
  { text: 'One AutoCar', value: UserGroups.OAC },
  { text: 'Automotive', value: UserGroups.Automotive },
  { text: 'Insurance', value: UserGroups.Insurance },
  { text: 'Rental Dealer', value: UserGroups.Rental },
  { text: 'Banking', value: UserGroups.Banking },
  { text: 'Users', value: UserGroups.User },
];

export const filterStatusOptions: IFilters[] = [
  { text: 'All', value: '', isDefault: true },
  { text: 'Approved', value: CarStatus.approved, color: 'green' },
  { text: 'Pending', value: CarStatus.pending, color: 'blue' },
  { text: 'Rejected', value: CarStatus.rejected, color: 'red' },
  { text: 'Draft', value: CarStatus.draft, color: 'orange' },
];

type CreateCarRequest = {
  year: number;
  description?: string;
  whatsapp_number?: string;
  mobile_number?: string;
  call_to_action?: string;
  mileage?: number;
  price: number;
  doors?: number;
  model_id?: number;
  seller_id?: number;
  seller_type?: string;
  city_id?: number;
  type_id?: number;
  transmission_id?: number;
  engine_type_id?: number;
  engine_size_id?: number;
  battery_capacity_id?: number;
  cylinder_id?: number;
  battery_range_id?: number;
  exterior_color_id?: number;
  interior_color_id?: number;
  condition_id?: number;
  seat_material_id?: number;
  variant_id?: number;
  optional_features_ids?: number[];
  status: string;
  add_images: Array<{ id: number }>;
};

export type UpdateCarRequest = {
  year?: number;
  description?: string;
  whatsapp_number?: string;
  mobile_number?: string;
  call_to_action?: string;
  mileage?: number;
  price?: number;
  doors?: number;
  model_id?: number;
  trim_id?: number;
  seller_id?: number;
  seller_type?: string;
  city_id?: number;
  type_id?: number;
  transmission_id?: number;
  engine_type_id?: number;
  engine_size_id?: number;
  battery_capacity_id?: number;
  cylinder_id?: number;
  battery_range_id?: number;
  exterior_color_id?: number;
  interior_color_id?: number;
  condition_id?: number;
  seat_material_id?: number;
  variant_id?: number;
  optional_features_ids?: number[];
  status?: string;
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
};

const adminStatuses = [
  { label: 'All', value: '' },
  { label: 'Approved', value: CarStatus.approved },
  { label: 'Pending', value: CarStatus.pending },
  { label: 'Rejected', value: CarStatus.rejected },
  { label: 'Draft', value: CarStatus.draft },
];

const dealerStatuses = [
  { label: 'Draft', value: CarStatus.draft },
  { label: 'Pending', value: CarStatus.pending },
];

const carsEndpoint = 'v2/admin/cars/used-cars';

const getUsedCars = async ({
  searchQueryParams,
  page,
  perPage,
  country,
}: IUseGetUsedCars): Promise<{ data: IUsedCar[]; meta: Metadata }> => {
  searchQueryParams = {
    ...searchQueryParams,
    is_active: 'true',
  };

  const headers: { country?: string } = {};
  if (country) {
    headers.country = country;
  }

  const response = await api.get(carsEndpoint, {
    params: {
      ...searchQueryParams,
      page,
      perPage,
    },
    headers,
  });
  return response.data;
};

const getUsedCarsByDealer = async (dealer_id: number): Promise<IUsedCar[]> => {
  const response = await api.get(`${carsEndpoint}/dealer/${dealer_id}`);
  return response.data;
};

const createCar = async (request: CreateCarRequest) => {
  const response = await api.post(carsEndpoint, request);
  return response;
};

const updateCar = async (id: number, request: UpdateCarRequest) => {
  const response = await api.put(`${carsEndpoint}/${id}`, request);
  return response.data;
};

const deleteUsedCar = async (id: number) => {
  const response = await api.delete(`${carsEndpoint}/${id}`);
  return response.data;
};

const batchDeleteCars = async (carIds: Array<number>) => {
  const response = await api.patch(`${carsEndpoint}/delete`, {
    ids: carIds,
  });
  return response.data;
};

const batchUpdateStatuses = async (
  carIds: Array<number>,
  status: CarStatus,
  reason?: string,
) => {
  const response = await api.patch(`${carsEndpoint}/status`, {
    ids: carIds,
    status,
    reason,
  });
  return response.data;
};

export {
  getUsedCars,
  getUsedCarsByDealer,
  createCar,
  updateCar,
  deleteUsedCar,
  adminStatuses,
  dealerStatuses,
  batchDeleteCars,
  batchUpdateStatuses,
};
