import { CurrenciesLayout } from '../../../resources/currencies';
import { RouteType } from '../../routes/routesConfig';

export const Currency = () => {
  return <CurrenciesLayout />;
};

export const currencyRoute: RouteType = {
  element: <Currency />,
  state: 'currency',
  index: true,
  path: '/resources/currencies',
  sideBarProps: {
    displayText: 'Currencies',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};
