import { useEffect, useState } from 'react';
import { Button } from 'antd';
import Loader from '../../components/misc/Loader';
import { EmptyState } from '../../components/emptyState/EmptyState';
import { Input, Spin } from 'antd';
import { useSearch } from '../../hooks/useSearch';
import { Layout } from '../../components/Layout';
import { useGetReviews } from '../hooks';
import { IReviews } from '../../models/reviews';
import { ReviewsCard } from './ReviewsCard';
import { ReviewModal } from './ReviewModal';

const { Search } = Input;

export const ReviewsLayout = () => {
  const { data: reviews, isLoading: isLoadingReviews } = useGetReviews();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentReview, setCurrentReview] = useState<IReviews | null>(null);
  const [sortedReviews, setSortedReviews] = useState<Array<IReviews>>([]);

  // Search States
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchReviews } = useSearch();
  const [filteredData, setFilteredData] = useState(reviews);

  const sortByFeatured = (reviewsToSort: Array<IReviews>) => {
    const featured = reviewsToSort?.filter(review => review.is_featured);
    const nonFeatured = reviewsToSort?.filter(review => !review.is_featured);
    return [...featured, ...nonFeatured];
  };

  const onReviewEdit = (review: IReviews) => {
    setCurrentReview(review);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setIsEditing(false);
    setCurrentReview(null);
    setIsFormOpen(true);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchReviews(reviews, searchValue);
  };

  useEffect(() => {
    if (reviews) {
      const newSortedReviews = sortByFeatured(reviews);
      setSortedReviews(newSortedReviews);
    }
  }, [reviews]);

  useEffect(() => {
    if (sortedReviews) {
      const newData = handleSearchReviews(sortedReviews, searchValue);
      setFilteredData(newData);
    }
  }, [sortedReviews, searchValue]);
  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Reviews"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          New Review
        </Button>
      </div>
      {isLoadingReviews ? (
        <div
          className="w-full p-5 flex justify-content-center align-items-center"
          style={{ height: '400px' }}
        >
          <Spin />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          {filteredData?.length > 0 ? (
            filteredData.map((review: any) => (
              <div key={review.id}>
                <ReviewsCard
                  review={review}
                  onEdit={() => onReviewEdit(review)}
                />
              </div>
            ))
          ) : (
            <EmptyState type="reviews" />
          )}
        </div>
      )}
      <ReviewModal
        currentReview={currentReview}
        isEditing={isEditing}
        isFormOpen={isFormOpen}
        setCurrentReview={setCurrentReview}
        setIsFormOpen={setIsFormOpen}
      />
    </Layout>
  );
};
