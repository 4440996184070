import { Layout } from '../../../components/Layout';
import { Input, Select, Space, Table, message } from 'antd';
import { useParams } from 'react-router-dom';
import {
  RAMADAN_OFFER_LEADS_DETAILS_QUERY_KEY_ELEMENT,
  useEditRamadanOfferLead,
  useRamadanOfferLeadsDetail,
} from '../hooks/useRamadanOfferLeads';
import { NewCarLeadsDetail } from '../../../models/overview/NewCarLead';
import dayjs from 'dayjs';
import { LeadContacted, leadContactStatuses } from '../../constants';
import { useEffect, useRef, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { queryClient } from '../../../App';
import { useSearch } from '../../../hooks/useSearch';
import { FilterDropDown } from '../../../listings/cars/used_cars/components/used_car_form/FilterDropDown';
import { RamadanOfferLeadsDetail } from '../../../models/overview/RamadanOfferLead';

export const RamadanOfferLeadsDetailsLayout = () => {
  const params = useParams();
  const { dealerSlug, brandSlug, modelSlug } = params;

  const [editKey, setEditKey] = useState<string | null>(null);
  const inputRef = useRef<any>(null);

  // Search States and Refs
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSelectedOption, setSearchSelectedOptions] =
    useState<OptionKeys>('name');

  const [selectedStatus, setSelectedStatus] = useState<string>('');

  const { handelSearchLeads } = useSearch();

  const { data: leadsDetails, isFetching: isFetchingLeadsDetails } =
    useRamadanOfferLeadsDetail({
      dealerSlug,
      brandSlug,
      modelSlug,
    });

  const { mutateAsync: updateLead, isLoading: isUpdating } =
    useEditRamadanOfferLead();

  const [filteredData, setFilteredData] = useState(leadsDetails);

  const { Search } = Input;

  const options: { label: string; value: OptionKeys }[] = [
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
  ];

  const placeholderOptions: Record<OptionKeys, string> = {
    name: 'Search by Name',
    email: 'Search by Email',
  };
  type OptionKeys = 'name' | 'email';

  const tableColumns: ColumnsType<RamadanOfferLeadsDetail> = [
    {
      title: 'Dealer',
      dataIndex: 'ramadan_offers',
      key: 'ramadan_offers',
      render: (rowData: RamadanOfferLeadsDetail['ramadan_offers']): string => {
        return rowData ? rowData?.dealer?.name : 'N/A';
      },
      width: 200,
    },
    {
      title: 'Brand',
      dataIndex: 'models',
      width: 170,
      key: 'models',
      render: (rowData: RamadanOfferLeadsDetail['models']): string => {
        return rowData ? rowData?.brand?.name : 'N/A';
      },
      ellipsis: true,
    },
    {
      title: 'Model',
      dataIndex: 'models',
      key: 'models',
      width: 150,
      render: (rowData: RamadanOfferLeadsDetail['models']) => {
        return rowData ? (
          <span style={{ fontSize: 14 }}>{rowData?.name}</span>
        ) : (
          'N/A'
        );
      },
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      width: 150,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: (rowData: Date): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 200,
      render: (rowData: string, record: RamadanOfferLeadsDetail) =>
        `${record.id}-source` === editKey ? (
          <Input
            ref={inputRef}
            placeholder="Source"
            onPressEnter={e =>
              save(
                {
                  id: record.id,
                  source: (e.target as HTMLInputElement).value,
                },
                rowData,
                true,
              )
            }
            onBlur={e =>
              save(
                {
                  id: record.id,
                  source: (e.target as HTMLInputElement).value,
                },
                rowData,
                true,
              )
            }
            defaultValue={rowData}
          />
        ) : (
          <div
            className="editable-cell-value-wrap"
            onClick={() => handleEditCell(`${record.id}-source`)}
          >
            {rowData}
          </div>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'contacted',
      key: 'contacted',
      width: 200,
      render: (rowData: LeadContacted, record: RamadanOfferLeadsDetail) =>
        `${record.id}-contacted` === editKey ? (
          <Select
            ref={inputRef}
            style={{ width: '100%' }}
            showSearch
            placeholder="Select a contact status"
            optionFilterProp="children"
            defaultValue={rowData}
            onSelect={value =>
              save({ id: record.id, contacted: value }, rowData, true)
            }
          >
            {leadContactStatuses
              .filter(status => status.value !== '')
              .map((status: any) => (
                <Select.Option key={status.value} value={status.value}>
                  {status.text}
                </Select.Option>
              ))}
          </Select>
        ) : (
          <div
            className="editable-cell-value-wrap"
            onClick={() => handleEditCell(`${record.id}-contacted`)}
          >
            {leadContactStatuses.find(status => status.value === rowData)
              ?.text ?? 'N/A'}
          </div>
        ),
      filters: leadContactStatuses,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={selectedStatus}
            setFilter={handleSelectStatus}
            options={leadContactStatuses}
            isLoading={isFetchingLeadsDetails}
          />
        );
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: 200,
      render: (rowData: string, record: RamadanOfferLeadsDetail) =>
        `${record.id}-notes` === editKey ? (
          <Input.TextArea
            ref={inputRef}
            placeholder="Notes"
            maxLength={150}
            showCount
            autoSize={{ minRows: 3 }}
            onPressEnter={e =>
              save(
                {
                  id: record.id,
                  notes: (e.target as HTMLTextAreaElement).value,
                },
                rowData,
              )
            }
            onBlur={e =>
              save(
                {
                  id: record.id,
                  notes: (e.target as HTMLTextAreaElement).value,
                },
                rowData,
              )
            }
            defaultValue={rowData}
          />
        ) : (
          <div
            className="editable-cell-value-wrap"
            onClick={() => handleEditCell(`${record.id}-notes`)}
          >
            {rowData}
          </div>
        ),
    },
  ];

  const handleSelectStatus = (value: any) => {
    setSelectedStatus(value);
    if (value === '') {
      setFilteredData(leadsDetails);
    } else {
      const filteredLeads = leadsDetails.filter(
        (lead: NewCarLeadsDetail) => lead.contacted === value,
      );
      setFilteredData(filteredLeads);
    }
  };

  const handleSelectChange = (value: OptionKeys) => {
    setSearchSelectedOptions(value);
  };

  const handleEditCell = (key: string) => {
    setEditKey(key);
    inputRef.current = key;
  };

  const save = async (
    newValues: any,
    oldValue: any,
    required: boolean = false,
  ) => {
    const { id, ...rest } = newValues;
    const keys = Object.keys(rest);
    const firstKey = keys[0];
    if (rest[firstKey] !== oldValue) {
      if (rest[firstKey] === '') {
        if (required) {
          message.error(
            `${firstKey[0].toUpperCase() + firstKey.slice(1)} can not be empty`,
          );
          return;
        }
        if (oldValue === null) {
          setEditKey(null);
          inputRef.current = null;
          return;
        }
        rest[firstKey] = null;
      }
      await updateLead({
        id,
        data: rest,
      });
      queryClient.invalidateQueries({
        queryKey: [RAMADAN_OFFER_LEADS_DETAILS_QUERY_KEY_ELEMENT],
      });
      message.success('Ramadan offer lead updated successfully');
    }
    setEditKey(null);
    inputRef.current = null;
  };

  useEffect(() => {
    if (editKey) {
      inputRef.current!.focus();
    }
  }, [editKey]);

  useEffect(() => {
    if (leadsDetails) {
      const newData = handelSearchLeads(
        leadsDetails,
        searchSelectedOption,
        searchValue,
      );
      setFilteredData(newData || []);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(leadsDetails);
  }, [leadsDetails]);

  return (
    <>
      <Layout>
        <div className="flex justify-content-between w-full mb-5">
          <Space.Compact>
            <Search
              placeholder={placeholderOptions[searchSelectedOption]}
              loading={isFetchingLeadsDetails}
              onSearch={value => setSearchValue(value)}
              style={{ width: 250 }}
              onChange={e => {
                if (e.target.value === '') setSearchValue('');
              }}
            />
            <Select
              options={options}
              defaultValue={options[0].value}
              onChange={value => handleSelectChange(value)}
              style={{ width: 120 }}
            />
          </Space.Compact>
        </div>
        <Table
          dataSource={filteredData}
          rowKey="id"
          scroll={{ x: 1300, y: 650 }}
          columns={tableColumns}
          loading={isFetchingLeadsDetails || isUpdating}
          pagination={{
            position: ['bottomCenter'],
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            defaultPageSize: 30,
            showSizeChanger: true,
          }}
        />
      </Layout>
    </>
  );
};
