import { ProgressSpinner } from 'primereact/progressspinner';

type LoaderProps = {
  color?: string;
  height?: string;
  width?: string;
  className?: string;
};

const Loader = ({
  color = 'var(--primary-color) !important',
  height = '40px',
  width = '40px',
  className,
}: LoaderProps) => {
  return (
    <div
      className={`w-full h-full flex justify-content-center align-items-center ${className}`}
    >
      <ProgressSpinner
        style={{
          stroke: color,
          width: width,
          height: height,
        }}
      />
    </div>
  );
};

export default Loader;
