import { Modal, message } from 'antd';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { useCreateBikeEngineType, useUpdatedBikeEngineType } from '../hooks';
import { IBikeEngineTypePayload } from '../apis';
import { AddEditBikeEngineTypeForm } from './AddEditBikeEngineTypeForm';

type IBikeEngineTypeModalProps = {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentBikeEngineType: IBikeEngineType | null;
  isLoading: boolean;
  setCurrentBikeEngineType: (value: IBikeEngineType | null) => void;
};

export const BikeEngineTypeModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBikeEngineType,
  setCurrentBikeEngineType,
}: IBikeEngineTypeModalProps) => {
  const { mutateAsync: createBikeEngineType, isLoading: isCreating } =
    useCreateBikeEngineType();
  const { mutateAsync: updateBikeEngineType, isLoading: isUpdating } =
    useUpdatedBikeEngineType();

  const _create = async (data: IBikeEngineTypePayload) => {
    if (data == null) return;
    const response = await createBikeEngineType(data);
    setIsFormOpen(false);
    setCurrentBikeEngineType(null);
    message.success('Engine Type Created Successfully');
    return response;
  };

  const _edit = async (data: IBikeEngineTypePayload) => {
    const response = await updateBikeEngineType({
      id: currentBikeEngineType!.id,
      data,
    });
    setIsFormOpen(false);
    setCurrentBikeEngineType(null);
    message.success('Engine Type Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Engine Type' : 'Add Engine Type'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBikeEngineType?.id}
        destroyOnClose
      >
        <AddEditBikeEngineTypeForm
          bikeEngineType={currentBikeEngineType}
          onClose={() => setIsFormOpen(false)}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          key={currentBikeEngineType?.id}
        />
      </Modal>
    </>
  );
};
