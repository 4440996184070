import { Modal, message } from 'antd';
import { LocationsForm } from './LocationsForm';
import { ILocation } from '../../../../models/locations/Location';
import { useCreateLocation, useUpdateLocation } from '../useLocationsApi';

type ILocationsModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentLocation: ILocation | null;
  locationLoading: boolean;
  setCurrentLocation: (value: ILocation | null) => void;
};

export const LocationsModal = ({
  isOpen,
  setIsOpen,
  isEditing,
  currentLocation,
  locationLoading,
  setCurrentLocation,
}: ILocationsModalProps) => {
  const { mutateAsync: createLocation, isLoading: isCreating } =
    useCreateLocation();

  const { mutateAsync: updateLocation, isLoading: isUpdating } =
    useUpdateLocation();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createLocation(data);
      setIsOpen(false);
      message.success('Location Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    try {
      const response = await updateLocation({
        id: currentLocation!.id,
        data,
      });
      setIsOpen(false);
      setCurrentLocation(null);
      message.success('Location Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal
      title={isEditing ? 'Edit Location' : 'Add Location'}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      width={1200}
      footer={null}
      key={currentLocation?.id}
    >
      <LocationsForm
        isEditing={isEditing}
        isLoading={locationLoading}
        isSubmitting={isCreating || isUpdating}
        onSubmit={isEditing ? _edit : _create}
        location={currentLocation}
        onClose={() => setIsOpen(false)}
      />
    </Modal>
  );
};
