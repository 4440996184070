import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeEngineTypePayload,
  activateBikeEngineType,
  createBikeEngineType,
  deactivateBikeEngineType,
  deleteBikeEngineType,
  getBikeEngineTypes,
  updateBikeEngineType,
} from '../apis';
import { queryClient } from '../../../../App';

export const ALL_BIKE__ENGINE_TYPES_KEY = ['allBikeEngineTypes'];

interface IUseBikeEngineTypes {
  enabled: boolean;
  active?: boolean;
}
export const useGetBikeEngineType = ({
  enabled,
  active,
}: IUseBikeEngineTypes) => {
  return useQuery({
    queryKey: ALL_BIKE__ENGINE_TYPES_KEY,
    queryFn: () => getBikeEngineTypes({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeEngineType = () => {
  return useMutation({
    mutationFn: (data: IBikeEngineTypePayload) => createBikeEngineType(data),
    mutationKey: ['createBikeEngineType'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE__ENGINE_TYPES_KEY);
    },
  });
};

export const useUpdatedBikeEngineType = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeEngineTypePayload }) =>
      updateBikeEngineType(id, data),
    mutationKey: ['updateBikeEngineType'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE__ENGINE_TYPES_KEY);
    },
  });
};

export const useDeleteBikeEngineType = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeEngineType(id),
    mutationKey: ['deleteBikeEngineType'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE__ENGINE_TYPES_KEY);
    },
  });
};

export const useActivateBikeEngineType = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeEngineType(id),
    mutationKey: ['activateBikeEngineType'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE__ENGINE_TYPES_KEY);
    },
  });
};

export const useDeactivateBikeEngineType = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeEngineType(id),
    mutationKey: ['deactivateBikeEngineType'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE__ENGINE_TYPES_KEY);
    },
  });
};
