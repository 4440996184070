import { RouteType } from '../../routes/routesConfig';

const Notifications = () => {
  return (
    <div className="flex justify-content-center align-items-center h-full">
      <h1>Notifications</h1>
    </div>
  );
};

const notificationsRoute: RouteType = {
  element: <Notifications />,
  state: 'notifications',
  index: true,
  path: 'notifications',
  sideBarProps: {
    displayText: 'Push Notifications',
    icon: 'pi pi-megaphone',
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Notifications, notificationsRoute };
