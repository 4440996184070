import api from '../../../api/apiIndex';
import { leadEmailsEndpoint } from './leadEmailsApi';

export const OACLeadsEmailsEndpoint = `${leadEmailsEndpoint}/oneautocar-lead-emails`;

export const OACSubmittedLeadsEmailsEndpoint = `${OACLeadsEmailsEndpoint}/submitted-leads-emails`;

export interface IGetOACSubmittedLeadsEmails {
  registeredEmails: Array<{ email: string }>;
  unregisteredEmails: Array<{ email: string }>;
}

export const getOACSubmittedLeadsEmails =
  async (): Promise<IGetOACSubmittedLeadsEmails> => {
    const response = await api.get(OACSubmittedLeadsEmailsEndpoint);
    return response.data;
  };

export const createOACSubmittedLeadsEmail = async (email: string) => {
  try {
    const response = await api.post(OACSubmittedLeadsEmailsEndpoint, {
      email,
    });
    return response.data;
  } catch (err) {}
};

export const deleteOACSubmittedLeadsEmail = async (email: string) => {
  try {
    const response = await api.delete(OACSubmittedLeadsEmailsEndpoint, {
      data: { email },
    });
    return response.data;
  } catch (err) {}
};
