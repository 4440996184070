import { useEffect, useState } from 'react';
import {
  CarBrand,
  ICarBrandLogos,
} from '../../../../models/resources/CarBrand';
import { Form, Row, Col, Input, Space, Button } from 'antd';
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import FileUpload from '../../../../components/inputs/FileUpload';
import type {
  UploadRequestError,
  UploadRequestOption,
} from 'rc-upload/lib/interface';
import { EBrandLogoType } from '../apis';
import { set, values } from 'lodash';
import { AxiosError } from 'axios';
import { uploadImage } from '../../../../common/utils';

type CustomUploadFileStatus = UploadFileStatus | 'old';

type AddEditBrandFormProps = {
  brand: CarBrand | null;
  isEditing: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: any) => void;
  addedLogos: any[];
  removedLogos: any[];
  setAddedLogos: (logos: ICarBrandLogos[]) => void;
  setRemovedLogos: (urls: Array<Record<string, string>>) => void;
};

const AddEditBrandForm = ({
  brand,
  isEditing = false,
  onClose,
  onSubmit,
  isLoading,
  addedLogos,
  removedLogos,
  setAddedLogos,
  setRemovedLogos,
}: AddEditBrandFormProps) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const initialOriginalLogo = brand?.original_logo
    ? [
        {
          uid: brand.original_logo,
          name: brand.original_logo?.split('/').pop(),
          status: 'old',
          url: brand?.original_logo,
          id: brand?.original_logo,
        },
      ]
    : [];

  const initialWhiteLogo = brand?.white_logo
    ? [
        {
          uid: brand.white_logo,
          name: brand.white_logo?.split('/').pop(),
          status: 'old',
          url: brand?.white_logo,
          id: brand?.white_logo,
        },
      ]
    : [];

  const [fileListOriginalLogo, setFileListOriginalLogo] = useState<
    UploadFile[]
  >(initialOriginalLogo as UploadFile[]);

  const [fileListWhiteLogo, setFileListWhiteLogo] = useState<UploadFile[]>(
    initialWhiteLogo as UploadFile[],
  );

  const initialValues = {
    name: brand?.name,
    arabic_name: brand?.t?.[0]?.name,
    original_logo: initialOriginalLogo,
    white_logo: initialWhiteLogo,
  };

  const imageUpload = async (
    { onSuccess, onProgress, file, data, onError }: UploadRequestOption,
    brandType: EBrandLogoType,
  ) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'brand-logo',
        onProgress: onProgress,
        extraBodyData: {
          type: brandType,
        },
      });
      if (onSuccess) onSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedLogos([...addedLogos, addedImage]);
      setIsUploadingImage(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  const removeLogo = (file: UploadFile) => {
    if (file?.status === ('old' as CustomUploadFileStatus)) {
      setRemovedLogos([
        ...removedLogos,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedLogos(addedLogos.filter(logo => logo.file.uid !== file.uid));
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values: any) => {
        onSubmit(values);
        setFileListOriginalLogo([]);
        setFileListWhiteLogo([]);
        setAddedLogos([]);
        setRemovedLogos([]);
      }}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input placeholder="Please enter name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_name"
            label="Arabic Name"
            rules={[
              {
                required: true,
                message: 'Please enter arabic name',
              },
            ]}
          >
            <Input placeholder="Please enter arabic name" />
          </Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        wrap
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          paddingTop: '1rem',
          marginLeft: '15%',
        }}
      >
        <Col span={12}>
          <FileUpload
            fileList={fileListOriginalLogo}
            label="Original Logo"
            maxNbFiles={1}
            name="original_logo"
            setFileList={setFileListOriginalLogo}
            customRequest={props =>
              imageUpload(props, EBrandLogoType.OriginalLogo)
            }
            onRemove={removeLogo}
            requiredMessage='Please upload an original logo for the brand'
          />
        </Col>
        <Col span={12}>
          <FileUpload
            fileList={fileListWhiteLogo}
            label="White Logo"
            maxNbFiles={1}
            name="white_logo"
            setFileList={setFileListWhiteLogo}
            customRequest={props =>
              imageUpload(props, EBrandLogoType.WhiteLogo)
            }
            onRemove={removeLogo}
            requiredMessage='Please upload a white logo for the brand'
          />
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              form.resetFields();
              setFileListOriginalLogo([]);
              setFileListWhiteLogo([]);
              setAddedLogos([]);
              setRemovedLogos([]);
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isUploadingImage}
          >
            {isEditing ? 'Edit Brand' : 'Add Brand'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default AddEditBrandForm;
