import api from '../../../../api/apiIndex';
import { CarEngineType } from '../../../../models/resources/CarEngineType';

const engineTypesEndpoint = '/cars/resources/engine-types';

const engineTypesEndpointv2 = 'v2/admin/resources/cars/engine-types';

export interface IGetEngineTypes {
  active?: boolean;
}

export interface IEngineTypePayload {
  name: string;
  arabic_name: string;
  label: string;
}

const getEngineTypes = async ({ active }: IGetEngineTypes) => {
  try {
    const response = await api.get(engineTypesEndpointv2, {
      params: { active },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createEngineType = async (data: IEngineTypePayload) => {
  try {
    const response = await api.post(engineTypesEndpointv2, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateEngineType = async (id: number, data: IEngineTypePayload) => {
  try {
    const response = await api.put(`${engineTypesEndpointv2}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteEngineType = async (id: number) => {
  try {
    const response = await api.delete(`${engineTypesEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateEngineType = async (id: number) => {
  try {
    const response = await api.put(`${engineTypesEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateEngineType = async (id: number) => {
  try {
    const response = await api.put(`${engineTypesEndpointv2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getAllEngineTypeNames = async () => {
  const response = await api.get(`${engineTypesEndpoint}/names`);
  const mappedEngineTypes = response.data.data.map(
    (engineType: any) => new CarEngineType(engineType),
  );

  return mappedEngineTypes;
};

const getFuelEngineTypeNames = async () => {
  const response = await api.get(`${engineTypesEndpoint}/names/fuel`);
  const mappedEngineTypes = response.data.data.map(
    (engineType: any) => new CarEngineType(engineType),
  );

  return mappedEngineTypes;
};

const getElecticEngineTypeNames = async () => {
  const response = await api.get(`${engineTypesEndpoint}/names/electric`);
  const mappedEngineTypes = response.data.data.map(
    (engineType: any) => new CarEngineType(engineType),
  );

  return mappedEngineTypes;
};

export {
  getEngineTypes,
  createEngineType,
  updateEngineType,
  deleteEngineType,
  getAllEngineTypeNames,
  getElecticEngineTypeNames,
  getFuelEngineTypeNames,
};
