import { Modal, message } from 'antd';
import { RentalForm } from './RentalForm';
import { useState } from 'react';
import api from '../../../../api/apiIndex';
import { IRentalCar } from '../../../../models/listings/cars/rentals/RentalCar';
import { useCreateRental, useUpdateRental } from '../hooks/useRentalCars';
import { IRentalCarImage } from '../../../../models/listings/cars/rentals/RentalCarImage';
import { queryClient } from '../../../../App';

interface RentalModalProps {
  isVisible: boolean;
  isEditing: boolean;
  selectedCar: IRentalCar | null;
  onClose: () => void;
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCar: React.Dispatch<React.SetStateAction<any>>;
}

export const RentalModal = ({
  isVisible,
  selectedCar,
  isEditing,
  onClose,
  setIsFormOpen,
  setSelectedCar,
}: RentalModalProps) => {
  const [countryCode, setCountryCode] = useState('+974');
  const [addedImages, setAddedImages] = useState<IRentalCarImage[]>([]);
  const [removedImages, setRemovedImages] = useState<
    Array<Record<string, string>>
  >([]);

  const { mutateAsync: createRental, isLoading: isCreating } =
    useCreateRental();

  const { mutateAsync: updateRental, isLoading: isUpdating } =
    useUpdateRental();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      data.year = data.year.year();
      delete data.images;
      data.mobile_number = data.mobile_number
        ? `${countryCode?.slice(1)}${data.mobile_number.toString()}`
        : null;
      data.whatsapp_number = data.whatsapp_number
        ? `${countryCode?.slice(1)}${data.whatsapp_number.toString()}`
        : null;
      data['add_images'] = addedImages.map(image => ({ id: image.id }));
      data.is_featured = data.is_featured ?? false;
      const response = await createRental(data);
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['rentalCars'],
      });
      message.success('Rental Car created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (data: any) => {
    try {
      data.year = data.year.year();
      delete data.images;
      data.mobile_number = data.mobile_number
        ? `${countryCode?.slice(1)}${data.mobile_number.toString()}`
        : null;
      data.whatsapp_number = data.whatsapp_number
        ? `${countryCode?.slice(1)}${data.whatsapp_number.toString()}`
        : null;
      data['add_images'] = addedImages.map(image => ({ id: image.id }));
      data['remove_images'] = removedImages;
      data.is_featured = data.is_featured ?? false;
      const response = await updateRental({
        id: selectedCar!.id,
        data,
      });
      setIsFormOpen(false);
      setSelectedCar(null);
      queryClient.invalidateQueries({
        queryKey: ['rentalCars'],
      });
      message.success('Rental car updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title={isEditing ? 'Edit Rental Car' : 'Add Rental Car'}
      open={isVisible}
      onCancel={onClose}
      width={1200}
      footer={null}
      style={{ top: 20 }}
      key={selectedCar?.id}
    >
      <RentalForm
        isEditing={isEditing}
        isLoading={isCreating || isUpdating}
        onSubmit={isEditing ? _edit : _create}
        selectedCar={selectedCar}
        addedImages={addedImages}
        removedImages={removedImages}
        setRemovedImages={setRemovedImages}
        setAddedImages={setAddedImages}
        onClose={onClose}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    </Modal>
  );
};
