import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { appRoutes } from './routes';
import _ from 'lodash';
import User from '../../models/User';
import { RouteType } from './routesConfig';

interface IGenerateRoute {
  userInfo: User;
}

interface IFlattenRoutes {
  appRoutes: Array<RouteType> | RouteType;
}

const flattenRoutes = ({ appRoutes }: IFlattenRoutes) => {
  let extractedObjects: any = [];

  if (_.isPlainObject(appRoutes)) {
    const appRoute = appRoutes as RouteType;
    if (appRoute.path && appRoute.allowedGroups) {
      extractedObjects.push(
        _.pick(appRoute, ['path', 'allowedGroups', 'disabled']),
      );
    }

    _.forOwn(appRoute, value => {
      extractedObjects = extractedObjects.concat(
        flattenRoutes({ appRoutes: value }),
      );
    });
  } else if (_.isArray(appRoutes)) {
    appRoutes.forEach(item => {
      extractedObjects = extractedObjects.concat(
        flattenRoutes({ appRoutes: item }),
      );
    });
  }

  return extractedObjects;
};

export const generateRoute = ({ userInfo }: IGenerateRoute) => {
  const group = userInfo.group;
  if (!group) return '/login';
  //flatten routes
  const routes = flattenRoutes({ appRoutes });
  if (!routes) return '/login';
  const allowedRoutes = _.filter(
    routes,
    (route: any) =>
      route?.allowedGroups?.includes(group) && route.path && !route.disabled,
  );
  if (allowedRoutes.length > 0) return allowedRoutes[0].path;
  return null;
};

const UnprotectedRoute = () => {
  const { userInfo, isAuthenticated } = useAuth();
  if (!userInfo || !isAuthenticated) return <Outlet />;
  const route = generateRoute({ userInfo });
  return route ? <Navigate to={route} /> : <Outlet />;
};

export default UnprotectedRoute;
