export const timeSince = (date: string) => {
  var seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval >= 1) {
    return Math.floor(interval) + ` year${interval > 1 && 's'} ago`;
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months ago ';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days ago ';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours ago ';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes ago ';
  }
  return Math.floor(seconds) + ' seconds ago ';
};

export function sliceIntoChunks<T>(arr: Array<T>, chunkSize: number) {
  const res: Array<Array<T>> = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export const dashSeparatedDateFormat = (date: string | Date) => {
  if (!date) return null;
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const downloadFile = (file: Blob, name: string) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.download = `${name}.xlsx`;
  document.body.append(link);
  link.click();
  link.remove();
};

export enum Language {
  en = 'English',
  ar = 'Arabic',
}
