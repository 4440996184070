import { UserGroups } from '../../../common/constants';
import ReviewersLayout from '../../../reviewers/components/ReviewersLayout';
import { RouteType } from '../../routes/routesConfig';

const Reviewers = () => {
  return <ReviewersLayout />;
};
const ReviewersRoutes: RouteType = {
  path: '/reviewers',
  index: true,
  element: <Reviewers />,
  state: 'reviewers',
  sideBarProps: {
    displayText: 'Reviewers',
  },
  allowedGroups: [UserGroups.OAC],
};

export { Reviewers, ReviewersRoutes };
