import { AllRentalDealersLayout } from '../../../dealers/RentalDealer/components';

const RentalDealers = () => {
  return <AllRentalDealersLayout />;
};

const rentalDealersRoute = {
  path: '/dealers/rentals',
  index: true,
  element: <RentalDealers />,
  state: 'rentals',
  sideBarProps: {
    displayText: 'Rental',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR', 'Rental Dealer'],
};

export { RentalDealers, rentalDealersRoute };
