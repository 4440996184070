import { Language } from '../../../../../helpers/helperFunctions';
import NewDealCard from '../NewDealCar';
import { INewCarDeal } from '../../../../../models/resources';

type DealsListProps = {
  deals: INewCarDeal[];
  onEditClick: (deal: INewCarDeal) => void;
  onDeleteClick: (deal: INewCarDeal) => void;
  loading?: boolean;
};

export const DealsList = ({
  deals,
  onEditClick,
  onDeleteClick,
}: DealsListProps) => {
  return (
    <div className="w-full flex flex-column row-gap-5">
      {deals?.length > 0 &&
        deals.map((deal: INewCarDeal) => (
          <NewDealCard
            onEdit={() => onEditClick(deal)}
            onDelete={() => onDeleteClick(deal)}
            key={deal?.id}
            deal={deal}
            lang={Language.en}
          />
        ))}
    </div>
  );
};
