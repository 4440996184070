import { useMutation } from '@tanstack/react-query';
import {
  deleteApiTokens,
  logout,
} from '../../authentication/apis/authenticationApi';
import { queryClient } from '../../App';
import useAuth from '../../hooks/useAuth';

export const useLogout = () => {
  const { setIsAuthenticated, setUserInfo } = useAuth();
  return useMutation(logout, {
    onSuccess: () => {
      deleteApiTokens();
      queryClient.removeQueries();
      setIsAuthenticated(false);
      setUserInfo(null);
    },
  });
};
