import api from '../../../../api/apiIndex';
import { CarBrand } from '../../../../models/resources/CarBrand';
import { IAllBrandNames, IBrandNames } from '../hooks/useBrands';

const brandsEndpoint = '/v2/admin/resources/brands';

export enum EBrandLogoType {
  OriginalLogo = 'original',
  WhiteLogo = 'white',
}

export interface IGetBrands {
  dealerId?: number;
  active?: boolean;
  type?: string;
}

export interface ICarBrandPayload {
  name: string;
  arabic_name: string;
  add_logos?: Array<{ id: number }>;
  remove_logos?: Array<{ url: string }>;
}

export const getBrandsWithModelsCount = async (
  country?: string,
): Promise<CarBrand[]> => {
  const headers: { country?: string } = {};
  if (country) {
    headers.country = country;
  }
  const response = await api.get(`${brandsEndpoint}/models/count`, {
    headers,
  });

  const data = response.data;
  const sort = data.sort((a: CarBrand, b: CarBrand) => {
    return a.name.localeCompare(b.name);
  });
  return sort;
};

export const getBrands = async ({ dealerId, active, type }: IGetBrands) => {
  try {
    const response = await api.get(`${brandsEndpoint}`, {
      params: {
        dealer: dealerId,
        active,
        type,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createBrand = async (data: ICarBrandPayload) => {
  try {
    const response = await api.post(brandsEndpoint, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const editBrand = async (id: number, data: ICarBrandPayload) => {
  try {
    const response = await api.put(`${brandsEndpoint}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteBrand = async (id: number) => {
  try {
    const response = await api.delete(`${brandsEndpoint}/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getBrandFilters = async (): Promise<IBrandNames[]> => {
  const response = await api.get(`/cars/resources/brands/names`);
  return response.data.data;
};

export const getAllBrandsNames = async (): Promise<IAllBrandNames[]> => {
  const response = await api.get(`/cars/resources/brands/names/all`);
  return response.data.data;
};

export const activateBrand = async (id: number) => {
  try {
    const response = await api.put(`${brandsEndpoint}/activate/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBrand = async (id: number) => {
  try {
    const response = await api.put(`${brandsEndpoint}/deactivate/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { createBrand, editBrand, deleteBrand, getBrandFilters };
