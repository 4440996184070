import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IEngineTypePayload,
  activateEngineType,
  createEngineType,
  deactivateEngineType,
  deleteEngineType,
  getEngineTypes,
  updateEngineType,
} from '../apis/engineTypeApi';
import { queryClient } from '../../../../App';

export const ALL_ENGINE_TYPES_KEY = ['allEngineTypes'];

interface IUseEngineTypes {
  enabled: boolean;
  active?: boolean;
}

export const useEngineTypes = ({ enabled, active }: IUseEngineTypes) => {
  return useQuery({
    queryKey: ALL_ENGINE_TYPES_KEY,
    queryFn: () => getEngineTypes({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useEngineType = ({ enabled, active }: IUseEngineTypes) => {
  return useQuery({
    queryKey: ALL_ENGINE_TYPES_KEY,
    queryFn: () => getEngineTypes({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useUpdateEngineTypes = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEngineTypePayload }) =>
      updateEngineType(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_TYPES_KEY);
    },
  });
};

export const useCreateEngineTypes = () => {
  return useMutation({
    mutationFn: (data: IEngineTypePayload) => createEngineType(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_TYPES_KEY);
    },
  });
};

export const useDeleteEngineTypes = () => {
  return useMutation({
    mutationFn: (id: number) => deleteEngineType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_TYPES_KEY);
    },
  });
};

export const useActivateEngineTypes = () => {
  return useMutation({
    mutationFn: (id: number) => activateEngineType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_TYPES_KEY);
    },
  });
};

export const useDeactivateEngineTypes = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateEngineType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_TYPES_KEY);
    },
  });
};
