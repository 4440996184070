import { allArticlesRoutes } from '../pages/Articles/AllArticles';
import { allBanksRoutes } from '../pages/Financing/AllBanks';
import { batteryCapacityRoute } from '../pages/Resources/BatteryCapacity';
import { batteryRangeRoute } from '../pages/Resources/BatteryRange';
import { bodyTypeRoute } from '../pages/Resources/BodyType';
import { brandsRoute } from '../pages/Resources/Brands';
import { carConditionRoute } from '../pages/Resources/CarConditionModel';
import { cylindersRoute } from '../pages/Resources/Cylinders';
import { drivetrainRoute } from '../pages/Resources/Drivetrain';
import { engineSizeRoute } from '../pages/Resources/EngineSize';
import { engineTypeRoute } from '../pages/Resources/EngineType';
import { exteriorColorRoute } from '../pages/Resources/ExteriorColor';
import { featuresRoute } from '../pages/Resources/Features';
import { interiorColorsRoute } from '../pages/Resources/InteriorColor';
import { modelsRoute } from '../pages/Resources/Models';
import { seatMaterialRoute } from '../pages/Resources/SeatMaterial';
import { transmissionRoute } from '../pages/Resources/Transmission';
import { usersRoute } from '../pages/Users/Users';
import { RouteType } from './routesConfig';
import { bannersRoute } from '../pages/Ads/Banners';
import { allListingsRoutes } from '../pages/Listings/AllListings';
import { analyticsRoute } from '../pages/Analytics/analyticsRoute';
import { locationsRoute } from '../pages/Resources/RentalLocations';
import { allDealersRoute } from '../pages/Dealers/AllDealers';
import { allLeadsRoute } from '../pages/Leads/AllLeads';
import { cityRoute } from '../pages/Resources/Cities';
import { countryRoute } from '../pages/Resources/Countries';
import { bannerRoute } from '../pages/Resources/Banners';
import { ReviewsRoutes } from '../pages/Reviews/Reviews';
import { trimsRoute } from '../pages/Resources/Trims';
import { ReviewersRoutes } from '../pages/Reviewers/Reviewers';
import { leadEmailsRoute } from '../pages/Settings/LeadEmails';
import { UserGroups } from '../../common/constants';
import { settingsRoute } from '../pages/Settings/Settings';
import { bikeEngineSizeRoute } from '../pages/Resources/bikes/BikeEngineSize';
import { bikeBrandsRoute } from '../pages/Resources/bikes/BikeBrand';
import { bikeEngineTypeRoute } from '../pages/Resources/bikes/BikeEngineType';
import { bikeCylindersRoute } from '../pages/Resources/bikes/BikeCylinders';
import { bikeOptionalFeatureRoute } from '../pages/Resources/bikes/BikeOptionalFeatures';
import { bikeConditionRoute } from '../pages/Resources/bikes/BikeConditions';
import { bikeColorRoute } from '../pages/Resources/bikes/BikeColors';
import { bikeTransmissionRoute } from '../pages/Resources/bikes/BikeTransmission';
import { bikeBatteryCapacityRoute } from '../pages/Resources/bikes/BikeBatteryCapacity';
import { bikeBatteryRangeRoute } from '../pages/Resources/bikes/BikeBatteryRange';
import { bikeTypesRoute } from '../pages/Resources/bikes/BikeType';
import { bikeModelsRoute } from '../pages/Resources/bikes/BikeModel';
import { bikeTrimsRoute } from '../pages/Resources/bikes/BikeTrim';
import { currencyRoute } from '../pages/Resources/Currencies';

export const appRoutes: Array<RouteType> = [
  analyticsRoute,
  allLeadsRoute,
  allDealersRoute,
  allListingsRoutes,
  allBanksRoutes,
  allArticlesRoutes,
  ReviewsRoutes,
  ReviewersRoutes,
  bannersRoute,
  usersRoute,
  // notificationsRoute,
  {
    index: false,
    state: 'resources',
    children: [
      {
        index: false,
        state: 'resources.cars',
        children: [
          brandsRoute,
          modelsRoute,
          trimsRoute,
          bodyTypeRoute,
          engineTypeRoute,
          engineSizeRoute,
          cylindersRoute,
          transmissionRoute,
          drivetrainRoute,
          batteryRangeRoute,
          batteryCapacityRoute,
          featuresRoute,
          seatMaterialRoute,
          interiorColorsRoute,
          exteriorColorRoute,
          carConditionRoute,
          locationsRoute,
        ],
        sideBarProps: {
          displayText: 'Cars',
        },
        allowedGroups: ['ONE AUTOCAR'],
      },
      {
        index: false,
        state: 'resources.bikes',
        children: [
          bikeBrandsRoute,
          bikeModelsRoute,
          bikeTypesRoute,
          bikeTrimsRoute,
          bikeEngineSizeRoute,
          bikeEngineTypeRoute,
          bikeCylindersRoute,
          bikeOptionalFeatureRoute,
          bikeConditionRoute,
          bikeColorRoute,
          bikeTransmissionRoute,
          bikeBatteryCapacityRoute,
          bikeBatteryRangeRoute,
        ],
        sideBarProps: {
          displayText: 'Bikes',
        },
        allowedGroups: [UserGroups.OAC],
      },
      cityRoute,
      countryRoute,
      currencyRoute,
      bannerRoute,
    ],
    sideBarProps: {
      displayText: 'Resources',
    },
    allowedGroups: ['ONE AUTOCAR'],
  },
  settingsRoute,
  // pageRolesRoute,
];

export default appRoutes;
