import { Modal, message } from 'antd';
import { NewBikeLeadsDetail } from '../../../models/overview/NewBikeLead';
import { useCreateNewBikeLead, useEditNewBikeLead } from '../hooks';
import { AddEditNewBikeLeadForm } from './AddEditNewBikeLeadForm';

interface NewBikeLeadModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing?: (value: boolean) => void;
  currentLead: NewBikeLeadsDetail | null;
  setCurrentLead?: (value: NewBikeLeadsDetail | null) => void;
}

export const NewBikeLeadModal = ({
  currentLead,
  isEditing,
  isFormOpen,
  setIsFormOpen,
  setCurrentLead,
  setIsEditing,
}: NewBikeLeadModalProps) => {
  const { mutateAsync: createLead, isLoading: isCreating } =
    useCreateNewBikeLead();
  const { mutateAsync: updateLead, isLoading: isUpdating } =
    useEditNewBikeLead();

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing && setIsEditing(false);
    setCurrentLead && setCurrentLead(null);
  };

  const _create = async (lead: any) => {
    if (lead == null) return;
    const response = await createLead({
      name: lead.name,
      email: lead.email,
      mobile_number: `+974${lead.mobile_number?.toString()}`,
      bike_dealer_id: lead.dealer_id,
      bike_brand_id: lead.brand_id,
      bike_model_id: lead.model_id,
      source: lead.source,
      contacted: lead.contacted,
      notes: lead.notes,
    });
    setIsFormOpen(false);
    message.success('New Bike lead created successfully');
    return response;
  };

  const _edit = async (lead: any) => {
    const response = await updateLead({
      id: currentLead!.id,
      data: {
        name: lead.name,
        email: lead.email,
        mobile_number: `+974${lead.mobile_number?.toString()}`,
        source: lead.source,
        contacted: lead.contacted,
        notes: lead.notes,
      },
    });
    setIsFormOpen(false);
    setCurrentLead && setCurrentLead(null);
    message.success('New Bike lead updated successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit New Bike Lead' : 'Add New Bike Lead'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={closeForm}
        footer={null}
        width={1200}
        key={currentLead?.id}
        destroyOnClose
      >
        <AddEditNewBikeLeadForm
          lead={currentLead}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={closeForm}
        />
      </Modal>
    </>
  );
};
