import api from '../../../../api/apiIndex';

const typesEndpointV2 = 'v2/admin/resources/cars/types';

export interface ICarTypePayload {
  name: string;
  arabic_name: string;
}

export interface IGetTypes {
  active?: boolean;
}

const getTypes = async ({ active }: IGetTypes) => {
  try {
    const response = await api.get(typesEndpointV2, {
      params: { active },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createType = async (data: ICarTypePayload) => {
  try {
    const response = await api.post(typesEndpointV2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateType = async (id: number, data: ICarTypePayload) => {
  try {
    const response = await api.put(`${typesEndpointV2}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteType = async (id: number) => {
  try {
    const response = api.delete(`${typesEndpointV2}/${id}`);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const activateType = async (id: number) => {
  try {
    const response = api.put(`${typesEndpointV2}/activate/${id}`);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const deactivateType = async (id: number) => {
  try {
    const response = api.put(`${typesEndpointV2}/deactivate/${id}`);

    return response;
  } catch (error) {
    console.error(error);
  }
};

export {
  getTypes,
  createType,
  updateType,
  deleteType,
  activateType,
  deactivateType,
};
