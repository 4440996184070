import { ArticlesLayout } from '../../../articles/components';
import { RouteType } from '../../routes/routesConfig';

const AllArticles = () => {
  return <ArticlesLayout />;
};
const allArticlesRoutes: RouteType = {
  path: '/articles',
  index: true,
  element: <AllArticles />,
  state: 'articles',
  sideBarProps: {
    displayText: 'Articles',
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { AllArticles, allArticlesRoutes };
