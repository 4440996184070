import { Outlet } from 'react-router-dom';
import SideBar from '../sidebar/SideBar';
import { Divider, Image, Layout, theme } from 'antd';
import LogoutComponent from '../sidebar/LogoutComponent';
import Logo from '../../../assets/logo.jpg';

const { Sider, Content } = Layout;

const MainLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout hasSider>
      <Sider
        style={{
          background: colorBgContainer,
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
        width={250}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '30px',
            height: '10%',
          }}
        >
          <Image src={Logo} preview={false} />
        </div>
        <Divider />
        <div style={{ overflow: 'scroll', height: '65vh' }}>
          <SideBar />
        </div>
        <div
          style={{
            height: '12%',
          }}
        >
          <Divider
            style={{
              marginTop: 0,
              paddingTop: 0,
              marginBottom: 0,
            }}
          />
          <LogoutComponent />
        </div>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 250 }}>
        <Content
          style={{
            margin: '0 16px 0',
            overflow: 'initial',
            minHeight: '100vh',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
