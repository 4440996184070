import api from '../../../api/apiIndex';
import { ILocation } from '../../../models/locations/Location';

const locationsEndpoint = 'v2/admin/resources/locations';

export interface ILocationPayload {
  id: number;
  address: string;
  arabic_address: string;
  country_id: number;
}

export const getLocations = async ({ active }: { active?: boolean }) => {
  try {
    const response = await api.get(locationsEndpoint, { params: { active } });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteLocation = async (id: number) => {
  try {
    const response = await api.delete(`${locationsEndpoint}/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createLocation = async (data: ILocationPayload) => {
  try {
    const response = await api.post(locationsEndpoint, data);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateLocation = async (id: number, data: ILocationPayload) => {
  try {
    const response = await api.put(`${locationsEndpoint}/${id}`, data);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const activateLocation = async (id: number) => {
  try {
    const response = await api.put(`${locationsEndpoint}/activate/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deactivateLocation = async (id: number) => {
  try {
    const response = await api.put(`${locationsEndpoint}/deactivate/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
