import { Modal, message } from 'antd';
import AddEditBrandForm from './AddEditBrandForm';
import { useState } from 'react';
import { ICarBrandLogos } from '../../../../models/resources/CarBrand';
import { useCreateBrand, useEditBrand } from '../hooks';

interface BrandsModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentBrand: any;
  setCurrentBrand: (value: any) => void;
  onClose: () => void;
}

export const BrandsModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBrand,
  setCurrentBrand,
  onClose,
}: BrandsModalProps) => {
  const [addedLogos, setAddedLogos] = useState<ICarBrandLogos[]>([]);
  const [removedLogos, setRemovedLogos] = useState<
    Array<Record<string, string>>
  >([]);
  const { mutateAsync: createBrand, isLoading: isCreating } = useCreateBrand();
  const { mutateAsync: updateBrand, isLoading: isUpdating } = useEditBrand();

  const _Create = async (brand: any) => {
    if (brand == null) return;
    try {
      delete brand.original_logo;
      delete brand.white_logo;
      brand['add_logos'] = addedLogos.map(logo => ({ id: logo.id }));
      const response = await createBrand(brand);
      setIsFormOpen(false);
      setCurrentBrand(null);
      message.success('Brand Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    try {
      delete data.original_logo;
      delete data.white_logo;
      data['add_logos'] = addedLogos.map(logo => ({ id: logo.id }));
      data['remove_logos'] = removedLogos;
      const response = await updateBrand({
        id: currentBrand!.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentBrand(null);
      message.success('Brand Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        className="crud-dialog"
        title={isEditing ? 'Edit Brand' : 'Add Brand'}
        open={isFormOpen}
        onCancel={onClose}
        footer={null}
        width={1200}
        key={currentBrand?.id}
        style={{ top: 20 }}
      >
        <AddEditBrandForm
          addedLogos={addedLogos}
          removedLogos={removedLogos}
          setAddedLogos={setAddedLogos}
          setRemovedLogos={setRemovedLogos}
          isLoading={isCreating || isUpdating}
          isEditing={isEditing}
          brand={currentBrand}
          onClose={onClose}
          onSubmit={isEditing ? _edit : _Create}
        />
      </Modal>
    </>
  );
};
