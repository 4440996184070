import { Form, Modal, UploadFile, message } from 'antd';
import { IBanner, IBannerImage } from '../../../models/ads/Banner';
import AddEditBannerForm from './AddEditBannerForm';
import {
  ALL_BANNERS_KEY,
  useCreateBanner,
  useUpdateBanner,
} from '../hooks/useBanners';
import { queryClient } from '../../../App';
import { useState } from 'react';
import { IBannerResource } from '../../../models/ads/BannerResource';

interface BannersModalProps {
  isFormOpen: boolean;
  currentBanner: IBanner | null;
  isEditing: boolean;
  bannerResources: IBannerResource[] | undefined;
  setIsFormOpen: (value: boolean) => void;
  setCurrentBanner: (value: IBanner | null) => void;
  closeForm: () => void;
}

export interface IBannerAddedImage {
  id: number;
  banner_id: number;
  name: string;
  lang: string;
  call_to_action: string;
  thumbnail: string;
  created_at: Date;
  updated_at: Date;
}

export interface IBannerRemovedImage {
  url: string;
}

export const BannersModal = ({
  isFormOpen,
  currentBanner,
  isEditing,
  bannerResources,
  setIsFormOpen,
  setCurrentBanner,
  closeForm,
}: BannersModalProps) => {
  const [addedImages, setAddedImages] = useState<IBannerAddedImage[]>([]);
  const [addedThumbails, setAddedThumbails] = useState<IBannerAddedImage[]>([]);

  const [form] = Form.useForm();

  const [fileListEn, setFileListEn] = useState<UploadFile[]>(
    currentBanner?.images.find((img: IBannerImage) => img.lang === 'en')?.url
      ? [
          {
            uid: `bannerEnUrl-${currentBanner?.id}`,
            name:
              currentBanner?.images
                .find(img => img.lang === 'en')
                ?.url.split('/images/')
                .pop() || '',
            status: 'done',
            url: currentBanner?.images.find(img => img.lang === 'en')?.url,
          },
        ]
      : [],
  );

  const [fileListAr, setFileListAr] = useState<UploadFile[]>(
    currentBanner?.images.find((img: IBannerImage) => img.lang === 'ar')?.url
      ? [
          {
            uid: `bannerArUrl-${currentBanner?.id}`,
            name:
              currentBanner?.images
                .find(img => img.lang === 'ar')
                ?.url.split('/images/')
                .pop() || '',
            status: 'done',
            url: currentBanner?.images.find(img => img.lang === 'ar')?.url,
          },
        ]
      : [],
  );

  const [fileListThumbEn, setFileListThumbEn] = useState<UploadFile[]>(
    currentBanner?.images?.find((img: IBannerImage) => img.lang === 'en')
      ?.thumbnail
      ? [
          {
            uid: `bannerEnUrl-${currentBanner?.id}`,
            name:
              currentBanner?.images
                .find(img => img.lang === 'en')
                ?.thumbnail.split('/images/')
                .pop() || '',
            status: 'done',
            url: currentBanner?.images.find(img => img.lang === 'en')
              ?.thumbnail,
          },
        ]
      : [],
  );

  const [fileListThumbAr, setFileListThumbAr] = useState<UploadFile[]>(
    currentBanner?.images?.find((img: IBannerImage) => img.lang === 'ar')
      ?.thumbnail
      ? [
          {
            uid: `bannerArUrl-${currentBanner?.id}`,
            name:
              currentBanner?.images.find(img => img.lang === 'ar')?.thumbnail ||
              '',
            status: 'done',
            url: currentBanner?.images.find(img => img.lang === 'ar')
              ?.thumbnail,
          },
        ]
      : [],
  );

  const { mutateAsync: createBanner, isLoading: isCreating } =
    useCreateBanner();
  const { mutateAsync: updateBanner, isLoading: isUpdating } =
    useUpdateBanner();

  const _create = async (banner: any) => {
    if (banner == null) return;
    try {
      const originalThumbEn =
        addedThumbails.find(thumb => thumb.lang === 'en')?.name || '';
      const originalThumbAr =
        addedThumbails.find(thumb => thumb.lang === 'ar')?.name || '';
      const response = await createBanner({
        section_name: banner.section_name,
        content_type: banner.content_type,
        placement: banner.placement,
        advertiser: banner.advertiser,
        order: banner.order,
        city_id: banner.city_id,
        from_date: banner.dateRange[0].format('YYYY-MM-DD HH:mm:ss'),
        to_date: banner.dateRange[1].format('YYYY-MM-DD HH:mm:ss'),
        add_images: addedImages?.map(img => {
          return { id: img.id };
        }),
        call_to_actions: [
          {
            call_to_action: banner.cta,
            lang: 'en',
          },
          {
            call_to_action: banner.cta_ar,
            lang: 'ar',
          },
        ],
        titles: [
          {
            title: banner.title,
            lang: 'en',
          },
          {
            title: banner.title_ar,
            lang: 'ar',
          },
        ],
        thumbnails: [
          {
            thumbnail: originalThumbEn,
            lang: 'en',
          },
          {
            thumbnail: originalThumbAr,
            lang: 'ar',
          },
        ],
      });
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: ALL_BANNERS_KEY,
      });
      message.success('Banner created successfully');
      setFileListAr([]);
      setFileListEn([]);
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      form.resetFields();
    }
  };

  const _edit = async (banner: any) => {
    try {
      const originalThumbEn =
        addedThumbails.find(thumb => thumb.lang === 'en')?.name ||
        (currentBanner?.images.find(img => img.lang === 'en')?.thumbnail || '')
          .split('/images/')
          .pop() ||
        '';

      const originalThumbAr =
        addedThumbails.find(thumb => thumb.lang === 'ar')?.name ||
        (currentBanner?.images.find(img => img.lang === 'ar')?.thumbnail || '')
          .split('/images/')
          .pop() ||
        '';
      const response = await updateBanner({
        id: currentBanner!.id,
        data: {
          section_name: banner.section_name,
          content_type: banner.content_type,
          placement: banner.placement,
          advertiser: banner.advertiser,
          order: banner.order,
          city_id: banner.city_id,
          from_date: banner.dateRange[0].format('YYYY-MM-DD HH:mm:ss'),
          to_date: banner.dateRange[1].format('YYYY-MM-DD HH:mm:ss'),
          add_images: addedImages?.map(img => {
            return { id: img.id };
          }),
          remove_images: addedImages.map(image => ({
            url: currentBanner?.images.find(
              (img: IBannerImage) => img.lang === image.lang,
            )?.url,
          })),
          call_to_actions: [
            {
              call_to_action: banner.cta,
              lang: 'en',
            },
            {
              call_to_action: banner.cta_ar,
              lang: 'ar',
            },
          ],
          titles: [
            {
              title: banner.title,
              lang: 'en',
            },
            {
              title: banner.title_ar,
              lang: 'ar',
            },
          ],
          thumbnails: [
            {
              thumbnail: originalThumbEn,
              lang: 'en',
            },
            {
              thumbnail: originalThumbAr,
              lang: 'ar',
            },
          ],
        },
      });
      setIsFormOpen(false);
      setCurrentBanner(null);
      queryClient.invalidateQueries({
        queryKey: ALL_BANNERS_KEY,
      });
      form.resetFields();
      setFileListAr([]);
      setFileListEn([]);
      message.success('Banner updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Banner' : 'Add Banner'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBanner?.id}
      >
        <AddEditBannerForm
          addedThumbails={addedThumbails}
          setAddedThumbails={setAddedThumbails}
          isEditing={isEditing}
          banner={currentBanner}
          bannerResources={bannerResources}
          isLoading={isCreating || isUpdating}
          onSubmit={isEditing ? _edit : _create}
          onClose={closeForm}
          addedImages={addedImages}
          setAddedImages={setAddedImages}
          form={form}
          fileListAr={fileListAr}
          setFileListAr={setFileListAr}
          fileListEn={fileListEn}
          setFileListEn={setFileListEn}
          fileListThumbAr={fileListThumbAr}
          fileListThumbEn={fileListThumbEn}
          setFileListThumbAr={setFileListThumbAr}
          setFileListThumbEn={setFileListThumbEn}
        />
      </Modal>
    </>
  );
};
