import { Modal, message } from 'antd';
import { useCreateTrim, useEditTrim } from '../hooks';
import { ICarTrim } from '../../../../models/resources/CarTrim';
import AddEditTrimForm from './AddEditTrimForm';

interface ModelModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  onClose: () => void;
  isEditing: boolean;
  currentTrim: ICarTrim | null;
  setCurrentTrim: (value: ICarTrim | null) => void;
}

export const TrimModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentTrim,
  setCurrentTrim,
  onClose,
}: ModelModalProps) => {
  const { mutateAsync: createTrim, isLoading: isCreating } = useCreateTrim();
  const { mutateAsync: updateTrim, isLoading: isUpdating } = useEditTrim();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createTrim(data);
      setIsFormOpen(false);
      setCurrentTrim(null);
      message.success('Trim Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (currentTrim == null) return;
    try {
      const response = await updateTrim({
        id: currentTrim.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentTrim(null);
      message.success('Model Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Trim' : 'Add Trim'}
        onCancel={onClose}
        open={isFormOpen}
        footer={null}
        width={1200}
        style={{ top: 20 }}
        key={currentTrim?.id}
      >
        <AddEditTrimForm
          isEditing={isEditing}
          trim={currentTrim}
          onClose={onClose}
          onSubmit={isEditing ? _edit : _create}
          isLoading={isCreating || isUpdating}
        />
      </Modal>
    </>
  );
};
