import api from '../../api/apiIndex';

const articlesEndpointV2 = '/v2/admin/articles';

export enum EArticleType {
  Image = 'image',
  Thumbnail = 'thumbnail',
}

export interface IArticlePayload {
  title: string;
  arabic_title: string;
  description: string;
  arabic_description: string;
  city_id: number;
  is_featured?: boolean;
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
}

export const getArticles = async () => {
  const response = await api.get(articlesEndpointV2);
  return response.data;
};

export const createArticle = async (request: IArticlePayload) => {
  const response = await api.post(articlesEndpointV2, request);
  return response.data;
};

export const updateArticle = async (
  id: number,
  data: IArticlePayload,
) => {
  const response = await api.put(`${articlesEndpointV2}/${id}`, data);
  return response.data;
};

export const deleteArticle = async (id: number) => {
  const response = await api.delete(`${articlesEndpointV2}/${id}`);
  return response.data;
};

export const activateArticle = async (id: number) => {
  const response = await api.put(`${articlesEndpointV2}/${id}/activate`);
  return response.data;
};

export const deactivateArticle = async (id: number) => {
  const response = await api.put(`${articlesEndpointV2}/${id}/deactivate`);
  return response.data;
};
