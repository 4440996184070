import { AnalyticsLayout } from '../../../analytics/AnalyticsLayout';
import { RouteType } from '../../routes/routesConfig';

export const analyticsRoute: RouteType = {
  path: '/overview',
  element: <AnalyticsLayout />,
  index: false,
  disabled: true,
  state: 'overview',
  sideBarProps: {
    displayText: 'Overview (WIP)',
  },
  allowedGroups: ['ONE AUTOCAR'],
};
