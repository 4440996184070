import api from '../../../../api/apiIndex';

const newCarsEndpointV2 = '/v2/admin/cars/new-cars';

export enum Objective {
  Leads = 'leads',
  Traffic = 'traffic',
}

export type ICreateDealRequest = {
  title: string;
  arabic_title: string;
  city_id: number;
  price?: number | null;
  call_to_action?: string | null;
  arabic_call_to_action?: string | null;
  model_id: number | null;
  dealer_id: number;
  description: string;
  arabic_description: string;
  test_drive_url?: string | null;
  arabic_test_drive_url?: string | null;
};

export type IUpdateDealRequest = {
  title?: string;
  arabic_title?: string;
  city_id?: number;
  price?: number | null;
  call_to_action?: string | null;
  arabic_call_to_action?: string | null;
  model_id?: number;
  dealer_id?: number;
  description?: string;
  arabic_description?: string;
  test_drive_url?: string | null;
  arabic_test_drive_url?: string | null;
};

const getDealByModelId = async (slug: string, country?: string) => {
  const response = await api.get(`${newCarsEndpointV2}/model/${slug}`, {
    headers: { country },
  });
  return response.data;
};

const createDeal = async (request: ICreateDealRequest) => {
  const response = await api.post(newCarsEndpointV2, request);
  return response.data;
};

const updateDeal = async (id: number, request: IUpdateDealRequest) => {
  const response = await api.put(`${newCarsEndpointV2}/${id}`, request);
  return response.data;
};

const deleteDeal = async (id: number) => {
  const response = await api.delete(`${newCarsEndpointV2}/${id}`);
  return response.data;
};

const activateDeal = async (id: number) => {
  const response = await api.put(`${newCarsEndpointV2}/activate/${id}`);
  return response.data;
};

const deactivateDeal = async (id: number) => {
  const response = await api.put(`${newCarsEndpointV2}/deactivate/${id}`);
  return response.data;
};

export {
  getDealByModelId,
  createDeal,
  updateDeal,
  deleteDeal,
  activateDeal,
  deactivateDeal,
};
