import LoginPageLayout from '../../../authentication/components/LoginPageLayout';
import { RouteType } from '../../routes/routesConfig';

const LoginPage = () => {
  return <LoginPageLayout />;
};

const loginPageRoute: RouteType = {
  element: <LoginPage />,
  path: '/login',
  index: true,
  state: 'login',
};

export { LoginPage, loginPageRoute };
