import { UserGroups } from '../../../common/constants';
import { RentalLeadsLayout } from '../../../leads/rentals/components/RentalLeadsLayout';

const RentalLeads = () => {
  return <RentalLeadsLayout />;
};

const rentalLeadsRoute = {
  path: '/leads/rentals',
  index: true,
  element: <RentalLeads />,
  state: 'rentalLeads',
  sideBarProps: {
    displayText: 'Rental',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Rental],
};

export { RentalLeads, rentalLeadsRoute };
