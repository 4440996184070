import { useEffect, useState } from 'react';
import { ICarBatteryCapacity } from '../../../models/resources/ CarBatteryCapacity';
import { Button, Input, Table, Tag, Popconfirm, message } from 'antd';
import { useSearch } from '../../../hooks/useSearch';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  useActivateBatteryCapacity,
  useBatteryCapacity,
  useDeactivateBatteryCapacity,
  useDeleteBatteryCapacity,
} from './hooks';
import { useEngineTypes } from '../engine_types/hooks';
import { BatteryCapacitiesModal } from './components';
import { Layout } from '../../../components/Layout';
import { ColumnsType } from 'antd/es/table';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';

const { Search } = Input;

const BatteryCapacitiesLayout = () => {
  const { data: batteryCapacities, isLoading: isLoadingBatterCapacities } =
    useBatteryCapacity({
      enabled: true,
    });
  const { data: engineTypes } = useEngineTypes({
    enabled: true,
  });

  const { mutateAsync: deleteBatteryCapacity, isLoading: isDeleting } =
    useDeleteBatteryCapacity();
  const { mutateAsync: activateBatteryCapacity, isLoading: isActivating } =
    useActivateBatteryCapacity();
  const { mutateAsync: deactivateBatteryCapacity, isLoading: isDeactivating } =
    useDeactivateBatteryCapacity();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBatteryCapacity, setCurrentBatteryCapacity] =
    useState<ICarBatteryCapacity | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchBatteryCapacity } = useSearch();
  const [filteredData, setFilteredData] = useState(batteryCapacities);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarBatteryCapacity | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<ICarBatteryCapacity | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateBatteryCapacity(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Battery Capacity deactivated successfully');
          },
        })
      : activateBatteryCapacity(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Battery Capacity activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteBatteryCapacity(id, {
      onSuccess: () => {
        message.success('Battery Capacity deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (batteryCapacity: ICarBatteryCapacity) => {
    setCurrentBatteryCapacity(batteryCapacity);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentBatteryCapacity(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchBatteryCapacity(batteryCapacities, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchBatteryCapacity(batteryCapacities, searchValue);
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(batteryCapacities);
  }, [batteryCapacities]);

  const tableColumns: ColumnsType<ICarBatteryCapacity> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: ICarBatteryCapacity, b: ICarBatteryCapacity) =>
        a.name.localeCompare(b.name),
    },
    {
      title: 'Arabic Name',
      dataIndex: `${batteryCapacities?.map(
        (batteryCapacity: ICarBatteryCapacity) => batteryCapacity.t[0]?.name,
      )}`,
      key: `${batteryCapacities?.map(
        (batteryCapacity: ICarBatteryCapacity) => batteryCapacity.t[0]?.name,
      )}`,
      sorter: (a: ICarBatteryCapacity, b: ICarBatteryCapacity) =>
        a.arabic_name.localeCompare(b.arabic_name),
      render: (text: any, record: ICarBatteryCapacity) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarBatteryCapacity) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICarBatteryCapacity) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
            style={{ fontSize: '1rem', color: 'var(--primary)' }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this battery capacity will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this battery capacity.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Here"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Battery Capacity
        </Button>
      </div>

      <Table
        loading={batteryCapacities?.length === 0 || isLoadingBatterCapacities}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={false}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
      />
      <BatteryCapacitiesModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        currentBatteryCapacity={currentBatteryCapacity}
        setCurrentBatteryCapacity={setCurrentBatteryCapacity}
        engineTypes={engineTypes}
      />
    </Layout>
  );
};

export default BatteryCapacitiesLayout;
