import { Form, Input, Col, Row, Button, Space, message } from 'antd';
import {
  CAR_INTERIOR_COLOR_QUERY_KEY,
  useCreateCarInteriorColor,
  useUpdateCarInteriorColor,
} from '../hooks/useCarInteriorColor';
import { queryClient } from '../../../../App';
import { ICarInteriorColor } from '../utils/apiUtils';

const { Item } = Form;

interface IRentalFormProps {
  handleClose: () => void;
  selectedCarInteriorColor?: ICarInteriorColor;
  isEdit?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCarInteriorColor: React.Dispatch<React.SetStateAction<any>>;
}

export const CarInteriorColorForm = ({
  handleClose,
  selectedCarInteriorColor,
  isEdit,
  setIsOpen,
  setSelectedCarInteriorColor,
}: IRentalFormProps) => {
  //Initial Values
  const initialValues = selectedCarInteriorColor
    ? {
        name: selectedCarInteriorColor.name,
        arabic_name: selectedCarInteriorColor.t?.[0]?.name,
      }
    : undefined;

  //Form
  const [form] = Form.useForm();

  // API Hooks
  const { mutateAsync: createCarInteriorColor, isLoading: isCreating } =
    useCreateCarInteriorColor();

  const { mutateAsync: updateCarInteriorColor, isLoading: isUpdating } =
    useUpdateCarInteriorColor();

  //Functions
  const handleFormSuccess = () => {
    form.resetFields();
    setIsOpen(false);
    setSelectedCarInteriorColor(null);
    queryClient.invalidateQueries({
      queryKey: CAR_INTERIOR_COLOR_QUERY_KEY,
    });
  };
  //Form Logic
  const handleSubmit = async (values: any) => {
    values = { ...values, status: true };

    try {
      createCarInteriorColor(values, {
        onSuccess: () => {
          handleFormSuccess();
          message.success('Interior color added successfully');
        },
      });
    } catch {
      console.log('error');
    }
  };

  const handleEdit = async (values: any) => {
    values = { ...values, status: true };
    try {
      if (selectedCarInteriorColor)
        updateCarInteriorColor(
          {
            id: selectedCarInteriorColor.id,
            data: values,
          },
          {
            onSuccess: () => {
              handleFormSuccess();
              message.success('Interior color edited successfully');
            },
            onError: () => {},
          },
        );
    } catch {
      console.log('error');
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      onFinish={isEdit ? handleEdit : handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name of the interior color',
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic name of the interior color',
              },
            ]}
          >
            <Input placeholder="Arabic Name" size="large" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button danger onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            {isEdit ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
