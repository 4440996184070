import { useState } from 'react';
import { CarBrand } from '../../../models/resources/CarBrand';
import { message, Modal } from 'antd';
import { useCreateDealer, useUpdateDealer } from '../hooks';
import AddEditDealerForm from './AddEditDealerForm';
import { BikeBrand } from '../../../models/resources/bikes/BikeBrand';

interface DealerModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentDealer: any;
  setCurrentDealer: (value: any) => void;
  brands: CarBrand[] | null;
  bikeBrands: BikeBrand[] | null;
  isLoadingBrands: boolean;
}

export const DealerModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentDealer,
  brands,
  bikeBrands,
  setCurrentDealer,
}: DealerModalProps) => {
  const { mutateAsync: createDealer, isLoading: isCreating } =
    useCreateDealer();
  const { mutateAsync: updateDealer, isLoading: isUpdating } =
    useUpdateDealer();

  const [addedLogo, setAddedLogo] = useState<string>('');
  const [removedLogo, setRemovedLogo] = useState<Record<string, string>>({
    url: '',
  });

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      data.whatsapp_number = data.whatsapp_number
        ? `974${data.whatsapp_number.toString()}`
        : null;
      data['add_logo'] = { id: parseInt(addedLogo) };
      delete data.logo;
      const response = await createDealer(data);
      message.success('Dealer Created Successfully');
      setIsFormOpen(false);
      setCurrentDealer(null);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    try {
      data['add_logo'] =
        addedLogo !== '' ? { id: parseInt(addedLogo) } : undefined;
      data['remove_logo'] = removedLogo.url !== '' ? removedLogo : undefined;
      data.whatsapp_number = data.whatsapp_number
        ? `974${data.whatsapp_number.toString()}`
        : null;
      delete data.logo;
      const response = await updateDealer({
        id: currentDealer!?.id,
        request: data,
      });
      setIsFormOpen(false);
      setCurrentDealer(null);
      message.success('Dealer Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Dealer' : 'Add Dealer'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentDealer?.id}
        destroyOnClose={true}
      >
        <AddEditDealerForm
          addedLogo={addedLogo}
          removedLogo={removedLogo}
          setAddedLogo={setAddedLogo}
          setRemovedLogo={setRemovedLogo}
          dealer={currentDealer}
          brands={brands}
          bikeBrands={bikeBrands}
          onSubmit={isEditing ? _edit : _create}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
          isEditing={isEditing}
        />
      </Modal>
    </>
  );
};
