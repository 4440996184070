import Banner, { IBanner } from '../models/ads/Banner';
import { IBannerResource } from '../models/ads/BannerResource';
import { IArticle } from '../models/articles/Article';
import { IBank } from '../models/banks/Bank';
import { BaseCompany } from '../models/companies/BaseCompany';
import { ICarDealer } from '../models/dealers/cars/CarDealer';
import { RentalDealer } from '../models/dealers/rentals/RentalDealer';
import { FeaturedCar } from '../models/listings/cars/FeaturedCar';
import { IRamadanOffer } from '../models/listings/cars/ramadan/ramadanOffer';
import { NewCarLeadsDetail } from '../models/overview/NewCarLead';
import { RamadanOfferLeadsDetail } from '../models/overview/RamadanOfferLead';
import { RentalLeadsDetail } from '../models/overview/RentalLead';
import { CarBatteryCapacity } from '../models/resources/ CarBatteryCapacity';
import { IBikeEngineSize } from '../models/resources/bikes/BikeEngineSize';
import { CarBatteryRange } from '../models/resources/CarBatteryRange';
import { CarBrand } from '../models/resources/CarBrand';
import { CarCylinder } from '../models/resources/CarCylinder';
import { CarDrivetrain } from '../models/resources/CarDrivetrain';
import { CarEngineSize } from '../models/resources/CarEngineSize';
import { CarEngineType } from '../models/resources/CarEngineType';
import { CarExteriorColor } from '../models/resources/CarExteriorColor';
import { CarFeature } from '../models/resources/CarFeature';
import { CarInteriorColor } from '../models/resources/CarInteriorColor';
import { ICarModel } from '../models/resources/CarModel';
import { CarTransmission } from '../models/resources/CarTransmission';
import { ICarTrim } from '../models/resources/CarTrim';
import CarType from '../models/resources/CarType';
import { BikeBrand } from '../models/resources/bikes/BikeBrand';
import { IBikeModel } from '../models/resources/bikes/BikeModel';
import { IBikeTrim } from '../models/resources/bikes/BikeTrim';
import { IBikeType } from '../models/resources/bikes/BikeType';
import { IReviewer } from '../models/reviewers';
import { IReviews } from '../models/reviews';
import { SearchOptionKeys as CarTrimSearchOptionKeys } from '../resources/cars/car_trims/components/TrimsLayout';
import { SearchOptionKeys as BikeTrimSearchOptionKeys } from '../resources/bikes/bike_trims/components/BikeTrimsLayout';
import { IBikeEngineType } from '../models/resources/bikes/BikeEngineType';
import { IBikeCylinders } from '../models/resources/bikes/BikeCylinders';
import { IBikeOptionalFeature } from '../models/resources/bikes/BikeOptionalFeatures';
import { IBikeCondition } from '../models/resources/bikes/BikeCondition';
import { IBikeColors } from '../models/resources/bikes/BikeColors';
import { IBikeTransmissions } from '../models/resources/bikes/BikeTransmission';
import { IBikeBatteryCapacity } from '../models/resources/bikes/BikeBatterCapacity';
import { IBikeBatteryRange } from '../models/resources/bikes/BikeBatteryRange';

export const useSearch = () => {
  const handleSearchCompanies = (companies: BaseCompany[], filter: string) => {
    const filteredData: BaseCompany[] = companies.filter(company =>
      company.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchDealers = (
    dealers: ICarDealer[] | ICarDealer | undefined,
    filter: string,
  ): ICarDealer[] | undefined => {
    if (Array.isArray(dealers)) {
      // If dealers is an array (list of dealers)
      return dealers.filter(dealer =>
        dealer.name.toLowerCase().includes(filter.toLowerCase()),
      );
    } else if (dealers && typeof dealers === 'object') {
      // If dealers is an object (single dealer)
      const singleDealer = dealers as ICarDealer;
      return [singleDealer];
    }
  };

  const handleSearchRentalDealers = (
    rentalDealers: RentalDealer[] | undefined,
    filter: string,
  ) => {
    const filteredData: RentalDealer[] | undefined = rentalDealers?.filter(
      rentalDealers =>
        rentalDealers.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBanks = (banks: IBank[] | undefined, filter: string) => {
    const filteredData: IBank[] | undefined = banks?.filter(bank =>
      bank.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchArticles = (articles: IArticle[], filter: string) => {
    const filteredData: IArticle[] = articles?.filter(article =>
      article.title.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchRamadanOffers = (
    offers: IRamadanOffer[],
    filter: string,
  ) => {
    const filteredData: IRamadanOffer[] = offers?.filter(offer =>
      offer.title.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchReviews = (reviews: IReviews[], filter: string) => {
    const filteredData: IReviews[] = reviews?.filter(review =>
      review.title.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchReviewers = (
    reviewers: IReviewer[] | undefined,
    filter: string,
  ) => {
    const filteredData: IReviewer[] | undefined = reviewers?.filter(reviewers =>
      reviewers.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBanner = (
    banners: IBanner[],
    filter: string,
    bannerResources: IBannerResource[],
  ) => {
    const filteredBannerResourcesIds: number[] = bannerResources
      ?.filter(bannerResource =>
        bannerResource.section_name
          ?.toLowerCase()
          .includes(filter.toLowerCase()),
      )
      .map(bannerResource => bannerResource.id);

    const uniqueBanners: Set<IBanner> = new Set<IBanner>();

    const addUniqueBanners = (bannersToAdd: IBanner[]) => {
      bannersToAdd.forEach(banner => uniqueBanners.add(banner));
    };

    const filteredBannersBySectionName: IBanner[] = banners.filter(banner =>
      filteredBannerResourcesIds.includes(banner.banner_resource_id),
    );
    const filteredBannersByAdvertiser: IBanner[] = banners.filter(banner =>
      banner.advertiser?.toLowerCase()?.includes(filter?.toLowerCase()),
    );

    addUniqueBanners(filteredBannersBySectionName);
    addUniqueBanners(filteredBannersByAdvertiser);

    // Convert the set back to an array
    return Array.from(uniqueBanners);
  };

  const handleSearchFeaturedCar = (
    featuredCars: FeaturedCar[],
    filter: string,
  ) => {
    const filteredData: FeaturedCar[] = featuredCars.filter(featuredCar =>
      featuredCar.title.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchNewCarsBrand = (brands: CarBrand[], filter: string) => {
    const filteredData: CarBrand[] = brands?.filter(brand =>
      brand.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchNewBikesBrand = (brands: BikeBrand[], filter: string) => {
    const filteredData: BikeBrand[] = brands?.filter(brand =>
      brand.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchNewCarsModel = (models: ICarModel[], filter: string) => {
    const filteredData: ICarModel[] = models?.filter(model =>
      model.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchNewBikesModel = (models: IBikeModel[], filter: string) => {
    const filteredData: IBikeModel[] = models?.filter(model =>
      model.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBatteryCapacity = (
    batteryCapacities: CarBatteryCapacity[],
    filter: string,
  ) => {
    const filteredData: CarBatteryCapacity[] = batteryCapacities?.filter(
      batteryCapacity =>
        batteryCapacity.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeBatteryCapacity = (
    bikeBatteryCapacities: IBikeBatteryCapacity[],
    filter: string,
  ) => {
    const filteredData: IBikeBatteryCapacity[] = bikeBatteryCapacities?.filter(
      bikeBatteryCapacity =>
        bikeBatteryCapacity.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBatteryRange = (
    batteryRanges: CarBatteryRange[],
    filter: string,
  ) => {
    const filteredData: CarBatteryRange[] = batteryRanges?.filter(
      batteryRange =>
        batteryRange.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeBatteryRange = (
    bikeBatteryRanges: IBikeBatteryRange[],
    filter: string,
  ) => {
    const filteredData: IBikeBatteryRange[] = bikeBatteryRanges?.filter(
      bikeBatteryRange =>
        bikeBatteryRange.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarTypes = (types: CarType[], filter: string) => {
    const filteredData: CarType[] = types?.filter(type =>
      type.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarBrand = (
    displayedBrands: CarBrand[],
    filter: string,
  ) => {
    const filteredData: CarBrand[] = displayedBrands?.filter(brand =>
      brand.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarModel = (models: ICarModel[], filter: string) => {
    const filteredData: ICarModel[] = models?.filter(model =>
      model.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarCylinder = (
    cylinders: CarCylinder[],
    filter: string,
  ) => {
    const trimmedFilter = filter.toLowerCase().replace(/\s/g, '');

    const filteredData: CarCylinder[] = cylinders?.filter(cylinder => {
      const trimmedCylinderName = cylinder.name
        .toLowerCase()
        .replace(/\s/g, '');
      return trimmedCylinderName.includes(trimmedFilter);
    });
    return filteredData;
  };

  const handleSearchBikeCylinder = (
    bikeCylinders: IBikeCylinders[],
    filter: string,
  ) => {
    // Remove spaces and convert to lowercase
    const trimmedFilter = filter.toLowerCase().replace(/\s/g, '');

    const filteredData: IBikeCylinders[] = bikeCylinders?.filter(cylinder => {
      const trimmedCylinderName = cylinder.name
        .toLowerCase()
        .replace(/\s/g, '');
      return trimmedCylinderName.includes(trimmedFilter);
    });
    return filteredData;
  };

  const handleSearchCarTransmission = (
    transmissions: CarTransmission[],
    filter: string,
  ) => {
    const filteredData: CarTransmission[] = transmissions?.filter(
      transmission =>
        transmission.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarDriveTrain = (
    drivetrains: CarDrivetrain[],
    filter: string,
  ) => {
    const filteredData: CarDrivetrain[] = drivetrains?.filter(drivetrain =>
      drivetrain.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarEngineSize = (
    engineSizes: CarEngineSize[],
    filter: string,
  ) => {
    const filteredData: CarEngineSize[] = engineSizes?.filter(engineSize =>
      engineSize.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeEngineSize = (
    engineSizes: IBikeEngineSize[],
    filter: string,
  ) => {
    const filteredData: IBikeEngineSize[] = engineSizes?.filter(engineSize =>
      engineSize.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeCondition = (
    conditions: IBikeCondition[],
    filter: string,
  ) => {
    const filteredData: IBikeCondition[] = conditions?.filter(condition =>
      condition.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarEngineType = (
    engineTypes: CarEngineType[],
    filter: string,
  ) => {
    const filteredData: CarEngineType[] = engineTypes?.filter(engineType =>
      engineType.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeEngineType = (
    bikeEngineTypes: IBikeEngineType[],
    filter: string,
  ) => {
    const filteredData: IBikeEngineType[] = bikeEngineTypes?.filter(
      bikeEngineType =>
        bikeEngineType.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarTrim = (
    trims: ICarTrim[],
    searchValue: string,
    searchOption: CarTrimSearchOptionKeys,
  ) => {
    if (!searchValue) return trims;
    if (searchOption === 'trimName') {
      return trims.filter(trim =>
        trim.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
    if (searchOption === 'brandName') {
      return trims.filter(trim =>
        trim.model.brand.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
    if (searchOption === 'modelName') {
      return trims.filter(trim =>
        trim.model.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
  };

  const handleSearchCarFeature = (features: CarFeature[], filter: string) => {
    const filteredData: CarFeature[] = features?.filter(feature =>
      feature.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeFeature = (
    features: IBikeOptionalFeature[],
    filter: string,
  ) => {
    const filteredData: IBikeOptionalFeature[] = features?.filter(feature =>
      feature.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarInteriorColor = (
    interiorColors: CarInteriorColor[],
    filter: string,
  ) => {
    const filteredData: CarInteriorColor[] = interiorColors?.filter(
      interiorColor =>
        interiorColor.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchCarExteriorColor = (
    exteriorColors: CarExteriorColor[],
    filter: string,
  ) => {
    const filteredData: CarExteriorColor[] = exteriorColors.filter(
      exteriorColor =>
        exteriorColor.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeColor = (bikeColors: IBikeColors[], filter: string) => {
    const filteredData: IBikeColors[] = bikeColors?.filter(bikeColor =>
      bikeColor.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeTransmission = (
    bikeTransmissions: IBikeTransmissions[],
    filter: string,
  ) => {
    const filteredData: IBikeTransmissions[] = bikeTransmissions?.filter(
      bikeTransmission =>
        bikeTransmission.name.toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handelSearchLeads = (
    leads: (NewCarLeadsDetail | RentalLeadsDetail | RamadanOfferLeadsDetail)[],
    searchOption: 'name' | 'email',
    filter: string,
  ) => {
    const filteredData: (
      | NewCarLeadsDetail
      | RentalLeadsDetail
      | RamadanOfferLeadsDetail
    )[] = leads.filter(lead =>
      lead[searchOption].toLowerCase().includes(filter.toLowerCase()),
    );
    return filteredData;
  };

  const handleSearchBikeTypes = (types: IBikeType[], filter: string) => {
    const filteredData: IBikeType[] = types?.filter(type => {
      const nameMatches = type.name
        .toLowerCase()
        .includes(filter.toLowerCase());

      const tMatches = type.t.some(t =>
        t.name.toLowerCase().includes(filter.toLowerCase()),
      );

      return nameMatches || tMatches;
    });
    return filteredData;
  };

  const handleSearchBikeBrand = (
    displayedBrands: BikeBrand[],
    filter: string,
  ) => {
    const filteredData: BikeBrand[] = displayedBrands?.filter(brand => {
      const nameMatches = brand.name
        .toLowerCase()
        .includes(filter.toLowerCase());

      const tMatches = brand.t.some(t =>
        t.name.toLowerCase().includes(filter.toLowerCase()),
      );

      return nameMatches || tMatches;
    });
    return filteredData;
  };

  const handleSearchBikeModel = (models: IBikeModel[], filter: string) => {
    const filteredData: IBikeModel[] = models?.filter(model => {
      const nameMatches = model.name
        .toLowerCase()
        .includes(filter.toLowerCase());

      const tMatches = model.t.some(t =>
        t.name.toLowerCase().includes(filter.toLowerCase()),
      );

      return nameMatches || tMatches;
    });
    return filteredData;
  };

  const handleSearchBikeTrim = (
    trims: IBikeTrim[],
    searchValue: string,
    searchOption: BikeTrimSearchOptionKeys,
  ) => {
    if (!searchValue) return trims;
    if (searchOption === 'trimName') {
      return trims.filter(trim => {
        const nameMatches = trim.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());

        const tMatches = trim.t.some(t =>
          t.name.toLowerCase().includes(searchValue.toLowerCase()),
        );

        return nameMatches || tMatches;
      });
    }
    if (searchOption === 'brandName') {
      return trims.filter(trim =>
        trim.bike_model.brand.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
      );
    }
    if (searchOption === 'modelName') {
      return trims.filter(trim =>
        trim.bike_model.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
  };

  return {
    handleSearchCompanies,
    handleSearchDealers,
    handleSearchArticles,
    handleSearchBanner,
    handleSearchFeaturedCar,
    handleSearchNewCarsBrand,
    handleSearchNewCarsModel,
    handleSearchBatteryCapacity,
    handleSearchBatteryRange,
    handleSearchCarTypes,
    handleSearchCarBrand,
    handleSearchCarModel,
    handleSearchCarCylinder,
    handleSearchCarDriveTrain,
    handleSearchCarEngineSize,
    handleSearchCarEngineType,
    handleSearchCarTrim,
    handleSearchCarFeature,
    handleSearchCarInteriorColor,
    handleSearchCarExteriorColor,
    handleSearchCarTransmission,
    handleSearchRentalDealers,
    handelSearchLeads,
    handleSearchBanks,
    handleSearchReviews,
    handleSearchReviewers,
    handleSearchRamadanOffers,
    handleSearchBikeEngineSize,
    handleSearchBikeBrand,
    handleSearchBikeEngineType,
    handleSearchBikeCylinder,
    handleSearchBikeFeature,
    handleSearchBikeCondition,
    handleSearchBikeColor,
    handleSearchBikeTransmission,
    handleSearchBikeBatteryCapacity,
    handleSearchBikeBatteryRange,
    handleSearchBikeTypes,
    handleSearchBikeModel,
    handleSearchBikeTrim,
    handleSearchNewBikesBrand,
    handleSearchNewBikesModel,
  };
};
