import api from '../../../api/apiIndex';

const bannersEndpointV2 = 'v2/admin/banner-resources';

export enum BannerContentType {
  Video = 'Video',
  Image = 'Image',
}

export enum BannerSection {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B1 = 'B1',
  C2 = 'C2',
  C3 = 'C3',
  CP = 'CP',
  D2 = 'D2',
  E1 = 'E1',
  E2 = 'E2',
}

export enum BannerPlacement {
  Portal = 'Portal',
  MobileApplication = 'MobileApp',
  MobileApplicationSwipe = 'MobileAppSwipe',
}

type AddImage = {
  id: number | undefined;
};

type RemoveImage = {
  url: string | undefined;
};

export interface IBannerPayload {
  placement: string;
  section_name: string;
  content_type: string;
  show_default_image?: boolean;
  add_images?: AddImage[];
  remove_images?: RemoveImage[];
}

export const getBanners = async () => {
  try {
    const response = await api.get(bannersEndpointV2);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBanner = async (data: IBannerPayload) => {
  try {
    const response = await api.post(bannersEndpointV2, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateBanner = async (id: number, data: IBannerPayload) => {
  try {
    const response = await api.put(`${bannersEndpointV2}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteBanner = async (id: number) => {
  try {
    const response = await api.delete(`${bannersEndpointV2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
