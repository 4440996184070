import { Tag, Button, Table, Popconfirm, message } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  useActivateCity,
  useCities,
  useDeactivateCity,
  useDeleteCity,
} from './hooks';
import { useState } from 'react';
import { ICity } from '../../models/countries/City';
import { ColumnsType } from 'antd/es/table';
import InactiveIcon from '../../components/icons/InactiveIcon';
import ActiveIcon from '../../components/icons/ActiveIcon';
import { Layout } from '../../components/Layout';
import { CitiesModal } from './components/citiesModal';

export const CitiesLayout = () => {
  const { data: cities, isLoading: isCitiesLoading } = useCities({
    enabled: true,
  });

  const { mutateAsync: deleteCity, isLoading: isDeleting } = useDeleteCity();
  const { mutateAsync: activateCity, isLoading: isActivating } =
    useActivateCity();
  const { mutateAsync: deactivateCity, isLoading: isDeactivating } =
    useDeactivateCity();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCity, setCurrentCity] = useState<ICity | null>(null);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICity | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<ICity | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateCity(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('City deactivated successfully');
          },
        })
      : activateCity(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('City activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteCity(id, {
      onSuccess: () => {
        message.success('City deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (city: ICity) => {
    setCurrentCity(city);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentCity(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const tableColumns: ColumnsType<ICity> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${cities?.map((city: ICity) => city?.t?.[0]?.name)}`,
      key: `${cities?.map((city: ICity) => city?.t?.[0]?.name)}`,
      render(text: any, record) {
        return record.t[0]?.name;
      },
    },
    {
      title: 'Country',
      dataIndex: 'countries',
      key: 'countries',
      render: countries => countries.name,
    },
    {
      title: 'Currency',
      dataIndex: 'currencies',
      key: 'currencies',
      render: currencies => currencies?.name,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICity) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICity) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this city will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this city.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={openEmptyForm}>
          Add City
        </Button>
      </div>
      <Table
        loading={cities?.length === 0 || isCitiesLoading}
        dataSource={cities}
        columns={tableColumns}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
        pagination={false}
      />
      <CitiesModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        currentCity={currentCity}
        setCurrentCity={setCurrentCity}
      />
    </Layout>
  );
};
