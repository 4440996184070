import { Modal } from 'antd';
import { RejectUsedBikesForm } from './';
import { BikeStatus } from '../../constants';

type RejectUsedBikesModalProps = {
  updateStatusFunction: (status: BikeStatus, reason: string) => Promise<void>;
  onClose: () => void;
  isVisible: boolean;
  initialReason?: string;
};

export const RejectUsedBikesModal = ({
  updateStatusFunction,
  onClose,
  isVisible,
  initialReason,
}: RejectUsedBikesModalProps) => {
  const _rejectUsedBikes = async (data: any) => {
    await updateStatusFunction(BikeStatus.rejected, data.reason);
    onClose();
  };

  const initialValues = {
    reason: initialReason,
  };

  return (
    <Modal
      title="Reject Used Bikes"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={1200}
    >
      <RejectUsedBikesForm
        onSubmit={_rejectUsedBikes}
        onClose={onClose}
        initialValues={initialValues}
      />
    </Modal>
  );
};
