import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { generateRoute } from './UprotectedRoute';

type ProtectedRouteProps = {
  allowedRoles?: Array<string>;
  disabled?: boolean;
};

const ProtectedRoute = ({ allowedRoles, disabled }: ProtectedRouteProps) => {
  const { isAuthenticated, userInfo } = useAuth();
  const location = useLocation();

  return !isAuthenticated ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : userInfo && allowedRoles?.includes(userInfo?.group) && !disabled ? (
    <Outlet />
  ) : userInfo ? (
    <Navigate
      to={generateRoute({ userInfo })}
      state={{ from: location }}
      replace
    />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
