import { Form, Row, Col, Input, Button, Space, Select } from 'antd';
import { ICountry } from '../../../models/countries/Country';

interface CountriesFormProps {
  country: ICountry | null;
  isEditing: boolean;
  onSubmit: (values: any) => void;
  onClose: () => void;
  isLoading: boolean;
}

const { Item } = Form;

export const CountriesForm = ({
  country,
  isEditing,
  onSubmit,
  onClose,
  isLoading,
}: CountriesFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: country?.name,
    arabic_name: country?.t?.[0]?.name,
    code: country?.code,
    area_code: country?.area_code,
  };

  const resetForm = () => {
    form.resetFields();
  };
  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter arabic name',
              },
            ]}
          >
            <Input placeholder="Arabic Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Country Code"
            name="code"
            rules={[
              {
                required: true,
                message: 'Please enter country code',
              },
            ]}
          >
            <Input placeholder="Country Code" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Country Area Code"
            name="area_code"
            rules={[
              {
                required: true,
                message: 'Please enter country area code',
              },
            ]}
          >
            <Input placeholder="Country Area Code" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit Country' : 'Add Country'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
