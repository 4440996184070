import { Form, Row, Col, Input, Button, Space, Select } from 'antd';
import { ICity } from '../../../models/countries/City';
import { useCountries } from '../../../countries/hooks/useCountries';
import { useCurrencies } from '../../currencies/hooks';
import { ICurrency } from '../../../models/countries/Currency';

interface CitiesFormProps {
  city: ICity | null;
  isEditing: boolean;
  onSubmit: (values: any) => void;
  onClose: () => void;
  isLoading: boolean;
}

const { Item } = Form;

export const CitiesForm = ({
  city,
  isEditing,
  onSubmit,
  onClose,
  isLoading,
}: CitiesFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: city?.name,
    arabic_name: city?.t?.[0]?.name,
    country_id: city?.country_id,
    currency_id: city?.currency_id,
  };

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies({
    enabled: true,
  });

  const resetForm = () => {
    form.resetFields();
  };
  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter arabic name',
              },
            ]}
          >
            <Input placeholder="Arabic Name" />
          </Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Item
            label="Country"
            name="country_id"
            rules={[
              {
                required: true,
                message: 'Please select country',
              },
            ]}
          >
            <Select loading={isLoadingCountries} placeholder="Select Country">
              {countries?.map(country => (
                <Select.Option key={country.id} value={country.id}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Currency"
            name="currency_id"
            rules={[
              {
                required: true,
                message: 'Please select currency',
              },
            ]}
          >
            <Select loading={isLoadingCurrencies} placeholder="Select Currency">
              {currencies?.map((currency: ICurrency) => (
                <Select.Option key={currency.id} value={currency.id}>
                  {currency.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit City' : 'Add City'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
