import api from '../../../../api/apiIndex';

const bikeTrimsEndpoint = '/v2/admin/resources/bikes/trims';

export interface IBikeTrimPayload {
  name: string;
  arabic_name: string;
  model_id: number;
}

export interface IGetBikeTrims {
  model_id?: number;
  active?: boolean;
}

export const getTrims = async ({ model_id, active }: IGetBikeTrims) => {
  const response = await api.get(bikeTrimsEndpoint, {
    params: {
      model_id,
      active,
    },
  });
  return response.data;
};

export const createBikeTrim = async (data: IBikeTrimPayload) => {
  const response = await api.post(bikeTrimsEndpoint, data);
  return response.data;
};

export const updateBikeTrim = async (id: number, data: IBikeTrimPayload) => {
  const response = await api.put(`${bikeTrimsEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeTrim = async (id: number) => {
  const response = await api.delete(`${bikeTrimsEndpoint}/${id}`);
  return response.data;
};

export const activateBikeTrim = async (id: number) => {
  const response = await api.put(`${bikeTrimsEndpoint}/activate/${id}`);
  return response.data;
};

export const deactivateBikeTrim = async (id: number) => {
  const response = await api.put(`${bikeTrimsEndpoint}/deactivate/${id}`);
  return response.data;
};
