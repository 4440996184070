import { useLocation, useParams } from 'react-router-dom';
import { useGetBikeModelBySlug } from '../../../../resources/bikes/bike_models/hooks';
import { useState } from 'react';
import { INewBike } from '../../../../models/listings/bikes/newBikes';
import { Spin } from 'antd';
import {
  HeaderComponent,
  ModelDisplayComponent,
} from '../../../cars/new_cars/components';
import { Layout } from '../../../../components/Layout';
import { NewBikeDealList } from './NewBikeDealList';
import { NewBikeDealModal } from './NewBikeDealModal';
import { useGetBikeDeals } from '../hooks';
import { TabsCountrySwitcher } from '../../../../components/TabsCountrySwitcher';

export const NewBikeDealLayout = () => {
  const location = useLocation();
  const country = location.search.split('=')[1];
  const [Country, setCountry] = useState(country);
  const { modelSlug } = useParams();
  const { data: model, isLoading: isLoadingModel } = useGetBikeModelBySlug(
    modelSlug as string,
  );
  const { data: deals, isLoading: isLoadingDeals } = useGetBikeDeals(
    model?.slug,
    Country,
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [displayedImage, setDisplayedImage] = useState<string>();
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditingDeal, setIsEditingDeal] = useState(false);
  const [currentDeal, setCurrentDeal] = useState<any>();

  const updateDisplayedImage = (image: string) => {
    setDisplayedImage(image);
    setCurrentImageIndex(model?.image.indexOf(image) || 0);
  };

  const openEmptyForm = () => {
    setIsFormOpen(true);
    setIsEditingDeal(false);
    setCurrentDeal(null);
  };

  const onEditClick = (deal: INewBike) => {
    setCurrentDeal(deal);
    setIsEditingDeal(true);
    setIsFormOpen(true);
  };

  const onDeleteClick = (deal: INewBike) => {
    setCurrentDeal(deal);
  };

  return (
    <Layout>
      {isLoadingModel ? (
        <div
          className="w-full p-5 flex justify-content-center align-items-center"
          style={{ height: '400px' }}
        >
          <Spin />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '30px',
            padding: '10px',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '30px',
            }}
          >
            <TabsCountrySwitcher setCountry={setCountry} country={Country} />
            <HeaderComponent openEmptyForm={openEmptyForm} model={model} />
            <ModelDisplayComponent
              setCurrentImageIndex={setCurrentImageIndex}
              currentImageIndex={currentImageIndex}
              displayedImage={displayedImage}
              modelImages={model?.image ? model?.image : [model?.image]}
              onImageClick={updateDisplayedImage}
              model={model}
              selectedLanguage={selectedLanguage}
              onLanguageChange={e =>
                setSelectedLanguage(prev => e.value || prev)
              }
              isLoading={isLoadingModel}
            />
          </div>
          <NewBikeDealList
            deals={deals}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            loading={isLoadingDeals}
          />
          <NewBikeDealModal
            model={model}
            currentDeal={currentDeal}
            isEditingDeal={isEditingDeal}
            isFormOpen={isFormOpen}
            setCurrentDeal={setCurrentDeal}
            setIsFormOpen={setIsFormOpen}
          />
        </div>
      )}
    </Layout>
  );
};
