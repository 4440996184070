import { Modal, message } from 'antd';
import { useCreateBikeEngineSize, useUpdateBikeEngineSize } from '../hooks';
import { AddEditBikeEngineSizeForm } from './AddEditBikeEngineSizeForm';
import { IBikeEngineSizePayload } from '../apis';
import { IBikeEngineSize } from '../../../../models/resources/bikes/BikeEngineSize';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';

interface BikeEngineSizeModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentBikeEngineSize: IBikeEngineSize | null;
  setCurrentBikeEngineSize: (value: IBikeEngineSize | null) => void;
  isLoading: boolean;
  bikeEngineTypes: IBikeEngineType[] | null;
}

export const BikeEngineSizeModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBikeEngineSize,
  bikeEngineTypes,
  setCurrentBikeEngineSize,
}: BikeEngineSizeModalProps) => {
  const { mutateAsync: createBikeEngineSize, isLoading: isCreating } =
    useCreateBikeEngineSize();
  const { mutateAsync: updateBikeEngineSize, isLoading: isUpdating } =
    useUpdateBikeEngineSize();

  const _create = async (data: IBikeEngineSizePayload) => {
    if (data == null) return;
    const response = await createBikeEngineSize(data);
    message.success('Engine Size Created Successfully');
    setIsFormOpen(false);
    setCurrentBikeEngineSize(null);
    return response;
  };

  const _edit = async (data: IBikeEngineSizePayload) => {
    const response = await updateBikeEngineSize({
      id: currentBikeEngineSize!.id,
      data,
    });
    setIsFormOpen(false);
    setCurrentBikeEngineSize(null);
    message.success('Engine Size Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Engine Size' : 'Add Engine Size'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBikeEngineSize?.id}
        destroyOnClose
      >
        <AddEditBikeEngineSizeForm
          bikeEngineSize={currentBikeEngineSize}
          bikeEngineTypes={bikeEngineTypes}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
          onSubmit={isEditing ? _edit : _create}
          key={currentBikeEngineSize?.id}
        />
      </Modal>
    </>
  );
};
