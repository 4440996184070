import { UserGroups } from '../../../../common/constants';
import { BikeEngineSizeLayout } from '../../../../resources/bikes/engine_sizes';
import { RouteType } from '../../../routes/routesConfig';

const BikeEngineSize = () => {
  return <BikeEngineSizeLayout />;
};

const bikeEngineSizeRoute: RouteType = {
  element: <BikeEngineSize />,
  state: 'engine-size',
  index: true,
  path: '/resources/bikes/engine-size',
  sideBarProps: {
    displayText: 'Engine Size',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeEngineSize, bikeEngineSizeRoute };
