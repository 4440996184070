import { useEffect, useState } from 'react';
import { ICylinders } from '../../../models/resources/CarCylinder';
import { Input } from 'antd';
import { useSearch } from '../../../hooks/useSearch';
import { Tag, Button, Table, message, Popconfirm } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  useActivateCylinder,
  useCylinders,
  useDeactivateCylinder,
  useDeleteCylinder,
} from './hooks';
import { useEngineTypes } from '../engine_types/hooks';
import { CarCylindersModal } from './components';
import { Layout } from '../../../components/Layout';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import { ColumnsType } from 'antd/es/table';

const { Search } = Input;

const CarCylindersLayout = () => {
  const { data: cylinders, isLoading: isLoadingCylinders } = useCylinders({
    enabled: true,
  });
  const { data: engineTypes } = useEngineTypes({
    enabled: true,
  });

  const { mutateAsync: deleteCylinder, isLoading: isDeleting } =
    useDeleteCylinder();
  const { mutateAsync: activateCylinder, isLoading: isActivating } =
    useActivateCylinder();
  const { mutateAsync: deactivateCylinder, isLoading: isDeactivating } =
    useDeactivateCylinder();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCylinder, setCurrentCylinder] = useState<ICylinders | null>(
    null,
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchCarCylinder } = useSearch();
  const [filteredData, setFilteredData] = useState(cylinders);

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICylinders | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<ICylinders | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateCylinder(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Cylinder deactivated successfully');
          },
        })
      : activateCylinder(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Cylinder activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteCylinder(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Cylinder deleted successfully');
      },
    });
  };

  const onEditButtonPress = (engineType: ICylinders) => {
    setCurrentCylinder(engineType);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentCylinder(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchCarCylinder(cylinders, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchCarCylinder(cylinders, searchValue);
    setFilteredData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(cylinders);
  }, [cylinders]);

  const tableColumns: ColumnsType<ICylinders> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${cylinders && cylinders[0]?.t && cylinders[0]?.t[0]?.name}}`,
      key: `${cylinders && cylinders[0]?.t && cylinders[0]?.t[0]?.name}}`,
      render: (text: any, record: ICylinders) => record.t[0].name,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICylinders) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICylinders) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this cylinder will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this cylinder.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Cylinders"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Cylinder
        </Button>
      </div>
      <Table
        loading={cylinders?.length === 0 || isLoadingCylinders}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={false}
        rowKey="id"
        scroll={{ x: 1300, y: 720 }}
      />
      <CarCylindersModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        currentCylinder={currentCylinder}
        setCurrentCylinder={setCurrentCylinder}
        engineTypes={engineTypes}
      />
    </Layout>
  );
};

export default CarCylindersLayout;
