import { CompanyType } from '../../banking_insurance/bankingInsuranceApi';
import { useState, useEffect } from 'react';

interface EmptyStateProps {
  type:
    | CompanyType
    | 'dealers'
    | 'articles'
    | 'banners'
    | 'featured cars'
    | 'new cars'
    | 'batteries'
    | 'types'
    | 'brands'
    | 'models'
    | 'cylinders'
    | 'drivetrains'
    | 'engines'
    | 'trims'
    | 'features'
    | 'colors'
    | 'transmission'
    | 'car optional features'
    | 'banks'
    | 'reviews'
    | 'reviewers'
    | 'new bikes';
}

export const EmptyState = ({ type }: EmptyStateProps) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    switch (type) {
      case CompanyType.Bank:
        setTitle('banks');
        break;
      case CompanyType.Insurance:
        setTitle('insurers');
        break;
      default:
        setTitle(type);
        break;
    }
  }, [type]);

  return (
    <div className="flex justify-content-center w-full">
      <h3>No {title} found</h3>
    </div>
  );
};
