import { useState } from 'react';
import AddEditBikeModelForm from './AddEditBikeModelForm';
import { Modal, message } from 'antd';
import { useCreateBikeModel, useEditBikeModel } from '../hooks';
import {
  IBikeModel,
  IBikeModelImage,
} from '../../../../models/resources/bikes/BikeModel';
import { UploadFileStatus } from 'antd/es/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

interface ModelModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  onClose: () => void;
  isEditing: boolean;
  currentModel: IBikeModel | null;
  setCurrentModel: (value: IBikeModel | null) => void;
}

export type CustomUploadFileStatus = UploadFileStatus | 'old';

export interface IBikeModelAddedImages extends IBikeModelImage {
  file: UploadRequestOption['file'];
  status: CustomUploadFileStatus;
}

export const BikeModelModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentModel,
  setCurrentModel,
  onClose,
}: ModelModalProps) => {
  const [addedImages, setAddedImages] = useState<IBikeModelAddedImages[]>([]);
  const [removedImages, setRemovedImages] = useState<
    Array<Record<string, string>>
  >([]);

  const { mutateAsync: createModel, isLoading: isCreating } =
    useCreateBikeModel();
  const { mutateAsync: updateModel, isLoading: isUpdating } =
    useEditBikeModel();

  const _create = async (data: any) => {
    if (data == null) return;
    delete data.image;
    delete data.cover;
    data['add_images'] = addedImages
      .filter(image => image.status === 'done')
      .map(image => ({ id: image.id }));
    const response = await createModel(data);
    setIsFormOpen(false);
    setCurrentModel(null);
    message.success('Bike Model Created Successfully');
    return response;
  };

  const _edit = async (data: any) => {
    if (currentModel == null) return;
    delete data.image;
    delete data.cover;
    data['add_images'] = addedImages
      .filter(image => image.status === 'done')
      .map(image => ({ id: image.id }));
    data['remove_images'] = removedImages;
    const response = await updateModel({
      id: currentModel.id,
      data,
    });
    setIsFormOpen(false);
    setCurrentModel(null);
    message.success('Bike Model Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Bike Model' : 'Add Bike Model'}
        onCancel={onClose}
        open={isFormOpen}
        footer={null}
        width={1200}
        style={{ top: 20 }}
        key={currentModel?.id}
        destroyOnClose
      >
        <AddEditBikeModelForm
          isEditing={isEditing}
          model={currentModel}
          onClose={onClose}
          addedImages={addedImages}
          onSubmit={isEditing ? _edit : _create}
          removedImages={removedImages}
          setAddedImages={setAddedImages}
          setRemovedImages={setRemovedImages}
          isLoading={isCreating || isUpdating}
        />
      </Modal>
    </>
  );
};
