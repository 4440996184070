import { useEffect, useState } from 'react';
import { IArticle, IArticleImages } from '../../models/articles/Article';
import { Option } from '../../components/inputs/DropDown';
import { ICity } from '../../models/countries/City';
import type {
  UploadRequestError,
  UploadRequestOption,
} from 'rc-upload/lib/interface';
import {
  Form,
  Row,
  Col,
  Space,
  Switch,
  Typography,
  Input,
  Select,
  Button,
} from 'antd';
import FileUpload from '../../components/inputs/FileUpload';
import { useCountries } from '../../countries/hooks/useCountries';
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import { Editor } from 'primereact/editor';
import { uploadImage } from '../../common/utils';
import { set } from 'lodash';
import { AxiosError } from 'axios';

type CustomUploadFileStatus = UploadFileStatus | 'old';

type AddEditArticleFormProps = {
  onClose: () => void;
  isEditing: boolean;
  article: IArticle;
  onSubmit: (article: IArticle) => void;
  isLoading: boolean;
  addedImages: any[];
  removedImages: any[];
  setAddedImages: (images: IArticleImages[]) => void;
  setRemovedImages: (urls: Array<Record<string, string>>) => void;
};

export const AddEditArticleForm = ({
  onClose,
  isEditing,
  article,
  onSubmit,
  isLoading,
  addedImages,
  removedImages,
  setAddedImages,
  setRemovedImages,
}: AddEditArticleFormProps) => {
  const { Text } = Typography;
  const { Item } = Form;
  const [form] = Form.useForm();

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [cityOptions, setCityOptions] = useState<Array<Option>>();

  const countryId = Form.useWatch('country_id', form);

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const maxTitleLength = 119;

  const initialImages = article?.image
    ? [
        {
          uid: `${article?.id}`,
          name: article?.image?.split('/').pop(),
          status: 'old',
          url: article?.image,
        },
      ]
    : [];

  const initialThumbnail = article?.thumbnail
    ? [
        {
          uid: `${article?.id}`,
          name: article?.thumbnail?.split('/').pop(),
          status: 'old',
          url: article?.thumbnail,
        },
      ]
    : [];

  const initialValues = {
    title: article?.title,
    arabic_title: article?.t[0]?.title,
    description: article?.description,
    arabic_description: article?.t[0]?.description,
    image: initialImages,
    thumbnail: initialThumbnail,
    is_featured: article?.is_featured ? true : false,
    country_id: countries?.find(country =>
      country.cities?.find(city => city?.id === article?.city_id),
    )?.id,
    city_id: article?.city_id,
  };

  const [imageFileList, setImageFileList] = useState<UploadFile[]>(
    initialImages as UploadFile[],
  );
  const [thumbnailFileList, setThumbnailFileList] = useState<UploadFile[]>(
    initialThumbnail as UploadFile[],
  );

  const imageUpload = async ({
    onSuccess,
    onProgress,
    file,
    data,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'article-image',
        onProgress: onProgress,
        extraBodyData: {
          type: data?.type,
        },
      });
      if (onSuccess) onSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedImages([...addedImages, addedImage]);
      setIsUploadingImage(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  const removeImage = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedImages([
        ...removedImages,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedImages(addedImages.filter(image => image.file.uid !== file.uid));
    }
  };

  const resetForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [article, form, countries]);

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
        setImageFileList([]);
        setThumbnailFileList([]);
        setAddedImages([]);
        setRemovedImages([]);
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row>
        <Col span={6}>
          <Space wrap align="start">
            <div
              style={{
                paddingTop: '5px',
              }}
            >
              <Text>Featured</Text>
            </div>
            <Item name="is_featured" valuePropName="checked">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                title="Featured"
                checked={article?.is_featured}
              />
            </Item>
          </Space>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: 'Please enter a tite' },
              {
                max: maxTitleLength,
                message: `Title should be less than ${
                  maxTitleLength + 1
                } characters`,
              },
            ]}
          >
            <Input placeholder="Please enter a title" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_title"
            label="Arabic Title"
            rules={[
              { required: true, message: 'Please enter an arabic title' },
              {
                max: maxTitleLength,
                message: `Arabic Title should be less than ${
                  maxTitleLength + 1
                } characters`,
              },
            ]}
          >
            <Input placeholder="Please enter an arabic title" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Country"
            name="country_id"
            rules={[
              {
                required: true,
                message: 'Please select the country',
              },
            ]}
          >
            <Select
              disabled={!countries || isLoadingCountries}
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              onChange={() => {
                form.setFieldsValue({
                  city_id: undefined,
                });
              }}
              size="large"
            >
              {countries?.map(country => {
                return (
                  <Select.Option key={country?.id} value={country?.id}>
                    {country?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="City"
            name="city_id"
            rules={[
              {
                required: true,
                message: 'Please select the city',
              },
            ]}
          >
            <Select
              disabled={!countryId || isLoadingCountries}
              showSearch
              placeholder="Select a city"
              optionFilterProp="children"
              size="large"
            >
              {cityOptions?.map(city => {
                return (
                  <Select.Option key={city.code} value={city.code}>
                    {city.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter a description',
              },
            ]}
          >
            <Editor
              value={form.getFieldValue('description')}
              placeholder="Description"
              onTextChange={(e: any) =>
                form.setFieldsValue({ description: e.htmlValue })
              }
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={24} wrap>
        <Col span={24}>
          <Item
            label="Arabic Description"
            name="arabic_description"
            rules={[
              {
                required: true,
                message: 'Please enter an Arabic Description  ',
              },
            ]}
          >
            <Editor
              value={form.getFieldValue('arabic_description')}
              placeholder="Arabic Description"
              onTextChange={(e: any) =>
                form.setFieldsValue({ arabic_description: e.htmlValue })
              }
            />
          </Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        wrap
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          paddingTop: '1rem',
          marginLeft: '15%',
        }}
      >
        <Col span={8}>
          <FileUpload
            fileList={thumbnailFileList}
            label="Thumbnail"
            maxNbFiles={1}
            name="thumbnail"
            setFileList={setThumbnailFileList}
            customRequest={props =>
              imageUpload({ ...props, data: { type: 'thumbnail' } })
            }
            onRemove={removeImage}
            requiredMessage="Please upload a thumbnail image."
          />
        </Col>
        <Col span={8}>
          <FileUpload
            fileList={imageFileList}
            label="Image"
            maxNbFiles={1}
            name="image"
            setFileList={setImageFileList}
            customRequest={props =>
              imageUpload({ ...props, data: { type: 'image' } })
            }
            onRemove={removeImage}
            requiredMessage="Please upload an image."
          />
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
              setImageFileList([]);
              setThumbnailFileList([]);
              setAddedImages([]);
              setRemovedImages([]);
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isUploadingImage}
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
