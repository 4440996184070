import React, { useEffect } from 'react';
import { NewCarLeadsDetail } from '../../../models/overview/NewCarLead';
import { useGetAllDealers } from '../../../dealers/Automotive/hooks';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
import { ICarModel } from '../../../models/resources/CarModel';
import { leadContactStatuses } from '../../constants';
import { CustomizedRequiredMark } from '../../../components/misc/RequiredMark';
import { RamadanOfferLeadsDetail } from '../../../models/overview/RamadanOfferLead';
import { useGetRamadanOffers } from '../../../listings/cars/ramadan_offers/hooks';
import { IRamadanOffer } from '../../../models/listings/cars/ramadan/ramadanOffer';
import useAuth from '../../../hooks/useAuth';
import { UserGroups } from '../../../common/constants';

type AddEditRamadanOfferLeadFormProps = {
  lead: RamadanOfferLeadsDetail | null;
  onSubmit: (formData: any) => void;
  isLoading: boolean;
  onClose: () => void;
  isEditing: boolean;
};

const AddEditRamadanOfferLeadForm = ({
  lead,
  onSubmit,
  isLoading,
  onClose,
  isEditing,
}: AddEditRamadanOfferLeadFormProps) => {
  const { userInfo } = useAuth();
  const [form] = Form.useForm();
  const { Item } = Form;
  const { TextArea } = Input;
  const dealerId = Form.useWatch('dealer_id', form);
  const ramadanOfferId = Form.useWatch('ramadan_offer_id', form);

  const isUserOAC = userInfo?.group === UserGroups.OAC;

  const { data: dealers, isLoading: isLoadingDealers } = useGetAllDealers({
    enabled: true,
    type: 'new',
  });

  const { data: ramadanOffers, isLoading: isLoadingRamadanOffers } =
    useGetRamadanOffers({
      dealerId: isUserOAC ? dealerId : userInfo?.dealer_id,
      waitForDealerId: true,
    });

  const initialValues = lead
    ? {
        name: lead.name,
        email: lead.email,
        mobile_number: lead.mobile_number.slice(4),
        source: lead.source,
        contacted: lead.contacted,
        notes: lead.notes,
      }
    : undefined;

  useEffect(() => {
    form.resetFields();
  }, [lead, form]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
        requiredMark={CustomizedRequiredMark}
      >
        {!isEditing && (
          <Row gutter={24} wrap>
            {isUserOAC && (
              <Col span={8}>
                <Item
                  label="Dealer"
                  name="dealer_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the dealer of the car',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select a dealer"
                    optionFilterProp="children"
                    loading={isLoadingDealers}
                    disabled={isLoadingDealers}
                    onChange={() => {
                      form.setFieldsValue({
                        brand_id: undefined,
                        model_id: undefined,
                      });
                    }}
                  >
                    {dealers?.map((dealerName: any) => (
                      <Select.Option key={dealerName.id} value={dealerName.id}>
                        {dealerName.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            )}
            <Col span={isUserOAC ? 8 : 12}>
              <Item
                label="Ramadan Offer"
                name="ramadan_offer_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select the ramadan offer',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a ramadan offer"
                  optionFilterProp="children"
                  loading={isLoadingRamadanOffers}
                  disabled={(isUserOAC && !dealerId) || isLoadingRamadanOffers}
                  onChange={() => {
                    form.setFieldsValue({
                      model_id: undefined,
                    });
                  }}
                >
                  {ramadanOffers?.map((offer: IRamadanOffer) => (
                    <Select.Option key={offer.id} value={offer.id}>
                      {offer.title}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={isUserOAC ? 8 : 12}>
              <Item
                label="Model"
                name="model_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select the model',
                  },
                ]}
                dependencies={['brand']}
              >
                <Select
                  showSearch
                  placeholder="Select a model"
                  optionFilterProp="children"
                  disabled={!ramadanOfferId}
                >
                  {ramadanOffers
                    ?.find(
                      (offer: IRamadanOffer) => offer.id === ramadanOfferId,
                    )
                    ?.models.map((model: ICarModel) => {
                      return (
                        <Select.Option key={model.id} value={model.id}>
                          {model.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Item>
            </Col>
          </Row>
        )}

        <Row gutter={24} wrap>
          <Col span={8}>
            <Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter a name' }]}
            >
              <Input placeholder="English Name" />
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Email" name="email">
              <Input placeholder="Email" />
            </Item>
          </Col>

          <Col span={8}>
            <Item
              label="Mobile Number"
              name="mobile_number"
              rules={[
                { required: true, message: 'Please enter a mobile number' },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Mobile Number"
                addonBefore="+974"
                minLength={3}
                min={0}
                controls={false}
              />
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={12}>
            <Item
              label="Source"
              name="source"
              rules={[{ required: true, message: 'Please enter the source' }]}
            >
              <Input placeholder="Source" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Status"
              name="contacted"
              rules={[
                {
                  required: true,
                  message: 'Select a status',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a contact status"
                optionFilterProp="children"
              >
                {leadContactStatuses
                  .filter(status => status.value !== '')
                  .map((status: any) => (
                    <Select.Option key={status.value} value={status.value}>
                      {status.text}
                    </Select.Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={24}>
            <Item label="Notes" name="notes">
              <TextArea placeholder="Notes" autoSize={{ minRows: 3 }} />
            </Item>
          </Col>
        </Row>

        <Row
          style={{
            paddingTop: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Space>
            <Button danger onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isEditing ? 'Edit' : 'Create'}
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};

export default AddEditRamadanOfferLeadForm;
