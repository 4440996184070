import { Image } from 'antd';
import LoginForm from './LoginForm';

const LoginPageLayout = () => {
  return (
    <div
      className="w-full flex justify-content-center align-items-center"
      style={{ backgroundColor: '#000000', height: '100dvh' }}
    >
      <div
        className="margin-auto flex border-round-md shadow-md m-5"
        style={{ backgroundColor: '#fff', width: '1000px', overflow: 'hidden' }}
      >
        <div
          className="flex justify-content-center align-items-center"
          style={{ backgroundColor: '#ccc', width: '400px' }}
        >
          <Image
            src=" /images/login-form-logo.png"
            alt="OAC-Logo"
            preview={false}
            draggable={false}
            style={{
              padding: '20px',
            }}
          />
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPageLayout;
