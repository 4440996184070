import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import DataTableHeader from './DataTableHeader';
import api from '../../api/apiIndex';
import { Column } from 'primereact/column';
import { exportBankingReport, getBankingInfo } from '../overviewApi';
import { BankingLead } from '../../models/overview/BankingLead';
import {
  dashSeparatedDateFormat,
  downloadFile,
} from '../../helpers/helperFunctions';
import { ToastSeverity } from '../../components/Toast';

const BankingTable = () => {
  const [inquiries, setInquiries] = useState<Array<BankingLead>>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeDateRange, setActiveDateRange] = useState<Array<any>>();

  const columns = [
    { field: 'name', header: 'Bank Name' },
    { field: 'leads', header: 'Leads' },
    { field: 'impressions', header: 'Impressions' },
    { field: 'clicks', header: 'Clicks' },
  ];

  const fetchAndSetData = async () => {
    setIsLoading(true);
    try {
      const response = await getBankingInfo();
      setInquiries(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportData = async () => {
    setIsExporting(true);
    try {
      const response = await exportBankingReport(
        dashSeparatedDateFormat(activeDateRange?.[0] || null),
        dashSeparatedDateFormat(activeDateRange?.[1]) || null,
      );
      downloadFile(response.data, 'Banking_Report');
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsExporting(false);
    }
  };

  const reloadData = () => {
    setActiveDateRange(pre => []);
    fetchAndSetData();
  };

  useEffect(() => {
    fetchAndSetData();
  }, []);

  return (
    <DataTable
      style={{ minHeight: '400px' }}
      value={inquiries}
      loading={isLoading}
      scrollable={true}
      scrollHeight="500px"
      header={
        <DataTableHeader
          title="Banking"
          hasExport
          isExporting={isExporting}
          onExport={exportData}
          activeDateRange={activeDateRange}
          setActiveDateRange={setActiveDateRange}
          onReload={reloadData}
        />
      }
      tableStyle={{ minWidth: '50rem' }}
    >
      {columns.map((col, index) => (
        <Column key={index} field={col.field} header={col.header} />
      ))}
    </DataTable>
  );
};

export default BankingTable;
