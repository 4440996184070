import BrandsLayout from '../../../resources/cars/car_brands/components/BrandsLayout';
import { RouteType } from '../../routes/routesConfig';

const Brands = () => {
  return <BrandsLayout />;
};

const brandsRoute: RouteType = {
  element: <Brands />,
  state: 'brands',
  index: true,
  path: '/resources/cars/brands',
  sideBarProps: {
    displayText: 'Brands',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Brands, brandsRoute };
