import { IBikeCylinders } from '../../../../models/resources/bikes/BikeCylinders';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { useCreateBikeCylinder, useUpdateBikeCylinder } from '../hooks';
import { Modal, message } from 'antd';
import { IBikeCylindersPayload } from '../apis';
import { AddEditBikeCylindersFrom } from './AddEditBikeCylindersFrom';

interface IBikeCylindersModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentBikeCylinder: IBikeCylinders | null;
  setCurrentBikeCylinder: (value: IBikeCylinders | null) => void;
  bikeEngineTypes: IBikeEngineType[];
}

export const BikeCylindersModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBikeCylinder,
  setCurrentBikeCylinder,
  bikeEngineTypes,
}: IBikeCylindersModalProps) => {
  const { mutateAsync: createBikeCylinder, isLoading: isCreating } =
    useCreateBikeCylinder();
  const { mutateAsync: updateBikeCylinder, isLoading: isUpdating } =
    useUpdateBikeCylinder();

  const _create = async (data: IBikeCylindersPayload) => {
    if (data == null) return;
    const response = await createBikeCylinder(data);
    message.success('Cylinder created successfully');
    setIsFormOpen(false);
    setCurrentBikeCylinder(null);
    return response;
  };

  const _edit = async (data: IBikeCylindersPayload) => {
    if (data == null) return;
    const response = await updateBikeCylinder({
      id: currentBikeCylinder!.id,
      data,
    });
    message.success('Cylinder updated successfully');
    setIsFormOpen(false);
    setCurrentBikeCylinder(null);
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Cylinder' : 'Add Cylinder'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBikeCylinder?.id}
        destroyOnClose
      >
        <AddEditBikeCylindersFrom
          bikeEngineTypes={bikeEngineTypes}
          bikeCyliners={currentBikeCylinder}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
        />
      </Modal>
    </>
  );
};
