import { IFilters } from '../listings/cars/used_cars/carsApi';

export enum LeadContacted {
  NOT_CONTACTED = 'NOT_CONTACTED',
  NO_ANSWER = 'NO_ANSWER',
  CONTACTED = 'CONTACTED',
  FOLLOW_UP = 'FOLLOW_UP',
  NEGOTIATING = 'NEGOTIATING',
  PURCHASED = 'PURCHASED',
  NOT_INTERESTED = 'NOT_INTERESTED',
}

export const leadContactStatuses: IFilters[] = [
  { text: 'All', value: '', isDefault: true },
  { text: 'Not Contacted', value: LeadContacted.NOT_CONTACTED },
  { text: 'No Answer', value: LeadContacted.NO_ANSWER },
  { text: 'Contacted', value: LeadContacted.CONTACTED },
  { text: 'Follow Up', value: LeadContacted.FOLLOW_UP },
  { text: 'Negotiating', value: LeadContacted.NEGOTIATING },
  { text: 'Purchased', value: LeadContacted.PURCHASED },
  { text: 'Not Interested', value: LeadContacted.NOT_INTERESTED },
];
