import { UserGroups } from '../../../common/constants';
import { UsedCarLeadsLayout } from '../../../leads/used_cars/components/UsedCarLeadsLayout';

const UsedCarLeads = () => {
  return <UsedCarLeadsLayout />;
};

const usedCarLeadsRoute = {
  path: '/leads/used-cars',
  index: true,
  element: <UsedCarLeads />,
  state: 'usedCarLeads',
  sideBarProps: {
    displayText: 'Used Cars',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { UsedCarLeads, usedCarLeadsRoute };
