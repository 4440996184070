import api from '../../../../api/apiIndex';
import { IRamadanOffer } from '../../../../models/listings/cars/ramadan/ramadanOffer';

const ramadanOffersEndpoint = '/v2/admin/ramadan-offers';

export interface IRamadanOfferPayload {
  title: string;
  arabic_title: string;
  description: string;
  arabic_description: string;
  dealer_id: number;
  brand_id: number;
  model_ids: number[];
  benefits: string[];
  arabic_benefits: string[];
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
}

export const getRamadanOffers = async (
  dealerId?: number,
): Promise<IRamadanOffer[]> => {
  try {
    const response = await api.get(ramadanOffersEndpoint, {
      params: { dealer_id: dealerId },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const activateRamadanOffer = async (id: number) => {
  try {
    const response = await api.put(`${ramadanOffersEndpoint}/activate/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateRamadanOffer = async (id: number) => {
  try {
    const response = await api.put(`${ramadanOffersEndpoint}/deactivate/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteRamadanOffer = async (id: number) => {
  try {
    const response = await api.delete(`${ramadanOffersEndpoint}/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createRamadanOffer = async (request: IRamadanOfferPayload) => {
  try {
    const response = await api.post(ramadanOffersEndpoint, request);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateRamadanOffer = async (
  id: number,
  data: IRamadanOfferPayload,
) => {
  try {
    const response = await api.put(`${ramadanOffersEndpoint}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
