import { UserGroups } from '../../../../common/constants';
import BikeBrandsLayout from '../../../../resources/bikes/bike_brands/components/BikeBrandsLayout';
import { RouteType } from '../../../routes/routesConfig';

const BikeBrands = () => {
  return <BikeBrandsLayout />;
};

const bikeBrandsRoute: RouteType = {
  element: <BikeBrands />,
  state: 'brands',
  index: true,
  path: '/resources/bikes/brands',
  sideBarProps: {
    displayText: 'Brands',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeBrands, bikeBrandsRoute };
