import { Button, Image, Input, List, Space, Spin, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSearch } from '../../../../hooks/useSearch';
import { useNavigate } from 'react-router-dom';
import { BikeBrand } from '../../../../models/resources/bikes/BikeBrand';
import { Layout } from '../../../../components/Layout';
import { EmptyState } from '../../../../components/emptyState/EmptyState';
import fallBackLogo from '../../../../../src/assets/fallback.webp';
import { useGetNewBikeBrandsWithModelsCount } from '../hooks';
import { Flex, FlexCentered, FlexColumn } from '../../../../components/Flex';
import { TabsCountrySwitcher } from '../../../../components/TabsCountrySwitcher';

const { Search } = Input;

export const NewBike = () => {
  const [country, setCountry] = useState('qa');
  const { data: bikeBrands, isLoading: isLoadingBikeBrands } =
    useGetNewBikeBrandsWithModelsCount(country);

  const [brandsSearchValue, setBrandsSearchValue] = useState<string>('');
  const { handleSearchNewBikesBrand } = useSearch();

  const navigate = useNavigate();

  const [brandFilteredData, setBrandFilteredData] = useState<
    BikeBrand[] | undefined
  >();

  useEffect(() => {
    if (!bikeBrands) return;
    const newBrandData = handleSearchNewBikesBrand(
      bikeBrands,
      brandsSearchValue,
    );
    setBrandFilteredData(newBrandData);
  }, [bikeBrands, brandsSearchValue, country]);

  return (
    <Layout>
      <div
        className="flex justify-content-between w-100 mb-5 align-items-center"
        style={{ width: '50%' }}
      >
        <Search
          placeholder="Search New Bikes Brands"
          style={{ width: 200 }}
          onChange={e => setBrandsSearchValue(e.target.value)}
        />
        <TabsCountrySwitcher
          setCountry={setCountry}
        />
      </div>
      {isLoadingBikeBrands ? (
        <div
          className="w-full p-5 flex justify-content-center align-items-center"
          style={{ height: '400px' }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {bikeBrands && bikeBrands?.length > 0 ? (
            <List
              style={{
                height: '100%',
                overflow: 'auto',
                padding: '0 1rem',
                paddingTop: '2rem',
              }}
              size="small"
              grid={{
                gutter: 16,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 3,
                xxl: 4,
              }}
              dataSource={brandFilteredData || bikeBrands}
              renderItem={(brand: BikeBrand) => (
                <List.Item key={brand.id}>
                  <List.Item.Meta key={brand.id} />
                  <FlexCentered
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '2rem',
                    }}
                  >
                    <Flex style={{ alignItems: 'center' }}>
                      <Space size="large">
                        <Image
                          src={brand.original_logo}
                          width={120}
                          preview={false}
                          style={{
                            objectFit: 'contain',
                            maxHeight: '80px',
                          }}
                          fallback={fallBackLogo}
                        />
                        <FlexColumn style={{ alignItems: 'flex-start' }}>
                          <Button
                            type="link"
                            style={{
                              padding: 0,
                            }}
                            onClick={() => {
                              navigate(
                                `/listings/bikes/new-bikes/${brand.id}`,
                                {
                                  state: { country: country },
                                },
                              );
                            }}
                          >
                            {brand.name}
                          </Button>
                          <Tag
                            color={brand.dealsCount === 0 ? 'red' : 'green'}
                            className="m-0"
                          >
                            <Typography.Text
                              type="secondary"
                              className="mb-0"
                              style={{ fontSize: '0.8rem' }}
                            >
                              {brand.dealsCount} Deals
                            </Typography.Text>
                          </Tag>
                        </FlexColumn>
                      </Space>
                    </Flex>
                  </FlexCentered>
                </List.Item>
              )}
            />
          ) : (
            <EmptyState type="new bikes" />
          )}
        </>
      )}
    </Layout>
  );
};
