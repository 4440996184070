import { IBikeColors } from '../../../../models/resources/bikes/BikeColors';
import { useCreateBikeColor, useUpdateBikeColor } from '../hooks';
import { IBikeColorPayload } from '../apis';
import { Modal, message } from 'antd';
import { AddEditBikeColorsForm } from './AddEditBikeColorsForm';

interface IBikeColorsModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentBikeColor: IBikeColors | null;
  setCurrentBikeColor: (value: IBikeColors | null) => void;
}

export const BikeColorsModal = ({
  currentBikeColor,
  isEditing,
  isFormOpen,
  setCurrentBikeColor,
  setIsFormOpen,
}: IBikeColorsModalProps) => {
  const { mutateAsync: createBikeColor, isLoading: isCreating } =
    useCreateBikeColor();
  const { mutateAsync: updateBikeColor, isLoading: isUpdating } =
    useUpdateBikeColor();

  const _create = async (data: IBikeColorPayload) => {
    if (data == null) return;
    const response = await createBikeColor(data);
    message.success('Color created successfully');
    setIsFormOpen(false);
    setCurrentBikeColor(null);
    return response;
  };

  const _edit = async (data: IBikeColorPayload) => {
    if (data == null) return;
    const response = await updateBikeColor({
      id: currentBikeColor!.id,
      data,
    });
    message.success('Color updated successfully');
    setIsFormOpen(false);
    setCurrentBikeColor(null);
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Color' : 'Add Color'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBikeColor?.id}
        destroyOnClose
      >
        <AddEditBikeColorsForm
          bikeColors={currentBikeColor}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
          key={currentBikeColor?.id}
        />
      </Modal>
    </>
  );
};
