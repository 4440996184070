import AllDealersLayout from '../../../dealers/Automotive/components/AllDealersLayout';

const AllDealers = () => {
  return <AllDealersLayout />;
};

const autoDealers = {
  path: '/dealers/auto',
  index: true,
  element: <AllDealers />,
  state: 'dealers',
  sideBarProps: {
    displayText: 'Automotive',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR', 'Automotive'],
};

export { AllDealers, autoDealers };
