import CarConditionsLayout from '../../../resources/cars/car_condition/components/CarConditionsLayout';
import { RouteType } from '../../routes/routesConfig';

const CarCondition = () => {
  return <CarConditionsLayout />;
};

const carConditionRoute: RouteType = {
  element: <CarCondition />,
  state: 'car-condition',
  index: true,
  path: '/resources/cars/car-condition',
  sideBarProps: {
    displayText: 'Car Condition',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { CarCondition, carConditionRoute };
