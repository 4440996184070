import { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api, { getNewToken } from './apiIndex';
import useAuth from '../hooks/useAuth';
import User from '../models/User';
import { notification } from 'antd';
import { isArray } from 'lodash';
import { queryClient } from '../App';
import { deleteApiTokens } from '../authentication/apis/authenticationApi';

export const ApiInterceptor = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserInfo } = useAuth();
  const location = useLocation();

  const interceptorId = useRef<number | null>(null);

  useEffect(() => {
    interceptorId.current = api.interceptors.response.use(
      response => response,
      async error => {
        if (error.response && error.response.status === 401) {
          // Token expired, refresh it and retry the request
          try {
            const newAccessToken = await refreshAccessToken();
            // Retry the original request with the new access token
            error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            const userDataFromToken: User = JSON.parse(
              atob(newAccessToken.split('.')[1]),
            );
            localStorage.setItem('accessToken', newAccessToken);

            setUserInfo(userDataFromToken);

            return api.request(error.config);
          } catch (refreshError) {
            // Refresh token has expired. User must authenticate
            notification.error({
              message: 'Session expired',
              description: 'Please login again',
            });
            deleteApiTokens();
            queryClient.removeQueries();
            setIsAuthenticated(false);
            setUserInfo(null);
            navigate('/login');
          }
        } else if (error.response && error.response.status === 403) {
          notification.error({
            message: 'Error',
            description: 'You are not authorized to access this resource',
          });
        } else if (error.response && error.response.status === 404) {
          notification.error({
            message: 'Error',
            description: 'Resource not found',
          });
        } else if (error.response && error.response.status === 500) {
          notification.error({
            message: 'Error',
            description: 'Internal server error',
          });
        } else if (error.response && error.response.status === 400) {
          if (location.pathname !== '/login') {
            const message = error.response?.data?.message;
            notification.error({
              message: 'Error',
              description: isArray(message) ? (
                <>
                  {
                    message.map((item: string, index: number) => (
                      <>
                        <p key={index}>
                          {item.charAt(0).toUpperCase() + item.slice(1)}.
                        </p>
                      </>
                    )) as any
                  }
                </>
              ) : (
                message
              ),
            });
          }
        } else {
          notification.error({
            message: 'Error',
            description: 'Something went wrong',
          });
        }
        return Promise.reject(error);
      },
    );

    return () => {
      api.interceptors.response.eject(interceptorId.current as number);
    };
  }, []);

  return null;
};

// Function to refresh the access token
export const refreshAccessToken = async () => {
  const currentRefreshToken =
    localStorage.getItem('refreshToken') ||
    sessionStorage.getItem('refreshToken');

  if (!currentRefreshToken) {
    throw new Error('No refresh token found');
  }

  // check expiry date
  const refreshTokenData = JSON.parse(atob(currentRefreshToken.split('.')[1]));
  const refreshTokenExpiryDate = refreshTokenData.exp * 1000;
  const currentTime = new Date().getTime();

  if (refreshTokenExpiryDate < currentTime) {
    throw new Error('Refresh token expired');
  }

  // Get a new access token
  const newAccessToken = await getNewToken(currentRefreshToken);

  // Set the new access token in the Axios instance
  api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

  return newAccessToken;
};
