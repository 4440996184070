import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Table, Button, Popconfirm, message, Tag } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  CAR_INTERIOR_COLOR_QUERY_KEY,
  useActivateCarInteriorColor,
  useCarInteriorColor,
  useDeactivateCarInteriorColor,
  useDeleteCarInteriorColor,
} from '../hooks/useCarInteriorColor';
import { useQueryClient } from '@tanstack/react-query';
import { CarInteriorColorModal } from './CarInteriorColorModal';
import { ICarInteriorColor } from '../utils/apiUtils';
import { Layout } from '../../../../components/Layout';
import ActiveIcon from '../../../../components/icons/ActiveIcon';
import InactiveIcon from '../../../../components/icons/InactiveIcon';

const CarInteriorColorsLayout = () => {
  const [open, setOpen] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCarInteriorColor, setSelectedCarInteriorColor] = useState<
    ICarInteriorColor | undefined
  >(undefined);

  const queryClient = useQueryClient();

  const { mutateAsync: deleteInteriorColor, isLoading: isDeleting } =
    useDeleteCarInteriorColor();
  const { mutateAsync: activateInteriorColor, isLoading: isActivating } =
    useActivateCarInteriorColor();
  const { mutateAsync: deactivateInteriorColor, isLoading: isDeactivating } =
    useDeactivateCarInteriorColor();
  const { data: carInteriorColors, isLoading: isLoadingCarInteriorColors } =
    useCarInteriorColor({ enabled: true });

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarInteriorColor | null>(null);

  const handleDelete = (id: number) => {
    deleteInteriorColor(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: CAR_INTERIOR_COLOR_QUERY_KEY,
        });
        setSelectedCarInteriorColor(undefined);
        message.success('Interior color deleted successfully');
        setOpen(null);
      },
    });
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateInteriorColor(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Interior Color deactivated successfully');
          },
        })
      : activateInteriorColor(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Interior Color activated successfully');
          },
        });
  };

  const tableColumns: ColumnsType<ICarInteriorColor> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (rowData): string => {
        return rowData ? rowData?.toString() : 'N/A';
      },
    },
    {
      title: 'Arabic Name',
      dataIndex: `${carInteriorColors?.map(
        (carInteriorColor: ICarInteriorColor) => carInteriorColor?.t[0]?.name,
      )}`,
      key: `${carInteriorColors?.map(
        (carInteriorColor: ICarInteriorColor) => carInteriorColor?.t[0]?.name,
      )}`,
      render: (text: any, record: ICarInteriorColor) => {
        return <div>{record.t[0]?.name}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarInteriorColor) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              onClick={() => {
                setSelectedCarInteriorColor(rowData);
                handleOpenModal();
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Are you sure you want to delete this interior color?"
              open={open === rowData.id}
              onConfirm={() => {
                handleDelete(rowData.id);
              }}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpen(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                onClick={() => {
                  setOpen(rowData.id);
                  setSelectedCarInteriorColor(rowData);
                }}
              />
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this interior color.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={handleOpenModal}>
          Add
        </Button>
      </div>
      <Table
        dataSource={carInteriorColors}
        rowKey={rowData => rowData.id}
        scroll={{ x: 1300, y: 720 }}
        columns={tableColumns}
        pagination={false}
        loading={isLoadingCarInteriorColors}
      />
      <CarInteriorColorModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedCarInteriorColor={selectedCarInteriorColor}
        setSelectedCarInteriorColor={setSelectedCarInteriorColor}
      />
    </Layout>
  );
};

export default CarInteriorColorsLayout;
