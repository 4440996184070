import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getRentals,
  createRental,
  updateRental,
  deleteRental,
  IRentalPayload,
} from '../rentalsApi';
import { TUploadImage, uploadImage } from '../../../../common/utils';

interface IUseRentalCars {
  page: number;
  perPage?: number;
  searchQueryParams: Record<string, string>;
  country?: string;
}

export const useRentalCars = ({
  page,
  perPage,
  searchQueryParams,
  country,
}: IUseRentalCars) => {
  return useQuery({
    queryKey: ['rentalCars', page, perPage, searchQueryParams, country],
    queryFn: () =>
      getRentals({
        page,
        perPage,
        searchQueryParams,
        country,
      }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useCreateRental = () => {
  return useMutation({
    mutationFn: (rentalData: IRentalPayload) => createRental(rentalData),
  });
};

export const useUpdateRental = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IRentalPayload }) =>
      updateRental({
        id,
        data,
      }),
    onError: (err: any) => {
      console.log(err);
    },
  });
};

export const useUploadRentalImage = () => {
  return useMutation({
    mutationFn: ({ file, path, onProgress, extraBodyData }: TUploadImage) =>
      uploadImage({ file, path, onProgress, extraBodyData }),
  });
};

export const useDeleteRental = () => {
  return useMutation({
    mutationFn: (id: number) => deleteRental(id),
  });
};
