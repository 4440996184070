import { Input, Spin } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useGetBikeModels } from '../../../../resources/bikes/bike_models/hooks';
import { useEffect, useState } from 'react';
import { useSearch } from '../../../../hooks/useSearch';
import { Layout } from '../../../../components/Layout';
import { IBikeModel } from '../../../../models/resources/bikes/BikeModel';
import ModelDisplayCard from '../../../../resources/common/components/ModelDisplayCard';
import { EmptyState } from '../../../../components/emptyState/EmptyState';

const { Search } = Input;

export const NewBikeBrand = () => {
  const { brandId } = useParams();
  const location = useLocation();
  const country = location.state.country;
  const { data: models, isLoading: isBikeModelsLoading } = useGetBikeModels({
    enabled: !!brandId && !!country,
    brandId: Number(brandId),
    country,
  });

  const [modelsSearchValue, setModelsSearchValue] = useState<string>('');
  const [modelFilteredData, setModelFilteredData] = useState(models);
  const { handleSearchNewBikesModel } = useSearch();

  useEffect(() => {
    const newBrandData = handleSearchNewBikesModel(models, modelsSearchValue);
    setModelFilteredData(newBrandData);
  }, [modelsSearchValue]);

  useEffect(() => {
    setModelFilteredData(models);
  }, [models]);

  return (
    <Layout>
      <div className="flex justify-content-between w-full mt-2 mb-5">
        <Search
          placeholder="Search Here"
          style={{ width: 200 }}
          onChange={e => setModelsSearchValue(e.target.value)}
        />
      </div>
      {isBikeModelsLoading ? (
        <div
          className="w-full p-5 flex justify-content-center align-items-center"
          style={{ height: '400px' }}
        >
          <Spin />
        </div>
      ) : (
        <div className="flex flex-wrap gap-5 ml-6">
          {modelFilteredData?.length > 0 ? (
            modelFilteredData.map((model: IBikeModel) => (
              <Link
                key={model?.id}
                className="link"
                to={{
                  pathname: `/listings/bikes/new-bikes/${model.brand?.id}/${model.slug}`,
                  search: `?country=${country}`,
                }}
              >
                <ModelDisplayCard
                  coverImageUrl={model?.cover}
                  brandLogoUrl={model?.brand?.original_logo}
                  brandName={model?.brand?.name}
                  modelName={model?.name}
                  dealsInCountry={
                    model?.new_bikes?.[0]?.cities?.countries?.code === country
                      ? 'Has Deals'
                      : ''
                  }
                />
              </Link>
            ))
          ) : (
            <EmptyState type="models" />
          )}
        </div>
      )}
    </Layout>
  );
};
